import React, { } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
// import Input from '@material-ui/core/Input'
import MapIcon from '@material-ui/icons/Map';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';


import useStyles from "./styles";

function CategoryFilter(props) {

    let allChecked = props.options.map((item, index) => {
        // let childarr = [];
        let childarr = item.children.map((child, childIndex) => {
            return childIndex;
        })
        return childarr;
    })

    const classes = useStyles();
    const [checked, setChecked] = React.useState(allChecked);
    const [checkedParent, setCheckedParent] = React.useState(0);

    // const [filteredList, setfilteredList] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);

    // const [selectedLabel, setSelectedLabel] = React.useState('All ' + props.description);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // setfilteredList(fL);

        // let selectelabel = '';
        // if (checked.length == 0) {
        //     selectelabel = 'NONE'
        // } else if (checked.length == props.options.length) {
        //     selectelabel = 'All ' + props.description;
        // } else if (checked.length > 0) {
        //     selectelabel = props.options[checked[0]].label;
        //     if (checked.length > 1) {
        //         selectelabel += ', +' + (checked.length - 1) + ' ' + props.description;
        //     }
        // }
        // setSelectedLabel(selectelabel);
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        // const currentIndex = checked.indexOf(index);
        // const newChecked = [...checked];

        // if (newChecked[index].length == value.children.length) {
        //     newChecked[index] = [];
        // } else {
        //     newChecked[index] = value.children.map((child, childIndex) => {
        //         return childIndex;
        //     })
        // }
        // console.log('newChecked1', newChecked);
        // setChecked(newChecked);
        setCheckedParent(index)

    };

    const handleChildToggle = (childIndex, index) => {
        const currentIndex = checked[index].indexOf(childIndex);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked[index].push(childIndex);
        } else {
            newChecked[index].splice(currentIndex, 1);
        }

        // console.log('newChecked2', newChecked);

        setChecked(newChecked);
    };

    const handleToggleAll = (item, index) => {
        const newChecked = [...checked];
        if (checked[index].length === item.children.length) {
            newChecked[index] = [];
        } else {
            newChecked[index] = item.children.map((c, i) => i);
        }
        setChecked(newChecked);
    }


    // useEffect(() => {
    //     setfilteredList(fL);
    // }, [])

    // let fL = props.options.map((item, index) => {
    //     // return {
    //     //     item,
    //     //     index
    //     // }
    //     return item;
    // })



    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            {/* <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                <div className={classes.label} title={selectedLabel}>
                    <div className={classes.labelText}>{props.label}</div>
                    <div className={classes.value}>{selectedLabel}</div>  
                </div>
                {(!open)? <ArrowDropDownIcon/>  : <ArrowDropUpIcon/>}
            </div> */}
            <Button aria-describedby={id} variant="contained" color="default" className={classes.layerbtn + ' ' + props.className} onClick={handleClick}>
                <MapIcon style={{ marginRight: '12px' }}></MapIcon> {props.label} {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ marginTop: '10px' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {/* {(props.search) ? (<Input placeholder="Search" className={classes.srch} onChange={(event) => {
                    let tFL = fL.filter(item => {
                        return (item.item.label.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1) ? true : false;
                    })
                    setfilteredList(tFL);
                }} />) : null} */}
                <List className={classes.list}>
                    {props.options.map((item, index) => {
                        const labelId = `checkbox-list-label-${index}`;

                        // let children = item.children.map((child,childIndex)=>{

                        //     const childlabelId = `checkbox-list-label-${index}-${childIndex}`;
                        //     return (
                        //         <ListItem key={index+'-'+childIndex} role={undefined} dense button onClick={handleChildToggle(child, childIndex, index)}>
                        //             <ListItemIcon>
                        //              <Checkbox
                        //                  edge="start"
                        //                  checked={checked.indexOf(index) !== -1}
                        //                  tabIndex={-1}
                        //                  disableRipple
                        //                  inputProps={{ 'aria-labelledby': childlabelId }}
                        //                  color="primary"
                        //              />
                        //             </ListItemIcon>
                        //             <ListItemText id={childlabelId} primary={`${child.label}`} className={classes.litxt} /> 
                        //         </ListItem>
                        //     )
                        // });
                        // console.log('checked', checked);
                        // console.log(`indexes  ${checked[index].length} ${item.children.length}`);
                        return (
                            <div key={index}>
                                <ListItem key={index} role={undefined} className={classes.liparent} dense button>
                                    <ListItemIcon onClick={handleToggle(item, index)}>
                                        <Radio
                                            edge="start"
                                            checked={index === checkedParent}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                    <ListItemText onClick={handleToggle(item, index)} id={labelId} primary={`${item.label}`} className={classes.litxt} />
                                    <ListItemIcon onClick={() => handleToggleAll(item, index)}>
                                        <Tooltip title={checked[index].length === item.children.length?'Uncheck All':'Check All'} placement="right">
                                            <Checkbox
                                                edge="start"
                                                checked={checked[index].length === item.children.length}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId + '-all' }}
                                                color="primary"
                                            />
                                        </Tooltip>
                                    </ListItemIcon>
                                </ListItem>
                                <List className={classes.childlist}>
                                    {item.children.map((child, childIndex) => {
                                        return (
                                            <ListItem key={childIndex} className={classes.nested} role={undefined} dense button onClick={() => handleChildToggle(childIndex, index)} disabled={index !== checkedParent}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked[index].indexOf(childIndex) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId + '-' + childIndex }}
                                                        color="primary"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId + '-' + childIndex} primary={`${child.label}`} className={classes.litxt} />
                                                <ListItemIcon>
                                                    <div style={{ display: 'block', backgroundColor: child.color, width: 20, height: 20 }}></div>
                                                </ListItemIcon>
                                            </ListItem>)
                                    })}
                                </List>
                                {/* {item.children && children} */}
                                {/* {(index < (props.options.length - 1)) && <Divider></Divider>} */}
                                <Divider></Divider>
                            </div>
                        );
                    })}
                </List>
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="default" className={classes.applybtn} onClick={() => {
                        let filterSelected = props.options.map((item, index) => {
                            // let childarr = [];
                            let childarr = item.children.map((child, childIndex) => {
                                return { ...child, checked: checked[index].indexOf(childIndex) !== -1 };
                            })
                            return { ...item, children: childarr, checked: (index === checkedParent) }
                        })
                        props.onChange(filterSelected);
                        setAnchorEl(null);
                    }}>
                        APPLY
                </Button>
                </div>

            </Popover>
        </div>
    )
}

export default CategoryFilter
