import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import moment from 'moment'
import {
  SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar,
  Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryFull
} from "@material-ui/icons";

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

// function createData(
//   equipmentId,
//   equipmentType,
//   contract,
//   locationsCovered,
//   areaCoveredInHa,
//   areaCoveredInPercentage
// ) {
//   return {
//     equipmentId,
//     equipmentType,
//     contract,
//     locationsCovered,
//     areaCoveredInHa,
//     areaCoveredInPercentage,
//   };
// }

// const rows = [
//   createData(
//     "ticklishOstritch764",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
//   createData(
//     "asdf",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
//   createData(
//     "qwer",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
//   createData(
//     "rtrtjr",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
//   createData(
//     "fhfghf",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
//   createData(
//     "dsfsd",
//     "KnapSack",
//     "Gregory Alexander",
//     "2235 Avondale ave",
//     7.75,
//     76.95
//   ),
// ];

let deviceTypes = ["Lawn Mower", "Tractor", "KnapSack"]
let contractors = ["Tai Sun", "Edwards"]

const STATUS = {
  ColdStart: 1,
  WarmStart: 2,
  UserShutDown: 4,
  AutoShutDown: 8,
  CalDone: 16,
  HSenseErr: 256,
  NoGPS: 512,
  NoRTK: 1024,
  Reserved32: 32,
  Reserved64: 64,
  Reserved128: 128
};

export default function ActiveDevicesTable(props) {
  // const classes = useStyles();
  const [devices, setdevices] = useState([])
  // const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let devicesArr = props.devices.map((item, index) => {

      let status_input = item.status;
      var status_output = []
      if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
      if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
      if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
      if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
      if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
      if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
      if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
      if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
      if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
      if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
      if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
      

      return {
        equipmentId: item.device_id,
        equipmentAliasName: item.alias_name,
        equipmentType: deviceTypes[Math.floor(Math.random() * Math.floor(3))],
        contract: contractors[Math.floor(Math.random() * Math.floor(2))],
        locationsCovered: item.location,
        areaCoveredInHa: Math.floor(Math.random() * Math.floor(5)) + 1,
        areaCoveredInPercentage: Math.floor(Math.random() * Math.floor(100)) + 1,
        status: item.status,
        fullStatus: status_output.join(', '),
        calibrated_height : (item.calibrated_height) ? parseFloat(item.calibrated_height/10) : '-',
        rssi: item.rssi,
        battery_level: item.battery_level,
        // lastSeen: moment(parseInt(item.last_seen)*1000).fromNow(),
        lastseenUnFormatted: item.last_seen
      };
    })
    setdevices(devicesArr);

  }, [props.devices])
  /*return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>EQUIPMENT ID</TableCell>
            <TableCell align="left">EQUIPMENT TYPE</TableCell>
            <TableCell align="left">CONTRACT</TableCell>
            <TableCell align="left">LOCATIONS COVERED</TableCell>
            <TableCell align="right">AREA COVERED (IN HA)</TableCell>
            <TableCell align="right">AREA COVERED (in %age)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.equipmentId}
              </TableCell>
              <TableCell align="left">{row.equipmentType}</TableCell>
              <TableCell align=I"left">{row.contract}</TableCell>
              <TableCell align="left">{row.locationsCovered}</TableCell>
              <TableCell align="right">{row.areaCoveredInHa}</TableCell>
              <TableCell align="right">{row.areaCoveredInPercentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );*/
  return (
    <div style={{ maxWidth: "100%" }} className="btmtable">
      <MaterialTable
        title=""
        columns={[
          {
            title: "EQUIPMENT ID",
            field: "equipmentId",
          },
          {
            title: "DEVICE NAME",
            field: "equipmentAliasName",
          },
          // {
          //   title: "DEVICE TYPE",
          //   field: "equipmentType",
          // },
          // {
          //   title: "CONTRACT",
          //   field: "contract",
          // },
          {
            title: "LOCATIONS",
            field: "locationsCovered",
          },
          {
            title: "Calibrated Height (in cm)",
            field: "calibrated_height",
          },
          {
            title: "STATUS",
            field: "fullStatus",
            // render: rowData => {
            //   let status_input = rowData.status;
            //   var status_output = []
            //   if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
            //   if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
            //   if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
            //   if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
            //   if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
            //   if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
            //   if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
            //   if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
            //   if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
            //   if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
            //   if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
            //   return status_output.join(', ');
            // }
          },
          {
            title: "RSSI",
            field: "rssi",
            render: rowData => {
              let deviceRssiVal = rowData.rssi;
              return (<>
                {/* <div>{deviceRssiVal}</div> */}
                {deviceRssiVal && deviceRssiVal >= 0 && deviceRssiVal <= 25 && <SignalCellular1Bar />}
                {deviceRssiVal && deviceRssiVal > 25 && deviceRssiVal <= 50 && <SignalCellular2Bar />}
                {deviceRssiVal && deviceRssiVal > 50 && deviceRssiVal <= 75 && <SignalCellular3Bar />}
                {deviceRssiVal && deviceRssiVal > 75 && deviceRssiVal <= 100 && <SignalCellular4Bar />}
              </>)
            }
          },
          {
            title: "BATTERY",
            field: "battery_level",
            render: rowData => {
              let deviceBatteryVal = rowData.battery_level;
              return (<>
                {/* <div>{deviceBatteryVal}</div> */}
                {deviceBatteryVal >= 0 && deviceBatteryVal <= 20 && <Battery20 />}
                {deviceBatteryVal > 20 && deviceBatteryVal <= 30 && <Battery30 />}
                {deviceBatteryVal > 30 && deviceBatteryVal <= 50 && <Battery50 />}
                {deviceBatteryVal > 50 && deviceBatteryVal <= 60 && <Battery60 />}
                {deviceBatteryVal > 60 && deviceBatteryVal <= 80 && <Battery80 />}
                {deviceBatteryVal > 80 && deviceBatteryVal <= 90 && <Battery90 />}
                {deviceBatteryVal > 90 && deviceBatteryVal <= 100 && <BatteryFull />}
                {deviceBatteryVal > 100 && <BatteryFull />}
                <span style={{ position: 'relative', top: '-6px' }}>{deviceBatteryVal}%</span>
              </>)
            }
          },
          {
            title: "LAST SEEN",
            field: "lastseenUnFormatted",
            defaultSort: 'desc',
            //hidden : true
            render: rowData => moment(parseInt(rowData.lastseenUnFormatted) * 1000).fromNow() + ' (' + moment.unix(parseInt(rowData.lastseenUnFormatted)).format('DD-MMM, hh:mm a') + ')'

          },
          // {
          //   title: "AREA COVERED (IN HA)",
          //   field: "areaCoveredInHa",
          // },
          // {
          //   title: "AREA COVERED (IN %age)",
          //   field: "areaCoveredInPercentage",
          // },
        ]}
        data={devices}
        options={{
          filtering: false,
          grouping: true,
          thirdSortClick: false,
          headerStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            color: "#698D8D",
            backgroundColor: "#F1FCFC",
            padding: "4px 12px",
          },
          cellStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            fontWeight: "bold",
            padding: "4px 12px",
          },
          rowStyle: {
            height: "auto",
          },
          searchFieldStyle: {
            // color: 'red'
          },
          pageSize: 10,
        }}
        onRowClick={(evt, selectedRow) => {
          // let nF = selectedFeeds.push(selectedRow)
          history.push({
            pathname: `/app/devicesNew/${selectedRow.equipmentId}`,
            state: { detail: selectedRow }
          });
        }}
      ></MaterialTable>
    </div>
  );
}
