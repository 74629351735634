import React, { Component } from 'react';
// import {render} from 'react-dom';
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl';
// import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';
var map;
var draw;
const MAPBOX_TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w';
mapboxgl.accessToken = MAPBOX_TOKEN;

const INITIAL_VIEW_STATE = {
  longitude: 103.8146803,
  latitude: 1.4572969,
  zoom: 18,
  minZoom: 5,
  maxZoom: 22,
  pitch: 15,
  bearing: 0
};

const applyToArray = (func, array) => func.apply(Math, array)

export default class Polygonmap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 103.816890,
      lat: 1.314826,
      zoom: 14,
      id: 'location',
      viewState: INITIAL_VIEW_STATE,
      geoJson: null,
      mapStyleType: 'map',
      feature: null
    }
    this.drawPolygon = this.drawPolygon.bind(this);
    this.updateArea = this.updateArea.bind(this)

  }
  componentDidMount() {
    const { lat, lng, zoom } = this.state;

    map = new mapboxgl.Map({
      container: this.mapDiv,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });

    this.mapProps(map)
  }


  mapProps(map) {

    map.addControl(draw);
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());

    map.on('draw.create', this.updateArea);
    map.on('draw.delete', this.updateArea);
    map.on('draw.update', this.updateArea);
    const { data, locationData } = this.props;
    if(data && data.length > 0){
      let mapData = data;
      const scatterplot = new MapboxLayer({
        id: 'scatterplot-layer',
        type: ScatterplotLayer,
        data: mapData,
        pickable: true,
        radiusScale: 0.7,
        radiusMinPixels: 0.5,
        filled: true,
        getPosition: d => d.geometry,
        getFillColor: d => [1, 128, 1],
      })

      let pointsLong;
      let pointsLat;
      let geoJsonData = []
      if (data && data.length > 0) {
        pointsLong = data.map(point => point.geometry[0])
        pointsLat = data.map(point => point.geometry[1])
      }
      if (locationData && locationData.length > 0) {
        let modifiedJson = []
        locationData.forEach((loc, index) => {
          pointsLong.push(loc.properties.cornersLatLng[0][0], loc.properties.cornersLatLng[1][0])
          pointsLat.push(loc.properties.cornersLatLng[0][1], loc.properties.cornersLatLng[1][1])
          let polygons = []
          if (loc && loc.geometry && loc.geometry.coordinates && loc.geometry.coordinates.length > 0) {
            polygons = loc.geometry.coordinates.map(c => {
              let mItem = {
                ...loc,
                geometry: {
                  type: 'Polygon',
                  coordinates: [c]
                },
                parentIndex: index
              }
              return mItem;
            })
          }
          modifiedJson = modifiedJson.concat(polygons);
          //console.log(modifiedJson, 'json')
          geoJsonData = {
            "type": "FeatureCollection",
            features: modifiedJson
          }
        })
      }
      const geoLayer = new MapboxLayer({
        id: 'geojson-layer',
        type: GeoJsonLayer,
        data: geoJsonData,
        stroked: true,
        extruded: false,
        getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
        getFillColor: f => [171, 171, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
      })
      map.on('load', () => {
        map.addLayer(scatterplot);
        map.addLayer(geoLayer);
      })
      map.fitBounds([
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
      ], { padding: 100 })
    }
  }

  drawPolygon(points) {
    map.addLayer({
      'id': this.state.id,
      'type': 'fill',
      'source': {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': points
          }
        }
      },
      'layout': {},
      'paint': {
        'fill-color': '#088',
        'fill-opacity': 0.3
      }
    });
  }

  updateArea(e) {
    var data = draw.getAll();
    if (data.features.length > 0) {
      var area = turf.area(data);
      // restrict to area to 2 decimal points
      var rounded_area = Math.round(area * 100) / 100;
      //console.log(rounded_area, 'area')
    }
    this.props.onDataUpdate(data)
    this.props.areaData(rounded_area)
  }


  render() {

    return (
      <div>
        <div ref={e => this.mapDiv = e} style={{ height: 425 }}>
        </div>
      </div>
    )
  }
}