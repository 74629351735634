import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Button } from "@material-ui/core";
import { useDevicesState, getDeviceRules, updateDeviceRule } from "../../context/DevicesContext";
import MaterialTable from "material-table";
import TextField from '@material-ui/core/TextField';
// import InfoTooltip from "../../components/InfoTooltip"
import { useSnackbar } from 'notistack';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from "./styles";

function DeviceRules(props) {


    const { enqueueSnackbar } = useSnackbar();
    var classes = useStyles();

    const context = useDevicesState();
    const [rules, setrules] = useState(null)
    const [display, setdisplay] = useState('rulesList')
    const [ruleName, setruleName] = useState('')
    const [ruleType, setruleType] = useState('')
    const [batterPercentage, setbatterPercentage] = useState('')

    const onRuleEditSubmit = () => {
        // console.log('ruledtails', { ruleName, ruleType, batterPercentage })
        let deviceId = props.device.device_id
        let values = {
            key: deviceId.toLowerCase() + '-' + ruleName.toLowerCase().replace(/ /g, '-'),
            name: ruleName,
            operator: (ruleType==='status')?'EQ':'LT',
            device_id: deviceId,
            arg1: (ruleType==='status')?'False':batterPercentage,
            rule_type: ruleType,
        }

        // if(values.arg2){
        //     body['arg2'] = values.arg2;
        //   }
        //   if(values.start){
        //     body['disabled_start'] = values.drStart;
        //   }
        //   if(values.end){
        //     body['disabled_end'] = values.drEnd;
        //   }
        //   if(values.drTrigger){
        //     body['max_notify_times'] = values.drTrigger;
        //   }
        //   if(values.drDuration){
        //     body['repeated_duration'] = values.drDuration;
        //   }
        updateDeviceRule(context.setData, values)
    }

    const cancelEditRule = () => {
        setruleName('');
        setruleType('');
        setbatterPercentage('')
        setdisplay('rulesList');
    }

    useEffect(() => {
        if (props.device && props.device.device_id) {
            getDeviceRules(context.setData, props.device.device_id);
        }
    }, [context.setData, props.device]);

    useEffect(() => {
        // console.log('context devicerules', context.data.selectedDevice.rules)
        if (context.data.selectedDevice.rules) {
            setrules(context.data.selectedDevice.rules)
        }
    }, [context.data.selectedDevice.rules]);

    useEffect(() => {
        // console.log('onDeviceRuleUpdated')
        if (context.data.updateDeviceRuleError) {
            enqueueSnackbar('Device Rule update failed',{ variant: 'error' });
        }
        if (context.data.updateDeviceRule) {
            enqueueSnackbar('Device Rule updated successfully',{ variant: 'success' });
            // console.log('onDeviceRuleUpdated 1')
            context.setData({type:'CLEAR_DEVICE_RULE_DATA'})
            setdisplay('rulesList');
            getDeviceRules(context.setData, props.device.device_id);
        }
        // eslint-disable-next-line
    }, [context.setData,props.device, enqueueSnackbar, context.data.updateDeviceRule, context.data.updateDeviceRuleError]);

    return (
        <div>
            {display === 'rulesList' && rules && <MaterialTable
                title="Manage Device Rules"
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Type", field: "rule_type.name",
                    render: rowData => (rowData.rule_type.name === 'Status')?'Inactive Status':rowData.rule_type.name },
                    { title: "Arg1", field: "arg1",
                    render: rowData => (rowData.rule_type.name === 'Status')? rowData.arg1: `${rowData.arg1}%`}
                ]}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit Rule',
                        onClick: (event, rowData) => {
                            setruleName(rowData.name);
                            setruleType(rowData.rule_type.key)
                            setbatterPercentage(rowData.arg1)
                            setdisplay('editRuleForm');
                        }
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Rule',
                        onClick: (event, rowData) => alert("You selected " + rowData.name)
                    },
                    {
                        icon: 'add',
                        tooltip: 'Add Rule',
                        isFreeAction: true,
                        onClick: (event) => alert("You want to add a new row")
                    }
                ]}
                data={rules.results}
                options={{
                    // filtering: true,
                    // grouping: true,
                    headerStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        color: "#698D8D",
                        backgroundColor: "#F1FCFC",
                        padding: "4px 12px",
                    },
                    cellStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        fontWeight: "bold",
                        padding: "4px 12px",
                    },
                    rowStyle: {
                        height: "auto",
                    },
                    searchFieldStyle: {
                        // color: 'red'
                    },
                    pageSize: 10,
                    actionsColumnIndex: -1
                }}
            />}
            {display === 'editRuleForm' &&
                <div>
                    <form className={classes.editform} onSubmit={onRuleEditSubmit}>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth disabled required id="rule-name" value={ruleName} onChange={(e) => setruleName(e.target.value)} label="Name" className={classes.editformfield} />
                            {/* <InfoTooltip contentKey="FeedName" /> */}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="rule-type-label">Feed Type</InputLabel>
                                <Select
                                    labelId="rule-type-label"
                                    id="rule-type"
                                    value={ruleType}
                                    onChange={(e) => setruleType(e.target.value)}
                                >
                                    <MenuItem value="" disabled>Select Rule Type</MenuItem>
                                    <MenuItem value="status">Status - Inactive</MenuItem>
                                    <MenuItem value="battery">Battery</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <TextField fullWidth required id="rule-type" value={ruleType} onChange={(e) => setruleType(e.target.value)} label="Rule Type" className={classes.editformfield} /> */}
                            {/* <InfoTooltip contentKey="FeedCategory" /> */}
                        </div>
                        {ruleType==='battery' && <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="battery-percentage" type="number" value={batterPercentage} onChange={(e) => setbatterPercentage(e.target.value)} label="Battery Percentage" className={classes.editformfield} />
                            {/* <InfoTooltip contentKey="FeedName" /> */}
                        </div>}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="primary" size="small" style={{ width: '100%' }} type="submit"
                            disabled={!(ruleName && ruleType && (ruleType==='status' || (ruleType==='battery' && batterPercentage)))}
                            >Submit</Button></div>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="default" size="small" style={{ width: '100%' }} onClick={cancelEditRule}>Cancel</Button></div>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default DeviceRules
