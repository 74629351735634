import React from "react";
import axios from "axios";
// import {config} from "../config"
import moment from 'moment'
import { squareGrid, flatten, bbox, booleanContains, booleanOverlap, polygon, difference, intersect, pointsWithinPolygon, point, featureCollection, collect, bboxPolygon, buffer, dissolve, combine, area } from '@turf/turf';

const BaseMapContext = React.createContext();


const rootReducer = (state, action) => {
    switch (action.type) {
        case "GET_TODAY_LOCATION_PROPS":
            return {
                ...state,
                isLoading: false,
                todayLocationProps: action.payload
            }
        case "GET_DATA":
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                summary: action.payload,
                error: ''
            };
        case "GET_DATA_FILTERED":
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                filtered: action.payload,
                error: ''
            };
        case "GET_DATA_ERROR":
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                summary: null,
                filtered: null,
                error: action.payload
            };
        case "GET_LOCATIONS":
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                locations: action.payload.locations ? action.payload.locations : action.payload,
                metrics: action.payload.metrics ? action.payload.metrics : null,
                locationsError: ''
            };
        case "GET_LOCATIONS_ERROR":
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                locations: null,
                locationsError: action.payload
            };
        case "CLEAR_LOCATIONS":
            return {
                ...state,
                locations: [],
                locationsError: ''
            };
        case "GET_TODAY_LOCATIONS":
            return {
                ...state,
                isLoading: false,
                todayLocations: action.payload,
                todayLocationsError: ''
            };
        case "GET_TODAY_LOCATIONS_ERROR":
            return {
                ...state,
                isLoading: false,
                todayLocations: null,
                todayLocationsError: action.payload
            };
        case "CLEAR_TODAY_LOCATIONS":
            return {
                ...state,
                todayLocations: null,
                todayLocationsError: ''
            };
        case "GET_PREVIOUS_DAY_LOCATIONS":
            return {
                ...state,
                isLoading: false,
                previousDayLocations: action.payload,
                previousDayLocationsError: ''
            };
        case "GET_PREVIOUS_DAY_LOCATIONS_ERROR":
            return {
                ...state,
                isLoading: false,
                previousDayLocations: null,
                previousDayLocationsError: action.payload
            };
        case "CLEAR_PREVIOUS_DAY_LOCATIONS":
            return {
                ...state,
                previousDayLocations: null,
                previousDayLocationsError: ''
            };
        case "GET_PREVIOUS_DAY_LOCATION_DATA":
            return {
                ...state,
                isLoading: false,
                previousDayLocationData: action.payload,
                previousDayLocationDataError: ''
            };
        case "GET_PREVIOUS_DAY_LOCATION_DATA_ERROR":
            return {
                ...state,
                isLoading: false,
                previousDayLocationData: null,
                previousDayLocationDataError: action.payload
            };
        case "CLEAR_PREVIOUS_DAY_LOCATION_DATA":
            return {
                ...state,
                previousDayLocationData: null,
                previousDayLocationDataError: ''
            };
        case "GET_DEVICES":
            return {
                ...state,
                isLoading: false,
                devices: action.payload,
                devicesError: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoading: false,
                devices: null,
                devicesError: action.payload
            };
        case "SET_LOADER":
            return {
                ...state,
                isLoading: true,
            };
        case "CLEAR_LOADER":
            return {
                ...state,
                isLoading: false,
            };
        case 'GET_DETAIL_DATA':
            return {
                ...state,
                detailMapData: action.payload,
                isLoading: false,
                detailMapDataError: ''
            }
        case 'GET_DETAIL_DATA_ERROR':
            return {
                ...state,
                detailMapData: null,
                isLoading: false,
                detailMapDataError: action.payload
            }
        case 'GET_DETAIL_COVERAGE_DATA':
            return {
                ...state,
                isLoading: false,
                detailMapCoverageData: action.payload,
                detailMapCoverageDataError: ''
            }
        case 'GET_DETAIL_COVERAGE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
                detailMapCoverageData: null,
                detailMapCoverageDataError: action.payload
            }
        default:
            return state;
    }
}

const BaseMapProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        summary: null,
        filtered: null,
        locations: null,
        error: ''
    });
    return (
        <BaseMapContext.Provider value={{ data, setData }}>
            {children}
        </BaseMapContext.Provider>
    );
};

const useBaseMapState = () => {
    const context = React.useContext(BaseMapContext);
    return context;
};

export function getBaseMapData(dispatch, selectedDate, filters) {
    dispatch({ type: 'SET_LOADER' });
    let inputData = {
        "duration": {
            "start_time": selectedDate[0], //"2020-06-06T10:05:00.966Z",
            "end_time": selectedDate[1], //"2020-06-06T10:05:00.966Z"
        },
        "filters": (filters) ? filters : {},
        "order_by": "date",
        "group_by": "date",
        "limit": 0,
        "offset": 0
    };
    return axios.post(`/${localStorage.getItem("organization")}/get-data-by-time`, inputData).then(res => {
        // dispatch({ type: "UPDATE_DEVICES", payload: res.data });

        if (filters) {
            dispatch({ type: "GET_DATA_FILTERED", payload: res.data });
        } else {
            dispatch({ type: "GET_DATA", payload: res.data });
        }
        // console.log('summary', res.data)

    }).catch(err => {
        dispatch({ type: "GET_DATA_ERROR", payload: err });
    });
}

export function getLocations(dispatch, staticLocations, contractorsArr, qualities, todayLocations) {
    dispatch({ type: 'SET_LOADER' });
    let reqArr = [];
    let zoneArr = [];
    if (!todayLocations) {
        for (var zone in staticLocations) {
            zoneArr.push(zone);
            reqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${staticLocations[zone].join(',')}`).catch(err => {
                console.log('loc api error', err);
            }))
        }
    } else {
        reqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${todayLocations.join(',')}`).catch(err => {
            // console.log('loc api error', err);
            dispatch({ type: "GET_LOCATIONS", payload: [] });
        }))
    }

    axios.all(reqArr).then(axios.spread((...responses) => {
        let locations = []
        // console.log('responses', responses);
        for (let r = 0; r < responses.length; r++) {
            if (responses[r] && responses[r].data && responses[r].data.results && responses[r].data.results.features) {
                let contractor = contractorsArr[0];//(contractorsArr.filter(c => c.zone == zoneArr[r]))[0];
                // console.log('responses[r].data.results', responses[r].data.results);
                // .filter(d => todayLocations || locationSummary[d.properties.loc_cd] ? true : false)
                let mJosn = responses[r].data.results.features.map((item, index) => {
                    let randomContract = contractor;
                    // item.geometry.type = 'Polygon'
                    item.type = 'Polygon'
                    item.properties.contractor = randomContract.contract
                    item.properties.zone = randomContract.zone; //zoneArr[r]
                    item.properties.lastCut = moment().format('YYYY-MM-DD'); //(todayLocations) ? moment().format('YYYY-MM-DD') : locationSummary[item.properties.loc_cd][' End'] //moment(`2020-06-${Math.floor(Math.random() * Math.floor(30))+1}`).format('DD-MM-YYYY')
                    item.properties.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
                    // item.properties.totalArea = (todayLocations) ? '' : locationSummary[item.properties.loc_cd][' Total Area']
                    //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
                    item.properties.grassCutPercent = '-'
                    item.properties.operationalhrs = ''; //(todayLocations) ? '' : locationSummary[item.properties.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))
                    // let cornersLatLng = [[+Infinity,+Infinity],[-Infinity,-Infinity]];
                    let coordinates = item.geometry.coordinates.map(item => {

                        // cornersLatLng = item[0].reduce((res, point) => {

                        //     const pointsLong = point[0];
                        //     const pointsLat = point[1];
                        //     res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
                        //     res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
                        //     res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
                        //     res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

                        //     return res;
                        //   }, cornersLatLng)

                        return item[0];
                    });
                    if (item.bbox && item.bbox.length === 4) {
                        item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
                    }

                    return {
                        ...item,
                        type: 'Feature',
                        // properties: item,
                        geometry: {
                            type: item.geometry.type, // 'Polygon',
                            coordinates: coordinates
                        }
                    }
                });

                locations = locations.concat(mJosn);
            }
        }
        dispatch({ type: "GET_LOCATIONS", payload: locations });
    }))
    // return axios.get(`/${localStorage.getItem("organization")}/location?loc_cd=${locationList.join(',')}`).then(res => { //limit=${limit}
    //     // dispatch({ type: "UPDATE_DEVICES", payload: res.data });
    //     dispatch({ type: "GET_LOCATIONS", payload: res.data });
    //     // console.log('summary', res.data)
    // }).catch(err => {
    //     dispatch({ type: "GET_LOCATIONS_ERROR", payload: err });
    // });
}

export function getTodayLocations(dispatch) {
    dispatch({ type: 'SET_LOADER' });
    return axios.get(`/${localStorage.getItem("organization")}/today-locations/`).then(res => {
        //res.data
        dispatch({ type: "GET_TODAY_LOCATIONS", payload: res.data })
    }).catch(err => {
        dispatch({ type: "GET_TODAY_LOCATIONS_ERROR", payload: err });
    });
}


export function getPreviousDayLocations(dispatch, selectedDate) {
    dispatch({ type: 'SET_LOADER' });
    return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?file_created_at=${selectedDate}`).then(res => {
        //res.data
        let fData = res.data.results.filter(l => {
            return l.file_type === 'location_data'
        });
        dispatch({ type: "GET_PREVIOUS_DAY_LOCATIONS", payload: fData })
    }).catch(err => {
        dispatch({ type: "GET_PREVIOUS_DAY_LOCATIONS_ERROR", payload: err });
    });
}

export function getPreviosLocationGeoJsons(dispatch, previosDayLocations, contractorsArr, selectedDate) {
    dispatch({ type: 'SET_LOADER' });
    let reqArr = [];

    reqArr = previosDayLocations.map(loc => {
        return axios.get(`/${localStorage.getItem("organization")}/locations?objectid=${loc.location}`)
    })

    axios.all(reqArr).then(axios.spread((...responses) => {
        let locations = []
        // console.log('responses', responses);
        for (let r = 0; r < responses.length; r++) {
            if (responses[r] && responses[r].data && responses[r].data.results && responses[r].data.results.features) {
                let contractor = contractorsArr[0];//(contractorsArr.filter(c => c.zone == zoneArr[r]))[0];
                // console.log('responses[r].data.results', responses[r].data.results);
                // .filter(d => locationSummary[d.properties.loc_cd] ? true : true)
                let mJosn = responses[r].data.results.features.map((item, index) => {
                    let randomContract = contractor;
                    // item.geometry.type = 'Polygon'
                    item.type = 'Polygon'
                    item.properties.contractor = randomContract.contract
                    item.properties.zone = randomContract.zone; //zoneArr[r]
                    item.properties.lastCut = selectedDate;
                    item.properties.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
                    // item.properties.totalArea = ''; //locationSummary[item.properties.loc_cd][' Total Area'];
                    //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
                    item.properties.grassCutPercent = '-'
                    item.properties.operationalhrs = '';//locationSummary[item.properties.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))

                    let coordinates = item.geometry.coordinates.map(item => {
                        return item[0];
                    });
                    if (item.bbox && item.bbox.length === 4) {
                        item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
                    }

                    return {
                        ...item,
                        type: 'Feature',
                        // properties: item,
                        geometry: {
                            type: item.geometry.type, // 'Polygon',
                            coordinates: coordinates
                        }
                    }
                });

                locations = locations.concat(mJosn);
            }
        }
        dispatch({ type: "GET_LOCATIONS", payload: locations });
    }))
}

export function getPreviousDayLocationData(dispatch, infoArr) {
    dispatch({ type: 'SET_LOADER' });

    let dataReqArr = infoArr.map(i => {
        return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/${i.id}`)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                return err.response;
            })
    })

    axios.all(dataReqArr).then(axios.spread((...responses) => {
        dispatch({ type: "GET_PREVIOUS_DAY_LOCATION_DATA", payload: responses })
    }));

    // return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/${itemId}`).then(res => {
    //     dispatch({ type: "GET_PREVIOUS_DAY_LOCATION_DATA", payload: res.data })
    // }).catch(err => {
    //     dispatch({ type: "GET_PREVIOUS_DAY_LOCATION_ERROR", payload: err });
    // });
}

export function getDevices(dispatch) {
    dispatch({ type: 'SET_LOADER' });
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}


export function getLocationList(dispatch, dateRange, contractorsArr) {
    dispatch({ type: 'SET_LOADER' });

    let dateArr = [];
    let reqArr = [];
    let noOfDays = moment(dateRange[1]).diff(dateRange[0], 'days') + 1;

    // console.log('GET_LOCATIONS_LIST noOfDays', noOfDays);

    for (let d = 0; d < noOfDays; d++) {
        let targetDate = moment(dateRange[0]).add(d, 'days');
        let isToday = targetDate.isSame(moment(), 'day');

        let formattedDate = targetDate.format('YYYY-MM-DD')

        if (isToday) {
            reqArr.push(axios.get(`/${localStorage.getItem("organization")}/today-locations/`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    return err.response;
                })
            );
        } else {
            reqArr.push(axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?file_created_at=${formattedDate}`)
                .then(res => {
                    let fData = res.data.results.filter(l => {
                        return l.file_type === 'location_data'
                    });
                    return fData;
                })
                .catch(err => {
                    return err.response;
                })
            );
        }
        dateArr.push({ date: targetDate, isToday })
    }

    let result = []
    let locationCDArr = []
    let locationObjectIdArr = {}
    axios.all(reqArr).then(axios.spread((...responses) => {
        result = responses;
        // console.log('GET_LOCATIONS responses', { responses, dateArr });
        for (let r = 0; r < responses.length; r++) {
            if (responses[r]) {
                if (dateArr[r].isToday) {
                    locationCDArr = responses[r];
                } else {
                    responses[r].forEach(l => {
                        if (!locationObjectIdArr[l.location]) {
                            locationObjectIdArr[l.location] = [l]
                        } else {
                            locationObjectIdArr[l.location].push(l)
                        }
                    })
                }
            }
        }
        if (locationCDArr.length == 0 && Object.keys(locationObjectIdArr).length == 0) {
            dispatch({ type: "GET_LOCATIONS_ERROR", payload: 'No locations done in the selected duration' })
        } else {
            getLocationGeoJsons(locationCDArr, locationObjectIdArr, contractorsArr, dispatch, dateRange);
        }
        // console.log('GET_LOCATIONS', { responses, dateArr, locationCDArr, locationObjectIdArr });
        // dispatch({ type: "GET_LOCATIONS_LIST", payload: responses })
    }));


}

function getLocationGeoJsons(locationCDArr, locationObjectIdArr, contractorsArr, dispatch, dateRange) {
    let polygonReqArr = []
    polygonReqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${locationCDArr.join(',')}`)
        // .then(res => {
        //     // return fData;
        // })
        .catch(err => {
            return err.response;
        }))

    for (var loc in locationObjectIdArr) {
        polygonReqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?objectid=${loc}`)
            // .then(res => {
            //     return fData;
            // })
            .catch(err => {
                return err.response;
            }))
    }

    axios.all(polygonReqArr).then(axios.spread((...responses) => {
        let locations = []
        // console.log('responses', responses);
        for (let r = 0; r < responses.length; r++) {
            if (responses[r] && responses[r].data && responses[r].data.results && responses[r].data.results.features) {
                let contractor = contractorsArr[0];
                let mJosn = responses[r].data.results.features.forEach((item, index) => {
                    if (!locations.find(l => l.id == item.id)) {
                        let randomContract = contractor;
                        // item.geometry.type = 'Polygon'
                        item.type = 'Polygon'
                        item.properties.contractor = randomContract.contract
                        item.properties.zone = randomContract.zone; //zoneArr[r]
                        item.properties.lastCut = moment(dateRange[1]).format('YYYY-MM-DD');
                        item.properties.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
                        // item.properties.totalArea = ''; //locationSummary[item.properties.loc_cd][' Total Area'];
                        //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
                        item.properties.grassCutPercent = '-'
                        item.properties.operationalhrs = '';//locationSummary[item.properties.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))
                        item.properties.dataInfo = locationObjectIdArr[item.id] || [];
                        item.properties.hasTodayData = (r == 0) ? true : false;


                        let coordinates = item.geometry.coordinates.map(item => {
                            return item[0];
                        });
                        if (item.bbox && item.bbox.length === 4) {
                            item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
                        }

                        let nItem = {
                            ...item,
                            type: 'Feature',
                            // properties: item,
                            geometry: {
                                type: item.geometry.type, // 'Polygon',
                                coordinates: coordinates
                            }
                        }
                        locations.push(nItem);
                    }

                });

                // locations = locations.concat(mJosn);
            }
        }
        dispatch({ type: "GET_LOCATIONS", payload: locations });
    }))
}

export function getAllLocationList(dispatch, dateRange, contractorsArr) {
    try {
        dispatch({ type: 'SET_LOADER' });

        let dateArr = [];
        let reqArr = [];
        let noOfDays = moment(dateRange[1]).diff(dateRange[0], 'days') + 1;

        // console.log('noOfDays', noOfDays)

        let startDate = moment(dateRange[0]);
        let endDate = moment(dateRange[1]);
        let isToday = startDate.isSame(moment(), 'day');
        let isEndDateToday = endDate.isSame(moment(), 'day');

        if (isEndDateToday) {
            reqArr.push(axios.get(`/${localStorage.getItem("organization")}/today-locations/`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    return err.response;
                })
            );
        }
        if (noOfDays == 1 && !isEndDateToday) { //single day slection
            let formattedDate = startDate.format('YYYYMMDD');

            reqArr.push(axios.post(`/${localStorage.getItem("organization")}/s3/`, {
                // file_name: `summary/${formattedDate}/ALL_location_summary_${formattedDate}.json`
                file_name: `trim/processed_summary/daywise/${formattedDate}/all-locations_${formattedDate}_location-summary.json`
            })
                .then(res => {
                    // let fData = res.data.results.filter(l => {
                    //     return l.file_type === 'location_data'
                    // });
                    return res.data;
                })
                .catch(err => {
                    return null; //err.response;
                })
            );
        } else {
            //cut based selection
            let cutNo;
            let fileName;
            let formattedDate = startDate.format('YYYYMM');

            if (isEndDateToday) {
                endDate = endDate.subtract(1, 'days');
            }

            if (startDate.date() == 1 && endDate.date() <= 15 && startDate.isSame(endDate, 'month')) {
                cutNo = 1;
                fileName = `grasscut_progress_${formattedDate}01_${formattedDate}${endDate.format('DD')}.json`;
            } else if (startDate.date() == 16 && startDate.isSame(endDate, 'month')) {
                let endOfMonth = startDate.endOf('month');
                if (endOfMonth.isSame(endDate, 'day')) {
                    //cut 2
                    cutNo = 2;
                    fileName = `grasscut_progress_${formattedDate}16_${formattedDate}${endOfMonth.format('DD')}.json`;
                } else { //if(endDate.isSame(moment(), 'day'))
                    cutNo = 2;
                    fileName = `grasscut_progress_${formattedDate}16_${formattedDate}${endDate.format('DD')}.json`;
                }
            }

            if (cutNo && fileName) {
                reqArr.push(axios.post(`/${localStorage.getItem("organization")}/s3/`, {
                    // file_name: `summary/${formattedDate}-cut${cutNo}/${fileName}`

                    file_name: `trim/processed_summary/cutwise/${formattedDate}/${cutNo}/all-locations_${endDate.format('YYYYMMDD')}_cut-progress.json`
                })
                    .then(res => {
                        // let fData = res.data.results.filter(l => {
                        //     return l.file_type === 'location_data'
                        // });
                        return res.data;
                    })
                    .catch(err => {
                        return null; //err.response;
                    })
                );
            }

        }

        // console.log('GET_LOCATIONS_LIST noOfDays', noOfDays);

        // for (let d = 0; d < noOfDays; d++) {
        //     let targetDate = moment(dateRange[0]).add(d, 'days');
        //     let isToday = targetDate.isSame(moment(), 'day');

        //     let formattedDate = targetDate.format('YYYYMMDD')

        //     if (isToday) {
        //         reqArr.push(axios.get(`/${localStorage.getItem("organization")}/today-locations/`)
        //             .then(res => {
        //                 return res.data;
        //             })
        //             .catch(err => {
        //                 return err.response;
        //             })
        //         );
        //     } else {
        //         reqArr.push(axios.get(`/Summary/${formattedDate}_all_location_summary.json`, { baseURL: '' })
        //             .then(res => {
        //                 // let fData = res.data.results.filter(l => {
        //                 //     return l.file_type === 'location_data'
        //                 // });
        //                 return res.data;
        //             })
        //             .catch(err => {
        //                 return null; //err.response;
        //             })
        //         );
        //     }
        //     dateArr.push({ date: targetDate, isToday })
        // }

        let result = []
        let locationCDArr = []
        let locationObjectIdArr = {}
        axios.all(reqArr).then(axios.spread((...responses) => {
            result = responses;
            // console.log('GET_LOCATIONS responses', { responses, dateArr });
            for (let r = 0; r < responses.length; r++) {
                if (responses[r]) {
                    if (isEndDateToday && r == 0) {
                        locationCDArr = responses[r].filter(l=>l!=null);
                        // locationCDArr = locationCDArr.filter(l=>l);
                        // locationCDArr = ["AMK-BP-21"]
                    }
                    // if (dateArr[r].isToday) {
                    //     locationCDArr = responses[r];
                    // } else {
                    //     // responses[r].forEach(l => {
                    //     //     if (!locationObjectIdArr[l.location]) {
                    //     //         locationObjectIdArr[l.location] = [l]
                    //     //     } else {
                    //     //         locationObjectIdArr[l.location].push(l)
                    //     //     }
                    //     // })
                    // }
                }
            }
            getAllLocationGeoJsons(locationCDArr, locationObjectIdArr, contractorsArr, dispatch, dateRange, responses, dateArr, isEndDateToday, noOfDays);

            // if (locationCDArr.length == 0 && Object.keys(locationObjectIdArr).length == 0) {
            //     dispatch({ type: "GET_LOCATIONS_ERROR", payload: 'No locations done in the selected duration' })
            // } else {
            //     getLocationGeoJsons(locationCDArr, locationObjectIdArr, contractorsArr, dispatch, dateRange);
            // }
            // console.log('GET_LOCATIONS', { responses, dateArr, locationCDArr, locationObjectIdArr });
            // dispatch({ type: "GET_LOCATIONS_LIST", payload: responses })
        }));
    } catch (Err) {
        console.log('getAllLocationListErr', Err)
    }

}

function getAllLocationGeoJsons(locationCDArr, locationObjectIdArr, contractorsArr, dispatch, dateRange, prevDayResponses, dateArr, isEndDateToday, noOfDays) {
    let polygonReqArr = []
    // if (isEndDateToday) {
    //     if (locationCDArr.length > 0) {
    //         polygonReqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${locationCDArr.join(',')}`)
    //             .then(res => {
    //                 return res.data.results;
    //             })
    //             .catch(err => {
    //                 return err.response;
    //             }))
    //     }
    // }

    // console.log('prevDayResponses', prevDayResponses)

    prevDayResponses.forEach((res, idx) => {
        if (isEndDateToday && idx == 0) {
            if(locationCDArr.length>0){
                polygonReqArr.push(axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${locationCDArr.join(',')}`)
                    .then(res => {
                        return res.data.results;
                    })
                    .catch(err => {
                        return err.response;
                    }))
            }
        } else {

            polygonReqArr.push(axios.get(res.file_s3_url, {
                transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                }
            })
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    return err.response;
                }))

        }

    })

    // }

    axios.all(polygonReqArr).then(axios.spread((...responses) => {
        let locations = []
        // console.log('responses', responses)
        // console.log('responses', responses);
        // for (let r = 0; r < responses.length; r++) {
        //     if (responses[r] && responses[r].data && responses[r].data.results && responses[r].data.results.features) {
        //         let contractor = contractorsArr[0];
        //         let mJosn = responses[r].data.results.features.forEach((item, index) => {
        //             if (!locations.find(l => l.id == item.id)) {
        //                 let randomContract = contractor;
        //                 // item.geometry.type = 'Polygon'
        //                 item.type = 'Polygon'
        //                 item.properties.contractor = randomContract.contract
        //                 item.properties.zone = randomContract.zone; //zoneArr[r]
        //                 item.properties.lastCut = moment(dateRange[1]).format('YYYY-MM-DD');
        //                 item.properties.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
        //                 // item.properties.totalArea = ''; //locationSummary[item.properties.loc_cd][' Total Area'];
        //                 //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
        //                 item.properties.grassCutPercent = '-'
        //                 item.properties.operationalhrs = '';//locationSummary[item.properties.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))
        //                 item.properties.dataInfo = locationObjectIdArr[item.id] || [];
        //                 item.properties.hasTodayData = (r == 0) ? true : false;


        //                 let coordinates = item.geometry.coordinates.map(item => {
        //                     return item[0];
        //                 });
        //                 if (item.bbox && item.bbox.length === 4) {
        //                     item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
        //                 }

        //                 let nItem = {
        //                     ...item,
        //                     type: 'Feature',
        //                     // properties: item,
        //                     geometry: {
        //                         type: item.geometry.type, // 'Polygon',
        //                         coordinates: coordinates
        //                     }
        //                 }
        //                 locations.push(nItem);
        //             }

        //         });

        //         // locations = locations.concat(mJosn);
        //     }
        // }



        // let TotalJobsCompleted = 0;
        // let TotalPloygonArea = 0.0;
        // let TotalGrassCutArea = 0.0;
        // let TotalOperationalTime = 0;
        let TotalDevices = 0;
        let DevicesList = {}
        let metrics = {}
        for (let r = responses.length - 1; r >= 0; r--) {
            if (responses[r] && responses[r].features) {
                let isTodayData = (r == 0 && isEndDateToday && locationCDArr.length>0);
                // console.log('GET_LOCATIONSasdasdad', responses[r].features)
                let contractor = contractorsArr[0];
                metrics = {}
                let mJosn = responses[r].features.forEach((item, index) => {
                    let fndLocation = locations.find(l => l.properties.loc_cd == item.properties.loc_cd);
                    if (item.properties.loc_cd == 'ALL') {
                        metrics = item.properties;
                        // TotalJobsCompleted = item.properties.CompletedLocationCount;
                        // TotalPloygonArea = item.properties.TotalPolygonArea;
                        // TotalGrassCutArea = item.properties.TotalGrassCutArea;
                        // TotalOperationalTime = item.properties['TotalOperationalDuration.sum'];
                        // // OverallCoverage = item.properties.OverallCoverage;
                        // TotalJobsCompleted = item.properties.CompletedLocationCount;
                    } else if (!fndLocation) {

                        let randomContract = contractor;
                        item.properties.contractor = randomContract.contract
                        item.properties.zone = randomContract.zone;
                        // item.properties.quality = 'Bad';
                        // item.properties.dataInfo = locationObjectIdArr[item.id] || [];
                        item.properties.hasTodayData = isTodayData;

                        // let coordinates = []
                        if (isTodayData) {
                            item.properties.lastCut = moment().format('YYYY-MM-DD')
                            item.properties.PolygonArea = +item.properties.polygon_area.toFixed(3);
                            item.properties.GrassCutArea = 0;
                            item.properties.Coverage = 0;
                            item.properties.TotalOperationalDuration = "00:00:00";
                            item.properties.JobCompletion = "0"
                            item.properties.jobStatus = "InProgress"
                            item.properties.Date = moment().format('YYYYMMDD')

                            if (item.bbox && item.bbox.length === 4) {
                                item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
                            }

                            // if (item.geometry.geometries) {
                            //     item.geometry.geometries.map(g => {
                            //         if (g.coordinates) {
                            //             let coords = g.coordinates.map(item => {
                            //                 return item[0];
                            //             });
                            //             coordinates = coordinates.concat(coords)
                            //         }
                            //     })
                            // }

                            // if (item.geometry.coordinates) {
                            //     let coords = item.geometry.coordinates.map(item => {
                            //         return item[0];
                            //     });
                            //     coordinates = coordinates.concat(coords)
                            // }
                        } else {

                            item.properties.hasTodayData = isTodayData;

                            if (item.properties.Date) {
                                let datesCut = item.properties.Date.split(',');
                                item.properties.lastCut = moment(datesCut[datesCut.length - 1], 'YYYYMMDD').format('YYYY-MM-DD');
                            }
                            item.properties.jobStatus = item.properties.JobCompletion == '1' ? 'Completed' : 'InProgress';
                            item.properties.PolygonArea = +item.properties.PolygonArea.toFixed(3);
                            item.properties.GrassCutArea = +item.properties.GrassCutArea.toFixed(3);
                            let Coverage = item.properties.Coverage? +(parseFloat(item.properties.Coverage)).toFixed(2): 0;

                            if(isNaN(Coverage)){
                                // console.log('item',item)
                            } else {
                                item.properties.Coverage = Coverage
                            }
                            let cutdevices = item.properties.Device.split(',');
                            cutdevices.forEach(dv => {
                                // if(!DevicesList[dv]){
                                DevicesList[dv] = dv;
                                // }
                            })

                            // let cornersLatLng = [[+Infinity, +Infinity], [-Infinity, -Infinity]];

                            // if (item.geometry.geometries) {
                            //     item.geometry.geometries.map(g => {
                            //         if (g.coordinates) {
                            //             let coords = g.coordinates.map(item => {

                            //                 cornersLatLng = item[0].reduce((res, point) => {

                            //                     const pointsLong = point[0];
                            //                     const pointsLat = point[1];
                            //                     res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
                            //                     res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
                            //                     res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
                            //                     res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

                            //                     return res;
                            //                 }, cornersLatLng)

                            //                 return item[0];
                            //             });
                            //             coordinates = coordinates.concat(coords)
                            //         }
                            //     })
                            // }

                            // if (item.geometry.coordinates) {
                            //     let coords = item.geometry.coordinates.map(item => {

                            //         cornersLatLng = item[0].reduce((res, point) => {

                            //             const pointsLong = point[0];
                            //             const pointsLat = point[1];
                            //             res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
                            //             res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
                            //             res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
                            //             res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

                            //             return res;
                            //         }, cornersLatLng)

                            //         return item[0];
                            //     });
                            //     coordinates = coordinates.concat(coords)
                            // }
                            let polyBbox = bbox(item);
                            item.properties.cornersLatLng = [[polyBbox[0], polyBbox[1]], [polyBbox[2], polyBbox[3]]]; //cornersLatLng;
                        }

                        locations.push(item);
                    } else if (fndLocation) {
                        // console.log('fndLocation', fndLocation, item.properties)
                        item.properties.hasTodayData = isTodayData;
                        fndLocation.properties = {
                            ...fndLocation.properties,
                            ...item.properties
                        }

                        if (isTodayData) {
                            fndLocation.properties.lastCut = moment().format('YYYY-MM-DD')
                            if (fndLocation.properties.Date) {
                                fndLocation.properties.Date += ',' + moment().format('YYYYMMDD')
                            }
                        }

                        // fndLocation.properties.PolygonArea = item.properties.polygon_area;
                        // fndLocation.properties.GrassCutArea = 0;
                        // item.properties.Coverage = 0;
                        // item.properties.TotalOperationalDuration = "";
                        // item.properties.JobCompletion = "0"
                        // item.properties.jobStatus = "InProgress"


                    }

                    // else if (!locations.find(l => l.properties.loc_cd == item.properties.loc_cd)) {
                    //     let randomContract = contractor;
                    //     // item.geometry.type = 'Polygon'
                    //     // item.type = 'Polygon'
                    //     item.properties.contractor = randomContract.contract
                    //     item.properties.zone = randomContract.zone; //zoneArr[r]

                    //     item.properties.quality = 'Bad'; //qualities[Math.floor(Math.random() * Math.floor(2))]
                    //     // item.properties.totalArea = item.properties.PolygonArea; //locationSummary[item.properties.loc_cd][' Total Area'];
                    //     //item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
                    //     // item.properties.grassCutPercent = item.properties.Coverage
                    //     // item.properties.operationalhrs = '';//locationSummary[item.properties.loc_cd]['Duration'] //Math.floor(Math.random() * Math.floor(15))
                    //     item.properties.dataInfo = locationObjectIdArr[item.id] || [];
                    //     item.properties.hasTodayData = isToday; //(r == 0) ? true : false;


                    //     let coordinates = []
                    //     if (isToday) {
                    //         item.properties.lastCut = moment().format('YYYY-MM-DD')
                    //         item.properties.PolygonArea = 0;
                    //         item.properties.GrassCutArea = 0;
                    //         item.properties.Coverage = 0;
                    //         item.properties.TotalOperationalDuration = "";
                    //         item.properties.JobCompletion = "0"
                    //         item.properties.jobStatus = "InProgress"
                    //         item.properties.Date = moment().format('YYYY-MM-DD')

                    //         if (item.bbox && item.bbox.length === 4) {
                    //             item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
                    //         }

                    //         if (item.geometry.geometries) {
                    //             item.geometry.geometries.map(g => {
                    //                 if (g.coordinates) {
                    //                     let coords = g.coordinates.map(item => {
                    //                         return item[0];
                    //                     });
                    //                     coordinates = coordinates.concat(coords)
                    //                 }
                    //             })
                    //         }

                    //         if (item.geometry.coordinates) {
                    //             let coords = item.geometry.coordinates.map(item => {
                    //                 return item[0];
                    //             });
                    //             coordinates = coordinates.concat(coords)
                    //         }

                    //     } else {
                    //         if (item.properties.Date) {
                    //             let datesCut = item.properties.Date.split(',');
                    //             item.properties.lastCut = moment(datesCut[datesCut.length - 1], 'YYYYMMDD').format('YYYY-MM-DD');
                    //         }
                    //         item.properties.jobStatus = item.properties.JobCompletion == '1' ? 'Completed' : 'InProgress';

                    //         let cutdevices = item.properties.Device.split(',');
                    //         cutdevices.forEach(dv => {
                    //             // if(!DevicesList[dv]){
                    //             DevicesList[dv] = dv;
                    //             // }
                    //         })

                    //         let cornersLatLng = [[+Infinity, +Infinity], [-Infinity, -Infinity]];

                    //         if (item.geometry.geometries) {
                    //             item.geometry.geometries.map(g => {
                    //                 if (g.coordinates) {
                    //                     let coords = g.coordinates.map(item => {

                    //                         cornersLatLng = item[0].reduce((res, point) => {

                    //                             const pointsLong = point[0];
                    //                             const pointsLat = point[1];
                    //                             res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
                    //                             res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
                    //                             res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
                    //                             res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

                    //                             return res;
                    //                         }, cornersLatLng)

                    //                         return item[0];
                    //                     });
                    //                     coordinates = coordinates.concat(coords)
                    //                 }
                    //             })
                    //         }

                    //         if (item.geometry.coordinates) {
                    //             let coords = item.geometry.coordinates.map(item => {

                    //                 cornersLatLng = item[0].reduce((res, point) => {

                    //                     const pointsLong = point[0];
                    //                     const pointsLat = point[1];
                    //                     res[0][0] = pointsLong < res[0][0] ? pointsLong : res[0][0];
                    //                     res[0][1] = pointsLat < res[0][1] ? pointsLat : res[0][1];
                    //                     res[1][0] = pointsLong > res[1][0] ? pointsLong : res[1][0];
                    //                     res[1][1] = pointsLat > res[1][1] ? pointsLat : res[1][1];

                    //                     return res;
                    //                 }, cornersLatLng)

                    //                 return item[0];
                    //             });
                    //             coordinates = coordinates.concat(coords)
                    //         }
                    //         item.properties.cornersLatLng = cornersLatLng;

                    //     }


                    //     let nItem = {
                    //         ...item,
                    //         type: 'Feature',
                    //         // properties: item,
                    //         geometry: {
                    //             type: item.geometry.type, // 'Polygon',
                    //             coordinates: coordinates
                    //         }
                    //     }
                    //     locations.push(nItem);
                    //     // console.log('GET_LOCATIONSasdasdad nItem', nItem)
                    // }

                });

                // console.log('GET_LOCATIONSasdasdad 2', locations)

            }
        }
        TotalDevices = Object.keys(DevicesList).length;
        metrics.TotalDevices = TotalDevices;
        if (isEndDateToday && responses.length == 0) {
            metrics = {
                ...metrics,
                CompletedLocationCount: 0,
                LocationCount: locations.length,
                TotalGrassCutArea: 0,
                TotalPolygonArea: 0,
            }
        }
        // TotalPloygonArea = TotalPloygonArea / 10000
        // TotalGrassCutArea = TotalGrassCutArea / 10000
        // let metrics = {
        //     TotalJobsCompleted, TotalPloygonArea, TotalGrassCutArea, TotalOperationalTime, TotalDevices
        // }
        //sort by coverage descending
        // console.log('locations', locations)
        locations = locations.sort((a, b) => { return b.properties.Coverage - a.properties.Coverage });

        // let locDataLenReqArr = []
        // let locIndexes = []
        // for (let l = 0; l < locations.features.length && l < 5; l++) {
        //     if (locations.features[l].hasTodayData) {
        //         let startDate = moment.utc().startOf('day').subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        //         let endDate = moment.utc().endOf('day').utc().subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        //         locDataLenReqArr.push(axios.get(`/${localStorage.getItem("organization")}/data-full/?loc_cd=${loc}&completed_at__range=${startDate},${endDate}&limit=1`)
        //             .then(res => {
        //                 let data = res.data;

        //                 // data = {"count":10,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=EA719&offset=1","previous":null,"results":[{"id":29085712,"device_id":"205328","grass_height":186.0,"gps":{"type":"Point","coordinates":[103.8697058433,1.4006792267]},"loc_cd":"EA719","near_locations":null,"data":{"RTK":36,"lat":"1.4006792267","Time":1606027432,"hAcc":5.39,"long":"103.8697058433","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":38,"grass height":186},"completed_at":"2020-11-22T06:43:52Z","device_data":10313820,"location":81633}]};
        //                 // data = {"count":2501,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=MEKH005&offset=1","previous":null,"results":[{"id":29057560,"device_id":"205336","grass_height":0.0,"gps":{"type":"Point","coordinates":[103.8465681233,1.3281569183]},"loc_cd":"MEKH005","near_locations":null,"data":{"RTK":4,"lat":"1.3281569183","Time":1606022541,"hAcc":5.04,"long":"103.8465681233","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":10,"grass height":0},"completed_at":"2020-11-22T05:22:21Z","device_data":10312899,"location":29237}]};
        //                 // data = {"count":93,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=MEKS251&offset=1","previous":null,"results":[{"id":29077056,"device_id":"205336","grass_height":12.0,"gps":{"type":"Point","coordinates":[103.84908554,1.322199615]},"loc_cd":"MEKS251","near_locations":null,"data":{"RTK":4,"lat":"1.322199615","Time":1606025744,"hAcc":14.0,"long":"103.84908554","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":0,"grass height":12},"completed_at":"2020-11-22T06:15:44Z","device_data":10313536,"location":87309}]};

        //                 return data;
        //             })
        //             .catch(err => {
        //                 return err.response;
        //             })
        //         )
        //         locIndexes.push(l);
        //     }
        // }

        // axios.all(locDataLenReqArr).then(axios.spread((...responses) => {

        //     for (let lr = 0; lr < responses.length; lr++) {
                
        //         let dataCount = responses[0].count;
        //         let TotalArea = locations[lr].features[0].properties.polygon_area * 1000000;

        //         let locIndex = locationCDArr.indexOf(item.properties.loc_cd);
        //         if (locIndex != -1) {
        //             dataCount = tDayDataLenResp[locIndex].count
        //         }


        //         locations[lr].features[0].properties.lastCut = moment().format('YYYY-MM-DD')
        //         locations[lr].features[0].properties.PolygonArea = TotalArea;
        //         locations[lr].features[0].properties.GrassCutArea = dataCount * 1.8;
        //         locations[lr].features[0].properties.Coverage = (locations[lr].features[0].properties.GrassCutArea / TotalArea) * 100;

        //         let hrs = parseInt(dataCount / (60 * 60));
        //         let remsecs = dataCount % (60 * 60);
        //         let mins = parseInt(remsecs / 60);
        //         let secs = remsecs % 60;
        //         // let secs = data.length%60;
        //         let oprHrs = `${('00' + hrs).slice(-2)}:${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`


        //         locations[lr].features[0].properties.TotalOperationalDuration = oprHrs;
        //         // locations[lr].features[0].properties.JobCompletion = "0"
        //         // locations[lr].features[0].properties.jobStatus = "InProgress"

        //         // locations[lr].features[0].properties.Date = moment().format('YYYY-MM-DD');

        //     }
        //     dispatch({ type: "GET_LOCATIONS", payload: { locations, metrics } });

        // }))

        dispatch({ type: "GET_LOCATIONS", payload: { locations, metrics } });
    }))
}

export function getTodayLocationProps(dispatch, locArr) {
    dispatch({ type: 'SET_LOADER' });

    let startDate = moment.utc().startOf('day').subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
    let endDate = moment.utc().endOf('day').utc().subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss')

    let reqArr = locArr.map(loc=>{
        return axios.get(`/${localStorage.getItem("organization")}/data-full/?loc_cd=${loc.loc_cd}&completed_at__range=${startDate},${endDate}&limit=1`)
        .then(res => {
            let data = res.data;

            let cutArea = data.count * 1.8;
            let cov = (cutArea * 100) / loc.polygon_area;
           

            let opData = loc.TotalOperationalDuration.split(":");
            let pSec = (parseInt(opData[0]) * 60 * 60) + (parseInt(opData[1]) * 60) + parseInt(opData[2]);
            let totalSecs = pSec + data.count;
            let hrs = parseInt(totalSecs / (60 * 60));
            let remsecs = totalSecs % (60 * 60);
            let mins = parseInt(remsecs / 60);
            let secs = remsecs % 60;
            let oprHrs = `${('00' + hrs).slice(-2)}:${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`
            
            cov += parseFloat(loc.Coverage);
            if(cov>100) cov = 100;
            if(isNaN(cov) || cov<0) cov = 0;

            let TotalGrassCutArea = cutArea + parseFloat(loc.GrassCutArea);
            if(TotalGrassCutArea>loc.polygon_area) TotalGrassCutArea = loc.polygon_area
            
            let locProps = {
                loc_cd: loc.loc_cd,
                dataLen: data.count,
                GrassCutArea: +TotalGrassCutArea.toFixed(3),
                Coverage: +cov.toFixed(2),
                TotalOperationalDuration: oprHrs,
                JobCompletion: (cov < 80) ? "0" : "1",
                jobStatus: (cov < 80) ? "InProgress" : "Completed",
                // PolygonArea: +loc.polygon_area.toFixed(3)
            }

            return locProps;
            // data = {"count":10,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=EA719&offset=1","previous":null,"results":[{"id":29085712,"device_id":"205328","grass_height":186.0,"gps":{"type":"Point","coordinates":[103.8697058433,1.4006792267]},"loc_cd":"EA719","near_locations":null,"data":{"RTK":36,"lat":"1.4006792267","Time":1606027432,"hAcc":5.39,"long":"103.8697058433","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":38,"grass height":186},"completed_at":"2020-11-22T06:43:52Z","device_data":10313820,"location":81633}]};
            // data = {"count":2501,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=MEKH005&offset=1","previous":null,"results":[{"id":29057560,"device_id":"205336","grass_height":0.0,"gps":{"type":"Point","coordinates":[103.8465681233,1.3281569183]},"loc_cd":"MEKH005","near_locations":null,"data":{"RTK":4,"lat":"1.3281569183","Time":1606022541,"hAcc":5.04,"long":"103.8465681233","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":10,"grass height":0},"completed_at":"2020-11-22T05:22:21Z","device_data":10312899,"location":29237}]};
            // data = {"count":93,"next":"https://api.qatrim.aws.r-eye.io/v2/trim/data-full/?completed_at__range=2020-11-21+16%3A00%3A00%2C2020-11-22+15%3A59%3A59&limit=1&loc_cd=MEKS251&offset=1","previous":null,"results":[{"id":29077056,"device_id":"205336","grass_height":12.0,"gps":{"type":"Point","coordinates":[103.84908554,1.322199615]},"loc_cd":"MEKS251","near_locations":null,"data":{"RTK":4,"lat":"1.322199615","Time":1606025744,"hAcc":14.0,"long":"103.84908554","SV_Num":1,"SV_low":0,"SV_high":0,"device tilt":0,"grass height":12},"completed_at":"2020-11-22T06:15:44Z","device_data":10313536,"location":87309}]};
           
            //return data;


            // dispatch({ type: "GET_LOCATIONS", payload: { locations, metrics } });
        })
        .catch(err => {
            return err.response;
        })
    })
    

    axios.all(reqArr).then(axios.spread((...responses) => {
        dispatch({ type: 'GET_TODAY_LOCATION_PROPS', payload: responses });
    }));
}

export function getLocationCsvData(dispatch, locId) {
    dispatch({ type: 'SET_LOADER' });
    return axios.get(`/${localStorage.getItem("organization")}/locations/${locId}/trim-data`, { headers: { accept: 'text/csv' } }).then(res => {
        // console.log('today csv data', res.data)
        // console.log('today csv data', csvJSON(res.data))
        // dispatch({ type: "GET_DEVICES", payload: res.data });
        dispatch({ type: 'CLEAR_LOADER' });
    }).catch(err => {
        // dispatch({ type: "GET_DEVICES_ERROR", payload: err });
        dispatch({ type: 'CLEAR_LOADER' });
    });
}

export function getPrevLocationCsvData(dispatch, infoArr) {
    dispatch({ type: 'SET_LOADER' });

    let dataReqArr = infoArr.map(i => {
        return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/${i.id}`)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                return err.response;
            })
    })

    axios.all(dataReqArr).then(axios.spread((...responses) => {

        let reqArr = responses.map(d => {
            return axios.get(d.file_s3_url, {
                transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                }
            }).then(res => {
                return csvJSON(res.data);
            }).catch(err => {
                return err.response;
            });
        })

        axios.all(reqArr).then(axios.spread((...responses) => {
            // console.log('prev csv data', responses)
        }))
    }));
}

export function getDetailMapData(dispatch, locationDetails, dateRange) {
    dispatch({ type: 'SET_LOADER' });
    let mapData = [];

    let startDate = moment(dateRange[0]);
    let endDate = moment(dateRange[1]);
    let noOfDays = endDate.diff(startDate, 'days') + 1;
    let isToday = startDate.isSame(moment(), 'day');

    // if (isToday) {
    //     getTodayDetailData(dispatch, mapData, locationDetails)
    // } else {
    //get previous day data
    // console.log('locationDetails.properties', locationDetails.properties);
    let cutDates = locationDetails.properties.Date.split(',');

    axios.get(`/${localStorage.getItem("organization")}/locations?loc_cd=${locationDetails.properties.loc_cd}`)
        .then(locres => {
            if (locres.data.results.features[0]) {

                let detailRawDataFileReqArr = []
                for (let d = 0; d < cutDates.length; d++) {
                    // let targetDate = moment(dateRange[0]).add(d, 'days');
                    let targetDate = moment(cutDates[d], 'YYYYMMDD');
                    let isTodayDate = targetDate.isSame(moment(), 'day');

                    if (!isTodayDate) {

                        let formattedDate = targetDate.format('YYYY-MM-DD')

                        detailRawDataFileReqArr.push(axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?location__loc_cd=${locationDetails.properties.loc_cd}&file_created_at=${formattedDate}&file_type=location_data`)
                            .then(res => {
                                let fData = res.data.results.filter(l => {
                                    return l.file_type === 'location_data'
                                });
                                return fData;
                            })
                            .catch(err => {
                                return err.response;
                            })
                        );
                    }

                }

                axios.all(detailRawDataFileReqArr).then(axios.spread((...fileResponses) => {

                    let dataReqArr = fileResponses.filter(f => f && f.length > 0).map(f => {
                        return axios.get(`/${localStorage.getItem("organization")}/get-processed-data/${f[0].id}`)
                            .then(res => {
                                return res.data;
                            })
                            .catch(err => {
                                return err.response;
                            })
                    })

                    axios.all(dataReqArr).then(axios.spread((...responses) => {
                        let reqArr = [];
                        let isDataFound = responses.find(d => d ? true : false);
                        if (isDataFound) {
                            for (let r = 0; r < responses.length; r++) {
                                if (responses[r]) {
                                    reqArr.push(axios.get(responses[r].file_s3_url, {
                                        transformRequest: (data, headers) => {
                                            delete headers.common['Authorization'];
                                        }
                                    }).then(res => {
                                        return csvJSON(res.data);
                                    }).catch(err => {
                                        return err.response;
                                    }))
                                }
                            }

                            axios.all(reqArr).then(axios.spread((...responses) => {
                                // console.log('reqArr', responses);
                                let prevData = responses.map(dt => {
                                    return {
                                        isToday: false,
                                        data: dt
                                    }
                                })
                                mapData = mapData.concat(prevData);

                                getTodayDetailData(dispatch, mapData, locationDetails)
                            }))
                        } else {
                            getTodayDetailData(dispatch, mapData, locationDetails)
                        }
                    }));
                }));
            }

        })
        .catch(err => {
            return err.response;
        })







    // }
}

function getTodayDetailData(dispatch, mapData, locationDetails) {
    if (locationDetails.properties.hasTodayData) {
        dispatch({ type: 'SET_LOADER' });
        //get today data
        axios.get(`/${localStorage.getItem("organization")}/locations/${locationDetails.properties.loc_cd}/trim-data`, { headers: { accept: 'text/csv' } }).then(res => {
            mapData.push({
                isToday: true,
                data: csvJSON(res.data)
            });
            let isDataFound = mapData.find(d => d.data.length > 0);
            if (isDataFound) {
                dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
            } else {
                dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
            }
        }).catch(err => {
            let isDataFound = mapData.find(d => d.data.length > 0);
            if (isDataFound) {
                dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
            } else {
                dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
            }
        });
    } else {
        let isDataFound = mapData.find(d => d.data && d.data.length && d.data.length > 0);
        if (isDataFound) {
            dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
        } else {
            dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
        }
    }
}

export function getDetailMapCoverageData(dispatch, locationDetails, dateRange) {
    dispatch({ type: 'SET_LOADER' });

    let dateArr = [];
    let reqArr = [];


    let startDate = moment(dateRange[0]);
    let endDate = moment(dateRange[1]);
    let noOfDays = endDate.diff(startDate, 'days') + 1;
    let isToday = startDate.isSame(moment(), 'day');
    let isEndDateToday = endDate.isSame(moment(), 'day');

    // if (isToday) {

    // } else 
    if (noOfDays == 1 && !isEndDateToday) { //single day slection
        let formattedDate = startDate.format('YYYYMMDD');

        reqArr.push(axios.post(`/${localStorage.getItem("organization")}/s3/`, {
            // file_name: `summary/${formattedDate}/${locationDetails.properties.loc_cd}_location_summary.json`
            file_name: `trim/processed_summary/daywise/${formattedDate}/${locationDetails.properties.loc_cd}_${formattedDate}_location-summary.json`
        })
            .then(res => {
                // let fData = res.data.results.filter(l => {
                //     return l.file_type === 'location_data'
                // });
                return res.data;
            })
            .catch(err => {
                return null; //err.response;
            })
        );

        dateArr.push({ date: startDate, isToday })

    } else {
        //cut based selection
        let cutNo;
        // let fileName;
        // let formattedDate = startDate.format('YYYYMM');

        // if (isEndDateToday) {
        //     endDate = endDate.subtract(1, 'days');
        // }

        if (startDate.date() == 1 && endDate.date() <= 15 && startDate.isSame(endDate, 'month')) {
            cutNo = 1;
            // fileName = `grasscut_progress_${formattedDate}01_${formattedDate}${endDate.format('DD')}.json`;
        } else if (startDate.date() == 16 && startDate.isSame(endDate, 'month')) {
            let endOfMonth = startDate.endOf('month');
            if (endOfMonth.isSame(endDate, 'day')) {
                //cut 2
                cutNo = 2;
                // fileName = `grasscut_progress_${formattedDate}16_${formattedDate}${endOfMonth.format('DD')}.json`;
            } else { //if(endDate.isSame(moment(), 'day'))
                cutNo = 2;
                // fileName = `grasscut_progress_${formattedDate}16_${formattedDate}${endDate.format('DD')}.json`;
            }
        }

        if (cutNo) {

            let cutDates = locationDetails.properties.Date.split(',');

            for (let d = 0; d < cutDates.length; d++) {
                let targetDate = moment(cutDates[d], 'YYYYMMDD'); //.add(d, 'days');
                let isToday = targetDate.isSame(moment(), 'day');

                if (!isToday) {
                    let formattedDate = startDate.format('YYYYMM');
                    let formattedTargetDate = targetDate.format('YYYYMMDD')
                    let fileName = `${locationDetails.properties.loc_cd}_grasscut_progress_${formattedTargetDate}.json`

                    reqArr.push(axios.post(`/${localStorage.getItem("organization")}/s3/`, {
                        // file_name: `summary/${formattedDate}-cut${cutNo}/location/${fileName}`
                        file_name: `trim/processed_summary/cutwise/${formattedDate}/${cutNo}/location/${locationDetails.properties.loc_cd}_${formattedTargetDate}_cut-progress.json`
                    })
                        .then(res => {
                            // let fData = res.data.results.filter(l => {
                            //     return l.file_type === 'location_data'
                            // });
                            return res.data;
                        })
                        .catch(err => {
                            return null; //err.response;
                        })
                    );

                    dateArr.push({ date: targetDate, isToday })
                }
            }
        }

    }

    axios.all(reqArr).then(axios.spread((...prevDayResponses) => {

        let polygonReqArr = []
        prevDayResponses.forEach(res => {
            polygonReqArr.push(axios.get(res.file_s3_url, {
                transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                }
            })
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    return err.response;
                }))
        })

        axios.all(polygonReqArr).then(axios.spread((...responses) => {

            // console.log('coverageresp', responses, dateArr);
            let result = responses.map((res, i) => {
                return {
                    data: res,
                    date: dateArr[i]
                }
            }).filter(res => res.data)
            // console.log('coverageresp res', result);
            dispatch({ type: "GET_DETAIL_COVERAGE_DATA", payload: result })

        }));

    }));

    // axios.get(`/Summary2/${date}/${locationDetails.properties.loc_cd}_location_summary.json`,
    //     { baseURL: '' }
    //     // {
    //     //     transformRequest: (data, headers) => {
    //     //         delete headers.common['Authorization'];
    //     //     }
    //     // }
    // ).then(res => {

    //     dispatch({ type: "GET_DETAIL_COVERAGE_DATA", payload: res.data })

    // }).catch(err => {
    //     dispatch({ type: "GET_DETAIL_COVERAGE_DATA_ERROR", payload: err })
    // });
}

//var csv is the CSV file with headers
function csvJSON(csv) {
    var lines = csv.split("\n");
    var result = [];
    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
        if (lines[i]) {
            var obj = {};
            var currentline = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
    }
    return result; //JSON
}



export { BaseMapProvider, BaseMapContext, useBaseMapState };