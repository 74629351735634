import React, { Component } from 'react';
// import {render} from 'react-dom';
import { _MapContext as MapContext, StaticMap, NavigationControl, FullscreenControl } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
// import redmarker from './map-marker-red.svg'
import greenmarker from './map-marker-green.svg'
import greymarker from './map-marker-grey.svg'
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';
import moment from 'moment'

import IconClusterLayer from './icon-cluster-layer';

// Set your mapbox token here
const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXR1bGt1bXIiLCJhIjoiY2xyYWNzN2JoMDhyazJsbnh3MWR1aWU2MCJ9.BDBKCFichBcahbwbDOxmjA' //'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; // eslint-disable-line

// Source data CSV
const DATA_URL =
  'https://raw.githubusercontent.com/uber-common/deck.gl-data/master/examples/icon/meteorites.json'; // eslint-disable-line

const MAP_VIEW = new MapView({ repeat: true });
const INITIAL_VIEW_STATE = {
  longitude: 103.8146803,
  latitude: 1.3572969,
  zoom: 10.5,
  maxZoom: 20,
  pitch: 0,
  bearing: 0
};

/* eslint-disable react/no-deprecated */
export default class ClusterMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
      hoveredObject: null,
      expandedObjects: null,
      mapStyleType: 'map'
    };
    this._onHover = this._onHover.bind(this);
    this._onClick = this._onClick.bind(this);
    this._closePopup = this._closePopup.bind(this);
    this._renderhoveredItems = this._renderhoveredItems.bind(this);
  }

  _onHover(info) {
    if (this.state.expandedObjects) {
      return;
    }

    const { x, y, objects } = info;
    // if(objects)
    this.setState({ x, y, hoveredObject: objects });
  }

  _onClick(info) {
    const { showCluster = true } = this.props;
    const { x, y, objects, object } = info;

    if (object && showCluster) {
      this.setState({ x, y, expandedObjects: objects || [object] });
    } else {
      this._closePopup();
    }
  }

  _closePopup() {
    if (this.state.expandedObjects) {
      this.setState({ expandedObjects: null, hoveredObject: null });
    }
  }

  _renderhoveredItems() {
    const { x, y, hoveredObject, expandedObjects } = this.state;
    //console.log('hoveredObject',hoveredObject)

    if (expandedObjects) {
      let expandedObjectsSorted  = expandedObjects.sort((a,b)=>{
        // return b.last_seen - a.last_seen
        if(a.is_active === b.is_active){
          return b.last_seen - a.last_seen
        } else {
          return a.is_active? -1 : 1;
        }
        
      })
      return (
        <div className="realtimemap-tooltip" style={{ left: x, top: y }}>
          {expandedObjectsSorted.map(({ name, aliasName, feed_status, is_active, last_seen }) => {
            return (
              <div key={name}>
                {/* <h5>{name}</h5> */}
                <div style={{ "display": "flex", "alignItems": "center", padding: '4px' }}>
                  <img src={is_active ? greenmarker : greymarker} alt={is_active ? 'active device' : 'inactive device'}></img>
                  {/* {feed_status === 'Inactive' && <img src={greymarker} alt="inactive marker" />}
                  {feed_status === 'Norm' && <img src={greenmarker} alt="active marker" />}
                  {(feed_status !== 'Inactive' && feed_status !== 'Norm') && <img src={redmarker} alt="alert marker" />} */}
                  <div style={{ "display": 'flex', flexDirection: 'column', textAlign: 'left' }}>
                    <div style={{ "flexGrow": 1, 'fontWeight': 'bold' }}> {aliasName + ' (' + name + ')'} </div>
                    <div>{moment(parseInt(last_seen) * 1000).fromNow() + ' (' + moment.unix(parseInt(last_seen)).format('DD-MMM, hh:mm a') + ')'}</div>
                  </div>

                  {/* <div style={{"flex-grow":1}}> {feed_status} </div> */}
                </div>
                {/* <span>Status: {feed_status}</span> */}
                {/* <div>Year: {year || 'unknown'}</div>
                <div>Class: {meteorClass}</div>
                <div>Mass: {mass}g</div> */}
              </div>
            );
          })}
        </div>
      );
    }

    if (!hoveredObject) {
      return null;
    }

    // return hoveredObject.cluster ? (
    //   <div className="tooltip" style={{left: x, top: y}}>
    //     <div>{hoveredObject.point_count} devices</div>
    //   </div>
    // ) : (
    //   <div className="tooltip" style={{left: x, top: y}}>
    //     <div>
    //       {hoveredObject.name}
    //     </div>
    //     {/* <div>Status: {hoveredObject.feed_status}</div> */}
    //   </div>
    // );

    if (hoveredObject) {

      let hoveredObjectSorted  = hoveredObject.sort((a,b)=>{
        // return b.last_seen - a.last_seen
        if(a.is_active === b.is_active){
          return b.last_seen - a.last_seen
        } else {
          return a.is_active? -1 : 1;
        }
        
      })
      return (
        <div className="tooltip interactive" style={{ left: x, top: y }}>
          {hoveredObjectSorted.map(({ name, aliasName, feed_status, is_active, last_seen }) => {
            return (
              <div key={name}>
                {/* <h5>{name}</h5> */}
                <div style={{ "display": "flex", "alignItems": "center", padding: '4px' }}>
                  <img src={is_active ? greenmarker : greymarker} alt={is_active ? 'active device' : 'inactive device'}></img>
                  {/* {feed_status === 'Inactive' && <img src={greymarker} alt="inactive marker" />}
                  {feed_status === 'Norm' && <img src={greenmarker} alt="active marker" />}
                  {(feed_status !== 'Inactive' && feed_status !== 'Norm') && <img src={redmarker} alt="alert marker" />} */}
                  {/* <div style={{ "display": 'flex' }}>
                    <div style={{ "flexGrow": 1, 'fontWeight': 'bold' }}> {aliasName} </div>
                    <div style={{ "flexGrow": 1, 'fontWeight': 'bold', 'fontSize': '10px' }}> {'(' + name + ')'} </div>
                  </div> */}
                  <div style={{ "display": 'flex', flexDirection: 'column', textAlign: 'left' }}>
                    <div style={{ "flexGrow": 1, 'fontWeight': 'bold' }}> {aliasName + ' (' + name + ')'} </div>
                    <div>{moment(parseInt(last_seen) * 1000).fromNow() + ' (' + moment.unix(parseInt(last_seen)).format('DD-MMM, hh:mm a') + ')'}</div>
                  </div>
                  {/* <div style={{"flex-grow":1}}> {feed_status} </div> */}
                </div>
                {/* <span>Status: {feed_status}</span> */}
                {/* <div>Year: {year || 'unknown'}</div>
                <div>Class: {meteorClass}</div>
                <div>Mass: {mass}g</div> */}
              </div>
            );
          })}
        </div>
      );
    }
  }

  _renderLayers() {
    const {
      data = DATA_URL,
      iconMapping = 'data/location-icon-mapping.json',
      iconAtlas = 'data/location-icon-atlas.svg',
      showCluster = true
    } = this.props;

    const layerProps = {
      data,
      pickable: true,
      getPosition: d => d.coordinates,
      iconAtlas,
      iconMapping,
      onHover: this._onHover
    };

    const layer = showCluster
      ? new IconClusterLayer({ ...layerProps, id: 'icon-cluster', sizeScale: 60 })
      : new IconLayer({
        ...layerProps,
        id: 'icon',
        getIcon: d => 'marker',
        sizeUnits: 'meters',
        sizeScale: 2000,
        sizeMinPixels: 6
      });

    return [layer];
  }

  render() {
    // const {mapStyle = 'mapbox://styles/mapbox/dark-v9'} = this.props;
    // const mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef';
    let mapStyle = '';
    const { mapStyleType } = this.state;
    if (mapStyleType === 'satellite') {
      mapStyle = 'mapbox://styles/mapbox/satellite-v9';
    } else {
      mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef'
    }

    return (
      <div id="cls-map">
        <DeckGL
          layers={this._renderLayers()}
          views={MAP_VIEW}
          initialViewState={INITIAL_VIEW_STATE}
          controller={{ dragRotate: false }}
          onViewStateChange={this._closePopup}
          onClick={this._onClick}
          ContextProvider={MapContext.Provider}
        >
          <StaticMap
            reuseMaps
            mapStyle={mapStyle}
            preventStyleDiffing={true}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          />

          <div style={{ position: 'absolute', right: 10, top: 10 }}>
            <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
              onClick={() => this.setState({ mapStyleType: 'satellite' })}
              hidden={mapStyleType === 'satellite'} >
              <SatelliteIcon variant="filled"></SatelliteIcon>
            </div>
            <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
              onClick={() => this.setState({ mapStyleType: 'map' })}
              hidden={mapStyleType === 'map'}>
              <MapIcon variant="filled"></MapIcon>
            </div>
            <FullscreenControl container={document.getElementById('rt-map')} />
            <NavigationControl showCompass={false} />
          </div>

          
        </DeckGL>
        {this._renderhoveredItems()}
      </div>
    );
  }
}

// export function renderToDOM(container) {
//   render(<App />, container);
// }
