import React from "react";
import axios from "axios";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "GET_DEVICES_HEALTH":
      return { ...state, 
      devicesHealth: action.payload,
      devicesHealthError: ''    
      };
    case "GET_DEVICES_HEALTH_ERROR":
      return  { ...state, 
        devicesHealth: null,
        devicesHealthError:  action.payload    
        };
    default: 
      return state;
    // {
    //   throw new Error(`Unhandled action type: ${action.type}`);
    // }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: false,
    devicesHealth: null,
    devicesHealthError: ''
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export function getDevicesHealth(dispatch) {
  return axios.get(`/${localStorage.getItem("organization")}/devices-health/`).then(res => {
    dispatch({ type: "GET_DEVICES_HEALTH", payload: res.data });
  }).catch(err => {
    dispatch({ type: "GET_DEVICES_HEALTH_ERROR", payload: err });
  });
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}
