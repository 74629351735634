import React, { useState, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import Input from '@material-ui/core/Input'

import useStyles from "./styles";

function Filter(props) {

    // let allChecked = props.options.map((item, index) => {
    //     return index;
    // })
    // let fL = [];

    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [filteredList, setfilteredList] = useState([])
    const [fullList, setfullList] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isFilterChanged, setisFilterChanged] = useState(false)

    const [selectedLabel, setSelectedLabel] = React.useState('All ' + props.description);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setisFilterChanged(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setfilteredList(fullList);

        let selectelabel = '';
        if (checked.length === 0) {
            selectelabel = 'NONE'
        } else if (checked.length === props.options.length) {
            selectelabel = 'All ' + props.description;
        } else if (checked.length > 0) {
            selectelabel = props.options[checked[0]].label;
            if (checked.length > 1) {
                selectelabel += ', +' + (checked.length - 1) + ' ' + props.description;
            }
        }
        setSelectedLabel(selectelabel);

        if (isFilterChanged && props.onFilterChange) {
            let filterSelected = props.options.map((item, index) => {
                // let childarr = [];
                return { ...item, checked: (checked.indexOf(index) !== -1) }
            })
            props.onFilterChange(filterSelected)
            setisFilterChanged(false);
        }
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        const currentIndex = checked.indexOf(index);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(index);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setisFilterChanged(true);
    };

    const handleAllToggle = () => () => {
        let newChecked = [];
        if(checked.length!==fullList.length){
            newChecked = fullList.map((li,index)=>index)
        }
        setChecked(newChecked);
        setisFilterChanged(true);
    };

    // useEffect(() => {
    //     setfilteredList(fL);
    // }, [])


    useEffect(() => {
        let allChecked = props.options.map((item, index) => {
            return index;
        })
        setChecked(allChecked);

        let fL = props.options.map((item, index) => {
            return {
                item,
                index
            }
        })
        setfullList(fL);
        setfilteredList(fL);

        if (props.options.length === 0) {
            setSelectedLabel('NONE')
        } else {
            setSelectedLabel('All ' + props.description)
        }
        
        // eslint-disable-next-line
    }, [props.options])





    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={props.className}>
            <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                <div className={classes.label} title={selectedLabel}>
                    <div className={classes.labelText}>{props.label}</div>
                    <div className={classes.value}>{selectedLabel}</div>
                </div>
                {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {(props.search) ? (<Input placeholder="Search" className={classes.srch} onChange={(event) => {
                    let tFL = fullList.filter(item => {
                        return (item.item.label.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) ? true : false;
                    })
                    setfilteredList(tFL);
                }} />) : null}
                <List className={classes.list}>
                    {fullList && fullList.length>0 && <ListItem key="all" dense button onClick={handleAllToggle()}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.length===fullList.length}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'all-toggle' }}
                                color="primary"
                            />
                        </ListItemIcon>
                        <ListItemText id='all-toggle' primary={`All ${props.description}`} className={classes.litxt} />
                    </ListItem>}
                    {filteredList.map((value, index) => {
                        const labelId = `checkbox-list-label-${value.index}`;

                        return (
                            <ListItem key={value.index} dense button onClick={handleToggle(value.item, value.index)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value.index) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.item.label}`} className={classes.litxt} />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </div>
    )
}

export default Filter
