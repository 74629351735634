import React, { Component } from 'react'
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { WebMercatorViewport } from 'react-map-gl';
import moment from 'moment'
import { GeoJsonLayer, ColumnLayer } from '@deck.gl/layers';
import { DataFilterExtension } from '@deck.gl/extensions';
import { squareGrid, flatten, bbox, booleanContains, booleanOverlap, polygon, difference, intersect, pointsWithinPolygon, point, featureCollection, collect, bboxPolygon, buffer, dissolve, combine, area } from '@turf/turf';
// import { ScatterplotLayer } from '@deck.gl/layers';

const google = window.google;

const INITIAL_VIEW_STATE = {
    longitude: 103.8146803,
    latitude: 1.4572969,
    zoom: 15,
    minZoom: 5,
    maxZoom: 22,
    pitch: 15,
    bearing: 0
};

const elevationScale = { min: 0, max: 1 };

// const applyToArray = (func, array) => func.apply(Math, array)
// test comment

const getBoundsForPoints = (cornersLongLat) => {
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({ width: window.innerWidth - 84, height: 400 })
        .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport
    return { longitude, latitude, zoom }
}

const dataFilter = new DataFilterExtension({
    filterSize: 1,
    fp64: false
});
export default class GoogleDetailMap extends Component {
    constructor(props) {
        super(props);
        // this.google_maps_api_key = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0'
        this.api = null;
        this.map = null;
        this.overlay = null;

        this.state = {
            elevationScale: elevationScale.min,
            x: 0,
            y: 0,
            tooltip: {},
            radius: 1.5,
            viewState: INITIAL_VIEW_STATE,
            geoJson: null,
            mapStyleType: 'map',
            coverageGeoJson: null,
            toolTipInfo: null
            // timeRange,
            // filterValue: timeRange
        };
        this._renderhoveredItems = this._renderhoveredItems.bind(this);
        this.selectedMaplayer = (this.props.mapLayers.filter(d => d.checked))[0]

    }

    // Load the Google Maps Platform JS API async
    // loadScript() {
    //     const GOOGLE_MAPS_API_KEY = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0';
    //     const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    //     const head = document.querySelector('head');
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = GOOGLE_MAPS_API_URL;
    //     head.appendChild(script);
    //     return new Promise(resolve => {
    //         script.onload = resolve;
    //     });
    // }

    async initMapWithOverlay(options) {
        // Init the Google Maps JS API and base map
        // await this.loadScript();
        this.api = google.maps;
        this.overlay = new GoogleMapsOverlay();

        const mapElement = document.getElementById('gmapdetail');
        if (mapElement) {
            this.map = new this.api.Map(mapElement, {
                center: { lng: 103.8146803, lat: 1.4572969 },
                zoom: 12,
                // styles: map_styles,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    // style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: google.maps.ControlPosition.RIGHT_TOP
                },
            });
            // Put the Deck.gl overlay on the map
            this.overlay.setMap(this.map);
        }
    }

    // Reposition the map for selected demo
    setMap(map_options) {
        this.map.setCenter(map_options.center);
        this.map.setZoom(map_options.zoom);
    }

    setLayer(deckgl_layers) {
        this.overlay.setProps({ layers: deckgl_layers });
    }

    componentDidMount() {
        this.initMapWithOverlay();

        this.modifyGeoJson()
        this.modifyCoverageGeoJson();
        // this.setViewPort();

        let { locationData, filterValue } = this.props;
        if (locationData && locationData.properties && locationData.properties.cornersLatLng) {
            const bounds = getBoundsForPoints(locationData.properties.cornersLatLng)
            let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };

            this.map.setCenter({ lng: bounds.longitude, lat: bounds.latitude });
            this.map.setZoom(bounds.zoom);

            this.setState({ viewState: currentViewState });
        }
        if (filterValue) {
            this.setState({ filterValue: filterValue });
        }

        if (this.props.mapStyleType) {
            this.setState({ mapStyleType: this.props.mapStyleType })
        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.locationData !== this.props.locationData) {
            let { locationData } = this.props;

            this.modifyGeoJson();

            if (locationData && locationData.properties && locationData.properties.cornersLatLng) {
                const bounds = getBoundsForPoints(locationData.properties.cornersLatLng)
                let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };

                this.map.setCenter({ lng: bounds.longitude, lat: bounds.latitude });
                this.map.setZoom(bounds.zoom);

                this.setState({ viewState: currentViewState });
            }
        }

        if (prevProps.coverage !== this.props.coverage) {
            this.modifyCoverageGeoJson();
        }

        if (prevProps.filterValue !== this.props.filterValue) {
            // console.log('filterValue', this.props.filterValue)
            this.setState({ filterValue: this.props.filterValue }, () => {
                this.overlay.setProps({ layers: this._renderLayers() });
            });
        }

        if (prevProps.mapStyleType !== this.props.mapStyleType) {
            this.setState({ mapStyleType: this.props.mapStyleType })
        }
        if (prevProps.data !== this.props.data) {

            // if(this.props.locationData.properties.hasTodayData){
            //     // this.getCoverageJson()
            // } else {
            this.overlay.setProps({ layers: this._renderLayers() });
            // }
        }
        if (prevProps.todayCoverage !== this.props.todayCoverage) {
            this.modifyCoverageGeoJson();
            // if(this.props.locationData.properties.hasTodayData){
            //     // this.getCoverageJson()
            // } else {
            // this.overlay.setProps({ layers: this._renderLayers() });
            // }
        }
        if (prevProps.mapLayers !== this.props.mapLayers) {
            this.overlay.setProps({ layers: this._renderLayers() });
        }

        if (prevProps.showCoverage !== this.props.showCoverage) {
            this.overlay.setProps({ layers: this._renderLayers() });
        }
    }

    getCoverageJson() {
        const { data, mapLayers, showCoverage } = this.props;
        let { coverageGeoJson } = this.state;
        if (!coverageGeoJson && data) {
            let mapGeoJson = null;


            let pointsArr = data.map(d => {
                let pointJson = point(d.geometry, { value: d })
                return buffer(pointJson, 0.001, { units: 'kilometers' })
            })
            let pointsCollection = featureCollection(pointsArr);

            let disssolvedJson = dissolve(pointsCollection);
            // let nextdisssolvedJson = null; //dissolve(disssolvedJson)
            // let itr = 0;
            // do {
            //     console.log('itr', itr)
            //     if (itr > 0) {
            //         disssolvedJson = nextdisssolvedJson;
            //     }
            //     nextdisssolvedJson = dissolve(disssolvedJson);
            //     itr++;
            // } while (disssolvedJson.features.length != nextdisssolvedJson.features.length)

            // let disssolvedJson = dissolve(pointsCollection);
            // let disssolvedJson2 = dissolve(disssolvedJson);
            // let disssolvedJson3 = dissolve(disssolvedJson2);
            // let disssolvedJson4 = dissolve(disssolvedJson3);
            // let disssolvedJson5 = dissolve(disssolvedJson4);

            // let combinedjson = combine(disssolvedJson)

            let cutArea = area(disssolvedJson)
            let calCutArea = data.length * 1.8;
            // console.log('area', cutArea, ' calc area ', calCutArea)

            // let oprHrs = moment().startOf('day').seconds(data.length).format('hh:mm:ss')

            let hrs = parseInt(data.length / (60 * 60));
            let remsecs = data.length % (60 * 60);
            let mins = parseInt(remsecs / 60);
            let secs = remsecs % 60;
            // let secs = data.length%60;
            let oprHrs = `${('00' + hrs).slice(-2)}:${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`

            // console.log('area', cutArea, ' calc area ', calCutArea, ' oprHrs ', oprHrs, ' data.length', data.length)


            this.setState({ coverageGeoJson: disssolvedJson }, () => {
                this.overlay.setProps({ layers: this._renderLayers() });
            });
        }
    }

    _renderhoveredItems() {
        const { x, y, tooltip, toolTipInfo } = this.state;

        if (tooltip && tooltip.points) {

            let pmin = tooltip.points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
            // let mind = tooltip.points.filter(d => d.value == tmin);

            return <div className="tooltip" style={{ left: x, top: y }}>
                {/* <div>Device Type: {mind[0].deviceType}</div> */}
                {/*<div>Device: {pmin.deviceType}</div>*/}
                <div>Grass Height: {pmin.value}cm</div>
                <div>Time: {moment(pmin.time).format('DD-MMM-YYYY hh:mm a')}</div>
            </div>
        } else if (tooltip && tooltip.time) {
            // console.log('tooltip', tooltip)
            return <div className="tooltip" style={{ left: x, top: y }}>
                {/* <div>Device Type: {mind[0].deviceType}</div> */}
                {/*<div>Device: {pmin.deviceType}</div>*/}
                {/* <div>Device: {tooltip.deviceName}</div> */}
                {/* <div>Grass Height: {tooltip.value}cm</div> */}
                {/* {(tooltip.value !== null)? <div>Grass Height: {tooltip.value} cm</div> : <div>Grass Height: null</div>} */}
                {/* <div>Time: {moment(tooltip.time).format('DD-MMM-YYYY hh:mm:ss a')}</div> */}
                {/* <div>Time: {tooltip.timeStamp}</div> */}
                <div className="drow"><div className="drowlabel">Time</div><div className="drowvalue">{moment(tooltip.time).format('DD-MMM-YYYY hh:mm:ss a')}</div></div>
                <div className="drow"><div className="drowlabel">Grass Height</div><div className="drowvalue">{tooltip.value !== null ? tooltip.value + ' cm' : 'null'}</div></div>
                <div className="drow"><div className="drowlabel">Device</div><div className="drowvalue">{tooltip.deviceName}</div></div>
                <div className="drow"><div className="drowlabel">Actual Distance</div><div className="drowvalue">{(tooltip.distance != undefined && tooltip.distance != '') ? (tooltip.distance + 'cm') : '-'}</div></div>
                <div className="drow"><div className="drowlabel">GPS Accuracy</div><div className="drowvalue">{(tooltip.accuracy != undefined && tooltip.accuracy != '') ? (tooltip.accuracy) : '-'}</div></div>
                <div className="drow"><div className="drowlabel">Error</div><div className="drowvalue">{tooltip.error}</div></div>
                <div className="drow"><div className="drowlabel">Speed</div><div className="drowvalue">{(tooltip.SOG != undefined && tooltip.SOG != '') ? (tooltip.SOG + 'kmph') : '-'}</div></div>
            </div>
        } else if (tooltip && tooltip.properties) {
            let hasToday = false;
            return <div className="tooltip" style={{ left: x, top: y }}>
                <div className="title">{tooltip.properties.loc_desc || tooltip.properties.loc_cd}</div>
                <div className="drow"><div className="drowlabel">Total Area</div><div className="drowvalue">{(toolTipInfo && toolTipInfo.length > 0) ? +toolTipInfo[0].props.PolygonArea.toFixed(3) : '-'} sqm</div></div>
                {/* <div className="drow"><div className="drowlabel">Total Area</div><div className="drowvalue">{tooltip.properties.PolygonArea} ha</div></div>
                <div className="drow"><div className="drowlabel">Coverage</div><div className="drowvalue">{tooltip.properties.Coverage} %</div></div>
                <div className="drow"><div className="drowlabel">Operational Hours</div><div className="drowvalue">{+(tooltip.properties.TotalOperationalDuration / 60).toFixed(2)} hrs</div></div> */}
                {/* <div>Time: {moment(tooltip.time).format('DD-MMM-YYYY hh:mm:ss a')}</div> */}
                {/* <div>Time: {tooltip.timeStamp}</div> */}
                <table>
                    <thead>
                        <tr>
                            <td className="drowvalue">Date</td>
                            <td className="drowvalue">Area of Grass Cut (in sqm)</td>
                            <td className="drowvalue">Area of Grass Cut (in %)</td>
                            <td className="drowvalue">Operational Hours</td>
                            <td className="drowvalue">Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {toolTipInfo && toolTipInfo.map((c, i) => {
                            let formattedDate = '';
                            let isToday = false;
                            if (c.props.lastCut) {
                                let tDate = moment(c.props.lastCut, 'YYYYMMDD');
                                formattedDate = tDate.format('DD-MM-YYYY');
                                isToday = tDate.isSame(moment(), 'day')
                                if (isToday) {
                                    // formattedDate += ' *(approx)'
                                    hasToday = true;
                                }
                            }

                            return (<tr key={i}>
                                <td className="drowvalue" style={{ whiteSpace: 'nowrap' }}>{formattedDate}</td>
                                <td><div className="drowvalue">{isToday ? '*' : ''}{c.props.GrassCutArea} sqm</div></td>
                                <td><div className="drowvalue">{isToday ? '*' : ''}{c.props.Coverage} %</div></td>
                                <td><div className="drowvalue">{isToday ? '*' : ''}{c.props.TotalOperationalDuration} hrs</div></td>
                                <td><div className="drowvalue">{c.props.JobCompletion == '1' ? 'Completed' : 'Inprogress'}</div></td>
                            </tr>)
                        })}
                        {hasToday && <tr><td colSpan="5" style={{paddingTop: 5}}>Note : Values with * represent approximate values</td></tr>}
                    </tbody>
                </table>
            </div>
        }
        return null;
    }

    getGradientColor(grassHeight) {
        if (grassHeight === 0 || grassHeight === null) {
            return [125, 125, 125]
        }
        else if (grassHeight > 10) {
            return [128, 10, 10]
        }
        // else if (grassHeight >= 10) {
        //     return [128, 10, 10]
        // }
        // else if (grassHeight > 5 && grassHeight < 10) {
        //     var redValue = (grassHeight - 5) * 20
        //     var greenValue = (grassHeight - 5) * 10
        //     return [redValue + 120, 100 - greenValue, 20]
        // }
        else if (grassHeight > 0 && grassHeight <= 10) {
            return [1, 128, 1]
        } else{
            return [71, 71, 71]
        }
    }

    _renderLayers() {
        // radius = 1, upperPercentile = 100, coverage = 2, locationData
        const { data, mapLayers, showCoverage } = this.props;
        const { filterValue, geoJson, coverageGeoJson } = this.state;
        // console.log('mapData.length', data.length);
        let mapData = data;
        this.selectedMaplayer = (mapLayers.filter(d => d.checked))[0];
        // if (!mapLayers[checkedParent].checked) {
        mapData = data.filter((d) => {
            for (let i = 0; i < this.selectedMaplayer.children.length; i++) {
                if (this.selectedMaplayer.children[i].checked && this.selectedMaplayer.children[i].isValid(d[this.selectedMaplayer.propName])) {
                    return true;
                }
            }
            return false;
        })
        // }
        let colorArr = this.selectedMaplayer.children.map(child => {
            return child.colorArr;
        })
        let filterPropName = this.selectedMaplayer.propName;

        // let currentViewState = {}
        // if (data && data.length > 0 && data[0].geometry) {
        //   currentViewState = { longitude: data[0].geometry[0], latitude: data[0].geometry[1], zoom: 16 };
        // }
        // this.setState({viewState: currentViewState});

        let viewLayers = [

            new GeoJsonLayer({
                id: 'geojson',
                data: geoJson,
                //opacity: 0.5,
                stroked: true,
                // filled: true,
                extruded: false,
                // wireframe: false,
                getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                getFillColor: f => [171, 171, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
                // getFillColor: f => {
                //     let colorIndex = 0

                //     let children = this.selectedMaplayer.children;
                //     for (let i = 0; i < children.length; i++) {
                //         if (children[i].checked && children[i].isValid(f[filterPropName])) {
                //             colorIndex = i;
                //         }
                //     }
                //     return colorArr[colorIndex]
                // }
                // getLineColor: [255, 255, 255],
                pickable: true,
                onHover: (info, ev) => {
                    let { object, x, y } = info;
                    // console.log('tooltip', info, ev);
                    if (this.props.showCoverage) {
                        this.setState({ tooltip: object, x, y })
                    }
                },
                // onClick: this._onClick
            })

            // new HexagonLayer({
            //   id: 'hexagon-layer-all',
            //   data: mapData,
            //   pickable: true,
            //   extruded: false,
            //   radius: this.state.radius,
            //   elevationScale: 0,
            //   getPosition: d => d.geometry,
            //   onHover: ({ object, x, y }) => {
            //     // console.log(object);
            //     this.setState({ tooltip: object, x, y })
            //     //   const tooltip = `${object.centroid.join(', ')}\nCount: ${object.points.length}`;
            //     /* Update tooltip
            //        http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
            //     */
            //   },
            //   getElevationValue: () => 0,
            //   getElevationWeight: () => 0,
            //   elevationRange: [0, 0],
            //   colorDomain: [0, colorArr.length - 1],
            //   colorRange: colorArr,
            //   // [
            //   //   [99, 194, 146, 255],
            //   //   [255, 186, 90, 255],
            //   //   [235, 69, 89, 255]
            //   // ],
            //   getColorValue: (points) => {
            //     // console.log('domain', points);
            //     let pmin = points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
            //     // let mind = points.filter(d=>d.value==tmin);
            //     // if(mind[0].deviceType=='D1'){
            //     //   return 0;
            //     // }
            //     // if(mind[0].deviceType=='D2'){
            //     //   return 1;
            //     // }
            //     // if(mind[0].deviceType=='D3'){
            //     //   return 2;
            //     // }
            //     let colorIndex = 0

            //     let children = this.selectedMaplayer.children;
            //     for (let i = 0; i < children.length; i++) {
            //       if (children[i].checked && children[i].isValid(pmin[filterPropName])) {
            //         colorIndex = i;
            //       }
            //     }
            //     // console.log('points',points,tmin, colorIndex);
            //     return colorIndex;

            //     // if (tmin <= 3) {
            //     //   return 0;
            //     // } else if (tmin > 3 && tmin <= 7) {
            //     //   return 1;
            //     // } else {
            //     //   return 2;
            //     // }
            //   },
            //   // // props added by DataFilterExtension
            //   // getFilterValue: d => d.value,  // in seconds
            //   // filterRange: [0, 3],  // 12:00 - 13:00

            //   // // Define extensions
            //   // extensions: [new DataFilterExtension({ filterSize: 1 })]
            // })
        ];

        if (coverageGeoJson && showCoverage) {
            viewLayers.push(new GeoJsonLayer({
                id: 'geojson1',
                data: coverageGeoJson,
                //opacity: 0.5,
                stroked: true,
                // filled: true,
                extruded: false,
                lineWidthMaxPixels: 1,
                getLineWidth: d => 1,
                // wireframe: false,
                getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                getFillColor: f => [99, 194, 146], //[0, 0, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
                // getFillColor: f => {
                //     let colorIndex = 0

                //     let children = this.selectedMaplayer.children;
                //     for (let i = 0; i < children.length; i++) {
                //         if (children[i].checked && children[i].isValid(f[filterPropName])) {
                //             colorIndex = i;
                //         }
                //     }
                //     return colorArr[colorIndex]
                // }
                // getLineColor: [255, 255, 255],
                // pickable: true,
                // onHover: (info, ev) => {
                //     let { object, x, y } =  info;
                //     // console.log('tooltip', info, ev);
                //     this.setState({ tooltip: object, x, y })
                // },
                // // onClick: this._onClick
                // onClick: (event) => {
                //     console.log('click', event);
                //     this.setState({ tooltip: null });
                //     // this.props.onAreaSelected(this.props.geoJsons[event.object.parentIndex]);
                // }
            }))
        }

        if (filterValue && !showCoverage) {
            // console.log('mapData', mapData)
            viewLayers.push(new ColumnLayer({
                id: 'column-layer',
                data: mapData,
                diskResolution: 6,
                radius: 1.5,
                extruded: false,
                stroked: false,
                pickable: true,
                elevationScale: 0,
                getPosition: d => d.geometry,
                getLineColor: [0, 0, 0],
                getElevation: d => 0,
                getFilterValue: d => d.timeStamp,
                // getLineWidth: 1,
                // lineWidthMinPixels: 1,
                // lineWidthMaxPixels: 1,
                getFillColor: d => {
                    // [99, 194, 146, 200]

                    if (filterPropName == 'value') {
                        // console.log(d)
                        return this.getGradientColor(d[filterPropName])
                    } else {
                        let colorIndex = 0;
                        let children = this.selectedMaplayer.children;
                        for (let i = 0; i < children.length; i++) {
                            if (children[i].checked && children[i].isValid(d[filterPropName])) {
                                colorIndex = i;
                            }
                        }
                        return colorArr[colorIndex];
                    }
                },
                filterRange: [filterValue[0], filterValue[1]],
                // filterSoftRange: [
                //   filterValue[0] * 0.9 + filterValue[1] * 0.1,
                //   filterValue[0] * 0.1 + filterValue[1] * 0.9
                // ],
                extensions: [dataFilter],
                onHover: ({ object, x, y }) => {
                    // console.log('tooltip', object);
                    this.setState({ tooltip: object, x, y })
                }
            }))
        }

        return viewLayers;
    }

    modifyGeoJson() {
        let { locationData } = this.props;
        // let polygons = []
        // if (locationData && locationData.geometry && locationData.geometry.coordinates && locationData.geometry.coordinates.length > 0) {
        //     // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
        //     polygons = locationData.geometry.coordinates.map(c => {
        //         let mItem = {
        //             ...locationData,
        //             geometry: {
        //                 type: 'Polygon',
        //                 coordinates: [c]
        //             }
        //         }
        //         return mItem;
        //     })
        // }
        // const mapGeoJson = {
        //     "type": "FeatureCollection",
        //     features: polygons
        // }
        // console.log('modifyGeoJson',mapGeoJson)
        this.setState({ geoJson: locationData }
            , () => {
                this.overlay.setProps({ layers: this._renderLayers() });
            });
    }

    modifyCoverageGeoJson() {
        let { coverage, todayCoverage } = this.props;
        // console.log('detail coverage', coverage)
        let polygons = []
        let mapGeoJson = null
        let toolTipInfo = []
        if (coverage && coverage.length > 0) {
            coverage.forEach(res => {
                // let res = coverage[coverage.length - 1];
                // console.log('detail coverage res', res)
                if (res.data && res.data.features) {

                    if (res.data.features.length > 0) {
                        toolTipInfo.push({
                            date: res.date,
                            props: res.data.features[0].properties
                        })
                    }
                    res.data.features.forEach(f => {
                        if (f.properties.Date) {
                            let datesCut = f.properties.Date.split(',')
                            f.properties.lastCut = datesCut[datesCut.length - 1];
                        }
                        // console.log('detail coverage res f', f)

                        // if (f.geometry.geometries) {
                        //     f.geometry.geometries.forEach(g => {
                        //         if (g.coordinates) {
                        //             let polys = g.coordinates.map(c => {
                        //                 let mItem = {
                        //                     properties: f.properties,
                        //                     geometry: {
                        //                         type: 'Polygon',
                        //                         coordinates: [c[0]]
                        //                     }
                        //                 }
                        //                 return mItem;
                        //             })
                        //             polygons = polygons.concat(polys)
                        //         }
                        //     })
                        // }

                        // if (f.geometry.coordinates && f.geometry.coordinates.length > 0) {
                        //     // let coordinated = f.geometry.coordinates.map(c=>c[0]);
                        //     // console.log('coordinated', coordinated)
                        //     f.geometry.coordinates.forEach(ci => {
                        //         let polys = ci.map(c => {

                        //             let mItem = {
                        //                 properties: f.properties,
                        //                 geometry: {
                        //                     type: 'Polygon',
                        //                     coordinates: [c]
                        //                 }
                        //             }
                        //             return mItem;
                        //         })
                        //         polygons = polygons.concat(polys)
                        //     })

                        // }
                    })
                }
            })


            let gjson = coverage.map(d => {
                // if (d.data.features && d.data.features[0] && d.data.features[0].geometry && d.data.features[0].geometry.geometries) {
                //     d.data.features[0].geometry.geometries = d.data.features[0].geometry.geometries.filter(d => d.type != 'GeometryCollection');
                // }
                return d.data;
            })
            if (gjson.length > 1) {
                gjson = [gjson[gjson.length - 1]]
            }
            // console.log('this.parseGeoJson', gjson)
            mapGeoJson = this.parseGeoJson(gjson)
            // console.log('this.mapGeoJson', mapGeoJson)
            // this.map.data.addGeoJson(gjson[0])
            // this.map.data.setStyle((feature) => {
            //     console.log(feature)
            //     var color = 'FF0000';
            //     var symbol = '%E2%80%A2';  // dot

            //     return /** @type {google.maps.Data.StyleOptions} */ {
            //         fillColor: 'red',
            //         // visible: feature.getProperty('active'), // this links visibility to feature property
            //         // icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=' + symbol + '|' + color
            //     };
            // })

            // this.map.data.addListener('mouseover', (event) => {


            //     this.setState({ tooltip: { properties: event.feature.j } })

            //     // console.log('event.feature', event.feature)
            //     // document.getElementById('info-box').textContent =
            //         // event.feature.getProperty('name');
            //   });
        }
        // const mapGeoJson = {
        //     "type": "FeatureCollection",
        //     features: polygons
        // }

        if (todayCoverage) {

            // let pointsCollection = featureCollection(mapGeoJson);

            // let disssolvedJson = dissolve(pointsCollection);

            mapGeoJson = todayCoverage.coverageJson;
        }

        // console.log('modifyCoverageGeoJson', mapGeoJson, coverage)
        this.setState({ coverageGeoJson: mapGeoJson }
            , () => {
                this.overlay.setProps({ layers: this._renderLayers() });
            });
        // console.log('toolTipInfo', toolTipInfo);
        this.setState({ toolTipInfo: toolTipInfo })
    }

    _getTimeRange(data) {
        if (!data) {
            return null;
        }
        return data.reduce(
            (range, d) => {
                const t = d.timeStamp;
                range[0] = Math.min(range[0], t)
                range[1] = Math.max(range[1], t)
                return range;
            },
            [Infinity, -Infinity]
        );
    }

    _formatLabel(t) {
        const date = new Date(t);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit', month: 'short'
        }).replace(/ /g, '-');
        const formattedTime = date.toLocaleTimeString([],
            { hour: '2-digit', minute: '2-digit' })
        return formattedDate + '-' + formattedTime
    }
    changeVal(val) {
        this.setState({ filterValue: val })
    }

    render() {
        return (
            <div>
                <div id="gmapdetail" style={{ height: 'calc(100vh - 64px)' }}></div>
                {this._renderhoveredItems()}
            </div>
        )
    }

    parseGeoJson(inpJson) {
        let gFeatures = [];

        const getPolygons = (gJson, properties) => {
            switch (gJson.type) {
                case 'FeatureCollection':
                    gJson.features.forEach(f => {
                        getPolygons(f);
                    })
                    break;
                case 'Feature':
                    if (gJson.geometry) getPolygons(gJson.geometry, gJson.properties)
                    if (gJson.geometries) {
                        gJson.geometries.forEach(g => {
                            getPolygons(g)
                        })
                    }
                    break;
                case 'GeometryCollection':
                    if (gJson.geometries) {
                        gJson.geometries.forEach(g => {
                            getPolygons(g, properties)
                        })
                    }
                    break;
                case 'MultiPolygon':
                case 'Polygon':
                    // case 'MultiPoint':
                    // case 'Point':
                    // case 'LineString':
                    // case 'MultiLineString':
                    let polygonItem = {
                        type: 'Feature',
                        properties,
                        geometry: gJson
                    }
                    gFeatures.push(polygonItem);
                    break;
            }
        }
        if (Array.isArray(inpJson)) {
            inpJson.forEach(j => {
                getPolygons(j);
            })
        } else {
            getPolygons(inpJson);
        }

        let finalJson = {
            type: 'FeatureCollection',
            features: gFeatures
        }
        // console.log('gFeatures', finalJson)
        return finalJson;
    }


}
