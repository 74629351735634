import React, { useState, Component, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import logo from "./nparks1.png";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import useStyles from "./styles";

export default function VerifyEmail(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [onLoad, setOnLoad] = React.useState(0);
  const [tokenId, setTokenId] = React.useState('')
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTokenId(props.match.params.token)
  }, [onLoad]);

  function onSubmitButton() {
    var tempArr = {key : tokenId }
    axios.post(`/verify-email`, tempArr)
      .then(res => {
        enqueueSnackbar('Email Verified Successfully', { variant: 'success' });
        setTimeout(() => {
          handleBackButton()
        }, 300);
      }).catch(err => {
        var message = err.response.data;
        var key = Object.keys(message)[0];
        enqueueSnackbar(message[key][0], { variant: 'warning' });
        setTimeout(() => {
          handleBackButton()
        }, 300);
      });
  }
  function handleBackButton() {
    history.push({
      pathname: '/login',
    });
  }
  return (
    <Grid container className={classes.container}>
      <div className={classes.root}>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <div className={classes.textDetail}>
          TRIM Dashboard
        </div>
        <div className={classes.textSubDetail}>
          Powered by SpaceAge Labs
        </div>
        <div className={classes.textVerifyEmail}>
          Please Click the Button to Verify Email
        </div>
        <form className={classes.root} autoComplete="off">
          <div style={{ textAlign : 'center' }} >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onSubmitButton}
              className={classes.button}>
              Verify Email
            </Button>
          </div>
        </form>
      </Paper>
    </Grid>

  )
};