import React, { Component } from 'react'
import { WebMercatorViewport } from 'react-map-gl';
import { GeoJsonLayer } from '@deck.gl/layers';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
// import { map_styles } from './map_styles';
const google = window.google;

const INITIAL_VIEW_STATE = {
    longitude: 103.8146803,
    latitude: 1.3572969,
    // longitude: 103.85945279634636, latitude: 1.3000360700768983,
    // longitude:103.81506192440402, latitude:1.461421158707049,
    zoom: 11,
    maxZoom: 20,
    pitch: 0,
    bearing: 0
}

// const colorArr = {
//     "Good": [99, 194, 146],
//     "Medium": [255, 186, 90],
//     "Bad": [235, 69, 89]
// }

const colorArr = {
    "1": [99, 194, 146],
    "0": [235, 69, 89]
}

export default class GoogleBaseMap extends Component {
    constructor(props) {
        super(props);
        // this.google_maps_api_key = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0'
        this.api = null;
        this.map = null;
        this.overlay = null;

        this.state = {
            hoveredObject: null,
            viewState: INITIAL_VIEW_STATE,
            geoJson: null,
            mapStyleType: 'map'
        };

    }

    // Load the Google Maps Platform JS API async
    // loadScript() {
    //     const GOOGLE_MAPS_API_KEY = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0';
    //     const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    //     const head = document.querySelector('head');
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = GOOGLE_MAPS_API_URL;
    //     head.appendChild(script);
    //     return new Promise(resolve => {
    //         script.onload = resolve;
    //     });
    // }

    async initMapWithOverlay(options) {
        // Init the Google Maps JS API and base map
        // await this.loadScript();
        this.api = google.maps;
        this.overlay = new GoogleMapsOverlay();
        this.map = new this.api.Map(document.getElementById('gmap'), {
            center: { lng: 103.8146803, lat: 1.4572969 },
            zoom: 12,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            // styles: map_styles
        });

        this._onHover = this._onHover.bind(this);
        this._onClick = this._onClick.bind(this);
        this._renderTooltip = this._renderTooltip.bind(this);

        // Put the Deck.gl overlay on the map
        this.overlay.setMap(this.map);
    }

    // Reposition the map for selected demo
    setMap(map_options) {
        this.map.setCenter(map_options.center);
        this.map.setZoom(map_options.zoom);
    }

    setLayer(deckgl_layers) {
        this.overlay.setProps({ layers: deckgl_layers });
    }

    componentDidMount() {
        try {
            if (google) {
                this.initMapWithOverlay();

                this.modifyGeoJson()
                this.setViewPort();

                // this.overlay.setProps({ layers: this._renderLayers() });

                if (this.props.mapStyleType) {
                    this.setState({ mapStyleType: this.props.mapStyleType })
                }
            }
        } catch (Err) {

        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.geoJsons !== this.props.geoJsons) {
            this.modifyGeoJson()
            this.setViewPort();
            // this.overlay.setProps({ layers: this._renderLayers() });
        }

        if (prevProps.mapStyleType !== this.props.mapStyleType) {
            this.setState({ mapStyleType: this.props.mapStyleType })
        }
    }
    modifyGeoJson() {
        let { geoJsons } = this.props;
        let modifiedJson = [];
        // if (geoJsons) {
        //     geoJsons.forEach((item, i) => {
        //         // console.log('geoJsons item'+i, item.geometry);
        //         let polygons = []
        //         if (item && item.geometry && item.geometry.coordinates && item.geometry.coordinates.length > 0) {
        //             // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
        //             polygons = item.geometry.coordinates.map(c => {
        //                 let mItem = {
        //                     ...item,
        //                     geometry: {
        //                         type: 'Polygon',
        //                         coordinates: [c]
        //                     },
        //                     parentIndex: i
        //                 }
        //                 return mItem;
        //             })
        //         }
        //         modifiedJson = modifiedJson.concat(polygons);
        //     });
        // }
        // console.log('modifiedJson', modifiedJson);

        modifiedJson = this.parseGeoJson(geoJsons.data)

        let  { hoveredObject } = this.state;
        if(hoveredObject){
            let fndFeature = modifiedJson.features.find(f=>f.properties.loc_cd==hoveredObject.properties.loc_cd)
            if(fndFeature){
                hoveredObject = fndFeature;
            }
        }

        this.setState({
            geoJson: modifiedJson,
            hoveredObject: hoveredObject
            // {
            //     "type": "FeatureCollection",
            //     features: modifiedJson
            // }
        }, () => {
            if (this.overlay) {
                this.overlay.setProps({ layers: this._renderLayers() });
            }
        });
    }
    setViewPort() {
        // console.log('setPort');
        let { geoJsons, mapBounds } = this.props;
        if (mapBounds && geoJsons.status=='refresh') {
            this.map.setCenter({ lng: mapBounds.lng, lat: mapBounds.lat });
            this.map.setZoom(mapBounds.zoom);
        }
        else {
            if (geoJsons && geoJsons.status=='refresh' && geoJsons.data.length > 0) {
                let cornersLatLng = [[+Infinity, +Infinity], [-Infinity, -Infinity]];
                cornersLatLng = geoJsons.data.reduce((res, loc) => {
                    res[0][0] = loc.properties.cornersLatLng[0][0] < res[0][0] ? loc.properties.cornersLatLng[0][0] : res[0][0];
                    res[0][1] = loc.properties.cornersLatLng[0][1] < res[0][1] ? loc.properties.cornersLatLng[0][1] : res[0][1];
                    res[1][0] = loc.properties.cornersLatLng[1][0] > res[1][0] ? loc.properties.cornersLatLng[1][0] : res[1][0];
                    res[1][1] = loc.properties.cornersLatLng[1][1] > res[1][1] ? loc.properties.cornersLatLng[1][1] : res[1][1];

                    return res;
                }, cornersLatLng)

                // console.log('cornersLatLng', cornersLatLng);
                try {
                    const viewport = new WebMercatorViewport({ width: window.innerWidth - 84, height: 600 })
                        .fitBounds(cornersLatLng, { padding: 50 }) // Can also use option: offset: [0, -100]

                    let currentViewState = { longitude: viewport.longitude, latitude: viewport.latitude, zoom: viewport.zoom, transitionDuration: 1000, bearing: 0, pitch: 15 };
                    this.setState({ viewState: currentViewState });

                    this.map.setCenter({ lng: viewport.longitude, lat: viewport.latitude });
                    this.map.setZoom(viewport.zoom);

                } catch (Err) {
                    console.log('cornersLatLng Err', Err);
                }

            }
        }
    }

    _renderLayers() {
        const { geoJson } = this.state;


        if (!this.props.isDetailView) {
            return [
                new GeoJsonLayer({
                    id: 'geojson',
                    data: geoJson,
                    //opacity: 0.5,
                    stroked: true,
                    // filled: true,
                    extruded: false,
                    // wireframe: false,
                    getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                    getFillColor: f => colorArr[f.properties.JobCompletion], //[10, 207, 131, 255], //f.properties.color,
                    // getLineColor: [255, 255, 255],
                    pickable: true,
                    onHover: this._onHover,
                    onClick: this._onClick
                })
            ];
        } else {
            return []
        }
    }

    _onHover({ x, y, object }) {
        // if(object){
        if (object && object.properties && object.properties.hasTodayData) {
            this.props.onTooltipHover(object.properties);
        }
        this.setState({ x, y, hoveredObject: object });
        // }
    }
    getMapBounds() {
        let mapbounds = {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
            zoom: this.map.getZoom()
        }
        return mapbounds
    }
    _onClick(event) {
        // console.log('click', event);
        this.setState({ hoveredObject: null });

        this.props.onAreaSelected(event.object); //this.props.geoJsons[event.object.parentIndex]);
    }

    _renderTooltip() {
        const { x, y, hoveredObject } = this.state;
        let { todayLocationProps } = this.props;

        let locProps = null;
        if(hoveredObject){
            locProps = hoveredObject.properties;
            if(todayLocationProps[hoveredObject.properties.loc_cd]){
                locProps.isLoading = todayLocationProps[hoveredObject.properties.loc_cd].isLoading;
                
                // locProps = {
                //     ...locProps,
                //     ...todayLocationProps[hoveredObject.properties.loc_cd]
                // }
            }
        }
        
        // console.log('locProps _renderTooltip', locProps)
        // return null;
        return (
            locProps && (
                <div className="tooltip" style={{ top: y, left: x }}>
                    <div style={{ minWidth: 200 }}>
                        {/* <b>{hoveredObject.properties.name}</b> */}
                        {/* <div>{(props.filters.locations.filter(item=>item.id==hoveredObject.properties.id))[0].label}</div> */}
                       
                        <div className="title">{locProps.loc_desc || locProps.loc_cd} {locProps.isLoading && <CircularProgress  style={{color:'#fff', fontSize: 12, width: 15, height: 15}}/>}</div>
                        {/* {locProps.isLoading && <span>Fetching data...</span>} */}
                        {/* <div className="drow"><div className="drowlabel">Agency</div><div className="drowvalue">{hoveredObject.properties.agency}</div></div> */}
                        {/* <div className="drow"><div className="drowlabel">Branch CD</div><div className="drowvalue">{hoveredObject.properties.branch_cd}</div></div> */}
                        {/* <div className="drow"><div className="drowlabel">Eve ID</div><div className="drowvalue">{hoveredObject.properties.eve_id}</div></div> */}
                        <div className="drow"><div className="drowlabel">Total Area</div><div className="drowvalue">{+locProps.PolygonArea.toFixed(3)} sqm</div></div>
            <div className="drow"><div className="drowlabel">Area of Grass Cut (in sqm)</div><div className="drowvalue">{(locProps.hasTodayData)?'*':''}{+locProps.GrassCutArea.toFixed(3)} sqm</div></div>
                        <div className="drow"><div className="drowlabel">Area of Grass Cut (in %)</div><div className="drowvalue">{(locProps.hasTodayData)?'*':''}{+locProps.Coverage.toFixed(2)} %</div></div>
                        {/* <div className="drow"><div className="drowlabel">Coverage</div><div className="drowvalue">{hoveredObject.properties.coverage} Ha ({hoveredObject.properties.coveragePercent}%) Ha</div></div> */}
                        <div className="drow"><div className="drowlabel">Status</div><div className="drowvalue">{(locProps.JobCompletion === '1') ? 'Completed' : 'InProgress'}</div></div>
                        {/* <div className="drow"><div className="drowlabel">Contractor Name</div><div className="drowvalue">{hoveredObject.properties.contractor}</div></div> */}
                        <div className="drow"><div className="drowlabel">Zone</div><div className="drowvalue">{locProps.zone}</div></div>
                        {/* <div className="drow"><div className="drowlabel">Location ID</div><div className="drowvalue">{hoveredObject.properties.loc_cd}</div></div> */}
                        <div className="drow"><div className="drowlabel">Operational Hours</div><div className="drowvalue">{(locProps.hasTodayData)?'*':''}{locProps.TotalOperationalDuration} hrs</div></div>
                        {/* <div className="drow"><div className="drowlabel">Number of equipments</div><div className="drowvalue">2</div></div> */}
                        <div className="drow"><div className="drowlabel">Last Cut</div><div className="drowvalue">{locProps.lastCut ? moment(locProps.lastCut, 'YYYYMMDD').format('DD-MM-YYYY') : ''}</div></div>
                        {locProps.hasTodayData && <div style={{textAlign: 'center', paddingTop: 5}}>Note: Values with * represent approximate values</div>}
                        {/* <div className="drow"><div className="drowlabel">Decription</div></div><div><div className="drowvalue">{hoveredObject.properties.loc_desc}</div></div> */}
                        {/* <ul>
                            {hoveredObject.properties.equipments.list.map(item => {
                                return <div className="drow"><div className="drowlabel">{item.type}</div><div className="drowvalue">{item.count}</div></div>
                            })}
                        </ul> */}
                    </div>
                </div>
            )
        );
    }


    render() {
        return (
            <div>
                <div id="gmap" style={{ height: 'calc(100vh - 64px)' }}></div>
                {this._renderTooltip()}
            </div>
        )
    }

    parseGeoJson(inpJson) {
        let gFeatures = [];

        const getPolygons = (gJson, properties) => {
            switch (gJson.type) {
                case 'FeatureCollection':
                    gJson.features.forEach(f => {
                        getPolygons(f);
                    })
                    break;
                case 'Feature':
                    if (gJson.geometry) getPolygons(gJson.geometry, gJson.properties)
                    if (gJson.geometries) {
                        gJson.geometries.forEach(g => {
                            getPolygons(g)
                        })
                    }
                    break;
                case 'GeometryCollection':
                    if (gJson.geometries) {
                        gJson.geometries.forEach(g => {
                            getPolygons(g, properties)
                        })
                    }
                    break;
                case 'MultiPolygon':
                case 'Polygon':
                    // case 'MultiPoint':
                    // case 'Point':
                    // case 'LineString':
                    // case 'MultiLineString':
                    let polygon = {
                        type: 'Feature',
                        properties,
                        geometry: gJson
                    }
                    gFeatures.push(polygon);
                    break;
            }
        }
        if (Array.isArray(inpJson)) {
            inpJson.forEach(j => {
                getPolygons(j);
            })
        } else {
            getPolygons(inpJson);
        }

        let finalJson = {
            type: 'FeatureCollection',
            features: gFeatures
        }
        // console.log('gFeatures', finalJson)
        return finalJson;
    }
}

