import React, { memo, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
// import Input from '@material-ui/core/Input'
// import { DateRangePicker } from 'material-ui-datetime-range-picker';
// import MuiThemeProvider from '@material-ui/core/styles/ThemeProvider'
// import { StaticDateRangePicker, DateRangeDelimiter, DateRange } from "@material-ui/pickers";
import { StaticDateRangePicker, DateRange } from "@material-ui/pickers";
import { StaticDatePicker } from "@material-ui/pickers";

// import TextField from "@material-ui/core/TextField";

import { LocalizationProvider } from '@material-ui/pickers';
// import MomentUtils from '@material-ui/pickers/adapter/moment';
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

// import { DatePicker } from "@material-ui/pickers";

import useStyles from "./styles";

import moment from 'moment'


const options = [
    {
        label: 'Last week',
        action: () => {
            return [moment().subtract(6, 'days').toDate(), moment().subtract(1, 'days')];
        }
    },
    {
        label: 'Custom range'
    }
];

const dafaultOption = 0;

function CustomDateRangeFilter(props) {



    const [selectedDate, handleDateChange] = React.useState(options[dafaultOption].action());
    const [selectedLabel, setSelectedLabel] = React.useState(options[dafaultOption].label);

    const classes = useStyles();
    const [checked, setChecked] = React.useState(dafaultOption);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [maxDate, setmaxDate] = React.useState(moment().toDate());

    const handleClose = () => {
        setAnchorEl(null);
        // setfilteredList(fL);

        let selectedlabel = options[checked].label;
        if (selectedlabel.indexOf("Custom") !== -1) {
            selectedlabel = moment(selectedDate[0]).format("MMM D")
            // if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
            // }
        } else {
            selectedlabel += ' (' + moment(selectedDate[0]).format("MMM D")
            if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
            }
            selectedlabel += ')'
        }

        setSelectedLabel(selectedlabel);
        props.onDateChange(selectedDate);
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        if (checked !== index) {
            setChecked(index);

            if (options[index].label.indexOf("Custom") === -1) {
                let daterange = options[index].action();
                handleDateChange(daterange);
            }
        }
    };

    const handleDateRangeSelected = (range) => {
        //console.log('range', range)
        // if(moment(range[0])){
        //     let date = moment(range[0]);
        //     date.set(15,'date');          
        // }
        let todaysDate = moment().subtract(1, 'days')
        setmaxDate(todaysDate.toDate())
        handleDateChange(range);
    }

    useEffect(() => {
        if (options[checked].label.indexOf("Custom") === -1) {
            let daterange = options[checked].action();
            handleDateChange(daterange);
        }

    }, [checked]);

    useEffect(() => {
        // console.log('DateFilter',selectedDate);
        if (selectedDate[0] != null && !Boolean(anchorEl)) {
            let selectedlabel = options[checked].label;
            if (selectedlabel.indexOf("Custom") !== -1) {
                selectedlabel = moment(selectedDate[0]).format("MMM D")
                // if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                    selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                // }
            } else {
                selectedlabel += ' (' + moment(selectedDate[0]).format("MMM D")
                if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                    selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                }
                selectedlabel += ')'
            }
            setSelectedLabel(selectedlabel);
            props.onDateChange(selectedDate);
        }
        // eslint-disable-next-line
    }, [selectedDate]);
    const id = open ? 'simple-popover' : undefined;

    // console.log('Datefilter render', props)
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div className={props.className}>
                <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                    <div className={classes.label}>
                        <div className={classes.labelText}>{props.label}</div>
                        <div className={classes.value} title={selectedLabel}>{selectedLabel}</div>
                    </div>
                    {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ display: "flex" }}>

                        <div>
                            <List className={classes.list}>
                                {options.map((item, index) => {
                                    const labelId = `checkbox-list-label-${index}`;

                                    let lbl = '';
                                    if (item.label.indexOf("Custom") === -1) {
                                        let dtR = item.action();
                                        if (item.label.indexOf("Today") !== -1) {
                                            lbl = '(' + moment(dtR[0]).format("MMM D") + ')'
                                        } else {
                                            lbl = '(' + moment(dtR[0]).format("MMM D") + ' - ' + moment(dtR[1]).format("MMM D") + ')'
                                        }
                                    }

                                    return (
                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(item, index)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={index === checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={item.label} secondary={lbl} className={classes.litxt} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        <div style={{ borderLeft: "solid 1px #97B2B2", display: (options[checked].label.indexOf('Custom') != -1) ? 'block' : 'none' }}>
                            <StaticDateRangePicker
                                displayStaticWrapperAs="static"
                                value={selectedDate}
                                onChange={date => handleDateRangeSelected(date)}
                                disableFuture={true}
                                showToolbar={true}
                                maxDate={maxDate}
                                // disableMaskedInput={true}
                                // ToolbarComponent={null}
                            />
                        </div>

                    </div>

                </Popover>
            </div>
        </LocalizationProvider>
    )
}

export default memo(CustomDateRangeFilter)
