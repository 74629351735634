import React, { useState, useEffect, useCallback } from 'react'
import { Select, MenuItem, Button } from "@material-ui/core";
import { useDevicesState, getDeviceFeeds, getFeedRules, updateFeed, updateFeedRule } from "../../context/DevicesContext";
import MaterialTable from "material-table";
import TextField from '@material-ui/core/TextField';
import InfoTooltip from "../../components/InfoTooltip"
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import useStyles, { switchStyles } from "./styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
// import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'

// const switchStyles = makeStyles((theme) => ({
//     labelPlacementStart:{
//         flexGrow: 1,
//         marginLeft: 0
//     },
//     label: {
//         flexGrow: 1
//     }
// }));

const ruleMap = {
    "EQ": "equal to",
    "BT": "between",
    "LT": "lesser than",
    "GT": "greater than"
}

function FeedsAndRules(props) {

    const { enqueueSnackbar } = useSnackbar();

    var classes = useStyles();
    var switchClasses = switchStyles();

    const context = useDevicesState();
    const [feeds, setfeeds] = useState(null)
    const [feedRules, setfeedRules] = useState(null)
    const [display, setdisplay] = useState('feedsList')
    const [selectedFeed, setselectedFeed] = useState(null)


    const [feedName, setfeedName] = useState('')
    const [feedCategory, setfeedCategory] = useState('')
    const [inputParamList, setinputParamList] = useState([])
    const [inputParam, setinputParam] = useState('')
    const [feedUnit, setfeedUnit] = useState('')
    const [feedType, setfeedType] = useState('')
    const [feedOffset, setfeedOffset] = useState(0)
    const [conversionFactor, setconversionFactor] = useState(1)
    const [runOnPreviousData, setrunOnPreviousData] = useState(false)
    const [isFeedVisible, setisFeedVisible] = useState(false)


    const [selectedFeedRule, setselectedFeedRule] = useState(null)
    const [ruleCategory, setruleCategory] = useState('')
    const [ruleType, setruleType] = useState('')
    const [arg1, setarg1] = useState('')
    const [arg2, setarg2] = useState('')
    const [weight, setweight] = useState('')
    const [ruleDisableStart, setruleDisableStart] = useState('')
    const [ruleDisableEnd, setruleDisableEnd] = useState('')


    const onFeedEditSubmit = (event) => {
        event.preventDefault();
        // console.log('feedDatails', { feedName, feedCategory, inputParam, feedUnit, feedType, feedOffset, conversionFactor, runOnPreviousData, isFeedVisible });

        let values = {
            key: selectedFeed.key,
            name: feedName,
            device: selectedFeed.device.id,
            device_data_key: inputParam,
            feed_type: feedType,
            conversion_factor: conversionFactor,
            unit_symbol: feedUnit,
            offset: feedOffset,
            retroactive: runOnPreviousData,
            is_visible: isFeedVisible,
            is_active: true
        }
        updateFeed(context.setData, values)
    }

    const cancelEditFeed = () => {
        context.setData({ type: 'CLEAR_FEED_DATA' })
        setselectedFeed(null);
        setfeedName('');
        setfeedCategory('');
        setinputParam('');
        setfeedUnit('');
        setfeedType('');
        setfeedOffset('');
        setconversionFactor('')
        setrunOnPreviousData(false);
        setisFeedVisible(false)
        setdisplay('feedsList');
    }

    const onRuleEditSubmit = (event) => {
        event.preventDefault();
        // console.log('ruleDatails', { ruleCategory, ruleType, arg1, arg2, weight, ruleDisableStart, ruleDisableEnd });

        let values = {
            category: ruleCategory,
            rule_type: ruleType,
            arg1: arg1,
            arg2: arg2 ? arg2 : null,
            id: selectedFeedRule.id,
            weight: weight,
            feed: selectedFeedRule.feed,
            disabled_start: ruleDisableStart ? setFormattedTime(ruleDisableStart) : null,
            disabled_end: ruleDisableEnd ? setFormattedTime(ruleDisableEnd) : null,
        }
        updateFeedRule(context.setData, values)
    }

    const cancelEditRule = () => {
        setselectedFeedRule(null);
        setruleCategory('');
        setruleType('');
        setarg1('');
        setarg2('');
        setweight('');
        setruleDisableStart('')
        setruleDisableEnd('')
        setdisplay('editFeedForm');
    }

    const getFormattedTime = (time) => {
        if (time) {
            var start = moment.utc(time, "HH:mm");
            start.add(8, 'hours')
            // console.log(time,start.format("HH:mm:ss"));
            return start.format("HH:mm")
        }
        return '';
    }
    const setFormattedTime = (time) => {
        if (time) {
            var start = moment.utc(time, "HH:mm");
            start.subtract(8, 'hours')
            // console.log(time,start.format("HH:mm:ss"));
            return start.format("HH:mm")
        }
        return '';
    }

    const getInputParamsList = useCallback(() => {
        let InputParamsList = [];
        const tempList = [];
        let resp = context.data.selectedDevice.details;

        if (resp && resp.template && resp.template.schema) {
            if (resp.template.protocol === 'sigfox') {
                var new_fields = [];
                new_fields = resp.template.schema.fields;

                for (let i = 0; i < new_fields.length; i++) {
                    if (new_fields[i].name === 'parsed_data') {

                        var filtered_fields = [];
                        filtered_fields = new_fields[i].fields
                    }
                }

                for (let j = 0; j < filtered_fields.length; j++) {
                    if (filtered_fields[j].frames) {
                        var newFrames = [];
                        newFrames = filtered_fields[j].frames
                        for (let k = 0; k < newFrames.length; k++) {
                            var newFields = []
                            newFields = newFrames[k];
                        }
                    }
                }

                for (let l = 0; l < newFields.length; l++) {
                    var finalFields = [];
                    finalFields = newFields[l].fields;
                }
                for (let m = 0; m < finalFields.length; m++) {
                    //  console.log(finalFields[m].name)
                    tempList.push({ name: finalFields[m].name })

                    if (finalFields[m].frames) {
                        tempList.splice(-1, 1)
                        var subFrames = finalFields[m].frames;

                        for (let n = 0; n < subFrames.length; n++) {
                            var insideFrames = subFrames[n]

                            for (let o = 0; o < insideFrames.length; o++) {
                                var insidesecondFrames = insideFrames[o];

                                if (insidesecondFrames.name === 'slaveName') {
                                    var values = insideFrames[o].value
                                    tempList.push({ name: finalFields[m].name + '.' + values });
                                }
                                if (insidesecondFrames.name === 'dataFields') {
                                    tempList.splice(-1, 1)
                                    var fields1 = insidesecondFrames.fields

                                    for (let p = 0; p < fields1.length; p++) {
                                        var fields2 = fields1[p].fields;

                                        for (let q = 0; q < fields2.length; q++) {
                                            if (fields2[q].input === 'parent') {
                                                var parentNames = fields2[q].name
                                                tempList.push({ name: finalFields[m].name + '.' + values + '.' + parentNames });
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                }
            }

            if (resp.template.protocol === 'nbiot') {
                var fields1nb = resp.template.schema.fields;

                for (let i = 0; i < fields1nb.length; i++) {
                    if (fields1nb[i].name === 'parsed_data') {
                        var filtered_fieldsnb = [];
                        filtered_fieldsnb = fields1nb[i].fields
                    }
                }

                for (let j = 0; j < filtered_fieldsnb.length; j++) {
                    var fields3 = [];
                    fields3 = filtered_fieldsnb[j].name;
                    tempList.push({ name: fields3 });

                    if (filtered_fieldsnb[j].frames) {
                        tempList.splice(-1, 1)
                        var subFramesnb = filtered_fieldsnb[j].frames;

                        for (let n = 0; n < subFramesnb.length; n++) {
                            var insideFramesnb = subFramesnb[n]

                            for (let o = 0; o < insideFramesnb.length; o++) {
                                var insidesecondFramesnb = insideFramesnb[o];

                                if (insidesecondFramesnb.name === 'slaveName') {
                                    var valuesnb = insideFramesnb[o].value
                                    tempList.push({ name: filtered_fieldsnb[j].name + '.' + valuesnb });
                                }
                                if (insidesecondFramesnb.name === 'dataFields') {
                                    tempList.splice(-1, 1)
                                    var fields1nbd = insidesecondFramesnb.fields

                                    for (let p = 0; p < fields1nbd.length; p++) {
                                        var fields2nbd = fields1nbd[p].fields;

                                        for (let q = 0; q < fields2nbd.length; q++) {
                                            if (fields2nbd[q].input === 'parent') {
                                                var parentNamesnb = fields2nbd[q].name
                                                tempList.push({ name: filtered_fieldsnb[j].name + '.' + valuesnb + '.' + parentNamesnb });
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                }

            }
        }

        if (resp && resp.template && resp.template.frames && resp.template.frames[0].fields) {
            const fields = resp.template.frames[0].fields;
            for (let i = 0; i < fields.length; i++) {
                tempList.push({ name: fields[i].name })
                if (fields[i].Txs) {
                    for (let t = 0; t < fields[i].Txs.length; t++) {
                        tempList.push({ name: fields[i].name + '.' + fields[i].Txs[t].slaveName })
                        if (fields[i].Txs[t].dataFields) {
                            const dataFields = fields[i].Txs[t].dataFields;
                            for (let dt = 0; dt < dataFields.length; dt++) {
                                tempList.push({ name: fields[i].name + '.' + fields[i].Txs[t].slaveName + '.' + dataFields[dt].paramName })
                            }
                        }
                    }
                }
            }
        }

        if (resp && resp.template && resp.template.fields) {
            const fields = resp.template.fields;
            var fieldsValue;
            var paramName;

            for (let i = 0; i < fields.length; i++) {
                fieldsValue = fields[i];
                for (paramName in fieldsValue) {
                    tempList.push({ name: paramName })

                }
            }
        }
        InputParamsList = tempList;
        setinputParamList(InputParamsList)
    }, [context.data.selectedDevice.details])
    
    useEffect(() => {
        if (props.device && props.device.device_id) {
            getDeviceFeeds(context.setData, props.device.device_id);
        }
    }, [context.setData, props.device]);

    useEffect(() => {
        // console.log('context feeds', context.data.selectedDevice.feeds)
        if (context.data.selectedDevice.feeds) {
            setfeeds(context.data.selectedDevice.feeds)
        }
    }, [context.data.selectedDevice.feeds]);

    useEffect(() => {
        // console.log('context feedrules', context.data.selectedDevice.feedRules)
        if (context.data.selectedDevice.feedRules) {
            setfeedRules(context.data.selectedDevice.feedRules)
        }
    }, [context.data.selectedDevice.feedRules]);

    useEffect(() => {
        if (selectedFeed) {
            getFeedRules(context.setData, selectedFeed.id);
        }
    }, [context.setData, selectedFeed]);

    useEffect(() => {
        if (context.data.selectedDevice.details) {
            // console.log('getInputParamsList')
            getInputParamsList();
        }
    }, [getInputParamsList, context.data.selectedDevice.details]);

    useEffect(() => {
        // console.log('onFeedUpdated')
        if (context.data.updateFeedError) {
            enqueueSnackbar('Feed update failed', { variant: 'error' });
        }
        if (context.data.updateFeed) {
            enqueueSnackbar('Feed updated successfully', { variant: 'success' });
            // console.log('onFeedUpdated 1')
            context.setData({ type: 'CLEAR_FEED_DATA' })
            setdisplay('feedsList');
            getDeviceFeeds(context.setData, props.device.device_id);

        }
        // eslint-disable-next-line
    }, [context.setData, props.device.device_id, enqueueSnackbar, context.data.updateFeed, context.data.updateFeedError]);

    useEffect(() => {
        // console.log('onFeedRuleUpdated')
        if (context.data.updateFeedRuleError) {
            enqueueSnackbar('Feed Rule update failed', { variant: 'error' });
        }
        if (context.data.updateFeedRule) {
            enqueueSnackbar('Feed Rule updated successfully', { variant: 'success' });
            // console.log('onFeedRuleUpdated 1')
            context.setData({ type: 'CLEAR_FEED_RULE_DATA' })
            setdisplay('editFeedForm');
            getFeedRules(context.setData, selectedFeed.id);
        }
        // eslint-disable-next-line
    }, [context.setData, enqueueSnackbar, selectedFeed, context.data.updateFeedRule, context.data.updateFeedRuleError]);



    return (
        <div>
            {display === 'feedsList' && feeds && <MaterialTable
                title="Manage Device Feeds"
                columns={[
                    { title: "Feed Name", field: "name" },
                    { title: "Input Parameter", field: "device_data_key" },
                    { title: "Feed Category", field: "category" },
                    { title: "Feed Unit", field: "unit_symbol" },
                    { title: "Feed Type", field: "feed_type" },
                    { title: "Conversion Factor", field: "conversion_factor" },
                    {
                        title: "Run on previos data",
                        field: "retroactive",
                        render: rowData => (rowData.retroactive) ? 'Enabled' : 'Disabled'
                    },
                    {
                        title: "Feed Visible",
                        field: "is_visible",
                        render: rowData => (rowData.is_visible) ? 'Visible' : 'Hidden'
                    }
                ]}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit feed',
                        onClick: (event, rowData) => {
                            setselectedFeed(rowData);
                            setfeedName(rowData.name);
                            setfeedCategory(rowData.category);
                            setinputParam(rowData.device_data_key);
                            setfeedUnit(rowData.unit_symbol);
                            setfeedType(rowData.feed_type);
                            setfeedOffset(rowData.conversion_factor);
                            setconversionFactor(rowData.conversion_factor)
                            setrunOnPreviousData(rowData.retroactive);
                            setisFeedVisible(rowData.is_visible)
                            setdisplay('editFeedForm');

                        }
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete feed',
                        onClick: (event, rowData) => alert("You selected " + rowData.name)
                    },
                    {
                        icon: 'add',
                        tooltip: 'Add Feed',
                        isFreeAction: true,
                        onClick: (event) => alert("You want to add a new row")
                    }
                ]}
                data={feeds.results}
                options={{
                    // filtering: true,
                    // grouping: true,
                    headerStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        color: "#698D8D",
                        backgroundColor: "#F1FCFC",
                        padding: "4px 12px",
                    },
                    cellStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        fontWeight: "bold",
                        padding: "4px 12px",
                    },
                    rowStyle: {
                        height: "auto",
                    },
                    searchFieldStyle: {
                        // color: 'red'
                    },
                    pageSize: 10,
                    actionsColumnIndex: -1
                }}
            />}
            {display === 'editFeedForm' &&
                <div>
                    <form className={classes.editform} onSubmit={onFeedEditSubmit}>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth disabled required id="feed-name" value={feedName} onChange={(e) => setfeedName(e.target.value)} label="Feed Name" className={classes.editformfield} />
                            <InfoTooltip contentKey="FeedName" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="feed-category-label">Feed Category</InputLabel>
                                <Select
                                    labelId="feed-category-label"
                                    id="feed-category"
                                    value={feedCategory}
                                    onChange={(e) => setfeedCategory(e.target.value)}
                                >
                                    <MenuItem value="computed">Computed</MenuItem>
                                    <MenuItem value="native">Native</MenuItem>
                                    <MenuItem value="gps">GPS</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <TextField fullWidth disabled required id="feed-category" value={feedCategory} onChange={(e) => setfeedCategory(e.target.value)} label="Feed Category" className={classes.editformfield} /> */}
                            <InfoTooltip contentKey="FeedCategory" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="input-parameter-label">Input Parameter</InputLabel>
                                <Select
                                    labelId="input-parameter-label"
                                    id="input-parameter"
                                    value={inputParam}
                                    onChange={(e) => setinputParam(e.target.value)}
                                >
                                    <MenuItem value="" disabled>Select Input Parameter</MenuItem>
                                    {inputParamList.map((ip, ipindex) => {
                                        return <MenuItem key={ipindex} value={ip.name}>{ip.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            {/* <TextField fullWidth required id="input-parameter" value={inputParam} onChange={(e) => setinputParam(e.target.value)} label="Input Parameter" className={classes.editformfield} /> */}
                            <InfoTooltip contentKey="InputParameter" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth id="feed-unit" value={feedUnit} onChange={(e) => setfeedUnit(e.target.value)} label="Feed Unit" className={classes.editformfield} />
                            <InfoTooltip contentKey="FeedUnit" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="feed-type-label">Feed Type</InputLabel>
                                <Select
                                    labelId="feed-type-label"
                                    id="feed-type"
                                    value={feedType}
                                    onChange={(e) => setfeedType(e.target.value)}
                                >
                                    <MenuItem value="" disabled>Select Feed Type</MenuItem>
                                    <MenuItem value="Energy">Energy</MenuItem>
                                    <MenuItem value="water">Water</MenuItem>
                                    <MenuItem value="gas">Gas</MenuItem>
                                    <MenuItem value="general">General</MenuItem>
                                    <MenuItem value="others">Others</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <TextField fullWidth id="feed-type" value={feedType} onChange={(e) => setfeedType(e.target.value)} label="Feed Type" className={classes.editformfield} /> */}
                            <InfoTooltip contentKey="FeedType" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="feed-offset" type="number" value={feedOffset} onChange={(e) => setfeedOffset(e.target.value)} label="Feed Offset" className={classes.editformfield} />
                            <InfoTooltip contentKey="FeedOffset" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="conversion-factor" type="number" value={conversionFactor} onChange={(e) => setconversionFactor(e.target.value)} label="Conversion Factor" className={classes.editformfield} />
                            <InfoTooltip contentKey="ConversionFactor" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={runOnPreviousData}
                                        control={<Switch color="primary" checked={runOnPreviousData} onChange={(event) => setrunOnPreviousData(event.target.checked)} />}
                                        label="Run on Previous Data"
                                        labelPlacement="start"
                                        classes={switchClasses}
                                    />
                                </FormGroup>
                            </FormControl>
                            <InfoTooltip contentKey="PreviousData" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={isFeedVisible}
                                        control={<Switch color="primary" checked={isFeedVisible} onChange={(event) => setisFeedVisible(event.target.checked)} />}
                                        label="Is Feed Visible"
                                        labelPlacement="start"
                                        classes={switchClasses}
                                    />
                                </FormGroup>
                            </FormControl>
                            <InfoTooltip contentKey="isFeedVisible" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="primary" size="small" style={{ width: '100%' }} type="submit"
                                disabled={!(feedOffset && conversionFactor)}>Submit</Button></div>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="default" size="small" style={{ width: '100%' }} onClick={cancelEditFeed}>Cancel</Button></div>
                        </div>
                    </form>

                    {feedRules && <div>
                        <MaterialTable
                            title="Manage Feed Rules"
                            columns={[
                                { title: "Category", field: "category" },
                                {
                                    title: "Rule Type", field: "rule_type",
                                    render: rowData => (rowData.rule_type) ? ruleMap[rowData.rule_type] : ''
                                },
                                { title: "Arg1", field: "arg1" },
                                { title: "Arg2", field: "arg2" },
                                { title: "Priority/Rank", field: "weight" },
                                {
                                    title: "Disable Start", field: "disabled_start",
                                    render: rowData => getFormattedTime(rowData.disabled_start)
                                },
                                {
                                    title: "Disable End", field: "disabled_end",
                                    render: rowData => getFormattedTime(rowData.disabled_end)
                                }
                            ]}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Rule',
                                    onClick: (event, rowData) => {
                                        setselectedFeedRule(rowData);
                                        setruleCategory(rowData.category);
                                        setruleType(rowData.rule_type);
                                        setarg1(rowData.arg1);
                                        setarg2(rowData.arg2 ? rowData.arg2 : '');
                                        setweight(rowData.weight);
                                        setruleDisableStart((rowData.disabled_start) ? getFormattedTime(rowData.disabled_start) : '')
                                        setruleDisableEnd((rowData.disabled_end) ? getFormattedTime(rowData.disabled_end) : '')
                                        setdisplay('editRuleForm');
                                    }
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete Rule',
                                    onClick: (event, rowData) => alert("You selected " + rowData.category)
                                },
                                {
                                    icon: 'add',
                                    tooltip: 'Add Rule',
                                    isFreeAction: true,
                                    onClick: (event) => alert("You want to add a new row")
                                }
                            ]}
                            data={feedRules.results}
                            options={{
                                // filtering: true,
                                // grouping: true,
                                headerStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    color: "#698D8D",
                                    backgroundColor: "#F1FCFC",
                                    padding: "4px 12px",
                                },
                                cellStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    fontWeight: "bold",
                                    padding: "4px 12px",
                                },
                                rowStyle: {
                                    height: "auto",
                                },
                                searchFieldStyle: {
                                    // color: 'red'
                                },
                                pageSize: 10,
                                actionsColumnIndex: -1
                            }}
                        />
                    </div>
                    }
                </div>
            }
            {display === 'editRuleForm' &&
                <form className={classes.editform} onSubmit={onRuleEditSubmit}>
                    <div style={{ display: 'flex' }}>
                        <FormControl required className={classes.formControl}>
                            <InputLabel id="rule-category-label">Category</InputLabel>
                            <Select
                                labelId="rule-category-label"
                                id="rule-category"
                                value={ruleCategory}
                                onChange={(e) => setruleCategory(e.target.value)}
                            >
                                <MenuItem value="" disabled>Select Category</MenuItem>
                                <MenuItem value="Norm">Normal</MenuItem>
                                <MenuItem value="Warn">Warning</MenuItem>
                                <MenuItem value="Minor">Minor</MenuItem>
                                <MenuItem value="Major">Major</MenuItem>
                                <MenuItem value="Crit">Critical</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField fullWidth required id="rule-category" value={ruleCategory} onChange={(e) => setruleCategory(e.target.value)} label="Category" className={classes.editformfield} /> */}
                        <InfoTooltip contentKey="Category" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <FormControl required className={classes.formControl}>
                            <InputLabel id="rule-type-label">Rule Type</InputLabel>
                            <Select
                                labelId="rule-type-label"
                                id="rule-type"
                                value={ruleType}
                                onChange={(e) => setruleType(e.target.value)}
                            >
                                <MenuItem value="" disabled>Select Rule Type</MenuItem>
                                <MenuItem value="EQ">Equal</MenuItem>
                                <MenuItem value="BT">In Between</MenuItem>
                                <MenuItem value="GT">Greater Than</MenuItem>
                                <MenuItem value="LT">Less Than</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField fullWidth required id="rule-type" value={ruleType} onChange={(e) => setruleType(e.target.value)} label="Rule Type" className={classes.editformfield} /> */}
                        <InfoTooltip contentKey="RuleType" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField fullWidth required id="arg1" value={arg1} onChange={(e) => setarg1(e.target.value)} label="Arg1" className={classes.editformfield} />
                        <InfoTooltip contentKey="Arg1" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField fullWidth id="arg2" value={arg2} onChange={(e) => setarg2(e.target.value)} label="Arg2" className={classes.editformfield} />
                        <InfoTooltip contentKey="Arg2" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField fullWidth required id="rule-weight" type="number" value={weight} onChange={(e) => setweight(e.target.value)} label="Priority/Rank" className={classes.editformfield} />
                        <InfoTooltip contentKey="Priority" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField fullWidth id="rule-disable-start" type="time" value={ruleDisableStart} onChange={(e) => setruleDisableStart(e.target.value)} label="Feed Rule Disable Start" InputLabelProps={{ shrink: true }} className={classes.editformfield} />
                        <InfoTooltip contentKey="DisabledStart" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField fullWidth id="rule-disable-end" type="time" value={ruleDisableEnd} onChange={(e) => setruleDisableEnd(e.target.value)} label="Feed Rule Disable End" className={classes.editformfield} InputLabelProps={{ shrink: true }} />
                        <InfoTooltip contentKey="DisabledEnd" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="primary" size="small" style={{ width: '100%' }} type="submit"
                            disabled={!(ruleCategory && ruleType && arg1 && weight)}
                        >Submit</Button></div>
                        <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="default" size="small" style={{ width: '100%' }} onClick={cancelEditRule}>Cancel</Button></div>
                    </div>
                </form>
            }
        </div>
    )
}

export default FeedsAndRules
