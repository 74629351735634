import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { TextLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';
// import redmarker from './map-marker-red.svg'
// import greenmarker from './map-marker-green.svg'
// import greymarker from './map-marker-grey.svg'

function getIconName(size) {
  if (size === 0) {
    return '';
  }
  if (size === 1) {
    return `marker`;
  } else {
    return `cluster`
  }
}
const ICON_COLOR_MAP = {
  GREEN: [0, 204, 204],
  RED: [255, 51, 102],
  GREY: [179, 186, 197],
  WHITE: [255, 255, 255],
  BLACK: [0, 0, 0]
}
function getIconColor(d, index) {
  let markerColor = ICON_COLOR_MAP.GREEN;
  if (d.properties.cluster_id) {
    
    let data = index
      .getLeaves(d.properties.cluster_id, 100)
      .map(f => f.properties);
      markerColor = ICON_COLOR_MAP.GREY
      // console.log('data', data)
      // console.log('d.properties.cluster_id',d.properties.cluster_id, data.length)
    for (let i = 0; i < data.length; i++) {
      if(data[i].is_active){
        markerColor = ICON_COLOR_MAP.GREEN;
      }
      // if (data[i].feed_status !== "Inactive" && data[i].feed_status !== "Norm") {
      //   markerColor = ICON_COLOR_MAP.RED;
      //   break;
      // } else if (markerColor !== 'red' && data[i].feed_status === "Inactive") {
      //   markerColor = ICON_COLOR_MAP.GREY;
      // }
      // else if (data[i].feed_status === "Norm") {
      //   markerColor = ICON_COLOR_MAP.GREEN;
      // }
    }
  } else {
    if(d.properties.is_active){
      markerColor = ICON_COLOR_MAP.GREEN;
    } else {
      markerColor = ICON_COLOR_MAP.GREY
    }
    // if (d.properties.feed_status === 'Inactive') {
    //   markerColor = ICON_COLOR_MAP.GREY;
    // } else if (d.properties.feed_status === 'Norm') {
    //   markerColor = ICON_COLOR_MAP.GREEN;
    // } else {
    //   markerColor = ICON_COLOR_MAP.RED;
    // }
  }
  return markerColor;
}

function getIconSize(size) {
  if (size === 1) {
    return 0.75;
  }
  return Math.min(10, size) / 10 + 1;
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    //console.log('props',props)
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 16, radius: props.sizeScale });
      index.load(
        props.data.map(d => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster) {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 100)
          .map(f => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;

    let textdataClsuter = data.filter(item => {
      return item.properties.cluster_id ? true : false;
    }).map(item => {
      return {
        coordinates: item.geometry.coordinates,
        name: (item.properties.point_count) ? '' + item.properties.point_count : item.properties.name,
        isCluster: item.properties.cluster_id ? true : false
      }
    });
    let textdata = data.filter(item => {
      return item.properties.cluster_id ? false : true;
    }).map(item => {
      return {
        coordinates: item.geometry.coordinates,
        aliasName: (item.properties.point_count) ? '' + item.properties.point_count : item.properties.aliasName,
        name: (item.properties.point_count) ? '' + item.properties.point_count : item.properties.name,
        isCluster: item.properties.cluster_id ? true : false
      }
    });
    //console.log('textdata',textdata);

    return [new IconLayer(
      this.getSubLayerProps({
        id: 'icon',
        data,
        iconAtlas,
        iconMapping,
        sizeScale,
        getPosition: d => d.geometry.coordinates,
        getIcon: d => getIconName(d.properties.cluster ? d.properties.point_count : 1),
        getSize: d => getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        getColor: d => getIconColor(d, this.state.index)
      })
    ),
    new TextLayer({
      id: 'cluster-text-layer',
      data: textdataClsuter,
      getText: d => {
        //console.log('getText',d)
        return d.name
      },
      getPosition: d => d.coordinates,
      getColor: d => {
        // if(d.isCluster){
        return ICON_COLOR_MAP.WHITE
        // } else {
        //   return ICON_COLOR_MAP.BLACK
        // }
      },
      getSize: d => 20,
      getPixelOffset: d => {
        if (d.isCluster) {
          return [0, 0]
        } else {
          return [0, -64]
        }
      }
    }),
    new TextLayer({
      id: 'text-layer',
      data: textdata,
      getText: d => {
        //console.log('getText',d)
        return d.aliasName + '(' + d.name + ')'
      },
      getPosition: d => d.coordinates,
      getColor: d => {
        // if(d.isCluster){
        return ICON_COLOR_MAP.WHITE
        // } else {
        //   return ICON_COLOR_MAP.BLACK
        // }
      },
      getSize: d => 20,
      getPixelOffset: d => {
        if (d.isCluster) {
          return [0, 0]
        } else {
          return [0, -64]
        }
      },
      // lineHeight: 1.5,
      // fontSettings: {
      //   // radius: 10,
      //   buffer: 10,
      //   sdf: true
      // },
      backgroundColor: ICON_COLOR_MAP.BLACK
    })
    ]
  }
}
