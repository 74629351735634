import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
// import Home from "../../pages/home";
// import Typography from "../../pages/typography";
// import Notifications from "../../pages/notifications";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
// import { DashboardProvider } from "../../context/DashboardContext";
// import Devices from "../../pages/devices/Devices";
// // import DevicesHex from "../../pages/devices-Hex/DevicesHex";
// import DevicesHexMap from "../../pages/devices-hex-map/DeviceHexMap";
import DeviceDetails from "../../pages/device-details/DeviceDetails";
import DeviceManagement from "../../pages/device-management/DeviceManagement";
import DevicesNew from "../../pages/devices-new/DevicesNew";
import PolygonMap from "../../pages/polygon-map/polygon-map";
import { BaseMapProvider } from "../../context/BaseMapContext"
import { DevicesProvider } from "../../context/DevicesContext";
import { DeviceDetailsProvider } from "../../context/DeviceDetailsContext";

import { DevicesNewProvider } from "../../context/DevicesNewContext"

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard">
              <BaseMapProvider>
                <Dashboard/>
              </BaseMapProvider>
            </Route>
            {/* <Route path="/app/devices" component={Devices} />
            <Route path="/app/deviceshex" component={DevicesHexMap} /> */}
            <Route path="/app/devicesNew/:deviceId">
              <DevicesNewProvider>
                <DevicesNew/>
              </DevicesNewProvider>
            </Route>  
            <Route path="/app/polygonmap">
              <DevicesNewProvider>
                <PolygonMap/>
              </DevicesNewProvider>
            </Route>  
            <Route path="/app/deviceDetails">
              <DeviceDetailsProvider>
                <DeviceDetails/>
              </DeviceDetailsProvider>
            </Route>
            <Route path="/app/deviceManagement/manageDevices">
              <DevicesProvider>
                <DeviceManagement/>
              </DevicesProvider>
            </Route>
            <Route path="/app/deviceManagement/triggerSettings" component={DeviceManagement} />
            {/* <Route path="/app/home" component={Home} /> */}

            {/* <Route path="/app/home" exact>
              <DashboardProvider>
                <Home />
              </DashboardProvider>
            </Route> */}

            <Route
                path="*"
                render={() => <Redirect to="/app/dashboard" />}
              />

            {/* <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} /> */}
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
