import React, { useState, useEffect, useCallback } from 'react'
import useStyles from "./styles";
// import { useTheme, withStyles } from "@material-ui/core/styles";
import DetailedMap from './DetailedMap';
import GoogleDetailedMap from './GoogleDetailedMap';
import moment from 'moment'
import { useSnackbar } from 'notistack';
import { Grid, Tab, Tabs } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDevicesNewState, getLocations, getPreviousData, getDeviceData, getFeedData, getDeviceDetails, getPreviousDeviceData, getPreviousTrimData, getPreviousDeviceSummaryData, getPreviousDeviceSessionData } from "../../context/DevicesNewContext";
import { FeedList } from '../device-details/FeedDetails';
import FeedGraph from './FeedGraph';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Tile from "../../components/Tile";
import Button from "@material-ui/core/Button";
import Refresh from "@material-ui/icons/Refresh";
// import Filter from "../../components/Filter";
import TimeTravel from "../../components/TimeTravel";
import TabPanel from "./TabPanel";
import OperationalHistory from "./OperationalHistory";
import RawDataTable from "./RawDataTable";
import DeviceDataTable from "./DeviceDataTable";
import Paper from "@material-ui/core/Paper";
import DateFilter from "../../components/DateFilter";
import MaterialTable from "material-table";
import {
    SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar,
    Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryFull, ArrowBack
} from "@material-ui/icons";
import RangeInput from './range-input';

const maplayers = [
    {
        label: 'Quality of cut',
        children: [
            { label: 'Good', isValid: (val) => val <= 10, color: 'rgb(99, 194, 146)', colorArr: [99, 194, 146, 255] },
            // { label: 'Medium', isValid: (val) => val > 5 && val <= 7.5, color: 'rgb(255, 186, 90)', colorArr: [255, 186, 90, 255] },
            { label: 'Bad', isValid: (val) => val > 10, color: 'rgb(235, 69, 89)', colorArr: [235, 69, 89, 255] }
        ],
        propName: 'value'
    },
    {
        label: 'Device layer',
        children: [
            { label: 'KnapSack', isValid: (val) => val === 'KnapSack', color: 'rgb(147, 153, 255)', colorArr: [147, 153, 255, 255] },
            { label: 'Lawn Mover', isValid: (val) => val === 'Lawn Mover', color: 'rgb(94, 223, 255)', colorArr: [94, 223, 255, 255] },
            { label: 'Tractor', isValid: (val) => val === 'Tractor', color: 'rgb(192, 255, 179)', colorArr: [192, 255, 179, 255] }
        ],
        propName: 'deviceType'
    }
]
function a11yProps(index) {
    return {
        id: `deviceslist-tab-${index}`,
        'aria-controls': `deviceslist-tabpanel-${index}`,
    };
}

function DevicesNew() {
    var classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const [detailData, setdetailData] = useState([]);
    const [deviceData, setdeviceData] = useState([]);
    const [deviceSummaryData, setdeviceSummaryData] = useState([]);
    const [deviceSummaryDataTable, setdeviceSummaryDataTable] = useState([]);
    const [deviceSummaryDataLoading, setdeviceSummaryDataLoading] = useState(false);
    const [deviceSessionDataTable, setdeviceSessionDataTable] = useState([]);
    const [deviceSessionDataLoading, setdeviceSessionDataLoading] = useState(false);
    const [deviceSessionData, setdeviceSessionData] = useState([]);
    const [deviceDataTable, setdeviceDataTable] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [deviceBatteryVal, setdeviceBatteryVal] = useState(null);
    const [deviceRssiVal, setdeviceRssiVal] = useState(null);
    const [selectedLocation, setselectedLocation] = useState(null)
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedMainTab, setSelectedMainTab] = React.useState(0);
    const [feedListData, setfeedListData] = React.useState(0);
    const [isLoading, setisLoading] = React.useState(true);
    const [feedData, setfeedData] = useState(null);
    const [tableFeedData, settableFeedData] = useState(null);
    // const [mapData, setmapData] = useState(null);
    const [selectedDeviceId, setselectedDeviceId] = useState(null);
    const [deviceDetails, setdeviceDetails] = useState(null)
    // const [feedList, setfeedList] = useState(null)
    const [timeRange, settimeRange] = useState(null);
    const [filterValue, setfilterValue] = useState(null)
    const [selectedDate, setselectedDate] = useState(null);
    const [selectedDateType, setselectedDateType] = useState('');
    const [currentDate, setcurrentDate] = useState('');
    const [fileformatDate, setfileformatDate] = useState('');
    const [lastSeenTime, setlastSeenTime] = useState('');
    const [FWVersion, setFWVersion] = useState('');
    const [operationalData, setoperationalData] = useState([]);
    const [operationalrawData, setoperationalrawData] = useState([]);
    const [locationClicked, setlocationClicked] = useState('');
    const [detailMapCoverageData, setdetailMapCoverageData] = useState([]);
    // const operationHistory = [
    //     { contract: 'abc', locationsCovered: 'Tuas', area: '0.5', duration: '00:35:00', remarks: 'none' },
    //     { contract: 'def', locationsCovered: 'Tuas', area: '0.3', duration: '00:21:00', remarks: 'none' },
    // ]
    let mapfilterSelected = maplayers.map((item, index) => {
        let childarr = item.children.map((child, childIndex) => {
            return { ...child, checked: true };
        })
        return { ...item, children: childarr, checked: (index === 0) }
    })
    const [mapLayers, setMapLayers] = useState(mapfilterSelected);

    const context = useDevicesNewState();
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleTabChange = (event, newValue) => {
        setSelectedMainTab(newValue);
    };
    useEffect(() => {
        setisLoading(true)
        if (location) {
            var deviceID = location.pathname.split('/')
            setselectedDeviceId(deviceID[3])
        }
        setMapLayers(mapfilterSelected)
        // eslint-disable-next-line
    }, [context.setData]);

    useEffect(() => {
        if (context.setData && currentDate && selectedDeviceId) {
            var today = moment().format('DD-MM-YYYY')
            var dateSelected = moment(selectedDate[0]).format('DD-MM-YYYY')
            if (dateSelected === today) {
                setisLoading(true)
                getFeedData(context.setData, selectedDeviceId)
                getDeviceDetails(context.setData, selectedDeviceId);
                getDeviceData(context.setData, selectedDeviceId, moment(selectedDate[0]).format('YYYY-MM-DD'));
            }
            else {
                setSelectedTab(0)
                setisLoading(true);
                setdeviceSummaryDataLoading(true);
                setdeviceSessionDataLoading(true);
                getPreviousData(context.setData, deviceDetails.id, deviceDetails.device_id, moment(selectedDate[0]).format('YYYY-MM-DD'))
            }

        }
        // eslint-disable-next-line
    }, [context.setData, currentDate, selectedDeviceId]);

    useEffect(() => {
        if (selectedDate) {
            var dateSelected = moment(selectedDate[0]).format('DD-MM-YYYY')
            var fileformatdate = moment(selectedDate[0]).format('YYYYMMDD')
            setcurrentDate(dateSelected)
            setfileformatDate(fileformatdate)
        }
    }, [selectedDate]);

    useEffect(() => {
        // if (context.data.pastData) {
        // console.log('context.data.pastData', context.data.pastData);
        //setfeedData(context.data.pastData);
        // if (context.data.pastData) {
        if (context.data.pastData) {
            let ptrimData = context.data.pastData.filter(d => d.file_type === 'trim_data')
            if (ptrimData && ptrimData.length > 0) {
                getPreviousTrimData(context.setData, ptrimData[0].id)
            } else {
                setdetailData([]);
                setfeedData([])
                settableFeedData([])
                setisLoading(false)

                enqueueSnackbar('No data found!', { variant: 'warning' });
            }
            let pdeviceData = context.data.pastData.filter(d => d.file_type === 'device_data')
            if (pdeviceData && pdeviceData.length > 0) {
                getPreviousDeviceData(context.setData, pdeviceData[0].id)
            } else {
                setdeviceData([])
                setdeviceDataTable([])
            }
            let pdeviceSummaryData = context.data.pastData.filter(d => d.file_type === 'device_summary')
            if (pdeviceSummaryData && pdeviceSummaryData.length > 0) {
                getPreviousDeviceSummaryData(context.setData, pdeviceSummaryData[0].id)
            } else {
                setdeviceSummaryData([])
                setdeviceSummaryDataTable([])
                setdeviceSummaryDataLoading(false)
            }
            let pdeviceSessionData = context.data.pastData.filter(d => d.file_type === 'device_session_summary')
            if (pdeviceSessionData && pdeviceSessionData.length > 0) {
                getPreviousDeviceSessionData(context.setData, pdeviceSessionData[0].id)
            } else {
                setdeviceSessionData([])
                setdeviceSessionDataTable([])
                setdeviceSessionDataLoading(false)
            }
            // var fileName =  'trim/processed_summary/daywise/' +fileformatDate +'/' + selectedDeviceId+'_'+fileformatDate+'_device-summary.csv'
            // var tempJson = { file_name : fileName }
            // let pdeviceSummaryData =   getPreviousDeviceSummaryData(context.setData, tempJson)

            // var sessionfileName =  'trim/processed_summary/daywise/' +fileformatDate +'/' + selectedDeviceId+'_'+fileformatDate+'_device-session-summary.json'
            // var tempJson = { file_name : sessionfileName }
            // let pdeviceSessionData =   getPreviousDeviceSessionData(context.setData, tempJson)
           
           
            // let pdeviceSummaryData = context.data.pastData.filter(d => d.file_type === 'device_summary')
            // if (pdeviceSummaryData && pdeviceSummaryData.length > 0) {
            //     getPreviousDeviceSummaryData(context.setData, pdeviceSummaryData[0].id)
            // } 
            // else {
            //     setdeviceSummaryData([])
            //     setdeviceSummaryDataTable([])
            //     setdeviceSummaryDataLoading(false)
            // }
        } else {
            setdetailData([]);
            setfeedData([])
            settableFeedData([])
            setisLoading(false)
            setdeviceSessionData([])
            setdeviceSessionDataTable([])
            setdeviceSessionDataLoading(false)
            setdeviceSummaryData([])
            setdeviceSummaryDataTable([])
            setdeviceSummaryDataLoading(false)
        }
        // }
        // }
        // eslint-disable-next-line
    }, [context.data.pastData, context.data.pastDataError]);

    const getData = useCallback((data, duration, fileType) => {
        if (duration === 'Today') {
            let feedList = [];
            const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];
            let dataArr = data
            let test = []
            let mapData1 = dataArr.map((d, i) => {
                if (d && d.gps && d.gps.coordinates) {
                    if(d.gps.coordinates && d.gps.coordinates[1] >= -90 && d.gps.coordinates[1] <= 90 && d.gps.coordinates && d.gps.coordinates[0] >= -180 && d.gps.coordinates[0] <= 180){
                    var coordinatesVal = d.gps.coordinates
                    let timeVal = moment(d.completed_at).format('DD-MMM-YYYY hh:mm:ss a')
                    let accuracy = d.hAcc + ' m'
                    let grassHeight = (d.grass_height !== null)? (d.grass_height / 10) : null
                    // let grassHeight = d.grass_height / 10 + ' cm'
                    let RTK = d.RTK
                    if (RTK === 0) { RTK = 'No GPS' }
                    else if (RTK === 1) { RTK = 'GPS Fix' }
                    else if (RTK === 2) { RTK = 'DGNSS + GPS' }
                    else if (RTK === 4) { RTK = 'GPS + RTK FIX' }
                    else if (RTK === 5) { RTK = 'GPS + RTK FLOAT' }
                    else { RTK = null }
                    let ErrorCode; 
                    ErrorCode = (d.error !== null)? (d.error) : ''
                    if((ErrorCode === 0) || (ErrorCode === 0.0)) { ErrorCode = 'NA'}
                    else if((ErrorCode === 1) || (ErrorCode === 1.0)) { ErrorCode = 'Sensor Error (Object is too far)'}
                    else if((ErrorCode === 2) || (ErrorCode === 2.0)) { ErrorCode = 'Negative Value'}
                    else if((ErrorCode === 4) || (ErrorCode === 4.0)) { ErrorCode = 'Grass Height out of range(>20 cm)'}
                    else if((ErrorCode === 8) || (ErrorCode === 8.0)) { ErrorCode = 'Object is too near'}
                    else if((ErrorCode === 16) || (ErrorCode === 16.0)) { ErrorCode = 'Device tilt angle out of range'}
                    return {
                        geometry: coordinatesVal,
                        value: grassHeight,
                        deviceTilt: d.device_tilt,
                        convertedgrassHeight : (d.grass_height !== null)? (d.grass_height / 10) : 0,
                        accuracy: accuracy,
                        RTK: d.RTK,
                        RTKVal: RTK,
                        error : ErrorCode,
                        distance : (d.distance) ? d.distance : '',
                        SOG : (d.SOG) ? d.SOG : '',
                        deviceType: deviceTypes[parseInt(i / (dataArr.length / 3))],
                        time: timeVal,
                        grass_height: grassHeight,
                        timeStamp: parseInt(moment(d.completed_at).format('x')),
                        locationId: d.loc_cd
                    }
                    }
                }
                else {
                    let timeVal = moment(d.completed_at).format('DD-MMM-YYYY hh:mm:ss a')
                    let accuracy = d.hAcc + ' m'
                    let grassHeight = (d.grass_height !== null)? (d.grass_height / 10) : null
                    // let grassHeight = d.grass_height / 10 + ' cm'
                    let RTK = d.RTK
                    if (RTK === 0) { RTK = 'No GPS' }
                    else if (RTK === 1) { RTK = 'GPS Fix' }
                    else if (RTK === 2) { RTK = 'DGNSS + GPS' }
                    else if (RTK === 4) { RTK = 'GPS + RTK FIX' }
                    else if (RTK === 5) { RTK = 'GPS + RTK FLOAT' }
                    else { RTK = null }
                    let ErrorCode; 
                    ErrorCode = (d.error !== null)? (d.error) : ''
                    if((ErrorCode === 0) || (ErrorCode === 0.0)) { ErrorCode = 'NA'}
                    else if((ErrorCode === 1) || (ErrorCode === 1.0)) { ErrorCode = 'Sensor Error (Object is too far)'}
                    else if((ErrorCode === 2) || (ErrorCode === 2.0)) { ErrorCode = 'Negative Value'}
                    else if((ErrorCode === 4) || (ErrorCode === 4.0)) { ErrorCode = 'Grass Height out of range(>20 cm)'}
                    else if((ErrorCode === 8) || (ErrorCode === 8.0)) { ErrorCode = 'Object is too near'}
                    else if((ErrorCode === 16) || (ErrorCode === 16.0)) { ErrorCode = 'Device tilt angle out of range'}
                    return {
                        geometry: [],
                        value: grassHeight,
                        convertedgrassHeight: (d.grass_height !== null)? (d.grass_height / 10) : 0,
                        deviceTilt: d.device_tilt,
                        accuracy: accuracy,
                        RTKVal: RTK,
                        RTK: d.RTK,
                        error : ErrorCode,
                        distance : (d.distance) ? d.distance : '',
                        SOG : (d.SOG) ? d.SOG : '',
                        deviceType: deviceTypes[parseInt(i / (dataArr.length / 3))],
                        time: timeVal,
                        grass_height: grassHeight,
                        timeStamp: parseInt(moment(d.completed_at).format('x')),
                        locationId: d.loc_cd
                    }
                }
            });
            if (mapData1.length > 0) {
                mapData1 = mapData1.reverse()
                test = mapData1.filter(f => f && f.geometry && f.geometry.length > 0)

                let uniqueLocs = [];
                test.forEach(v => {
                    if (v && v.locationId && uniqueLocs.indexOf(v.locationId) === -1) {
                        uniqueLocs.push(v.locationId)
                    }
                });
                if (uniqueLocs.length > 0)
                    getLocations(context.setData, uniqueLocs, '');
            }
            if (test) {
                let TR = _getTimeRange(test);
                settimeRange(TR);
                setfilterValue(TR);
            }
            setdetailData(test);
            setisLoading(false)
            settableFeedData(mapData1.reverse())
            feedList.push({ name: 'RTK' })
            feedList.push({ name: 'grass_height' })
            feedList.push({ name: 'hAcc' })
            setfeedListData(feedList)
            setdeviceSummaryDataLoading(false)
            setdeviceSummaryDataTable([]);
            setdeviceSessionDataTable([]);
            setdeviceSessionDataLoading(false)
            setoperationalData([])
        }
        else {
            //const DATA_URL = './576976_20200730_trim_data.csv'
            const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];
            let test = []
            let feedList = []
            var tableData = []
            if (fileType === 'device_session_summary'){
                require('d3-request').json(data, (error, response) => {
                    if (!error) {
                            if (response) {
                                setdeviceSessionDataTable(response);
                                setdeviceSessionDataLoading(false)
                            } else {
                                setdeviceSessionData([]);
                                setdeviceSessionDataTable([]);
                                setdeviceSessionDataLoading(false)
                            }
                    }
                    else{
                        setdeviceSessionData([]);
                        setdeviceSessionDataTable([]);
                        setdeviceSessionDataLoading(false)
                    }
                })
            }
            else if (fileType === 'device_summary'){
            require('d3-request').csv(data, (error, response) => {
                if (!error) {
                    if (response && response.length > 0) {
                        setdeviceSummaryDataTable(response);
                        setdeviceSummaryDataLoading(false)
                    } else {
                        setdeviceSummaryData([]);
                        setdeviceSummaryDataTable([]);
                        setdeviceSummaryDataLoading(false)
                    }
                } 
                else {
                    setdeviceSummaryData([]);
                    setdeviceSummaryDataTable([]);
                    setdeviceSummaryDataLoading(false)
                }
             })
            }
            else {
            require('d3-request').csv(data, (error, response) => {
                if (!error) {
                    // var promises = [];
                    if (fileType === 'trim_data') {
                        feedList.push({ name: 'RTK' })
                        feedList.push({ name: 'grass_height' })
                        feedList.push({ name: 'hAcc' })
                        const data1 = response.map((d, i) => {
                            if (d && d.lat && d.long) {
                                if(d.lat && d.lat >= -90 && d.lat <= 90 && d.long && d.long >= -180 && d.long <= 180){
                                let RTK = d.RTK
                                if (RTK === "0") { RTK = 'No GPS' }
                                else if (RTK === "1") { RTK = 'GPS Fix' }
                                else if (RTK === "2") { RTK = 'DGNSS + GPS' }
                                else if (RTK === "4") { RTK = 'GPS + RTK FIX' }
                                else if (RTK === "5") { RTK = 'GPS + RTK FLOAT' }
                                else { RTK = null }
                                let ErrorCode;
                                ErrorCode = (d.Error !== "")? (d.Error) : ''
                                if((ErrorCode === '0') || (ErrorCode === '0.0')) { ErrorCode = 'NA'}
                                else if((ErrorCode === '1') || (ErrorCode === '1.0')) { ErrorCode = 'Sensor Error (Object is too far)'}
                                else if((ErrorCode === '2') || (ErrorCode === '2.0')) { ErrorCode = 'Negative Value'}
                                else if((ErrorCode === '4') || (ErrorCode === '4.0')) { ErrorCode = 'Grass Height out of range(>20 cm)'}
                                else if((ErrorCode === '8') || (ErrorCode === '8.0')) { ErrorCode = 'Object is too near'}
                                else if((ErrorCode === '16') || (ErrorCode === '16.0')) { ErrorCode = 'Device tilt angle out of range'}
                                return {
                                    geometry: [Number(d.long), Number(d.lat)],
                                    value : (d.grass_height !== "")? (d.grass_height / 10) : null,
                                    // value: d.grass_height / 10 + ' cm',
                                    convertedgrassHeight : (d.grass_height !== "")? d.grass_height / 10 : 0 ,
                                    error : ErrorCode,
                                    distance : (d.Distance) ? d.Distance : '',
                                    SOG : (d.SOG) ? d.SOG : '',
                                    deviceTilt: d.device_tilt,
                                    accuracy: d.hAcc + ' m',
                                    RTK: d.RTK,
                                    RTKVal: RTK,
                                    deviceType: deviceTypes[parseInt(i / (response.length / 3))],
                                    time: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    completed_at: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    timeStamp: parseInt(d.Time) * 1000,
                                    grass_height: d.grass_height,
                                    hAcc: d.hAcc + ' m',
                                    locationId: d.loc_cd
                                }
                                }
                            }
                            else {
                                let RTK = d.RTK
                                if (RTK === "0") { RTK = 'No GPS' }
                                else if (RTK === "1") { RTK = 'GPS Fix' }
                                else if (RTK === "2") { RTK = 'DGNSS + GPS' }
                                else if (RTK === "4") { RTK = 'GPS + RTK FIX' }
                                else if (RTK === "5") { RTK = 'GPS + RTK FLOAT' }
                                else { RTK = null }
                                let ErrorCode;
                                ErrorCode = (d.Error !== "")? (d.Error) : ''
                                if((ErrorCode === '0') || (ErrorCode === '0.0')) { ErrorCode = 'NA'}
                                else if((ErrorCode === '1') || (ErrorCode === '1.0')) { ErrorCode = 'Sensor Error (Object is too far)'}
                                else if((ErrorCode === '2') || (ErrorCode === '2.0')) { ErrorCode = 'Negative Value'}
                                else if((ErrorCode === '4') || (ErrorCode === '4.0')) { ErrorCode = 'Grass Height out of range(>20 cm)'}
                                else if((ErrorCode === '8') || (ErrorCode === '8.0')) { ErrorCode = 'Object is too near'}
                                else if((ErrorCode === '16') || (ErrorCode === '16.0')) { ErrorCode = 'Device tilt angle out of range'}
                                return {
                                    geometry: [],
                                    value : (d.grass_height !== "")? (d.grass_height / 10) : null,
                                    // value: d.grass_height / 10 + ' cm',
                                    convertedgrassHeight: (d.grass_height !== "")? d.grass_height / 10 : 0,
                                    error : ErrorCode,
                                    distance : (d.Distance) ? d.Distance : '',
                                    SOG : (d.SOG) ? d.SOG : '',
                                    deviceTilt: d.device_tilt,
                                    accuracy: d.hAcc + ' m',
                                    RTK: d.RTK,
                                    RTKVal: RTK,
                                    deviceType: deviceTypes[parseInt(i / (response.length / 3))],
                                    time: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    completed_at: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    timeStamp: parseInt(d.Time) * 1000,
                                    grass_height: d.grass_height,
                                    hAcc: d.hAcc + ' m',
                                    locationId: d.loc_cd
                                }
                            }
                        })
                        Promise.all(data1).then((values) => {
                            const uniqueLocs = [];
                            values.forEach(v => {
                                if (v && v.locationId && uniqueLocs.indexOf(v.locationId) === -1) {
                                    uniqueLocs.push(v.locationId)
                                }
                            });
                            // uniqueLocs.splice(-1, 1)
                            // if (uniqueLocs && uniqueLocs.length > 0) {
                            //     let staticLocations = {
                            //         'CN': uniqueLocs
                            //     }
                            if (uniqueLocs.length > 0)
                                getLocations(context.setData, uniqueLocs, '');
                            // }
                            setfeedData(values)
                            if (values.length > 0) {
                                test = values.filter(f => f && f.geometry && f.geometry.length > 0)
                            }
                            if (test) {
                                let TR = _getTimeRange(test);
                                settimeRange(TR);
                                setfilterValue(TR);
                            }
                            setdetailData(test);
                            tableData = values.reverse()
                            settableFeedData(tableData)
                            setfeedListData(feedList)
                            setisLoading(false)
                        });
                    } else if (fileType === 'device_data') {
                        // console.log('device_data', response[0])
                        if (response && response.length > 0) {
                            var deviceDataResp = response
                            var STATUS = {
                                ColdStart: 1,
                                WarmStart: 2,
                                UserShutDown: 4,
                                AutoShutDown: 8,
                                CalDone: 16,
                                HSenseErr: 256,
                                NoGPS: 512,
                                NoRTK: 1024,
                                Reserved32: 32,
                                Reserved64: 64,
                                Reserved128: 128
                            };
                            var dataList = []
                            var firstIndex = response[response.length - 1]
                            var lastSeenTime = moment(firstIndex.completed_at).format('DD-MMM-YYYY hh:mm a');
                            dataList.push({ label: 'Last Seen', value: lastSeenTime })
                            setlastSeenTime(lastSeenTime)
                            var dataString = firstIndex.parsed_data.replace(/'/g, '"');
                            var dataString1 = dataString.replace(/None/g, '"None"');
                            var parsedJSON = JSON.parse(dataString1);
                            Object.entries(parsedJSON).forEach(([key, value]) => {
                                if (key !== 'Reboot Counter') { dataList.push({ label: key, value: value }) }
                                if (key === 'rssi') { setdeviceRssiVal(value) }
                                if (key === 'Battery Level') { setdeviceBatteryVal(value) }
                                if (key === 'Status') {
                                    var status_input = value
                                    var status_output = []
                                    if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
                                    if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
                                    if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
                                    if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
                                    if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
                                    if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
                                    if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
                                    if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
                                    if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
                                    if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
                                    if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
                                    setstatusData(status_output)
                                }
                                if (key === 'FW Version') {
                                    setFWVersion(value)
                                }
                            })
                            setdeviceData(dataList);
                            var deviceTableList = [];
                            deviceDataResp.forEach((data, index) => {
                                var rebootCounter = null
                                var rssi = null
                                var batterylevel = null
                                var status = null
                                var source = null
                                var calibratedheight = null
                                var lastSeenTime = null
                                lastSeenTime = moment(data.completed_at).format('DD-MMM-YYYY hh:mm a');
                                var dataString = data && data.parsed_data.replace(/'/g, '"');
                                var dataString1 = dataString && dataString.replace(/None/g, '"None"');
                                if(dataString1){
                                var parsedJSON = JSON.parse(dataString1);
                                }
                                if(parsedJSON){
                                Object.entries(parsedJSON).forEach(([key, value]) => {
                                    if (key === 'Reboot Counter') { rebootCounter = value }
                                    if (key === 'rssi') { rssi = value }
                                    if (key === 'Battery Level') { batterylevel = value }
                                    if (key === 'source') { source = value }
                                    if (key === 'Calibrated Height') { calibratedheight = value / 10 }
                                    if (key === 'Status') {
                                        var status_input = value
                                        var status_output = []
                                        if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
                                        if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
                                        if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
                                        if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
                                        if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
                                        if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
                                        if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
                                        if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
                                        if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
                                        if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
                                        if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
                                        status = status_output.join(', ')
                                    }
                                })
                                deviceTableList.push({
                                    lastSeenTime: lastSeenTime,
                                    rebootCounter: rebootCounter,
                                    rssi: rssi,
                                    batterylevel: batterylevel,
                                    status: status.replace(/,\s*$/, ""),
                                    source: source,
                                    calibratedheight: calibratedheight
                                })
                                }
                            })
                            deviceTableList = deviceTableList.reverse()
                            setdeviceDataTable(deviceTableList)
                        } else {
                            setdeviceData([])
                            setdeviceDataTable([])
                        }
                    } 
                }
            })
            }
            
        }
    }, [context.setData]);


    useEffect(() => {
        if (context.data.previousTrimData) {
            getData(context.data.previousTrimData.file_s3_url, 'custom', 'trim_data')
        }
    }, [getData, context.data.previousTrimData, context.data.previousTrimDataError]);

    useEffect(() => {
        if (context.data.previousDeviceData) {
            getData(context.data.previousDeviceData.file_s3_url, 'custom', 'device_data')
        }
    }, [getData, context.data.previousDeviceData, context.data.previousDeviceDataError]);

    useEffect(() => {
        if (context.data.previousDeviceSummaryData) {
            getData(context.data.previousDeviceSummaryData.file_s3_url, 'custom', 'device_summary')
        }
        if (context.data.previousDeviceSummaryDataError) {
            setdeviceSummaryDataLoading(false);
        }
    }, [getData, context.data.previousDeviceSummaryData, context.data.previousDeviceSummaryDataError]);

    useEffect(() => {
        if (context.data.previousDeviceSessionData) {
            getData(context.data.previousDeviceSessionData.file_s3_url, 'custom', 'device_session_summary')
        }
        if (context.data.previousDeviceSessionDataError) {
            setdeviceSessionDataLoading(false);
        }
    }, [getData, context.data.previousDeviceSessionData, context.data.previousDeviceSessionDataError]);
    useEffect(() => {
        if (context.data.feedData) {
            setfeedData(context.data.feedData);
            getData(context.data.feedData, 'Today', '')
        }
    }, [getData, context.data.feedData, context.data.feedDataError]);

    useEffect(() => {
        if (context.data.deviceDetails) {
            setdeviceDetails(context.data.deviceDetails);
        }
    }, [context.data.deviceDetails, context.data.deviceDetailsError]);



    // useEffect(() => {
    //     if (feedData) {

    //     }
    // }, [feedData]);

    // useEffect(() => {
    //     if (context.data.mapData) {
    //         setmapData(context.data.mapData);
    //     }
    // }, [context.data.mapData, context.data.mapDataError]);


    useEffect(() => {
        if (context.data.deviceDatas) {
            if (context.data.deviceDatas.results && context.data.deviceDatas.results.length > 0) {
                var deviceDataResp = context.data.deviceDatas.results
                var STATUS = {
                    ColdStart: 1,
                    WarmStart: 2,
                    UserShutDown: 4,
                    AutoShutDown: 8,
                    CalDone: 16,
                    HSenseErr: 256,
                    NoGPS: 512,
                    NoRTK: 1024,
                    Reserved32: 32,
                    Reserved64: 64,
                    Reserved128: 128
                };
                var dataList = []
                var firstIndex = context.data.deviceDatas.results[0]
                var lastSeenTime = moment(firstIndex.completed_at).format('DD-MMM-YYYY hh:mm a');
                dataList.push({ label: 'Last Seen', value: lastSeenTime })
                setlastSeenTime(lastSeenTime)
                var dataString = firstIndex.parsed_data.replace(/'/g, '"');
                var dataString1 = dataString &&  dataString.replace(/None/g, '"None"');
                if(dataString1){
                var parsedJSON = JSON.parse(dataString1);
                }
                if(parsedJSON){
                Object.entries(parsedJSON).forEach(([key, value]) => {
                    if (key !== 'Reboot Counter') { dataList.push({ label: key, value: value }) }
                    if (key === 'rssi') { setdeviceRssiVal(value) }
                    if (key === 'Battery Level') { setdeviceBatteryVal(value) }
                    if (key === 'Status') {
                        var status_input = value
                        var status_output = []
                        if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
                        if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
                        if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
                        if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
                        if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
                        if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
                        if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
                        if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
                        if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
                        if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
                        if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
                        setstatusData(status_output)
                    }
                    if (key === 'FW Version') {
                        setFWVersion(value)
                    }
                })
                }
                setdeviceData(dataList);
                var deviceTableList = [];
                deviceDataResp.forEach((data, index) => {
                    var rebootCounter = null
                    var rssi = null
                    var batterylevel = null
                    var status = null
                    var source = null
                    var calibratedheight = null
                    var lastSeenTime = null
                    lastSeenTime = moment(data.completed_at).format('DD-MMM-YYYY hh:mm a');
                    var dataString = data && data.parsed_data && data.parsed_data.replace(/'/g, '"');
                    var dataString1 = dataString && dataString.replace(/None/g, '"None"');
                    var parsedJSON = dataString1 && JSON.parse(dataString1);
                    if(parsedJSON){
                    Object.entries(parsedJSON).forEach(([key, value]) => {
                        if (key === 'Reboot Counter') { rebootCounter = value }
                        if (key === 'rssi') { rssi = value }
                        if (key === 'Battery Level') { batterylevel = value }
                        if (key === 'source') { source = value }
                        if (key === 'Calibrated Height') { calibratedheight = value / 10 }
                        if (key === 'Status') {
                            var status_input = value
                            var status_output = []
                            if ((status_input & STATUS.WarmStart) === STATUS.WarmStart) { status_output.push('Warm Start') }
                            if ((status_input & STATUS.ColdStart) === STATUS.ColdStart) { status_output.push('Cold Start') }
                            if ((status_input & STATUS.AutoShutDown) === STATUS.AutoShutDown) { status_output.push('Auto ShutDown') }
                            if ((status_input & STATUS.UserShutDown) === STATUS.UserShutDown) { status_output.push('User ShutDown') }
                            if ((status_input & STATUS.CalDone) === STATUS.CalDone) { status_output.push('Calibration Done') }
                            if ((status_input & STATUS.HSenseErr) === STATUS.HSenseErr) { status_output.push('Height Sensing Err') }
                            if ((status_input & STATUS.NoGPS) === STATUS.NoGPS) { status_output.push('No GPS') }
                            if ((status_input & STATUS.NoRTK) === STATUS.NoRTK) { status_output.push('No RTK') }
                            if ((status_input & STATUS.Reserved32) === STATUS.Reserved32) { status_output.push('Reserved') }
                            if ((status_input & STATUS.Reserved64) === STATUS.Reserved64) { status_output.push('Reserved') }
                            if ((status_input & STATUS.Reserved128) === STATUS.Reserved128) { status_output.push('Reserved') }
                            status = status_output.join(', ')
                        }
                    })
                    
                    deviceTableList.push({
                        lastSeenTime: lastSeenTime,
                        rebootCounter: rebootCounter,
                        rssi: rssi,
                        batterylevel: batterylevel,
                        status: status.replace(/,\s*$/, ""),
                        source: source,
                        calibratedheight: calibratedheight
                    })
                    }
                })
                setdeviceDataTable(deviceTableList)
            } else {
                setdeviceData([])
                setdeviceDataTable([])
            }
        }
    }, [context.data.deviceDatas, context.data.deviceDatasError]);

    // useEffect(() => {
    //     if (deviceDetails?.template?.schema?.fields) {
    //         let fields = deviceDetails.template.schema.fields;
    //         let pDS = fields.filter(f => f.name == 'parsed_data_series');
    //         if (pDS && pDS.length > 0 && pDS[0].fields) {
    //             setfeedList(pDS[0].fields);
    //         }
    //     }
    // }, [deviceDetails]);



    useEffect(() => {
        if (context.data.locations) {
            setselectedLocation(context.data.locations)
        }
    }, [context.data.locations]);

    const onDateChange = (date, label) => {
        setselectedDate(date);
        setselectedDateType(label);
    }
    const onRefreshData = () => {
        if (selectedDateType === 'Today') {
            getFeedData(context.setData, selectedDeviceId)
            getDeviceDetails(context.setData, selectedDeviceId);
            getDeviceData(context.setData, selectedDeviceId, moment(selectedDate).format('YYYY-MM-DD'));
        }
        else {
            //console.log('Yesterday')
            if (context.data.previousDeviceData) {
                getData(context.data.previousDeviceData.file_s3_url, 'device_data')
            }
            if (context.data.previousTrimData) {
                getData(context.data.previousTrimData.file_s3_url, 'trim_data')
            }
        }

    };
    const onBackPressed = () => {
        history.push({
            pathname: `/app/deviceDetails`,
        });
    };

    const _getTimeRange = (data) => {
        if (!data) {
            return null;
        }


        if (data && data[data.length - 1] && data[data.length - 1].timeStamp) {
            if (data && data[0] && data[0].timeStamp) {
                if (data[data.length - 1].timeStamp < data[0].timeStamp) {
                    return [data[data.length - 1].timeStamp, data[0].timeStamp]
                } else {
                    return [data[0].timeStamp, data[data.length - 1].timeStamp]
                }
                // return [data[data.length - 1].timeStamp, data[0].timeStamp]
            }
        }
    }

    const _formatLabel = (t) => {
        const date = new Date(t);
        const formattedDate = date.toLocaleDateString('en-US', {
            day: '2-digit', month: 'short'
        }).replace(/ /g, '-');
        const formattedTime = date.toLocaleTimeString('en-US',
            { hour: '2-digit', minute: '2-digit' })
        return formattedTime
    }
    const getSummaryParameters = (paramValue) => {
        if(paramValue == 'gps_dgnss'){ return null }
        else if(paramValue == 'gh_bad'){ return null }
        else if(paramValue == 'gh_good'){ return null }
        else if(paramValue == 'start_time'){ return 'Start Time' }
        else if(paramValue == 'end_time'){ return 'End Time' }
        else if(paramValue == 'duration'){ return 'Duration' }
        else if(paramValue == 'locations'){ return 'Locations Covered' }
        else if(paramValue == 'gps_only'){ return null }
        else if(paramValue == 'gps_only'){ return null }
        else if(paramValue == 'gps_rtk_fix'){ return null }
        else if(paramValue == 'gps_rtk_float'){ return null }
        else if(paramValue == 'avg_gh'){ return null }
        else if(paramValue == 'avg_dt'){ return null }
        else if(paramValue == 'avg_rtk'){ return null }
        else if(paramValue == 'avg_acc'){ return 'Avg Positioning Accuracy' }
        else if(paramValue == 'dt_good'){ return null}
        else if(paramValue == 'dt_bad'){ return null}
        else if(paramValue == 'acc_lt_1'){ return 'Points with accuracy value less than 1m'}
        else if(paramValue == 'acc_bw_1_3'){ return 'Points with accuracy value between 1m and 3m'}
        else if(paramValue == 'acc_bw_3_10'){ return 'Points with accuracy value between 3m and 10m'}
        else if(paramValue == 'acc_gt_10'){ return 'Points with accuracy value greater than 10m'}
    };

    const getSummaryValues = (param, value) => {
        if(param == 'start_time'){ if(value){return moment.utc(value).local().format('DD-MMM-YYYY hh:mm a')}else{ return '-'}  }
        else if(param == 'end_time'){ if(value){return moment.utc(value).local().format('DD-MMM-YYYY hh:mm a')}else{ return '-'}  }
        else if(param == 'duration'){ if(value){return value} else{ return '-'}}
        else if(param == 'locations'){ if(value){return value} else{ return '-'} }
        else if(param == 'avg_acc'){ if(value){return value + ' m'} else{ return '-'} }
        else if(param == 'acc_lt_1'){ if(value){return value} else{ return '-'} }
        else if(param == 'acc_bw_1_3'){ if(value){return value} else{ return '-'} }
        else if(param == 'acc_bw_3_10'){ if(value){return value} else{ return '-'} }
        else if(param == 'acc_gt_10'){ if(value){return value} else{ return '-'} }
    };



    useEffect(() => {
        var operationHistory = []
        if(deviceSessionDataTable && deviceSessionDataTable.features){
            deviceSessionDataTable.features.map((data1, index)=>{
                let props = {}
                if(data1.properties){
                    props = data1.properties
                    if(props.locations_covered){
                        var string = props.locations_covered.toString()
                        string = string.split(' ')
                        // console.log(split,'spl')
                        props.locations_covered = string
                    }
                    props.checked = false;
                    operationHistory.push(props)
                }
                
            })
            setoperationalrawData(deviceSessionDataTable)
            setoperationalData(operationHistory)
           
        }
        // eslint-disable-next-line
    }, [deviceSessionDataTable]);
    // let chartWidth = window.innerWidth - 97;
    const locationSelected = (data) => {
        setisLoading(true)
        setlocationClicked(data)
        setdetailData(detailData)
    }
    useEffect(() => {
        setisLoading(false)
        setlocationClicked(locationClicked)
        setdetailData(detailData)
    }, [locationClicked]);



    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            {<div style={{ borderBottom: "solid 1px #c4d7d7", background: 'white', padding: "12px 12px 8px 20px", alignItems: "center" }}>
                <div style={{ display: 'flex' }}>
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.backbtn}
                        onClick={onBackPressed}
                    >
                        <ArrowBack style={{ marginRight: "12px" }} /> BACK TO DEVICES LIST
                            </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.refreshbtn}
                        onClick={onRefreshData}
                    >
                        <Refresh style={{ marginRight: "12px" }}></Refresh> REFRESH DATA
                            </Button>
                </div>
                <div className={classes.locationName}>{deviceDetails && deviceDetails.alias_name + ' (' + deviceDetails.device_id + ')'}</div>
                <div style={{ display: 'flex', borderBottom: 'solid 1px #C4D7D7' }}>
                    <div className={classes.deviceDetails} >
                        <div>
                            {FWVersion && <div className={classes.Fwversion}> {FWVersion} </div>}
                        </div>
                        <div style={{ marginTop: '-10px' }}>
                            {deviceRssiVal && deviceRssiVal >= 0 && deviceRssiVal <= 25 && <SignalCellular1Bar style={{ marginLeft: "12px" }} />}
                            {deviceRssiVal && deviceRssiVal > 25 && deviceRssiVal <= 50 && <SignalCellular2Bar style={{ marginLeft: "12px" }} />}
                            {deviceRssiVal && deviceRssiVal > 50 && deviceRssiVal <= 75 && <SignalCellular3Bar style={{ marginLeft: "12px" }} />}
                            {deviceRssiVal && deviceRssiVal > 75 && deviceRssiVal <= 100 && <SignalCellular4Bar style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal!==null && deviceBatteryVal!==undefined && deviceBatteryVal >= 0 && deviceBatteryVal <= 20 && <Battery20 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 20 && deviceBatteryVal <= 30 && <Battery30 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 30 && deviceBatteryVal <= 50 && <Battery50 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 50 && deviceBatteryVal <= 60 && <Battery60 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 60 && deviceBatteryVal <= 80 && <Battery80 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 80 && deviceBatteryVal <= 90 && <Battery90 style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 90 && deviceBatteryVal <= 100 && <BatteryFull style={{ marginLeft: "12px" }} />}
                            {deviceBatteryVal > 100 && <BatteryFull style={{ marginLeft: "12px" }} />}
                        </div>
                        <div>
                            {deviceBatteryVal!==null && deviceBatteryVal!==undefined && <div className={classes.Fwversion}> {deviceBatteryVal + '%'} </div>}
                        </div>

                        <div style={{ marginLeft: '2%', display: 'flex' }} >
                            {lastSeenTime && <div className={classes.TimeLabel}> {lastSeenTime} </div>}
                        </div>
                    </div>
                    <div className={classes.dateFilterDiv}>
                        <DateFilter
                            options={[]}
                            label="Duration"
                            className={classes.dateFilterChip}
                            onDateChange={onDateChange}
                        ></DateFilter>
                    </div>
                </div>
                <Tabs
                    value={selectedMainTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="disabled tabs example"
                    centered
                    TabIndicatorProps={{
                        style: {
                            height: "4px",
                            borderRadius: "0px 0px 4px 4px",
                            transform: "matrix(1, 0, 0, -1, 0, 0)",
                        },
                    }}
                >
                    <Tab label="DEVICE SUMMARY" className={classes.tabTitle} />
                    <Tab label="DEVICE DIAGNOSTICS" className={classes.tabTitle} />
                </Tabs>
            </div>}
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid item>
                            <Paper className={classes.paper} elevation={3}>
                                <div style={{height:'0px'}} >
                                    <TabPanel value={selectedMainTab} index={0}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Paper className={classes.paper} elevation={3}>
                                                    {/* <div className={classes.paperHeader}> GPS FEED </div> */}
                                                    <div className={classes.mapContainer} id="detailedmap">
                                                        {isLoading === false ? <div>
                                                            {detailData && detailData.length > 0  ?
                                                                <GoogleDetailedMap data={detailData} locationClicked={locationClicked} coverage={detailMapCoverageData} mapLayers={mapLayers} locationData={selectedLocation} filterValue={filterValue}></GoogleDetailedMap> :
                                                                <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '15%' }} > No data to display on map  </div>}
                                                        </div>
                                                            : <div style={{ justifyContent: "center", textAlign: 'center', marginTop: "15%" }}><CircularProgress /></div>
                                                        }
                                                         {isLoading === false && detailData && detailData.length > 0 && <div className={classes.slidercontrol}>
                                                                <RangeInput
                                                                min={timeRange[0]}
                                                                max={timeRange[1]}
                                                                value={filterValue}
                                                                formatLabel={(t) => _formatLabel(t)} 
                                                                animationSpeed={8.64e7 * 30}
                                                                onChange={({ value }) => setfilterValue(value)}/> 
                                                              </div> }
                                                        {isLoading === false && detailData && detailData.length > 0 && timeRange && filterValue && <div className={classes.timecontrol}>
                                                            <TimeTravel min={timeRange[0]} max={timeRange[1]} value={filterValue}
                                                                formatLabel={(t) => _formatLabel(t)}
                                                                onChange={({ value }) => setfilterValue(value)} />
                                                        </div>}
                                                    </div>
                                                </Paper>
                                            </Grid>
                                            <Grid item lg={4} md={5} sm={12} xs={12}>
                                                {isLoading === false ? <Paper className={classes.paper} elevation={3}>
                                                    <div className={classes.paperHeader}> DEVICE SUMMARY </div>
                                                    <div className={classes.detailView}>
                                                        {(detailData && detailData.length > 0 && !deviceSummaryDataLoading ) ? (<div>
                                                            {selectedDateType == 'Today'&& <div>
                                                                <div className={classes.detailLabel}> Status </div>
                                                                {statusData.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={classes.filterChip}> {item} </div>
                                                                    );
                                                                })
                                                                }
                                                            </div>}
                                                            <div style={{ marginTop: '2%' }}>
                                                                {/* <div key={0}>
                                                                    <div className={classes.detailLabel}> {'Last Seen'} </div>
                                                                    <div className={classes.detailValue}> {lastSeenTime} </div>
                                                                </div> */}
                                                                <div key={0}>
                                                                    <div className={classes.detailLabel}> {'FW Version'} </div>
                                                                    <div className={classes.detailValue}> {FWVersion} </div>
                                                                </div>
                                                                <div key={1}>
                                                                <div>
                                                                    <div className={classes.detailLabel}> Total data points collected </div>
                                                                    <div className={classes.detailValue}> {detailData && detailData.length} </div>
                                                                </div>
                                                                </div>
                                                                {!deviceSummaryDataLoading && ((deviceSummaryDataTable && deviceSummaryDataTable.length>0) ?                                                     
                                                                    (<div style={{height: 'calc(100vh - 380px)', overflow: 'auto'}}>
                                                                        {deviceSummaryDataTable.columns.map((c,idx)=>{
                                                                            return (getSummaryParameters(c) !== null)&& (
                                                                                <div key={idx}>
                                                                                    {getSummaryParameters(c) === ('Duration') && <div>
                                                                                        <div className={classes.detailLabel}> {'Total Operational Hours'} </div> <div className={classes.detailValue}> {getSummaryValues(c, deviceSummaryDataTable[0][c])} </div> </div> } 
                                                                                    {getSummaryParameters(c) === ('Start Time') && <div> <div className={classes.detailLabel}> {getSummaryParameters(c)} </div>
                                                                                        <div className={classes.detailValue}> {getSummaryValues(c, deviceSummaryDataTable[0][c])} </div> </div>}
                                                                                    {getSummaryParameters(c) === ('End Time') && <div> <div className={classes.detailLabel}> {getSummaryParameters(c)} </div>
                                                                                        <div className={classes.detailValue}> {getSummaryValues(c, deviceSummaryDataTable[0][c])} </div> </div>}
                                                                                    {getSummaryParameters(c) === ('Locations Covered') && <div> <div className={classes.detailLabel}> {getSummaryParameters(c)} </div>
                                                                                        <div className={classes.detailValue}> {getSummaryValues(c, deviceSummaryDataTable[0][c])} </div> </div>}   
                                                                                </div>
                                                                            )
                                                                            
                                                                        })}                                                           
                                                                    </div>)                                                  
                                                                : ( <div> </div>
                                                                    // <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No other summary data available  </div>
                                                                ))}
                                                            
                                                                {/* {deviceData.map((item, index) => {
                                                                    if (item.label !== 'source') {
                                                                        if (item.label !== 'rssi') {
                                                                            if (item.label !== 'Battery Level') {
                                                                                if (item.label !== 'Status') {
                                                                                    var label = item.label
                                                                                    var value = item.value
                                                                                    if (item.label === 'Calibrated Height') {
                                                                                        return (
                                                                                            <div key={index}>
                                                                                                <div className={classes.detailLabel}> {label} </div>
                                                                                                <div className={classes.detailValue}> {value ? value / 10 + ' cm' : '-'} </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <div key={index}>
                                                                                                <div className={classes.detailLabel}> {label} </div>
                                                                                                <div className={classes.detailValue}> {value} </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    return <div key={index}></div>;
                                                                })
                                                                } */}
                                                            </div>
                                                            
                                                        </div>) : (
                                                                <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '150px' }} > No data to Display  </div>
                                                            )}

                                                    </div>

                                                </Paper> : null}
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={12} xs={12}>
                                                {isLoading === false ? <Paper className={classes.paper} elevation={3}>
                                                    <div className={classes.paperHeader}> OPERATIONAL HISTORY </div>
                                                    <div className={classes.tableView}>
                                                    {isLoading === false && !deviceSessionDataLoading && (( detailData && detailData.length > 0 && operationalData && operationalData.length > 0) ?  
                                                            <div style={{ maxWidth: "100%" }} className="btmtable">
                                                            <MaterialTable
                                                                title='' //{'Device - ' + deviceAliasName}
                                                                columns={[
                                                                    { title: "START TIME", field: "start_date",
                                                                      render: rowData => moment.utc(rowData.start_date).local().format('DD-MMM, hh:mm a')
                                                                    },
                                                                    { title: "END TIME", field: "end_time",
                                                                      render: rowData => moment.utc(rowData.end_time).local().format('DD-MMM, hh:mm a')
                                                                    },
                                                                    {
                                                                        title: "SESSION DURATION",
                                                                        field: "session_duration",
                                                                        //type: "numeric",
                                                                        grouping: false,
                                                                        headerStyle: { textAlign: "left" },
                                                                    },
                                                                    {
                                                                        title: "AREA COVERED (in hA)",
                                                                        field: "total_area_covered",
                                                                        //type: "numeric",
                                                                        grouping: false,
                                                                        headerStyle: { textAlign: "left" },
                                                                        render: rowData => (rowData.total_area_covered) ? (parseFloat(rowData.total_area_covered/10000)).toFixed(3) : '-' 
                                                                    }, 
                                                                    // { title: "Total Data Points", field: "total_count" },
                                                                    {   title: "LOCATIONS COVERED", field: "locations_covered",
                                                                        render: rowData => 
                                                                    
                                                                        <div title="Click to view the location" style={{ cursor: 'pointer', color: '#2C7873' }} >
                                                                            {rowData.locations_covered && rowData.locations_covered.map((data,index)=>{
                                                                                return(
                                                                                 <div onClick={() => locationSelected(data)} style={{ flexGrow: 1 }} > {data + ' '} </div>)
                                                                            })}
                                                                        </div>
                                                                        // render: rowData => (rowData.locations_covered) ? (rowData.locations_covered) : 'null' 
                                                                    },
                                                                                    
                                                                ]}
                                                                onSelectionChange={(rows) => {
                                                                    let dmData = operationalrawData.features.filter(d=>{
                                                                      let isSelected = false;
                                                                      rows.forEach(r=>{
                                                                        if(!isSelected){                                                                           
                                                                            if(r.start_date === d.properties.start_date){
                                                                                isSelected = true
                                                                                
                                                                           }
                                                                        
                                                                        }
                                                                      })
                                                                      return isSelected;
                                                                    })
                                                                    setdetailMapCoverageData(dmData);
                                                                  }}
                                                                data={operationalData}
                                                                options={{
                                                                    //toolbar : false,
                                                                    selection: true,
                                                                    grouping: false,
                                                                    search: false,
                                                                    exportAllData: false,
                                                                    exportButton: false,
                                                                    headerStyle: {
                                                                        fontSize: "12px",
                                                                        fontFamily: "Karla",
                                                                        color: "#698D8D",
                                                                        backgroundColor: "#F1FCFC",
                                                                        padding: "4px 12px",
                                                                    },
                                                                    cellStyle: {
                                                                        fontSize: "12px",
                                                                        fontFamily: "Karla",
                                                                        fontWeight: "bold",
                                                                        padding: "4px 12px",
                                                                    },
                                                                    rowStyle: {
                                                                        height: "auto",
                                                                    },
                                                                    pageSize: 5,
                                                                    maxBodyHeight: '280px',
                                                                    //maxBodyHeight: 'calc(100vh - 431px)',
                                                
                                                                }}
                                                            />
                                                        </div>                                                   
                                                        // <OperationalHistory data={deviceSessionDataTable} ></OperationalHistory>                                                 
                                                    : (
                                                        <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No data to Display  </div>
                                                    ))}
                                                    {deviceSessionDataLoading && <div style={{ justifyContent: "center", textAlign: 'center', paddingTop: "150px" }}><CircularProgress /></div>}
                                                        {/* {feedListData && feedListData.length > 0 && detailData && detailData.length > 0 ?
                                                            <OperationalHistory></OperationalHistory> :
                                                            <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '150px' }} > No data to Display  </div>
                                                        } */}
                                                    </div></Paper> : null}
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={selectedMainTab} index={1}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <Paper className={classes.paperFeedInfo} elevation={3}>
                                                    <div className={classes.paperHeader}>DEVICE DIAGNOSTICS SUMMARY</div>
                                                    {isLoading === false && !deviceSummaryDataLoading && ((detailData && detailData.length > 0 && deviceSummaryDataTable && deviceSummaryDataTable.length>0) ?                                                     
                                                        (<div style={{padding: 15, height: 'calc(100vh - 320px)', overflow: 'auto'}}>
                                                            <table>
                                                                {deviceSummaryDataTable.columns.map((c,idx)=>{
                                                                    return (getSummaryParameters(c)!= null) && (
                                                                        <tr style={{paddingBottom : 25}} key={idx}>
                                                                            <td style={{paddingBottom : 25,width: '50%'}}  ><b>{getSummaryParameters(c)}</b></td>
                                                                            <td style={{paddingBottom : 25, paddingLeft : 10}}>{getSummaryValues(c, deviceSummaryDataTable[0][c])}</td>
                                                                        </tr>
                                                                    )
                                                                })}                                                            
                                                            </table>
                                                        </div>)                                                  
                                                    : (
                                                        <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No data to Display  </div>
                                                    ))}
                                                    {deviceSummaryDataLoading && <div style={{ justifyContent: "center", textAlign: 'center', paddingTop: "150px" }}><CircularProgress /></div>}
                                                </Paper>
                                            </Grid>
                                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                                <Paper className={classes.paper} elevation={3}>
                                                    <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
                                                        {/* <Tab label={<span className={classes.tabTitle}>GPS FEED</span>} {...a11yProps(0)} /> */}
                                                        <Tab label={<span className={classes.tabTitle}>FEED TREND</span>} {...a11yProps(0)} />
                                                        <Tab label={<span className={classes.tabTitle}>RAW DATA</span>} {...a11yProps(1)} />
                                                        <Tab label={<span className={classes.tabTitle}>DEVICE DATA</span>} {...a11yProps(2)} />
                                                    </Tabs>
                                                    <div hidden={selectedTab !== 0} style={{ height: 'calc(100vh - 320px)', overflow: 'hidden' }}>
                                                        {isLoading === false ? <div className={classes.paper}>
                                                            <div className={classes.activeTableContainer}>
                                                                {feedData && feedData.length > 0 ?
                                                                    <div>
                                                                        <FeedGraph data={feedData} selectedFeeds={feedListData} /> </div> :
                                                                    <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No data to Display  </div>
                                                                }
                                                            </div></div> :
                                                            <div>
                                                                <div style={{ justifyContent: "center", textAlign: 'center', paddingTop: "150px" }}><CircularProgress /></div>
                                                            </div>}
                                                    </div>
                                                    <div hidden={selectedTab !== 1} style={{ minHeight: 'calc(100vh - 320px)' }}>
                                                        {isLoading === false ? <div className={classes.paper}>
                                                            <div className={classes.activeTableContainer}>
                                                                {tableFeedData && tableFeedData.length > 0 ?
                                                                    <div>
                                                                        <div className={classes.tableTitle}>Raw Data Table</div>
                                                                        <RawDataTable data={tableFeedData} ></RawDataTable> </div> :
                                                                    <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No data to Display  </div>
                                                                }
                                                            </div></div> :
                                                            <div>
                                                                <div style={{ justifyContent: "center", textAlign: 'center', paddingTop: "150px" }}><CircularProgress /></div>
                                                            </div>}
                                                    </div>
                                                    <div hidden={selectedTab !== 2} style={{ minHeight: 'calc(100vh - 320px)' }}>
                                                        {isLoading === false ? <div className={classes.paper}>
                                                            <div className={classes.activeTableContainer}>
                                                                {deviceDataTable && deviceDataTable.length > 0 ?
                                                                    <div>
                                                                        <div className={classes.tableTitle}>Device Data Table</div>
                                                                        <DeviceDataTable data={deviceDataTable} ></DeviceDataTable> </div> :
                                                                    <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", paddingTop: '150px' }} > No data to Display  </div>
                                                                }
                                                            </div></div> :
                                                            <div>
                                                                <div style={{ justifyContent: "center", textAlign: 'center', paddingTop: "150px" }}><CircularProgress /></div>
                                                            </div>}
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}

export default DevicesNew
