import React, { memo, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import Button from '@material-ui/core/Button';
// import Input from '@material-ui/core/Input'
// import { DateRangePicker } from 'material-ui-datetime-range-picker';
// import MuiThemeProvider from '@material-ui/core/styles/ThemeProvider'
// import { StaticDateRangePicker, DateRangeDelimiter, DateRange } from "@material-ui/pickers";
import { StaticDateRangePicker, DateRange } from "@material-ui/pickers";
import { StaticDatePicker } from "@material-ui/pickers";

// import TextField from "@material-ui/core/TextField";

import { LocalizationProvider } from '@material-ui/pickers';
// import MomentUtils from '@material-ui/pickers/adapter/moment';
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

// import { DatePicker } from "@material-ui/pickers";

import useStyles from "./styles";

import moment from 'moment'


const options = [
    {
        label: 'Today',
        action: () => {
            return [moment().toDate(), moment().toDate()];
        }
    },
    {
        label: 'Yesterday',
        action: () => {
            return [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()];
        }
    },
    {
        label: 'Current Cut',
        action: () => {
            let endDate = moment(); //.subtract(1,'days');
            let startDate = endDate.date() > 15 ? 16 : 1
            return [moment().set("date", startDate).toDate(), endDate.toDate()];
        }
    },
    {
        label: 'Last Cut',
        action: () => {
            let startDate = moment();
            let endDate = moment();
            if (endDate.date() > 15) {
                startDate.set("date", 1)
                endDate.set("date", 15)
            } else {
                startDate = startDate.subtract(1, 'month').set("date", 16);
                endDate = endDate.subtract(1, 'month').endOf('month')
            }
            return [startDate.toDate(), endDate.toDate()];
        }
    },
    // {
    //     label: 'Last week',
    //     action: () => {
    //         return [moment().subtract(6, 'days').toDate(), moment().toDate()];
    //     }
    // },
    // {
    //     label: 'Last month',
    //     action: () => {
    //         return [moment().subtract(1, 'month').toDate(), moment().toDate()];
    //     }
    // },
    {
        label: 'Custom range'
    }
];

const dafaultOption = 2;

function DateRangeFilter(props) {



    const [selectedDate, handleDateChange] = React.useState(options[dafaultOption].action());
    const [selectedLabel, setSelectedLabel] = React.useState(options[dafaultOption].label);

    const classes = useStyles();
    const [checked, setChecked] = React.useState(dafaultOption);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [maxDate, setmaxDate] = React.useState(moment().toDate());

    const [isValidRange, setisValidRange] = React.useState(false);

    const handleClose = () => {
        setAnchorEl(null);
        // setfilteredList(fL);
        let selectedlabel = options[checked].label;

        if (selectedlabel.indexOf("Custom") === -1) {

            // if (selectedlabel.indexOf("Custom") !== -1) {
            //     selectedlabel = moment(selectedDate[0]).format("MMM D")
            //     // if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
            //     selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
            //     // }
            // } else {
                selectedlabel += ' (' + moment(selectedDate[0]).format("MMM D")
                if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                    selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                }
                selectedlabel += ')'
            // }

            setSelectedLabel(selectedlabel);
            props.onDateChange(selectedDate);
        }
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        if (checked !== index) {
            setChecked(index);

            if (options[index].label.indexOf("Custom") === -1) {
                let daterange = options[index].action();
                handleDateChange(daterange);
            }
        }
    };

    const handleDateRangeSelected = (range) => {
        // console.log('range', range)
        // if (moment(range[0])) {
        //     let date = moment(range[0]);
        //     date.set(15, 'date');
        //     setmaxDate(date.toDate())
        // }
        if (range[0] && range[1]) {
            let startDate = moment(range[0]);
            let endDate = moment(range[1]);

            let noOfDays = endDate.diff(startDate, 'days') + 1;
            // console.log('noOfDays', noOfDays)
            if (noOfDays == 1) {
                setisValidRange(true);
            } else {
                if (startDate.date() == 1 && endDate.date() <= 15 && startDate.isSame(endDate, 'month')) {
                    setisValidRange(true);
                } else if (startDate.date() == 16 && startDate.isSame(endDate, 'month')) {
                    setisValidRange(true);
                } else {
                    setisValidRange(false);
                }
            }
        } else {
            setisValidRange(false);
        }


        handleDateChange(range);
    }

    useEffect(() => {
        if (options[checked].label.indexOf("Custom") === -1) {
            let daterange = options[checked].action();
            handleDateChange(daterange);
        }

    }, [checked]);

    useEffect(() => {
        // console.log('DateFilter',selectedDate);
        if (selectedDate[0] != null && !Boolean(anchorEl)) {
            let selectedlabel = options[checked].label;
            if (selectedlabel.indexOf("Custom") !== -1) {
                selectedlabel = moment(selectedDate[0]).format("MMM D")
                // if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                // }
            } else {
                selectedlabel += ' (' + moment(selectedDate[0]).format("MMM D")
                if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                    selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                }
                selectedlabel += ')'
            }
            setSelectedLabel(selectedlabel);
            props.onDateChange(selectedDate);

            if (selectedDate[0] && selectedDate[1]) {
                let startDate = moment(selectedDate[0]);
                let endDate = moment(selectedDate[1]);

                let noOfDays = endDate.diff(startDate, 'days') + 1;

                if (noOfDays == 1) {
                    setisValidRange(true);
                } else {
                    if (startDate.date() == 1 && endDate.date() <= 15 && startDate.isSame(endDate, 'month')) {
                        setisValidRange(true);
                    } else if (startDate.date() == 16 && startDate.isSame(endDate, 'month')) {
                        setisValidRange(true);
                    } else {
                        setisValidRange(false);
                    }
                }
            } else {
                setisValidRange(false);
            }
        }
        // eslint-disable-next-line
    }, [selectedDate]);
    const id = open ? 'simple-popover' : undefined;

    // console.log('Datefilter render', props)
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div className={props.className}>
                <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                    <div className={classes.label}>
                        <div className={classes.labelText}>{props.label}</div>
                        <div className={classes.value} title={selectedLabel}>{selectedLabel}</div>
                    </div>
                    {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ display: "flex" }}>

                        <div>
                            <List className={classes.list}>
                                {options.map((item, index) => {
                                    const labelId = `checkbox-list-label-${index}`;

                                    let lbl = '';
                                    if (item.label.indexOf("Custom") === -1) {
                                        let dtR = item.action();
                                        if (item.label.indexOf("Today") !== -1 || item.label.indexOf("Yesterday") !== -1) {
                                            lbl = '(' + moment(dtR[0]).format("MMM D") + ')'
                                        } else {
                                            lbl = '(' + moment(dtR[0]).format("MMM D") + ' - ' + moment(dtR[1]).format("MMM D") + ')'
                                        }
                                    }

                                    return (
                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(item, index)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={index === checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={item.label} secondary={lbl} className={classes.litxt} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                            {(options[checked].label.indexOf('Custom') != -1) && <div style={{ flexGrow: 1, padding: '5px 5px 10px 10px', color: '#6E6E6E', fontFamily: "Karla" }}>
                                <div>* Valid date ranges are,</div>
                                <ol style={{ margin: 0, paddingLeft: 15 }}>
                                    <li>Select single day or</li>
                                    <li>
                                        <div>Start date = 1st &amp;</div>
                                        <div>End date &lt;= 15th or</div>
                                    </li>
                                    <li>
                                        <div>Start date = 16th &amp;</div>
                                        <div>End date &lt;= End of month</div>
                                    </li>
                                </ol>
                            </div>}
                        </div>
                        <div style={{ borderLeft: "solid 1px #97B2B2", display: (options[checked].label.indexOf('Custom') != -1) ? 'block' : 'none' }}>
                            <StaticDateRangePicker
                                displayStaticWrapperAs="static"
                                value={selectedDate}
                                onChange={date => handleDateRangeSelected(date)}
                                disableFuture={true}
                                showToolbar={true}
                            // maxDate={maxDate}
                            // disableMaskedInput={true}
                            // ToolbarComponent={null}
                            />

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flexGrow: 1, paddingLeft: 10 }}>
                                    {!isValidRange && <span style={{ color: 'red' }}>Invalid date range</span>}
                                </div>
                                <Button variant="contained" color="default" className={classes.applybtn} disabled={!isValidRange} onClick={() => {

                                    let selectedlabel = options[checked].label;
                                    if (selectedlabel.indexOf("Custom") !== -1) {
                                        selectedlabel = moment(selectedDate[0]).format("MMM D")
                                        // if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                                        selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                                        // }
                                    } else {
                                        selectedlabel += ' (' + moment(selectedDate[0]).format("MMM D")
                                        if (!moment(selectedDate[0]).isSame(moment(selectedDate[1]), 'day')) {
                                            selectedlabel += ' - ' + moment(selectedDate[1]).format("MMM D")
                                        }
                                        selectedlabel += ')'
                                    }

                                    setSelectedLabel(selectedlabel);
                                    props.onDateChange(selectedDate);

                                    setAnchorEl(null);
                                }}>
                                    APPLY
                            </Button>
                            </div>
                        </div>


                    </div>

                </Popover>
            </div>
        </LocalizationProvider>
    )
}

export default memo(DateRangeFilter)
