import React, { memo, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
// import Input from '@material-ui/core/Input'
// import { DateRangePicker } from 'material-ui-datetime-range-picker';
// import MuiThemeProvider from '@material-ui/core/styles/ThemeProvider'
// import { StaticDateRangePicker, DateRangeDelimiter, DateRange } from "@material-ui/pickers";
// import { StaticDateRangePicker, DateRange } from "@material-ui/pickers";
import { StaticDatePicker } from "@material-ui/pickers";

// import TextField from "@material-ui/core/TextField";

import { LocalizationProvider } from '@material-ui/pickers';
// import MomentUtils from '@material-ui/pickers/adapter/moment';
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

// import { DatePicker } from "@material-ui/pickers";

import useStyles from "./styles";

import moment from 'moment'

const options = [
    {
        label: 'Today',
        action: () => {
            return [moment().toDate(), moment().toDate()];
        }
    },
    {
        label: 'Yesterday',
        action: () => {
            return [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()];
        }
    },
    // {
    //     label: 'Last week',
    //     action: () => {
    //         return [moment().subtract(6, 'days').toDate(), moment().toDate()];
    //     }
    // },
    // {
    //     label: 'Last month',
    //     action: () => {
    //         return [moment().subtract(1, 'month').toDate(), moment().toDate()];
    //     }
    // },
    {
        label: 'Custom range'
    }
];

function DateFilter(props) {

    let dafaultOption = 0;
    // const [selectedFromDate, handleFromDateChange] = useState(new Date());
    // const [selectedToDate, handleToDateChange] = useState(new Date());

    // const [selectedDate, handleDateChange] = React.useState([moment("2020-03-20"), moment("2020-03-30")]);
    // const [selectedLabel, setSelectedLabel] = React.useState(options[4].label);

    const [selectedDate, handleDateChange] = React.useState(options[dafaultOption].action());
    const [selectedLabel, setSelectedLabel] = React.useState(options[dafaultOption].label);

    const classes = useStyles();
    const [checked, setChecked] = React.useState(dafaultOption);
    // const [filteredList, setfilteredList] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // setfilteredList(fL);
        let selectedlabel = options[checked].label;
        if (selectedlabel.indexOf("Custom") !== -1) {
            selectedlabel = moment(selectedDate[0]).format("MMM D"); // + ' - ' + moment(selectedDate[1]).format("MMM D")
        }

        setSelectedLabel(selectedlabel);
        props.onDateChange(selectedDate, options[checked].label);
    };
    const open = Boolean(anchorEl);

    const handleToggle = (value, index) => () => {
        if (checked !== index) {
            setChecked(index);

            if (options[index].label.indexOf("Custom") === -1) {
                let daterange = options[index].action();
                handleDateChange(daterange);
            }
        }
    };

    const handleDateRangeSelected = (range) => {

        var today = moment();
        var yesterday = moment().subtract(1, 'day');
        if(moment(range[0]).isSame(today, 'day')){
            setChecked(0);
        } else if(moment(range[0]).isSame(yesterday, 'day')){
            setChecked(1);
        } else{
            setChecked(options.length - 1);
        }
        handleDateChange(range);
    }

    useEffect(() => {
        if (options[checked].label.indexOf("Custom") === -1) {
            let daterange = options[checked].action();
            handleDateChange(daterange);
        }

    }, [checked]);

    // useEffect(() => {
    //     selectedlabel = options[checked].label;
    // }, []);

    // useEffect(() => {
    //     setfilteredList(fL);
    // }, [])

    // let fL = props.options.map((item, index) => {
    //     return {
    //         item,
    //         index
    //     }
    // })

    useEffect(() => {
        // console.log('DateFilter',selectedDate);
        if (selectedDate[0] != null && !Boolean(anchorEl)) {
            props.onDateChange(selectedDate, options[checked].label);
        }
    // eslint-disable-next-line
    }, [selectedDate]);
    const id = open ? 'simple-popover' : undefined;

    // console.log('Datefilter render', props)
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div className={props.className}>
                <div aria-describedby={id} onClick={handleClick} className={classes.root}>
                    <div className={classes.label}>
                        <div className={classes.labelText}>{props.label}</div>
                        <div className={classes.value}>{selectedLabel}</div>
                    </div>
                    {(!open) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{display: 'none'}}>
                            <List className={classes.list}>
                                {options.map((item, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return (
                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(item, index)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={index === checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${item.label}`} className={classes.litxt} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        {/* <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
                <DateRangePicker
                    autoOk={true}
                    autoOpenField={true}
                    // onChange={onChange}
                    // onDismiss={onDismiss}
                    showCalendarStatus={true}
                    className="my-date-picker"
                    firstDayOfWeek={0}
                    dayButtonSize="6.25vw"
                    calendarDateWidth="80vw"
                    calendarTimeWidth="40vw"
                    local='en-US'
                    mode='portrait'
                    startLabel='Beginning'
                    endLabel='Ending' />
                    </MuiThemeProvider> */}

                        {/* <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDate}
                    onChange={date => handleDateChange(date)}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField {...startProps} />
                            <DateRangeDelimiter> to </DateRangeDelimiter>
                            <TextField {...endProps} />
                        </>
                    )}
                /> */}
                        <div style={{ borderLeft: "solid 1px #97B2B2" }}>
                            {
                                // (options[checked].label.indexOf("Custom") >= -1) ? (
                                // <div style={{ display: "flex" }}>
                                // <div>
                                //     <div>From Date</div>
                                //     <DatePicker
                                //         label="From"
                                //         value={selectedFromDate}
                                //         onChange={handleFromDateChange}
                                //         animateYearScrolling
                                //         variant="static"
                                //     />
                                // </div>
                                // <div>
                                //     <div>To Date</div>
                                //     <DatePicker
                                //         label="To"
                                //         value={selectedToDate}
                                //         onChange={handleToDateChange}
                                //         animateYearScrolling
                                //         variant="static"
                                //     />
                                // </div>
                                // </div>

                                // <StaticDateRangePicker
                                //     displayStaticWrapperAs="static"
                                //     value={selectedDate}
                                //     onChange={date => handleDateRangeSelected(date)}
                                //     disableFuture={true}
                                // />

                                <StaticDatePicker
                                    autoOk
                                    // orientation="landscape"
                                    openTo="date"
                                    value={selectedDate[0]}
                                    // shouldDisableDate={disableWeekends}
                                    // displayStaticWrapperAs="static"
                                    // showTodayButton
                                    disableFuture={true}
                                    todayLabel="TODAY"
                                    onChange={date => handleDateRangeSelected([date,date])}
                                />

                                // ) : (null)
                            }

                        </div>

                    </div>

                </Popover>
            </div>
        </LocalizationProvider>
    )
}

export default memo(DateFilter)
