import React, { useState, useEffect } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table"; //{ MTableToolbar }
// import { useHistory, useLocation } from "react-router-dom";
// import moment from 'moment'

// const StyledMTableToolbar = withStyles({
//     root: {
//         minHeight: '1px',
//         height: '5px',
//         paddingRight: "2%",
//         display: 'contents'
//     },
//     actions: {
//         // marginTop: "-15%",
//         minHeight: '5px',
//         //height: '35px',
//     },
// })(MTableToolbar);

export default function RawDataTable(props) {
    const [rawdata, setrawdata] = useState([])
    // const location = useLocation();
    // const history = useHistory();

    useEffect(() => {
        let dataArr = props.data.map((item, index) => {
            return {
                time: item.time,
                value: ((item.value !== null) && (item.value !== "")) ? item.value + ' cm' : '-',
                accuracy: item.accuracy,
                RTK: item.RTKVal,
                deviceTilt :  item.deviceTilt,
                error : item.error,
                distance : item.distance,
                SOG : item.SOG,        
            };
        })
        setrawdata(dataArr);

    }, [props.data])
    return (
        <div style={{ maxWidth: "100%" }} className="btmtable">
            <MaterialTable
                title='' //{'Device - ' + deviceAliasName}
                columns={[
                    { title: "DATE/TIME", field: "time" },
                    { title: "GRASS HEIGHT", field: "value" },
                    { title: "HACC", field: "accuracy", grouping: false, headerStyle: { textAlign: "left" }},
                    { title: "RTK", field: "RTK", grouping: false, headerStyle: { textAlign: "left" }},
                    { title: "Device Tilt", field: "deviceTilt", grouping: false, headerStyle: { textAlign: "left" }},
                    { title: "Error", field: "error", grouping: false, headerStyle: { textAlign: "left" }},
                    { title: "Distance", field: "distance", grouping: false, headerStyle: { textAlign: "left" }},
                    { title: "SOG", field: "SOG", grouping: false, headerStyle: { textAlign: "left" }}

                ]}
                data={rawdata}
                // components={{
                //     Toolbar: props => (
                //         <div style={{ minHeight: '0px', height: '0px' }}>
                //             <StyledMTableToolbar {...props} />
                //         </div>
                //     )
                // }}
                options={{
                    //toolbar : false,
                    search: false,
                    exportAllData: true,
                    exportButton: true,
                    headerStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        color: "#698D8D",
                        backgroundColor: "#F1FCFC",
                        padding: "4px 12px",
                    },
                    cellStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        fontWeight: "bold",
                        padding: "4px 12px",
                    },
                    rowStyle: {
                        height: "auto",
                    },
                    pageSize: 100,
                    maxBodyHeight: 'calc(100vh - 431px)',

                }}
            />
        </div>
    );
}
