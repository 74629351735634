import React, { useState, useEffect } from 'react'
import { Button } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from 'moment'

import { useDevicesState, getOTALogs } from "../../context/DevicesContext";

let statusColorMap = {
    'FAILED': '#FF3366',
    'CANCELLED': '#FF3366',
    'TIMED_OUT': '#FF3366',
    'REJECTED': '#FF3366',
    'REMOVED': '#FF3366',
    'CANCELED': '#FF3366',
    'IN_PROGRESS': '#f0ad4e',
    'COMPLETED': 'rgba(9, 94, 42, 0.822)',
    'CREATED': '#00CCCC',
}

function OTASettings(props) {


    const context = useDevicesState();
    const [OTALogs, setOTALogs] = useState([])
    const [display, setdisplay] = useState('otasettings')
    const [appconfigNBIOT, setappconfigNBIOT] = useState([])

    useEffect(() => {
        if(props.device){
            getOTALogs(context.setData, props.device.device_id);
        }
    }, [context.setData,props.device]);

    useEffect(() => {
        // console.log('context settings bkp', context.data)
        if (context.data.OTALogs) {
            setOTALogs(context.data.OTALogs);
        }
    }, [context.data.OTALogs, context.data.OTALogsError]);

    useEffect(() => {
        // console.log('context appconfigNBIOT', context.data)
        if (context.data.appconfigNBIOT) {
            setappconfigNBIOT(context.data.appconfigNBIOT);
        } else {
            setappconfigNBIOT([]);
        }
    }, [context.data.appconfigNBIOT]);

    return (
        <div style={{ padding: 10 }}>
            {display === 'otasettings' && <div>
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" size="small" onClick={() => { setdisplay('otalogs') }}>OTA Logs</Button>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold' }}>Last Job Details</div>
                    {OTALogs && OTALogs.results && OTALogs.results.length > 0 && <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexGrow: 1, flexBasis: 0 }}>Job Status - <span style={{ fontWeight: 'bold', color: statusColorMap[OTALogs.results[0].status] }}>  {OTALogs.results[0].status} </span></div>
                        <div style={{ flexGrow: 1, flexBasis: 0 }}>Job Id -   <span style={{ fontWeight: 'bold' }} > {OTALogs.results[0].jobId} </span> </div>
                        <div style={{ flexGrow: 1, flexBasis: 0 }}>Last Updated At - {moment(OTALogs.results[0].updated_at).format('DD-MMM-YYYY hh:mm a')}</div>
                        <div><Button variant="contained" color="primary" size="small">Refresh</Button></div>
                    </div>}
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontWeight: 'bold', flexGrow: 1 }}>Device Settings</div>
                            <div>
                                <Button variant="contained" color="primary" size="small">Reboot</Button>
                                <Button variant="contained" color="primary" size="small">Test Connectivity</Button>
                            </div>
                        </div>
                        {appconfigNBIOT.map((data, i) => {
                            if (data.type === 'page' && ((data.title === 'Digital Output Settings') || (data.title === 'RS-485 Write'))) {
                                return (<div key={i}>
                                <div style={{ fontWeight: 'bold' }}>{data.title}</div>
                                </div>)
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>}
            {display === 'otalogs' && <div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" color="primary" size="small">Refresh Logs</Button>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button variant="contained" color="primary" size="small" onClick={() => { setdisplay('otasettings') }}>Back to Settings</Button>
                </div>
                <MaterialTable
                    title="OTA Logs"
                    columns={[
                        {
                            title: "Time", field: "device_name",
                            render: rowData => {
                                return moment(rowData.created_at).format('DD-MMM-YYYY hh:mm a')
                            }
                        },
                        {
                            title: "Job Status", field: "alias_name",
                            render: rowData => {
                                return (<span> Job Updated with Status -  <span style={{ fontWeight: 'bold', color: statusColorMap[rowData.status] }}> {rowData.status} </span> at {moment(rowData.updated_at).format('DD-MMM-YYYY hh:mm a')}  </span>)
                            }
                        }
                    ]}
                    actions={[
                        {
                            icon: 'cloud_download',
                            tooltip: 'Download Job Details',
                            onClick: (event, rowData) => {
                                // setselectedDevice(rowData);
                                // setdisplay('editdevice');
                            }
                        }
                    ]}
                    data={OTALogs.results}
                    options={{
                        // filtering: true,
                        // grouping: true,
                        headerStyle: {
                            fontSize: "12px",
                            fontFamily: "Karla",
                            color: "#698D8D",
                            backgroundColor: "#F1FCFC",
                            padding: "4px 12px",
                        },
                        cellStyle: {
                            fontSize: "12px",
                            fontFamily: "Karla",
                            fontWeight: "bold",
                            padding: "4px 12px",
                        },
                        rowStyle: {
                            height: "auto",
                        },
                        searchFieldStyle: {
                            // color: 'red'
                        },
                        pageSize: 10,
                        actionsColumnIndex: -1
                    }}
                />
            </div>}
        </div>
    )
}

export default OTASettings
