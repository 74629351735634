import React, { useState, useEffect, useRef } from "react";
// import { Grid, Card, CardContent, CardActions } from "@material-ui/core";
// import PageTitle from "../../components/PageTitle";
// import ClusterMap from "../home/components/ClusterMap";
import Filter from "../../components/Filter";
// import DateFilter from "../../components/DateFilter";
import DateRangeFilter from "../../components/DateRangeFilter";
import Tile from "../../components/Tile";
import TimeTravel from "../../components/TimeTravel";
import Button from "@material-ui/core/Button";
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { squareGrid, flatten, bbox, booleanContains, booleanOverlap, polygon, difference, intersect, pointsWithinPolygon, point, featureCollection, collect, bboxPolygon, buffer, dissolve, combine, area } from '@turf/turf';
import useStyles from "./styles";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
// import MapIcon from "@material-ui/icons/Map";

import MaterialTable from "material-table";

import BaseMap from "./BaseMap";
import DetailMap from "./DetailMap";
import GoogleBaseMap from "./GoogleBaseMap"
import GoogleDetailMap from "./GoogleDetailMap";

import CategoryFilter from "../../components/CategoryFilter"
import moment from 'moment'
// import RangeInput from './range-input';
// import { Provider as StyletronProvider } from 'styletron-react';
// import { LightTheme, BaseProvider } from 'baseui';
// import { Client as Styletron } from 'styletron-engine-atomic';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import LinearProgress from '@material-ui/core/LinearProgress';


import { config } from '../../config';

//context
import { useBaseMapState, getLocations, getTodayLocations, getPreviousDayLocations, getPreviosLocationGeoJsons, getPreviousDayLocationData, getDevices, getLocationList, getAllLocationList, getLocationCsvData, getPrevLocationCsvData, getDetailMapData, getDetailMapCoverageData, getTodayLocationProps } from "../../context/BaseMapContext";
// const MS_PER_DAY = 1.64e7;
const zonesArr = [
  { id: "NE", label: "NORTH-EAST" },
  { id: "SE", label: "SOUTH-EAST" },
  { id: "CN", label: "CENTRAL-NORTH" },
  { id: "NW", label: "NORTH-WEST" },
  { id: "SW", label: "SOUTH-WEST" },
  { id: "CS", label: "CENTRAL-SOUTH" },
];
let staticLocations = {
  'CN': ['URA133', 'MWSH101', 'MWSH105', 'MWSS207', 'WS907', 'MWSS209', 'MWSS208', 'MWSS018', 'MWSS205', 'MWSH091', 'MWSH092', 'MWSP095', 'MWSP103', 'MWSP101', 'MWSS142', 'MWSP098', 'MWSS170', 'MWSP120', 'MWSH103', 'MWSS140', 'WS813', 'MWSP117', 'MWSP115', 'MWSH100', 'MWSH099', 'MWSM021', 'MWSS017', 'MWSH044', 'MWBS294', 'MWBM013', 'MWBM015', 'MWSH009', 'MWSS005', 'MWSS089', 'MWSH163', 'MWSH014', 'MWSP031', 'MWSS073', 'WT872', 'MWSH005', 'MWSH002', 'WT869', 'MWSS059', 'WS859', 'MWSS099', 'MWSS004', 'MWSH064', 'MWSH067', 'MWSS100', 'MWSH052', 'MWSH047', 'MWSH038', 'MWSH039', 'MWSP057', 'MWSH028', 'MWSP050', 'MWBM014', 'MWSS068', 'MWBM009', 'MWSP018', 'MWSP179', 'MWSS162', 'MWSM002', 'MWSS166', 'MWSS040', 'MWSP079', 'MWSS361', 'MWSS160', 'MWSH035', 'MWSH042', 'MWSH054', 'MWSH055', 'URA121', 'MWSS202', 'MWSP087', 'MWSS204', 'MWSS014', 'MWSS366', 'MWSS163', 'WS876', 'MWSH041', 'MWSH066', 'WS815', 'MWSS008', 'MWSP076', 'WS816', 'MWSH058', 'MWSP075', 'MWSH061', 'MWSH063', 'MWSH068', 'MWSH070', 'MWSH072', 'MWSH073', 'MWBS056', 'MWBP141', 'MWBS058', 'MWBS230', 'MWSP008', 'MWSP022', 'MWSP011', 'MWBS054', 'MWBP144', 'MWBP130', 'MWBP136', 'MWBS222', 'URA104', 'MWBS445', 'WB737', 'MWSP002', 'MWBS211', 'MWSP003', 'MWSH046', 'MWSH036', 'MWSH048', 'MWSH051', 'MWSP176', 'MWBS438', 'MWBP105', 'MWBS052', 'MWBS228', 'MWBM004', 'MWBH024', 'MWBP131', 'MWBH033', 'MWBS199', 'MWBH006', 'MWBP099', 'MWBS212', 'MWBH034', 'MWBP134', 'MWBS216', 'MWSM022', 'MWSP053', 'MWSP068', 'MWSP048', 'MWSP175', 'MWSS268', 'MWSS010', 'URA102', 'MWSS041', 'WS714', 'MWSP073', 'MWSS428'],
  'SE': ['MEAS048', 'MEAS322', 'MEAH093', 'MEAS320', 'MEAS316', 'MEAS312', 'MEAH090', 'MEAH083', 'MEAH081', 'MEAS044', 'MEAS330', 'MEAM017', 'MEAH082', 'MEAM028', 'MEAM036', 'MEAS331', 'MEAH087', 'MEAS046', 'MEAS332', 'MEAS323', 'MEAM030', 'MEAH078', 'MEAH076', 'MEAS311', 'MEAS314', 'MEAS442', 'MEAS309', 'MEAS313', 'MEAS037', 'MEAH088', 'MEAP108', 'MEAH091', 'MEAH080', 'MEAH079', 'MEAS274', 'MEAH071', 'MEAH066', 'MENH037']
}
// const locations = [
//   { label: "2235 Avondale Ave", id: "location1" },
//   { label: "2727 Lakeshore", id: "location2" },
//   { label: "3995 Valley view", id: "location3" },
//   { label: "4228 Homestead Rd", id: "location4" },
//   { label: "5781 Thornridge", id: "location5" },
//   { label: "3605 Spring St", id: "location6" },
// ];

// const contractors = [{ label: "Lee Cooper" }, { label: "Edwards" }];

// const qualities = [{ label: "Good" }, { label: "Medium" }, { label: "Bad" }];

// const eqipments = [
//   { label: "KnapSack", id: 1 },
//   { label: "Lawn Mover", id: 2 },
//   { label: "Tractor", id: 3 }
// ]
let maplayers = [
  {
    label: 'Quality of cut',
    children: [
      // { label: 'Zero', isValid: (val) => (val === 0 || val === null), color: 'rgb(125, 125, 125)', colorArr: [125, 125, 125] },
      { label: 'Good', isValid: (val) => (val === 0 || val === null) || (val > 0 && val <= 10), color: 'rgb(1, 128, 1)', colorArr: [1, 128, 1]},
      // { label: 'Medium', isValid: (val) => val > 5 && val <= 7.5, color: 'rgb(255, 186, 90)', colorArr: [255, 186, 90, 255] },
      { label: 'Bad', isValid: (val) => val > 10, color: 'rgb(128, 10, 10)', colorArr: [128, 10, 10] }
    ],
    propName: 'value'
  },
  {
    label: 'Device layer',
    children: [
      { label: 'KnapSack', isValid: (val) => val === 'KnapSack', color: 'rgb(147, 153, 255)', colorArr: [147, 153, 255, 255] },
      { label: 'Lawn Mover', isValid: (val) => val === 'Lawn Mover', color: 'rgb(94, 223, 255)', colorArr: [94, 223, 255, 255] },
      { label: 'Tractor', isValid: (val) => val === 'Tractor', color: '#0066CC', colorArr: [192, 255, 179, 255] }
    ],
    propName: 'device_id'
  }
]

let contractorsArr = [
  {
    date: "01-04-2020",
    contract: "N/98/2017 Toh Chin Leong Construction Pte Ltd",
    zone: "NE",
    location: "HDB - ADMIRALTY ST / SEMBAWANG DR",
    areaha: 7.75,
    areaper: 76,
    operationalhrs: 2.35,
    quality: "Good",
  },
  {
    date: "02-04-2020",
    contract: "N/99/2017 Toh Chin Leong Construction Pte Ltd",
    zone: "SE",
    location: "HDB - ADMIRALTY ST / SEMBAWANG DR",
    areaha: 8.75,
    areaper: 86,
    operationalhrs: 3.35,
    quality: "Good",
  },
  {
    date: "03-04-2020",
    contract: "N/100/2017 Toh Chin Leong Construction Pte Ltd",
    zone: "CN",
    location: "URA - SEMBAWANG DRIVE HVP",
    areaha: 9.75,
    areaper: 96,
    operationalhrs: 4.35,
    quality: "Good",
  },
  {
    date: "04-04-2020",
    contract: "N/101/2017 Kim Bock Contractor Pte Ltd",
    zone: "NW",
    location: "URA - SEMBAWANG DRIVE HVP",
    areaha: 6.75,
    areaper: 46,
    operationalhrs: 5.35,
    quality: "Bad",
  },
  {
    date: "05-04-2020",
    contract: "N/102/2017 Ho Eng Huat Construction Pte Ltd",
    zone: "SW",
    location: "URA - SEMBAWANG DRIVE HVP",
    areaha: 5.75,
    areaper: 56,
    operationalhrs: 6.35,
    quality: "Good",
  },
  {
    date: "06-04-2020",
    contract: "N/103/2017 Felicity Engineering Pte Ltd",
    zone: "CS",
    location: "URA - SEMBAWANG DRIVE HVP",
    areaha: 4.75,
    areaper: 66,
    operationalhrs: 7.35,
    quality: "Good",
  }
]
// let contractorsArr = ["Tai Sun", "Edwards"]
let qualities = ["Good", "Bad"] //{hoveredObject.properties.loc_cd}

let colrs = [
  ['rgb(147, 153, 255)', [147, 153, 255, 255]],
  ['rgb(94, 223, 255)', [94, 223, 255, 255]],
  ['#0066CC', [0, 102, 204, 255]],
  ['#C0FFB3', [192, 255, 179, 255]],
  ['#EB4559', [235, 69, 89, 255]]
]
let metricConfig = [
  { label: 'No of Contractors', value: '1' },
  { label: 'Total Coverage (in %)', value: '-' },
  { label: 'Quality of Cut (in %)', value: '-' },
  { label: 'Total Hectorage (in Ha)', value: '420.19' }
]

const CoverageLabel = withStyles({
  label: { fontSize: '14px', fontFamily: 'Karla', fontWeight: 'bold' }
})(FormControlLabel);

let worker;
let workers = [];
let finalWorker;

function Dashboard(props) {

  const classes = useStyles(props);

  const context = useBaseMapState();

  const { enqueueSnackbar } = useSnackbar();

  // const [devices, setdevices] = useState(null)
  const [zones, setZones] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [contextLocations, setcontextLocations] = useState([])
  const [locations, setLocations] = useState([]);
  // const [displayLocations, setdisplayLocations] = useState([]);
  // const [todayLocations, settodayLocations] = useState([]);
  const [geoJsons, setgeoJsons] = useState({
    data: [],
    status: ''
  });
  // const [equipments, setEquipments] = useState(eqipments);

  const [showFullPanel, setshowFullPanel] = useState(false);
  const [isDetailView, setisDetailView] = useState(false);
  const [selectedLocation, setselectedLocation] = useState(false);

  const [detailData, setdetailData] = useState([]);

  const [detailMapCoverageData, setdetailMapCoverageData] = useState([]);
  const [detailMapTodayCoverageData, setdetailMapTodayCoverageData] = useState(null);
  const [detailTableData, setdetailTableData] = useState([]);



  // const [selectedDate, setselectedDate] = useState(null);
  // const [selectedDateType, setselectedDateType] = useState('');
  const [selectedDateDetails, setselectedDateDetails] = useState(null)
  const [selectedFilters, setselectedFilters] = useState(null);
  // const [locationSummary, setlocationSummary] = useState(null)

  // eslint-disable-next-line
  const [metrics, setmetrics] = useState(metricConfig)

  let mapfilterSelected = maplayers.map((item, index) => {
    // let childarr = [];
    let childarr = item.children.map((child, childIndex) => {
      return { ...child, checked: true };
    })
    return { ...item, children: childarr, checked: (index === 0) }
  })

  const tableRef = useRef(null);

  const [mapLayers, setMapLayers] = useState(mapfilterSelected);
  const [hideControls, setHideControls] = useState(false)
  // const [locationLimit, setLocationLimit] = useState(100)
  const [mapStyleType, setmapStyleType] = useState('map')
  const [showCoverage, setshowCoverage] = useState(true)
  const [mapBounds, setmapBounds] = useState(null)
  const baseMapRef = useRef(null);
  const [timeRange, settimeRange] = useState(null);
  const [filterValue, setfilterValue] = useState(null)
  const [todayLocationProps, settodayLocationProps] = useState({})
  const [todayLocationPropsInProgress, settodayLocationPropsInProgress] = useState('false')

  const showMore = () => {
    if (!showFullPanel) {
      setHideControls(false);
    }
    setshowFullPanel(!showFullPanel);
  };
  const backToOverview = () => {
    let nGeojson = { ...geoJsons, status: 'refresh' }
    setgeoJsons(nGeojson)
    setisDetailView(false);
    setselectedLocation(null);
    setshowFullPanel(false);
    setHideControls(false)
    setdetailMapTodayCoverageData(null);
    settodayLocationPropsInProgress(false);


    if (finalWorker) {
      finalWorker.terminate();
    }
    if (workers) {
      // console.log('workers', workers)
      for (var w in workers) {
        // console.log(w)
        if (workers[w]) workers[w].terminate()
      }
    }
  };
  const onTooltipHover = (tdata) => {
    // console.log('tdata', tdata);
    if (tdata && tdata.loc_cd) {
      if (!todayLocationProps[tdata.loc_cd]) {
        let tLocationProps = { ...tdata }
        tLocationProps[tdata.loc_cd] = {
          isLoading: true
        }
        settodayLocationProps(tLocationProps)
        getTodayLocationProps(context.setData, [tLocationProps])
      }
    }

  }
  const areaSelected = (data) => {
    // console.log('areaSelected', data)
    let overviewBounds = (baseMapRef && baseMapRef.current) ? baseMapRef.current.getMapBounds() : null;
    setmapBounds(overviewBounds)
    setisDetailView(true);
    setselectedLocation(data);
    setshowFullPanel(false);
    setHideControls(false)
    // setdetailMapTodayCoverageData(null);
    setshowCoverage(false)


    let noOfDays = moment(selectedDateDetails.selectedDate[1]).diff(selectedDateDetails.selectedDate[0], 'days') + 1;

    // let hasToday = false;
    // for (let d = 0; d < noOfDays; d++) {
    //   let targetDate = moment(selectedDateDetails.selectedDate[0]).add(d, 'days');
    //   let isToday = targetDate.isSame(moment(), 'day');
    //   if(!hasToday){
    //     hasToday = isToday;
    //   }
    // }

    setdetailData([]);
    settimeRange(null);
    setfilterValue(null);
    maplayers[1].children = [];

    getDetailMapData(context.setData, data, selectedDateDetails.selectedDate)
    let fdate = moment(selectedDateDetails.selectedDate[0]).format('YYYYMMDD')
    getDetailMapCoverageData(context.setData, data, selectedDateDetails.selectedDate)

    // if (selectedDateDetails.selectedDateType === 'Today') {
    //   getCSVData(data.properties.loc_cd)
    // } else {
    //   if (context.data.previousDayLocations) {
    //     let locInfo = context.data.previousDayLocations.filter(l => l.location === data.id);
    //     if (locInfo && locInfo.length > 0) {
    //       getPreviousDayLocationData(context.setData, locInfo[0].id)
    //     }
    //   }
    // }
  };

  const onDateChange = (dateRange) => {
    // setselectedDate(date);
    // setselectedDateType(label);
    // console.log('onDateChange', dateRange)

    if (!selectedDateDetails ||
      !moment(selectedDateDetails.selectedDate[0]).isSame(dateRange[0], 'day') ||
      !moment(selectedDateDetails.selectedDate[1]).isSame(dateRange[1], 'day')) {
      // console.log('onDateChange1', selectedDateDetails, dateRange)

      settodayLocationProps({})
      setselectedDateDetails({
        selectedDate: dateRange
      })

      // if (label === 'Today') {
      //   getTodayLocations(context.setData)
      // } else {
      //   // context.setData({ type: 'CLEAR_TODAY_LOCATIONS' });
      //   getPreviousDayLocations(context.setData, moment(date[0]).format('YYYY-MM-DD'))
      //   // getLocations(context.setData, locationLimit, staticLocations, contractorsArr, qualities, locationSummary, '');
      // }

      // getLocationList(context.setData, dateRange, contractorsArr)
      getAllLocationList(context.setData, dateRange, contractorsArr)

    }
  }
  // const engine = new Styletron();
  const onFilterChange = (type, data) => {
    // console.log(type, data);
    let filters = {}
    if (selectedFilters) {
      filters = { ...selectedFilters }
    }
    // if(type=='zones'){
    filters[type] = data;
    setselectedFilters(filters);
    // }

    if (type === 'zones') {
      // console.log('zone', data);
      let selectedZones = data.filter(z => z.checked).map(z => z.id);
      // console.log('selectedZones', selectedZones);
      let cts = contractorsArr.filter(c => selectedZones.indexOf(c.zone) !== -1).map((c, i) => {
        return {
          id: i,
          label: c.contract,
          zone: c.zone
        }
      })
      setContractors(cts)
      // console.log('cts', cts);

      let lFiter = context.data.locations.filter(l => selectedZones.indexOf(l.properties.zone) !== -1)
      let fil = lFiter.map((item, index) => {
        return {
          id: item.properties.loc_cd,
          label: item.properties.loc_desc
        }
      })
      setLocations(fil);

      setgeoJsons({
        data: lFiter,
        status: 'refresh'
      });
    }

    if (type === 'contractors') {
      // console.log('zone', data);
      let selectedContractors = data.filter(z => z.checked).map(z => z.label);
      let lFiter = context.data.locations.filter(l => selectedContractors.indexOf(l.properties.contractor) !== -1)
      let fil = lFiter.map((item, index) => {
        return {
          id: item.properties.loc_cd,
          label: item.properties.loc_desc
        }
      })
      setLocations(fil);
      setgeoJsons({
        data: lFiter,
        status: 'refresh'
      });
    }

    if (type === 'locations') {
      // console.log('zone', data);
      let selectedLocations = data.filter(z => z.checked).map(z => z.id);
      let lFiter = context.data.locations.filter(l => selectedLocations.indexOf(l.properties.loc_cd) !== -1)
      // let fil = lFiter.map((item, index) => {
      //   return {
      //     id: item.properties.loc_cd,
      //     label: item.properties.loc_desc
      //   }
      // })
      // setLocations(fil);
      setgeoJsons({
        data: lFiter,
        status: 'refresh'
      });
    }

    // getBaseMapData(context.setData, selectedDate, filters);
  }

  const runWorker = (dData, deviceIds) => {
    context.setData({ type: 'SET_LOADER' });
    // dispatch({ type: "SET_ERROR", err: "" });
    worker = new window.Worker('./todaylocation-worker.js')
    console.log('worker start', moment().toDate());
    worker.postMessage({
      data: dData,
      // point: point
      // point, buffer, featureCollection, dissolve
    });
    worker.onerror = (err) => console.log(err);
    worker.onmessage = (e) => {
      // console.log(e.data);
      context.setData({ type: 'CLEAR_LOADER' });

      getTodayCoverage(e.data, dData, deviceIds)
      // const { time, fibNum } = e.data;
      // dispatch({
      //   type: "UPDATE_FIBO",
      //   id,
      //   time,
      //   fibNum,
      // });
      console.log('worker end', moment().toDate());
      worker.terminate();
      worker = undefined;
    };
  };

  const runMultiWorker = (dData, deviceIds) => {
    context.setData({ type: 'SET_LOADER' });
    console.log('worker start', moment().toDate());
    settodayLocationPropsInProgress('true')

    let runningWorkers = 0;
    let maxWorkers = 3;
    let chunk = parseInt(dData.length / maxWorkers)
    let dJsons = []
    for (let w = 0; w < maxWorkers; w++) {
      let tempData = [];
      if (w == maxWorkers - 1) {
        let startIndex = w * chunk;
        tempData = dData.slice(startIndex)
      } else {
        let startIndex = w * chunk;
        let endIndex = startIndex + chunk;
        tempData = dData.slice(startIndex, endIndex)
      }
      runningWorkers++;

      workers[w] = new window.Worker('./todaylocation-multiworker.js');
      workers[w].onerror = (err) => console.log(err);
      workers[w].onmessage = (e) => {
        runningWorkers--;
        // console.log(e.data);
        // context.setData({ type: 'CLEAR_LOADER' });

        // getTodayCoverage(e.data, dData, deviceIds)
        // const { time, fibNum } = e.data;
        // dispatch({
        //   type: "UPDATE_FIBO",
        //   id,
        //   time,
        //   fibNum,
        // });
        console.log('worker end', e.data.wIndex, moment().toDate());
        console.log('runningWorkers', runningWorkers)
        workers[e.data.wIndex].terminate()
        dJsons.push(e.data)
        delete workers[e.data.wIndex]
        if (runningWorkers <= 0) {
          // workers.terminate();
          // workers = undefined;
          runFinalWorker(dJsons, dData, deviceIds);
          // context.setData({ type: 'CLEAR_LOADER' });
          console.log('worker end all', e.data.wIndex, moment().toDate());
        }
      };
      workers[w].postMessage({
        data: tempData,
        wIndex: w,
        action: 'datapoints'
      });

    }

  };

  const runFinalWorker = (dJsons, dData, deviceIds) => {
    finalWorker = new window.Worker('./todaylocation-multiworker.js')
    finalWorker.onerror = (err) => console.log(err);
    finalWorker.onmessage = (e) => {
      console.log(e.data);
      context.setData({ type: 'CLEAR_LOADER' });
      settodayLocationPropsInProgress('false')

      getTodayCoverage(e.data, dData, deviceIds)

      console.log('worker end all', moment().toDate());
      finalWorker.terminate();
      finalWorker = undefined;
    };
    finalWorker.postMessage({
      data: dJsons,
      action: 'dissolve'
    });
  }

  const getTodayCoverage = async (inpData, dData, deviceIds) => {
    let disssolvedJson = inpData.data;
    let cutArea = inpData.cutArea;
    // let pointsArr = dData.map(d => {
    //   let pointJson = point(d.geometry, { value: d })
    //   return buffer(pointJson, 0.001, { units: 'kilometers' })
    // })
    // let pointsCollection = featureCollection(pointsArr);
    // let disssolvedJson = dissolve(pointsCollection);
    // let nextdisssolvedJson = null; //dissolve(disssolvedJson)
    // let itr = 0;
    // do {
    //   console.log('itr', itr)
    //   if (itr > 0) {
    //     disssolvedJson = nextdisssolvedJson;
    //   }
    //   nextdisssolvedJson = dissolve(disssolvedJson);
    //   itr++;
    // } while (disssolvedJson && nextdisssolvedJson && disssolvedJson.features && nextdisssolvedJson.features && disssolvedJson.features.length != nextdisssolvedJson.features.length)
    // let cutArea = area(disssolvedJson)
    // let calCutArea = dData.length * 1.8;
    // console.log('area', cutArea, ' calc area ', calCutArea)
    // let oprHrs = moment().startOf('day').seconds(data.length).format('hh:mm:ss')

    // let tData = {
    //   coverageJson: disssolvedJson,
    //   GrassCutArea: cutArea,
    //   Coverage: (cutArea * 100) / (selectedLocation.properties.polygon_area),
    //   TotalOperationalDuration: oprHrs
    // };

    let cov = (cutArea * 100) / (selectedLocation.properties.polygon_area);
    if (cov > 100) cov = 100;
    if (cov < 0) cov = 0;


    // setdetailMapTodayCoverageData(tData)
    // console.log('d.date.isToday', detailMapCoverageData, detailMapCoverageData.find(d => d.date.isToday));
    if (!detailMapCoverageData.find(d => d.date.isToday)) {

      if (cutArea > selectedLocation.properties.polygon_area) cutArea = selectedLocation.properties.polygon_area;

      let locProps = {
        Coverage: +cov.toFixed(2),
        Date: moment().format('YYYYMMDD'),
        Device: deviceIds.join(','),
        DeviceCount: deviceIds.length,
        GrassCutArea: +cutArea.toFixed(3),
        JobCompletion: (cov < 80) ? "0" : "1",
        jobStatus: (cov < 80) ? "InProgress" : "Completed",
        PolygonArea: selectedLocation.properties.polygon_area,
        // TotalOperationalDuration: oprHrs,
        checked: true,
        lastCut: moment().format('YYYYMMDD'),
        loc_cd: selectedLocation.properties.loc_cd,
        loc_desc: selectedLocation.properties.loc_desc,
      }

      let totalSecs = dData.length;
      if (detailMapCoverageData.length > 0) {
        let prevLocProps = detailMapCoverageData[detailMapCoverageData.length - 1].data.features[0].properties;
        locProps.Coverage += parseFloat(prevLocProps.Coverage);
        if (locProps.Coverage > 100) locProps.Coverage = 100;
        if (locProps.Coverage < 0) locProps.Coverage = 0;

        locProps.Coverage = +locProps.Coverage.toFixed(2)
        locProps.GrassCutArea += prevLocProps.GrassCutArea;

        if (locProps.GrassCutArea > selectedLocation.properties.polygon_area) locProps.GrassCutArea = selectedLocation.properties.polygon_area;
        locProps.GrassCutArea = +locProps.GrassCutArea.toFixed(3)

        let opData = prevLocProps.TotalOperationalDuration.split(":");
        let pSec = (parseInt(opData[0]) * 60 * 60) + (parseInt(opData[1]) * 60) + parseInt(opData[2]);
        totalSecs += pSec;
        // locProps.TotalOperationalDuration += prevLocProps.TotalOperationalDuration;
        locProps.JobCompletion = (locProps.Coverage < 80) ? "0" : "1";
        locProps.jobStatus = (locProps.Coverage < 80) ? "InProgress" : "Completed"

        let prevFeatures = detailMapCoverageData[detailMapCoverageData.length - 1].data.features;
        prevFeatures = prevFeatures.map(d => {
          let nd = { ...d }
          // nd.properties = null;
          return nd;
        })
        disssolvedJson.features = disssolvedJson.features.concat(prevFeatures);
      }

      let hrs = parseInt(totalSecs / (60 * 60));
      let remsecs = totalSecs % (60 * 60);
      let mins = parseInt(remsecs / 60);
      let secs = remsecs % 60;
      // let secs = data.length%60;
      let oprHrs = `${('00' + hrs).slice(-2)}:${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`
      locProps.TotalOperationalDuration = oprHrs;
      // console.log('area', cutArea, ' calc area ', cutArea, ' oprHrs ', oprHrs, ' data.length', dData.length)


      disssolvedJson.features.forEach(f => {
        f.properties = {
          ...f.properties,
          ...locProps
        }
      })
      // console.log('getTodayCoverage', disssolvedJson)

      let todayData = {
        date: {
          date: moment(),
          isToday: true,
        },
        data: disssolvedJson,

      }
      let tempData = [...detailMapCoverageData, todayData];

      context.setData({ type: "GET_DETAIL_COVERAGE_DATA", payload: tempData })
    }

  }
  // const getCSVData = async (locId, isToday) => {
  //   // render(<HeatMap />, container);
  //   context.setData({ type: 'SET_LOADER' });
  //   if (isToday) {
  //     require("d3-request")
  //       .csv(config.baseURLApi + `/${localStorage.getItem("organization")}/data/?loc_cd=${locId}`)
  //       // .csv(`/locdata/${locId}.csv`)
  //       .header("Authorization", "JWT " + localStorage.getItem("id_token"))
  //       .get((error, response) => {
  //         // console.log("response.length", response[0], response[1]);
  //         if (!error) {

  //           const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];
  //           // ]
  //           let dataArr = response
  //           // .filter((d) => {
  //           //   return d.GPS ? true : false;
  //           // })
  //           let deviceIds = [];
  //           let deviceNames = []
  //           let mapData = dataArr.filter(d => d["gps.coordinates.0"] && d["gps.coordinates.1"] && (d["grass_height"] !== undefined)).map((d, i) => {
  //             // let location = JSON.parse(d.GPS);
  //             let dIndex = deviceIds.indexOf(d.device_id)
  //             if (dIndex === -1) {
  //               deviceIds.push(d.device_id)
  //               if (context.data.devices) {
  //                 let dDetail = context.data.devices.results.filter(device => device.device_id === d.device_id);
  //                 deviceNames.push((dDetail && dDetail.length > 0 && dDetail[0].alias_name) ? dDetail[0].alias_name : d.device_id)
  //               } else {
  //                 deviceNames.push(d.device_id)
  //               }
  //             }
  //             return {
  //               geometry: [Number(d["gps.coordinates.0"]), Number(d["gps.coordinates.1"])],
  //               value: d["grass_height"] ? parseFloat(d["grass_height"]) : 0, //Math.floor(Math.random() * Math.floor(10)), //
  //               deviceType: deviceTypes[parseInt(i / (dataArr.length / 3))],
  //               time: d.completed_at,
  //               timeStamp: parseInt(moment(d.completed_at).format('x')),
  //               device_id: d.device_id,
  //               deviceName: deviceNames[dIndex]
  //             };
  //           });

  //           // console.log('deviceIds', deviceIds);
  //           deviceIds.forEach((d, i) => {
  //             if (!maplayers[1].children.find(c => c.label == deviceNames[i])) {
  //               maplayers[1].children.push({
  //                 label: deviceNames[i], isValid: (val) => val === d, color: colrs[i % 3][0], colorArr: colrs[i % 3][1]
  //               })
  //             }
  //           })
  //           // console.log('deviceIds', maplayers);

  //           let mapfilterSelected = maplayers.map((item, index) => {
  //             // let childarr = [];
  //             let childarr = item.children.map((child, childIndex) => {
  //               return { ...child, checked: true };
  //             })
  //             return { ...item, children: childarr, checked: (index === 0) }
  //           })

  //           setMapLayers(mapfilterSelected);
  //           // console.log("response.length 100", mapData.length, mapData.slice(0,5));
  //           console.log('mapData.length today', mapData);
  //           setdetailData(dData => ([...dData, ...mapData]));

  //           let TR = _getTimeRange(mapData);
  //           settimeRange(TR);
  //           setfilterValue(TR);


  //           // render(<App data={data} />, container);
  //         }

  //         context.setData({ type: 'CLEAR_LOADER' });

  //         return;
  //       });
  //   } else {
  //     // `/location2/${locId}.csv`
  //     // `/locdata/MWBH053_20200730_location_data.csv`
  //     require("d3-request").csv(locId, (error, response) => {
  //       // console.log("response.length", response[0], response[1]);
  //       if (!error) {

  //         const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];
  //         // ]
  //         let dataArr = response
  //         // .filter((d) => {
  //         //   return d.GPS ? true : false;
  //         // })
  //         let deviceIds = [];
  //         let deviceNames = []
  //         let mapData = dataArr.filter(d => d["long"] && d["lat"]).map((d, i) => {
  //           // let location = JSON.parse(d.GPS);
  //           let dIndex = deviceIds.indexOf(d.device_id)
  //           if (dIndex === -1) {
  //             deviceIds.push(d.device_id)
  //             if (context.data.devices) {
  //               let dDetail = context.data.devices.results.filter(device => device.device_id === d.device_id);
  //               deviceNames.push((dDetail && dDetail.length > 0 && dDetail[0].alias_name) ? dDetail[0].alias_name : d.device_id)
  //             } else {
  //               deviceNames.push(d.device_id);
  //             }
  //           }
  //           return {
  //             geometry: [Number(d["long"]), Number(d["lat"])],
  //             value: d["grass_height"] ? parseFloat(d["grass_height"]) : 0,
  //             deviceType: deviceTypes[parseInt(i / (dataArr.length / 3))],
  //             time: moment.unix(d.Time).format('YYYY-MM-DD HH:mm:ss'),
  //             timeStamp: parseInt(d.Time) * 1000, //parseInt(moment(d.time).format('x'))
  //             device_id: d.device_id,
  //             deviceName: deviceNames[dIndex]
  //           };
  //         });

  //         // console.log('deviceIds', deviceIds);

  //         deviceIds.forEach((d, i) => {
  //           if (!maplayers[1].children.find(c => c.label == deviceNames[i])) {
  //             maplayers[1].children.push({
  //               label: deviceNames[i], isValid: (val) => val === d, color: colrs[i % 3][0], colorArr: colrs[i % 3][1]
  //             })
  //           }
  //         })
  //         // console.log('deviceIds', maplayers);

  //         let mapfilterSelected = maplayers.map((item, index) => {
  //           // let childarr = [];
  //           let childarr = item.children.map((child, childIndex) => {
  //             return { ...child, checked: true };
  //           })
  //           return { ...item, children: childarr, checked: (index === 0) }
  //         })

  //         setMapLayers(mapfilterSelected);
  //         // console.log("response.length 100", mapData.length, mapData.slice(0,5));
  //         console.log('mapData.length prevday', mapData);
  //         setdetailData(dData => ([...dData, ...mapData]));

  //         // mapData = detailData.concat(mapData)
  //         let TR = _getTimeRange(mapData);
  //         settimeRange(TR);
  //         setfilterValue(TR);
  //         // setdetailData(mapData);
  //         // render(<App data={data} />, container);
  //       } else {
  //         console.log('loc data error', error)
  //       }
  //       context.setData({ type: 'CLEAR_LOADER' });
  //       return;
  //     });
  //   }
  // };

  // const getLocationSummaryCSVData = () => {
  //   // render(<HeatMap />, container);

  //   require("d3-request").csv(`/location2/location_summary2.csv`, (error, response) => {
  //     // console.log("response.length", response[0], response[1]);
  //     if (!error) {

  //       // const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];
  //       // // ]
  //       // let dataArr = response
  //       //   // .filter((d) => {
  //       //   //   return d.GPS ? true : false;
  //       //   // })

  //       // let mapData = dataArr.map((d, i) => {
  //       //   // let location = JSON.parse(d.GPS);
  //       //   return {
  //       //     geometry: [Number(d[" long"]), Number(d[" lat"])],
  //       //     value: parseFloat(d[" Grass Height"]),
  //       //     deviceType: deviceTypes[parseInt(i / (dataArr.length / 3))],
  //       //     time: d.time
  //       //   };
  //       // });

  //       // console.log("response.length", mapData.slice(0, 100));

  //       let tSum = {}
  //       for (let l = 0; l < response.length; l++) {
  //         tSum[response[l].Location] = response[l];
  //       }

  //       setlocationSummary(tSum);
  //       // render(<App data={data} />, container);
  //     }
  //   });
  // };

  useEffect(() => {
    return () => {
      context.setData({ type: 'CLEAR_LOADER' });
      if (finalWorker) {
        finalWorker.terminate();
      }
      if (workers) {
        // console.log('workers', workers)
        for (var w in workers) {
          // console.log(w)
          if (workers[w]) workers[w].terminate()
        }
      }
    }
  }, [isDetailView]);


  // useEffect(() => {
  //   if (selectedDate && locationSummary) {
  //     setLocationLimit(locationLimit + 100)
  //     // getBaseMapData(context.setData, selectedDate);

  //     // getLocations(context.setData, locationLimit, staticLocations, contractorsArr, qualities, locationSummary);
  //   }
  // }, [context.setData, selectedDate, locationSummary]);

  useEffect(() => {
    if (context.data.todayLocationProps) {
      let newtodayLocationProps = { ...todayLocationProps }

      context.data.todayLocationProps.forEach(locProps => {
        newtodayLocationProps[locProps.loc_cd] = {
          isLoading: false,
          ...locProps
        }
      })

      settodayLocationProps(newtodayLocationProps)


      let newgeoJsons = geoJsons.data.map(l => {
        let fndLoc = context.data.todayLocationProps.find(loc => loc.loc_cd == l.properties.loc_cd);
        if (fndLoc) {
          let locProps = {
            ...l.properties,
            ...fndLoc
          }
          l.properties = locProps
        }
        return l;
      })
      // console.log('newgeoJsons', newgeoJsons);
      setgeoJsons({
        data: newgeoJsons,
        status: 'tooltip'
      });
    }
    // eslint-disable-next-line
  }, [context.data.todayLocationProps]);

  useEffect(() => {
    let locList = []
    if (geoJsons && geoJsons.status !== 'tooltip') {
      // console.log('todayLocationProps', todayLocationProps, tableRef)
      // for (let i = 0; i < geoJsons.data.length && i < 5; i++) {
      //   if (geoJsons.data[i].properties.hasTodayData && !todayLocationProps[geoJsons.data[i].properties.loc_cd]) {
      //     locList.push(geoJsons.data[i].properties)
      //   }
      // }
      let pageSize = 5;
      let currentPage = 0;
      if (tableRef && tableRef.current) {
        pageSize = tableRef.current.state.pageSize;
        currentPage = tableRef.current.state.currentPage;
      }
      for (let i = (currentPage * pageSize); i < geoJsons.data.length && i < ((currentPage + 1) * pageSize); i++) {
        if (geoJsons.data[i].properties.hasTodayData && !todayLocationProps[geoJsons.data[i].properties.loc_cd]) {
          locList.push(geoJsons.data[i].properties)
        }
      }
    }
    if (locList.length > 0) {
      getTodayLocationProps(context.setData, locList);
    }
  }, [geoJsons]);

  useEffect(() => {
    if (context.data.todayLocations) {
      if (context.data.todayLocations.length === 0) {
        enqueueSnackbar('No locations done today!', { variant: 'warning' });
        context.setData({ type: 'CLEAR_LOCATIONS' });
      } else {
        getLocations(context.setData, staticLocations, contractorsArr, qualities, context.data.todayLocations);
      }
    }
    // eslint-disable-next-line
  }, [context.data.todayLocations, context.data.todayLocationsError]);

  // useEffect(() => {
  //   console.log('selectedDateDetails',selectedDateDetails)
  //   if (selectedDateDetails && selectedDateDetails.selectedDateType) {
  //     if (selectedDateDetails.selectedDateType === 'Today') {
  //       getTodayLocations(context.setData)
  //     } else {
  //       // context.setData({ type: 'CLEAR_TODAY_LOCATIONS' });
  //       getPreviousDayLocations(context.setData, moment(selectedDateDetails.selectedDate[0]).format('YYYY-MM-DD'))
  //       // getLocations(context.setData, locationLimit, staticLocations, contractorsArr, qualities, locationSummary, '');
  //     }
  //   }
  // }, [selectedDateDetails]);


  // useEffect(() => {
  //   if (context.data.previousDayLocations) {
  //     if (context.data.previousDayLocations.length === 0) {
  //       enqueueSnackbar('No locations found!', { variant: 'warning' });
  //       // context.setData({ type: 'CLEAR_LOCATIONS' });
  //     } else {
  //       // console.log('context.data.previousDayLocations', context.data.previousDayLocations)
  //       // console.log('previousDayLocations', context.data.previousDayLocations);
  //       // getLocations(context.setData, locationLimit, staticLocations, contractorsArr, qualities, locationSummary, context.data.todayLocations);
  //       getPreviosLocationGeoJsons(context.setData, context.data.previousDayLocations, contractorsArr, moment(selectedDateDetails.selectedDate[0]).format('YYYY-MM-DD'))
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [context.data.previousDayLocations, context.data.previousDayLocationsError]);

  // useEffect(() => {
  //   if (context.data.previousDayLocationData) {
  //     if (context.data.previousDayLocationData.length === 0) {
  //       // enqueueSnackbar('No data found!', { variant: 'warning' });
  //     } else {
  //       // console.log('previousDayLocationData', context.data.previousDayLocationData);

  //       context.data.previousDayLocationData.forEach(d => {
  //         getCSVData(d.file_s3_url, false)
  //       })
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [context.data.previousDayLocationData, context.data.previousDayLocationDataError]);


  // useEffect(() => {
  //   getLocations(context.setData);
  // }, [context.setData]);

  // useEffect(() => {
  //   // console.log('context', context);
  //   if (context.data.summary && context.data.summary.zones) {
  //     // var zones = [];
  //     // zones = context.data.summary.zones
  //     // zones.push({id: 5, label: "SOUTH-EAST"})
  //     // zones.push({id: 6, label: "NORTH-EAST"})
  //     // zones.push({id: 7, label: "NORTH-WEST"})
  //     // zones.push({id: 8, label: "SOUTH-WEST"})

  //     // setZones(zonesArr)
  //   }
  //   // if (context.data.summary && context.data.summary.contractors) {
  //   //   setContractors(context.data.summary.contractors)
  //   // }
  //   // if(context.data.summary && context.data.summary.locations){
  //   //   setLocations(context.data.summary.locations)
  //   // }
  //   // if(context.data.summary && context.data.summary.equipments){
  //   //   setEquipments(context.data.summary.equipments)
  //   // }
  // }, [context.data.summary]);

  useEffect(() => {

    if (context.data.locations) {
      try {
        // console.log('context.data.locations all', context.data.locations);
        // console.log('context.data.locations all akm', context.data.locations.filter(l => l.properties.loc_cd.indexOf('AKM') != -1));
        // console.log('context.data.locations multiple', context.data.locations.filter(l => l.properties.Date.split(',').length > 1 && l.properties.hasTodayData));
        // console.log('context.data.locations today', context.data.locations.filter(d => d.properties.hasTodayData)[0].properties.loc_desc);
        // console.log('context.data.locations not today', context.data.locations.filter(d => !d.properties.hasTodayData)[0].properties.loc_desc);

      } catch (Err) {

      }

      // const modifiedJson = context.data.locations.results.map((item, index) => {
      //   let randomContract = contractorsArr[Math.floor(Math.random() * Math.floor(6))];
      //   item.geom.type = 'Polygon'
      //   item.type = 'Polygon'
      //   item.contractor = randomContract.contract
      //   item.zone = randomContract.zone
      //   // item.lastCut = randomContract.zone
      //   item.quality = qualities[Math.floor(Math.random() * Math.floor(2))]
      //   item.grassCutPercent = Math.floor(Math.random() * Math.floor(100))
      //   item.operationalhrs = Math.floor(Math.random() * Math.floor(15))
      //   return {
      //     type: 'Feature',
      //     properties: item,
      //     geometry: {
      //       type: 'Polygon',
      //       coordinates: item.geom.coordinates.map(item => item[0])
      //     }
      //   }
      // });
      // if (selectedDateType == 'Today') {
      //   if (context.data.todayLocations) {
      //     let lFiter = context.data.locations.filter(l => context.data.todayLocations.indexOf(l.properties.loc_cd) != -1)
      //     let fil = lFiter.map((item, index) => {
      //       return {
      //         id: item.properties.loc_cd,
      //         label: item.properties.loc_desc
      //       }
      //     })
      //     setLocations(fil);
      //     setgeoJsons(lFiter);
      //   } else {
      //     setLocations([]);
      //     setgeoJsons([]);
      //   }

      // } else {
      // setcontextLocations(context.data.locations)
      setgeoJsons({
        data: context.data.locations,
        status: 'refresh'
      })
      let lFiter = context.data.locations.map((item, index) => {
        return {
          id: item.properties.loc_cd,
          label: item.properties.loc_desc
        }
      })
      setLocations(lFiter);
      // }
      let zns = zonesArr.map((z, i) => {
        return z
      })
      setZones(zns);
      let cts = contractorsArr.map((c, i) => {
        return {
          id: i,
          label: c.contract,
          zone: c.zone
        }
      })
      setContractors(cts)


      // if (context.data.locations.length === 0) {
      //   enqueueSnackbar('No locations found!', { variant: 'warning' });
      //   // context.setData({ type: 'CLEAR_LOCATIONS' });
      // }
    } else if (context.data.locationsError) {
      enqueueSnackbar(context.data.locationsError, { variant: 'warning' });
    }
  }, [context.data.locations, context.data.locationsError]);

  // useEffect(() => {
  //   console.log('contextfiltered', context);

  // }, [context.data.filtered]);

  // useEffect(() => {
  //   // console.log('contextfiltered', context);
  //   getLocationSummaryCSVData();
  // }, []);

  useEffect(() => {
    getDevices(context.setData);
  }, [context.setData]);

  // useEffect(() => {
  //   if(context.data.devices){
  //     setdevices(context.data.devices.results);
  //   }
  // }, [context.data.devices,context.data.devicesError]);

  // useEffect(() => {
  //   console.log('filterValue', filterValue);
  // }, [filterValue]);

  // useEffect(() => {
  //   console.log('context.data.isLoading', context.data.isLoading);
  // }, [context.data.isLoading]);


  const _getTimeRange = (data) => {
    if (!data || data.length === 0) {
      return timeRange;
    }
    // return data.reduce(
    //   (range, d) => {
    //     const t = d.timeStamp;
    //     range[0] = Math.min(range[0], t)
    //     range[1] = Math.max(range[1], t)
    //     return range;
    //   },
    //   [Infinity, -Infinity]
    // );
    let min = timeRange ? timeRange[0] : Infinity;
    let max = timeRange ? timeRange[1] : -Infinity;

    if (data[data.length - 1].timeStamp < data[0].timeStamp) {
      if (data[data.length - 1].timeStamp < min) {
        min = data[data.length - 1].timeStamp;
      }
      if (data[0].timeStamp > max) {
        max = data[0].timeStamp;
      }

    } else {
      if (data[0].timeStamp < min) {
        min = data[0].timeStamp;
      }
      if (data[data.length - 1].timeStamp > max) {
        max = data[data.length - 1].timeStamp;
      }
    }
    return [min, max]

  }

  const _formatLabel = (t) => {
    const date = new Date(t);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short'
    }).replace(/ /g, '-');
    const formattedTime = date.toLocaleTimeString([],
      { hour: '2-digit', minute: '2-digit' })
    return formattedDate + '-' + formattedTime
  }

  useEffect(() => {
    if (context.data.detailMapData && isDetailView) {
      // console.log('context.data.detailMapData', context.data.detailMapData)

      let deviceIds = [];
      let deviceNames = []
      let allData = []
      let allDataArr = []
      let TR = [Infinity, -Infinity];
      context.data.detailMapData.forEach(mapData => {

        const deviceTypes = ["KnapSack", "Lawn Mover", "Tractor"];

        let dData = [];
        if (mapData.isToday) {
          let todayDeviceIds = []
          dData = mapData.data.filter(d => d["gps.coordinates.0"] && d["gps.coordinates.1"] && (d["grass_height"] !== undefined)).map((d, i) => {
            let dIndex = deviceIds.indexOf(d.device_id)
            if (todayDeviceIds.indexOf(d.device_id) == -1) {
              todayDeviceIds.push(d.device_id)
            }
            if (dIndex === -1) {
              deviceIds.push(d.device_id)
              if (context.data.devices) {
                let dDetail = context.data.devices.results.filter(device => device.device_id === d.device_id);
                deviceNames.push((dDetail && dDetail.length > 0 && dDetail[0].alias_name) ? dDetail[0].alias_name : d.device_id)
              } else {
                deviceNames.push(d.device_id)
              }
              dIndex = deviceNames.length - 1;
            }

            let accuracy = (d.hAcc) ? d.hAcc + ' m' : '-'
            // let grassHeight = (d.grass_height !== null) ? (d.grass_height / 10) : null
            let RTK = d.RTK
            if (RTK === 0) { RTK = 'No GPS' }
            else if (RTK === 1) { RTK = 'GPS Fix' }
            else if (RTK === 2) { RTK = 'DGNSS + GPS' }
            else if (RTK === 4) { RTK = 'GPS + RTK FIX' }
            else if (RTK === 5) { RTK = 'GPS + RTK FLOAT' }
            else { RTK = null }
            let ErrorCode;
            ErrorCode = (d.error !== null) ? (d.error) : ''
            if ((ErrorCode == 0) || (ErrorCode == 0.0)) { ErrorCode = 'No Error' }
            else if ((ErrorCode == 1) || (ErrorCode == 1.0)) { ErrorCode = 'Sensor Error (Object is too far)' }
            else if ((ErrorCode == 2) || (ErrorCode == 2.0)) { ErrorCode = 'Negative Value' }
            else if ((ErrorCode == 4) || (ErrorCode == 4.0)) { ErrorCode = 'Grass Height out of range(>20 cm)' }
            else if ((ErrorCode == 8) || (ErrorCode == 8.0)) { ErrorCode = 'Object is too near' }
            else if ((ErrorCode == 16) || (ErrorCode == 16.0)) { ErrorCode = 'Device tilt angle out of range' }

            return {
              geometry: [Number(d["gps.coordinates.0"]), Number(d["gps.coordinates.1"])],
              value: (d["grass_height"] !== "") ? parseFloat(d["grass_height"] / 10) : null,
              deviceType: deviceTypes[parseInt(i / (mapData.data.length / 3))],
              time: d.completed_at,
              timeStamp: parseInt(moment(d.completed_at).format('x')),
              device_id: d.device_id,
              deviceName: deviceNames[dIndex],
              accuracy: accuracy,
              RTK: d.RTK,
              RTKVal: RTK,
              error: ErrorCode,
              distance: d.distance,
              SOG: d.SOG
            };
          });

          //calc today coverage
          // getTodayCoverage(dData);

          if (dData.length > 4000) {
            settodayLocationPropsInProgress('na')
          } else {
            runMultiWorker(dData, todayDeviceIds)
          }

          // console.log('after worker')

        } else {
          dData = mapData.data.filter(d => d["long"] && d["lat"]).map((d, i) => {
            // let location = JSON.parse(d.GPS);
            let dIndex = deviceIds.indexOf(d.device_id)
            if (dIndex === -1) {
              deviceIds.push(d.device_id)
              if (context.data.devices) {
                let dDetail = context.data.devices.results.filter(device => device.device_id === d.device_id);
                deviceNames.push((dDetail && dDetail.length > 0 && dDetail[0].alias_name) ? dDetail[0].alias_name : d.device_id)
              } else {
                deviceNames.push(d.device_id);
              }
            }

            let accuracy = (d.hAcc) ? d.hAcc + ' m' : '-'
            // let grassHeight = (d.grass_height !== null) ? (d.grass_height / 10) : null
            // let RTK = d.RTK
            // if (RTK === 0) { RTK = 'No GPS' }
            // else if (RTK === 1) { RTK = 'GPS Fix' }
            // else if (RTK === 2) { RTK = 'DGNSS + GPS' }
            // else if (RTK === 4) { RTK = 'GPS + RTK FIX' }
            // else if (RTK === 5) { RTK = 'GPS + RTK FLOAT' }
            // else { RTK = null }
            let ErrorCode;
            // ErrorCode = (d.error !== null) ? (d.error) : ''
            // if ((ErrorCode == 0) || (ErrorCode == 0.0)) { ErrorCode = 'No Error' }
            // else if ((ErrorCode == 1) || (ErrorCode == 1.0)) { ErrorCode = 'Sensor Error (Object is too far)' }
            // else if ((ErrorCode == 2) || (ErrorCode == 2.0)) { ErrorCode = 'Negative Value' }
            // else if ((ErrorCode == 4) || (ErrorCode == 4.0)) { ErrorCode = 'Grass Height out of range(>20 cm)' }
            // else if ((ErrorCode == 8) || (ErrorCode == 8.0)) { ErrorCode = 'Object is too near' }
            // else if ((ErrorCode == 16) || (ErrorCode == 16.0)) { ErrorCode = 'Device tilt angle out of range' }

            if (!d.error) ErrorCode = 'No Error'
            return {
              geometry: [Number(d["long"]), Number(d["lat"])],
              value: (d["grass_height"] !== "") ? parseFloat(d["grass_height"] / 10) : null,
              deviceType: deviceTypes[parseInt(i / (mapData.data.length / 3))],
              time: moment.unix(d.Time).format('YYYY-MM-DD HH:mm:ss'),
              timeStamp: parseInt(d.Time) * 1000, //parseInt(moment(d.time).format('x'))
              device_id: d.device_id,
              deviceName: deviceNames[dIndex],
              accuracy: accuracy,
              // RTK: d.RTK,
              // RTKVal: RTK,
              error: ErrorCode,
              distance: d.distance,
              SOG: d.SOG
            };
          });
        }

        allData = allData.concat(dData);

        if (dData.length > 0) {
          // min
          if (dData[0].timeStamp < TR[0]) { TR[0] = dData[0].timeStamp; }
          if (dData[dData.length - 1].timeStamp < TR[0]) { TR[0] = dData[dData.length - 1].timeStamp; }

          // max
          if (dData[0].timeStamp > TR[1]) { TR[1] = dData[0].timeStamp; }
          if (dData[dData.length - 1].timeStamp > TR[1]) { TR[1] = dData[dData.length - 1].timeStamp; }
        }
      });


      // console.log('deviceIds', deviceIds);
      deviceIds.forEach((d, i) => {
        if (!maplayers[1].children.find(c => c.label == deviceNames[i])) {
          maplayers[1].children.push({
            label: deviceNames[i], isValid: (val) => val === d, color: colrs[i % 5][0], colorArr: colrs[i % 5][1]
          })
        }
      })
      // console.log('deviceIds', maplayers);

      let mapfilterSelected = maplayers.map((item, index) => {
        // let childarr = [];
        let childarr = item.children.map((child, childIndex) => {
          return { ...child, checked: true };
        })
        return { ...item, children: childarr, checked: (index === 0) }
      })

      setMapLayers(mapfilterSelected);
      // console.log("response.length 100", mapData.length, mapData.slice(0,5));
      // console.log('mapData.length alldata', allData, TR);
      setdetailData(allData);
      settimeRange(TR);
      setfilterValue(TR);
      if (allData.length == 0) {
        enqueueSnackbar('No data Found', { variant: 'warning' });
      }

    } else if (context.data.detailMapDataError) {
      enqueueSnackbar(context.data.detailMapDataError, { variant: 'warning' });
    }
    // eslint-disable-next-line
  }, [context.data.detailMapData, context.data.detailMapDataError]);


  useEffect(() => {
    if (context.data.detailMapCoverageData && isDetailView) {
      // console.log('context.data.detailMapCoverageData', context.data.detailMapCoverageData)
      let tableData = context.data.detailMapCoverageData.map(res => {
        let locProps = {};
        if (res.data.features && res.data.features.length > 0) {
          locProps = res.data.features[0].properties
        }
        if (locProps.Date) {
          let datesCut = locProps.Date.split(',');
          locProps.lastCut = datesCut[datesCut.length - 1];
        }
        locProps.checked = true;
        locProps.isToday = res.date.isToday;
        return locProps;
      })
      setdetailTableData(tableData)
      // console.log('detailTableData', tableData)
      setdetailMapCoverageData(context.data.detailMapCoverageData)

    }
  }, [context.data.detailMapCoverageData, context.data.detailMapCoverageDataError]);

  // console.log('Dashboard render');
  return (
    <>
      <LinearProgress hidden={!context.data.isLoading} />
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          height: "calc(100vh - 64px)",
          minHeight: "400px",
          transition: "height .6s ease-in",
        }}
      >
        {/* <div hidden={isDetailView}>
          <GoogleBaseMap
            filters={{ locations }}
            onAreaSelected={areaSelected}
            isDetailView={isDetailView}
            geoJsons={geoJsons}
            mapStyleType={mapStyleType}
            onMapStyleChange={(val) => setmapStyleType(val)}
          />
        </div> */}
        {isDetailView ? <GoogleDetailMap data={detailData} mapLayers={mapLayers} locationData={selectedLocation} filterValue={filterValue} mapStyleType={mapStyleType} coverage={detailMapCoverageData} showCoverage={showCoverage} todayCoverage={detailMapTodayCoverageData}></GoogleDetailMap> :
          <GoogleBaseMap
            ref={baseMapRef}
            filters={{ locations }}
            onAreaSelected={areaSelected}
            onTooltipHover={onTooltipHover}
            isDetailView={isDetailView}
            mapBounds={mapBounds}
            geoJsons={geoJsons}
            mapStyleType={mapStyleType}
            onMapStyleChange={(val) => setmapStyleType(val)}
            todayLocationProps={todayLocationProps}
          />
        }
      </div>
      {isDetailView && (
        <div className={classes.topPanel}>
          <Button
            variant="contained"
            color="default"
            className={classes.backbtn}
            onClick={backToOverview}
          >
            <ArrowBackIcon style={{ marginRight: "12px" }}></ArrowBackIcon> BACK
            TO OVERVIEW
          </Button>

          {todayLocationPropsInProgress == 'true' && <div className={classes.calcProgress}> Calculating today coverage...</div>}
          {todayLocationPropsInProgress == 'na' && <div className={classes.calcProgress} style={{ color: 'rgb(235, 69, 89)' }}> Coverage will be available tomorrow</div>}
          {/* <Button
            variant="contained"
            color="default"
            className={classes.layerbtn}
            onClick={showMore}
          >
            <MapIcon style={{ marginRight: "12px" }}></MapIcon> MAP LAYERS
          </Button> */}

          <Button
            variant="contained"
            color="default" className={classes.coverageBtn}>
            <FormGroup>
              <CoverageLabel
                control={<Switch checked={showCoverage} color="primary" onChange={(e) => setshowCoverage(e.target.checked)} name="checkedA" />}
                label={showCoverage ? "Show Data" : "Show Coverage"}
              />
            </FormGroup>
          </Button>



          <CategoryFilter options={maplayers} label="MAP LAYERS" description="" className={classes.layerbtn} onChange={(selectedFilter) => {
            // console.log('selectedFilter', selectedFilter);
            setMapLayers(selectedFilter)
          }}></CategoryFilter>

          {!hideControls && <Button
            variant="contained"
            color="default"
            className={classes.hidebtn}
            onClick={() => {
              setHideControls(true);
              setshowFullPanel(false);
            }}
          >
            <CropFreeOutlinedIcon
              style={{ marginRight: "12px" }}
            ></CropFreeOutlinedIcon> HIDE CONTROLS
          </Button>}
          {hideControls && <Button
            variant="contained"
            color="default"
            className={classes.hidebtn}
            onClick={() => setHideControls(false)}
          >
            <svg style={{ marginRight: 12 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 17V19H9V17H3ZM3 5V7H13V5H3ZM13 21V19H21V17H13V15H11V21H13ZM7 9V11H3V13H7V15H9V9H7ZM21 13V11H11V13H21ZM15 9H17V7H21V5H17V3H15V9Z" fill="#2C7873" />
            </svg> SHOW CONTROLS
          </Button>}
          {timeRange && filterValue && !showCoverage && <div className={classes.timecontrol + ((showFullPanel || hideControls) ? ' ' + classes.hideControls : '')}>
            <TimeTravel
              min={timeRange[0]}
              max={timeRange[1]}
              value={filterValue}
              formatLabel={(t) => _formatLabel(t)}
              onChange={({ value }) => setfilterValue(value)} />
          </div>}
          {/* {timeRange && filterValue && (<div className={classes.timecontrol + ((showFullPanel || hideControls) ? ' ' + classes.hideControls : '')}>
            <StyletronProvider value={engine}>
              <BaseProvider theme={LightTheme}>
                <RangeInput
                  min={timeRange[0]}
                  max={timeRange[1]}
                  value={filterValue}
                  animationSpeed={MS_PER_DAY*0.007}
                  formatLabel={(t)=>_formatLabel(t)}
                  onChange={({ value }) => setfilterValue(value)}
                /></BaseProvider></StyletronProvider></div>)} */}
        </div>
      )}

      <div hidden={isDetailView}
        className={
          classes.bottompanel +
          (!showFullPanel ? "" : " " + classes.fullpanel)
        }
      >
        <div style={{ textAlign: "center" }}>
          {!showFullPanel ? (
            <Button
              variant="contained"
              color="default"
              className={classes.morebtn}
              onClick={showMore}
            >
              SEE MORE <ExpandLessIcon></ExpandLessIcon>
            </Button>
          ) : (
              <Button
                variant="contained"
                color="default"
                className={classes.morebtn}
                onClick={showMore}
              >
                SEE LESS <ExpandMoreIcon></ExpandMoreIcon>
              </Button>
            )}
        </div>

        <div className={classes.btmPanelContent} style={showFullPanel ? { overflow: 'auto', height: '100%' } : null}>
          <div
            style={{
              display: "flex",
              borderBottom: "solid 1px #c4d7d7",
              padding: "12px",
            }}
          >
            <Filter
              options={zones}
              label="Zones"
              description="zones"
              className={classes.filter}
              onFilterChange={(data) => onFilterChange('zones', data)}
            ></Filter>
            {/* <Filter
              options={contractors}
              label="Contractors"
              description="contractors"
              search={true}
              className={classes.filter}
              onFilterChange={(data) => onFilterChange('contractors', data)}
            ></Filter> */}
            <Filter
              options={locations}
              label="Locations"
              description="locations"
              search={true}
              className={classes.filter}
              onFilterChange={(data) => onFilterChange('locations', data)}
            ></Filter>
            {/* <Filter
                options={equipments}
                label="Equipments"
                description="equipments"
                className={classes.filter}
              ></Filter> */}
            <DateRangeFilter
              // options={[]}
              label="Duration"
              className={classes.filter}
              onDateChange={onDateChange}
            ></DateRangeFilter>
          </div>
          <div style={{ display: "flex", padding: "12px" }}>
            {/* {(!context || !context.data || !context.data.isLoading) &&
                <div style={{ textAlign: "center", padding: 20, flexGrow: 1 }}>Loading...</div>
              } */}

            <Tile
              // key={index}
              label="Total No. of Assets"
              unit={'Total Jobs Completed : ' + (context.data.metrics && context.data.metrics.CompletedLocationCount ? context.data.metrics.CompletedLocationCount : '-')}
              value={context.data.metrics ? context.data.metrics.LocationCount : '-'}
              className={classes.tile}
            ></Tile>
            {/* <Tile
              // key={index}
              label="Total Jobs Completed"
              unit=""
              value={context.data.metrics && context.data.metrics.TotalJobsCompleted ? context.data.metrics.TotalJobsCompleted : '-'}
              className={classes.tile}
            ></Tile> */}
            <Tile
              // key={index}
              label="Total Area (in Ha)"
              unit={'Grass Cut Area : ' + (context.data.metrics && context.data.metrics.TotalGrassCutArea ? (context.data.metrics.TotalGrassCutArea / 10000).toFixed(2) : '-')}
              value={context.data.metrics && context.data.metrics.TotalPolygonArea ? (context.data.metrics.TotalPolygonArea / 10000).toFixed(2) : '-'}
              className={classes.tile}
            ></Tile>
            {/* <Tile
              // key={index}
              label="Total Grass Cut Area"
              unit="sqm"
              value={context.data.metrics && context.data.metrics.TotalGrassCutArea ? Math.trunc(context.data.metrics.TotalGrassCutArea) : '-'}
              className={classes.tile}
            ></Tile> */}
            {/* <Tile
              // key={index}
              label="Total Operation Hours"
              unit="hrs"
              value={context.data.metrics && context.data.metrics.TotalOperationalTime ? moment().startOf('day').seconds(context.data.metrics.TotalOperationalTime).format('HH:mm:ss') : '-'}
              className={classes.tile}
            ></Tile> */}
            <Tile
              // key={index}
              label="Total No. of Operators"
              unit=""
              value={context.data.metrics && context.data.metrics.TotalDevices ? context.data.metrics.TotalDevices : '-'}
              // value={maplayers[1].children.length}
              className={classes.tile}
            ></Tile>

            {/* {
              metrics.map((item, index) => {
                var label = item.label
                var value = item.value
                return (
                  <Tile
                    key={index}
                    label={label}
                    unit=""
                    value={value}
                    className={classes.tile}
                  ></Tile>
                );
              })
            } */}
            {/* <Tile
                label="Total Hectarage"
                unit="(in ha)"
                value="130"
                className={classes.tile}
              ></Tile>
              <Tile
                label="Total Coverage"
                unit="(in %)"
                value="55.2"
                className={classes.tile}
              ></Tile>
              <Tile
                label="Quality of Cut"
                unit="(in %)"
                value="60%"
                className={classes.tile}
              ></Tile>
              <Tile
                label="No of Contracts"
                unit=""
                value="4"
                className={classes.tile}
              ></Tile>
              <Tile
                label="No of Equipments"
                unit=""
                value="15"
                className={classes.tile}
              ></Tile> */}
          </div>
          <div style={{ padding: "12px 24px" }}>
            {/* <Button
              variant="contained"
              color="default"
              className={classes.modifycolbtn}
            >
              MODIFY COLUMNS
              </Button> */}

              <div>Note : Values with * represent approximate values</div>
          </div>
          <div style={{ maxWidth: "100%" }} className="btmtable">
            <MaterialTable
              tableRef={tableRef}
              title=""
              columns={[
                {
                  title: "DATE", field: "properties.lastCut", type: 'date',
                  render: rowData => moment(rowData.properties.lastCut, 'YYYYMMDD').format('DD-MM-YYYY')
                },
                // { title: "CONTRACT", field: "properties.contractor" },
                // {
                //   title: "ZONE",
                //   field: "properties.zone",
                //   lookup: {
                //     NE: "NORTH-EAST",
                //     SE: "SOUTH-EAST",
                //     CN: "CENTRAL-NORTH",
                //     NW: "NORTH-WEST",
                //     SW: "SOUTH-WEST",
                //     CS: "CENTRAL-SOUTH",
                //   }
                // },
                {
                  title: "LOCATION", field: "properties.loc_desc",
                  render: rowData => <div title="Click to view the location" style={{ cursor: 'pointer', color: '#2C7873', display: 'flex' }} onClick={() => areaSelected(rowData)}><div style={{ flexGrow: 1 }} >{rowData.properties.loc_desc || rowData.properties.loc_cd} </div><ZoomInIcon /></div>
                },
                {
                  title: "Total Area (in sqm)",
                  field: "properties.PolygonArea",
                  type: "numeric",
                  grouping: false,
                  headerStyle: { textAlign: "left" },
                  render: rowData => rowData.properties.PolygonArea //typeof rowData.properties.PolygonArea == 'string'? +parseFloat(rowData.properties.PolygonArea).toFixed(3): rowData.properties.PolygonArea
                },
                {
                  title: "Area of Grass Cut (in sqm)",
                  field: "properties.GrassCutArea",
                  type: "numeric",
                  grouping: false,
                  headerStyle: { textAlign: "left" },
                  render: rowData => rowData.properties.GrassCutArea + ((rowData.properties.hasTodayData)?'*':'') //typeof rowData.properties.GrassCutArea == 'string'? +parseFloat(rowData.properties.GrassCutArea).toFixed(3): rowData.properties.GrassCutArea
                },
                {
                  title: "Area of Grass Cut (in %)",
                  field: "properties.Coverage",
                  type: "numeric",
                  grouping: false,
                  headerStyle: { textAlign: "left" },
                  render: rowData => rowData.properties.Coverage + ((rowData.properties.hasTodayData)?'*':'')//typeof rowData.properties.Coverage == 'string'? +parseFloat(rowData.properties.Coverage).toFixed(2): rowData.properties.Coverage
                },
                {
                  title: "OPERATIONAL HOURS (in hh:mm:ss)",
                  field: "properties.TotalOperationalDuration",
                  type: "numeric",
                  grouping: false,
                  render: rowData => rowData.properties.TotalOperationalDuration + ((rowData.properties.hasTodayData)?'*':''),
                  headerStyle: { textAlign: "left" },
                },
                // {
                //   title: "TOTAL EQUIPMENTS",
                //   field: "eqipments",
                //   type: "numeric",
                //   grouping: false,
                //   headerStyle: { textAlign: "left" },
                // },
                {
                  title: "Completion Status", field: "properties.jobStatus",
                  render: rowData => {
                    if (rowData.properties.JobCompletion == '1') {
                      return <span style={{ background: '#63c292', padding: '2px 8px', borderRadius: 8, color: '#ffffff' }}>Completed</span>
                    } else {
                      return <span style={{ background: '#eb4559', padding: '2px 8px', borderRadius: 8, color: '#ffffff' }}>InProgress</span>
                    }
                  }
                },
                // {
                //     title: 'Birth Place',
                //     field: 'birthCity',
                //     lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                // },
              ]}
              data={geoJsons.data}
              onChangePage={(currentPage, dd) => {
                console.log('onChangePage', currentPage, tableRef)
                let pageSize = tableRef.current.state.pageSize
                let locList = []
                if (geoJsons) {
                  for (let i = (currentPage * pageSize); i < geoJsons.data.length && i < ((currentPage + 1) * pageSize); i++) {
                    if (geoJsons.data[i].properties.hasTodayData && !todayLocationProps[geoJsons.data[i].properties.loc_cd]) {
                      locList.push(geoJsons.data[i].properties)
                    }
                  }
                }
                if (locList.length > 0) {
                  getTodayLocationProps(context.setData, locList);
                }
              }}
              onChangeRowsPerPage={(pageSize) => {
                console.log('onChangeRowsPerPage', pageSize, tableRef)

                // let pageSize = tableRef.current.state.pageSize;
                let currentPage = tableRef.current.state.currentPage;
                let locList = []
                if (geoJsons) {
                  for (let i = (currentPage * pageSize); i < geoJsons.data.length && i < ((currentPage + 1) * pageSize); i++) {
                    if (geoJsons.data[i].properties.hasTodayData && !todayLocationProps[geoJsons.data[i].properties.loc_cd]) {
                      locList.push(geoJsons.data[i].properties)
                    }
                  }
                }
                if (locList.length > 0) {
                  getTodayLocationProps(context.setData, locList);
                }

              }}
              options={{
                filtering: true,
                grouping: false,
                exportAllData: true,
                exportButton: true,
                headerStyle: {
                  fontSize: "12px",
                  fontFamily: "Karla",
                  color: "#698D8D",
                  backgroundColor: "#F1FCFC",
                  padding: "4px 12px",
                },
                cellStyle: {
                  fontSize: "12px",
                  fontFamily: "Karla",
                  fontWeight: "bold",
                  padding: "4px 12px",
                },
                rowStyle: {
                  height: "auto",
                },
                searchFieldStyle: {
                  // color: 'red'
                },
                pageSize: 5,
              }}
            />
           
          </div>
        </div>
      </div>


      {isDetailView && (
        <div
          className={
            // classes.bottompanel +
            // " " +
            classes.detailbottompanel +
            (!showFullPanel ? "" : " " + classes.detailfullpanel) +
            (!hideControls ? "" : " " + classes.hiddenbottompanel)
          }
        >
          <div style={{}}>
            {!showFullPanel ? (
              <Button
                variant="contained"
                color="default"
                className={classes.detailmorebtn}
                onClick={showMore}
              >
                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
              </Button>
            ) : (
                <Button
                  variant="contained"
                  color="default"
                  className={classes.detaillessbtn}
                  onClick={showMore}
                >
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon> SEE LESS
                </Button>
              )}
          </div>
          <div className={classes.detailbtmPanelContent} style={showFullPanel ? { overflow: 'auto', height: '100%' } : null}>
            {selectedLocation && <div
              style={{
                // display: "flex",
                borderBottom: "solid 1px #c4d7d7",
                padding: "24px 24px 16px 24px",
                alignItems: "center",
              }}
            >
              <div className={classes.locationName}>{selectedLocation.properties.loc_desc || selectedLocation.properties.loc_cd}</div>
              <div>
                <div className={classes.filterChip}>ZONE: {selectedLocation.properties.zone ? zonesArr.filter(z => z.id === selectedLocation.properties.zone)[0].label : ''}</div>
                {/* <div className={classes.filterChip}>CONTRACTOR: {selectedLocation.properties.contractor}</div> */}
                <div className={classes.filterChip}>Last Cut: {moment(selectedLocation.properties.lastCut).format('DD-MM-YYYY')}</div>
                <div className={classes.filterChip}>No. of Operators: {maplayers[1].children.length}</div>
                {/* {selectedLocation.properties.DeviceCount} */}
                <div className={classes.filterChip}>Status: {selectedLocation.properties.jobStatus}</div>
              </div>
            </div>}
            {selectedLocation && <div style={{ display: "flex", padding: "12px", flexWrap: 'wrap' }}>
              <Tile
                label="Total Area"
                unit="(in sqm)"
                value={selectedLocation.properties.PolygonArea}
                className={classes.detailtile}
              ></Tile>
              <Tile
                label="Total Grass Cut Area"
                unit="(in sqm)"
                value={selectedLocation.properties.GrassCutArea + ((selectedLocation.properties.hasTodayData)?'*':'')}
                className={classes.detailtile}
              ></Tile>
              <Tile
                label="Total Grass Cut Area"
                unit="(in %)"
                value={selectedLocation.properties.Coverage + ((selectedLocation.properties.hasTodayData)?'*':'')}
                className={classes.detailtile}
              ></Tile>
              {/* <Tile
                label="Good Cut"
                unit="(in %)"
                value="-"
                className={classes.detailtile}
                type="outline"
                color="#63C292"
              ></Tile> */}
              {/* <Tile
                label="Medium Cut"
                unit="(in %)"
                value="22"
                className={classes.tile}
                type="outline"
                color="#F78259"
              ></Tile> */}
              {/* <Tile
                label="Bad Cut"
                unit="(in %)"
                value="-"
                className={classes.detailtile}
                type="outline"
                color="#EB4559"
              ></Tile> */}
              <Tile
                label="Operational hours"
                unit="(in hh:mm:ss)"
                value={selectedLocation.properties.TotalOperationalDuration + ((selectedLocation.properties.hasTodayData)?'*':'')}
                className={classes.detailtile}
              ></Tile>
              {selectedLocation.properties.hasTodayData && <div style={{textAlign: 'center', padding: "5px 5px 5px 12px"}}>Note: Values with * represent aprroximate values</div>}
            </div>}
            {selectedLocation && <div style={{ maxWidth: "100%", padding: "0 24px" }}>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "DATE", field: "lastCut", type: 'date',
                    render: rowData => {
                      // moment(rowData.lastCut, 'YYYYMMDD').format('DD-MM-YYYY')


                      let formattedDate = '';
                      if (rowData.lastCut) {
                        let tDate = moment(rowData.lastCut, 'YYYYMMDD');
                        formattedDate = tDate.format('DD-MM-YYYY');
                        let isToday = tDate.isSame(moment(), 'day')
                        if (isToday) {
                          // formattedDate += ' *(approx)'
                        }
                      }
                      return formattedDate;
                    }
                  },
                  // { title: "CONTRACT", field: "properties.contractor" },
                  // {
                  //   title: "ZONE",
                  //   field: "properties.zone",
                  //   lookup: {
                  //     NE: "NORTH-EAST",
                  //     SE: "SOUTH-EAST",
                  //     CN: "CENTRAL-NORTH",
                  //     NW: "NORTH-WEST",
                  //     SW: "SOUTH-WEST",
                  //     CS: "CENTRAL-SOUTH",
                  //   }
                  // },
                  // { title: "LOCATION", field: "properties.loc_desc" },
                  {
                    title: "Area of Grass Cut (in sqm)",
                    field: "GrassCutArea",
                    type: "numeric",
                    grouping: false,
                    headerStyle: { textAlign: "left" },
                    render: rowData => rowData.GrassCutArea + ((rowData.isToday)?'*':'')
                  },
                  {
                    title: "Area of Grass Cut (in %)",
                    field: "Coverage",
                    type: "numeric",
                    grouping: false,
                    headerStyle: { textAlign: "left" },
                    render: rowData => rowData.Coverage + ((rowData.isToday)?'*':'')
                  },
                  {
                    title: "OPERATIONAL HOURS",
                    field: "TotalOperationalDuration",
                    type: "numeric",
                    grouping: false,
                    headerStyle: { textAlign: "left" },
                    render: rowData => rowData.TotalOperationalDuration + ((rowData.isToday)?'*':'')
                  },
                  // {
                  //   title: "TOTAL EQUIPMENTS",
                  //   field: "eqipments",
                  //   type: "numeric",
                  //   grouping: false,
                  //   headerStyle: { textAlign: "left" },
                  // },
                  // {
                  //   title: "Grass Cut Satisfactory Status", field: "quality",
                  //   render: rowData => (rowData.quality === 'Good') ? 'Yes' : 'No'
                  // },
                  // {
                  //     title: 'Birth Place',
                  //     field: 'birthCity',
                  //     lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                  // },
                ]}
                data={detailTableData}
                onSelectionChange={(rows) => {
                  let dmData = context.data.detailMapCoverageData.filter(d => {
                    let isSelected = false;
                    rows.forEach(r => {
                      if (!isSelected) {
                        let tDate = moment(r.lastCut, 'YYYYMMDD');
                        isSelected = d.date.date.isSame(tDate, 'day');
                      }
                    })
                    return isSelected;
                  })
                  setdetailMapCoverageData(dmData);
                }}
                options={{
                  selection: true,
                  // selectionProps: {
                  //   color: 'primary'
                  // },
                  toolbar: false,
                  search: false,
                  // grouping: true,
                  // exportAllData: true,
                  // exportButton: true,
                  headerStyle: {
                    fontSize: "12px",
                    fontFamily: "Karla",
                    color: "#698D8D",
                    backgroundColor: "#F1FCFC",
                    padding: "4px 12px",
                  },
                  cellStyle: {
                    fontSize: "12px",
                    fontFamily: "Karla",
                    fontWeight: "bold",
                    padding: "4px 12px",
                  },
                  rowStyle: {
                    height: "auto",
                  },
                  // searchFieldStyle: {
                  //   // color: 'red'
                  // },
                  pageSize: 5,
                }}
              />
            </div>}

          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
