import React, {PureComponent} from 'react';
import {styled} from 'baseui';
import {Slider} from 'baseui/slider';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';

const engine = new Styletron();
const PositionContainer = styled('div', ({$theme}) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: '40px',
  width: '100%'
}));

const CenterContainer = styled('div', ({$theme}) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '40%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Container = styled('div', {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    bottom: '20px',
    width: '100%'
  });

const ThumbValue = styled('div', ({$theme}) => ({
  position: 'absolute',
  top: '-3em',
  //width:'75px',
  textAlign: 'center',
  background: 'black',
  color: 'white',
  borderRadius: '5px',
  width: '130px'
}));

const TickBar = styled('div', ({$theme}) => ({
  width: '640px',
  height: '24px',
  maxWidth: '80vw',
  // backgroundColor : 'rgb(0, 80, 179)'
}));
export default class RangeInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false
    };
    this._renderThumbValue = this._renderThumbValue.bind(this);
    this._animate = this._animate.bind(this);
    this._toggle = this._toggle.bind(this);
    this._animationFrame = null;
  }
  componentWillUnmount() {
    cancelAnimationFrame(this._animationFrame);
  }
  _toggle() {
    cancelAnimationFrame(this._animationFrame);
    const {isPlaying} = this.state;
    if (!isPlaying) {
      this._animate();
    }
    this.setState({isPlaying: !isPlaying});
  }
  _animate() {
    const {min, max, value, animationSpeed} = this.props;
    const span = value[1] - value[0];
    var stopValue = ''

    let newValueMin = value[0] + animationSpeed;
    let oldValueMin = value[0];
    if (newValueMin + span >= max) {
      newValueMin = min;
    }
    stopValue = value[1] - newValueMin
    this.props.onChange({
      value: [oldValueMin, newValueMin + span]
    });
    if(stopValue === span){
      this.setState({isPlaying: true});
      this._toggle()
    }
    else{
    this._animationFrame = requestAnimationFrame(this._animate);
    }
  }

  _renderThumbValue({$thumbIndex, $value}) {
    const value = $value[$thumbIndex];
    if($thumbIndex === 0){
    return <ThumbValue style={{top: '-1em', fontSize:'10px',textAlign: 'center', borderRadius: '5px',width: '60px', height:'23px', position :'absolute'}} >{this.props.formatLabel(value)}</ThumbValue>
    }
    else if ($thumbIndex === 1){
        return <ThumbValue style={{top: '-1em', fontSize:'10px', textAlign: 'center', borderRadius: '5px',width: '60px', height:'23px', position :'absolute'}} >{this.props.formatLabel(value)}</ThumbValue>
    }
  }

  render() {
    const {value, min, max} = this.props;
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
        <PositionContainer>
          <CenterContainer>
            {((this.props.max) && (this.props.max > this.props.min)) && 
              <div>
              <Slider
                {...this.props}
                overrides={{
                  ThumbValue: this._renderThumbValue,
                  TickBar: () => <TickBar/>
                }}
              />
              </div>
            }  
          </CenterContainer>
        </PositionContainer>
        </BaseProvider>
      </StyletronProvider>
    //   <PositionContainer>
    //     <CenterContainer>
    //       <Slider
    //         {...this.props}
    //         overrides={{
    //           Root : this.props,
    //           ThumbValue: this._renderThumbValue,
    //           TickBar: () => <TickBar />
    //         }}
    //       />
    //     </CenterContainer>
    //   </PositionContainer>
    );
  }
}
