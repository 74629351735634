import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        display: "flex",
        // width: "100%",
        backgroundColor: "rgba(196, 215, 215, 0.2)",
        borderBottom: "solid 1px #97B2B2",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        padding: "8px 12px"
    },
    label: {
        flexGrow: 1,
        overflow: "hidden"
    },
    labelText: {
        fontSize: "12px",
        fontFamily: "Karla",
        color: "#426262"
    },
    value: {
        fontSize: "15px",
        fontFamily: "Karla",
        color: "#213131",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    list: {
        width: '100%',
        minWidth: 260,
        // maxHeight: 300,
        minHeight: 70,
        backgroundColor: theme.palette.background.paper,
    },
    srch: {
        width: '100%',
        minWidth: 260,
        backgroundColor: theme.palette.background.paper,
        padding: '10px'
    },
    litxt: {
        color: '#2C7873',
        fontFamily: "Karla",
    },
    layerbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginBottom: '10px'
    },
    childlist: {
        width: '100%',
        minWidth: 260,
        // maxHeight: 300,
        // minHeight: 70,
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    nested: {
        paddingLeft: theme.spacing(4),
        // height: 35
    },
    liparent: {
        // height: 35
    },
    applybtn: {
        borderRadius: '18px',
        fontSize: '12px',
        color: '#F1FCFC',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#2C7873',
        margin: '0 16px 8px 16px'
    }
}));