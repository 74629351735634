import React, { useState, useEffect } from "react";
// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import ContractorChart from "./ContractorChart";
// import { FeedList } from "./FeedDetails";
import ActiveDevicesTable from "./ActiveDevicesTable";
import CustomDateRangeFilter from "../../components/CustomDateRangeFilter";
// import DeviceMap from "./DeviceMap";
import ClusterMap from "./components/ClusterMap"
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import Refresh from "@material-ui/icons/Refresh";
import { useDeviceDetailsState, getDevices, getGPSData, getalldeviceSession } from "../../context/DeviceDetailsContext";
import SessionReportTable from "./SessionReportTable";

const fontStyle = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "19.68px",
};

const useStyles = makeStyles(({ palette }) => {
  return createStyles({
    root: {
      // flexGrow: 1,
      // marginTop: "20px",
      padding: 20,
      backgroundColor: "#F1FCFC",
    },
    paper: {
      // color: "#2c7873",
      height: "300px",
      textAlign: "center",
      color: palette.text.secondary,
    },
    paperHeader: {
      ...fontStyle,
      color: "#2c7873",
      textAlign: "center",
      borderBottom: "1px solid #c4d7d7",
      padding: "10px",
    },
    tabHeader: {
      ...fontStyle,
    },
    activeTableContainer: {
      overflow: "visible",
    },
    mapsContainer: {
      position: "relative",
      background: "#000",
      height: "calc(100% - 48px)",
    },
    mapLoader: {
      justifyContent: "center",
      paddingTop: "15%"
    },
    tableLoader :{
      justifyContent: "center",
      alignItems : "center",
      textAlign: 'center',
      paddingTop: "10%",
      paddingBottom : "10%"
    },
    refreshbtn: {
      borderRadius: '18px',
      fontSize: '14px',
      padding: '4px 12px',
      color: '#2C7873',
      fontFamily: 'Karla',
      fontWeight: 'bold',
      backgroundColor: '#F1FCFC',
      position: 'relative',
      marginTop: '-30px',
      float: 'left'
    },
    tabTitle: {
      flexGrow: 1,
      fontSize: '17px',
      fontFamily: 'Rubik',
      fontWeight: 'normal',
      color: '#2C7873',
    }
  });
});

export default function DeviceDetails(props) {
  const classes = useStyles();
  const context = useDeviceDetailsState();

  const [value, setValue] = React.useState(0);
  const [devices, setdevices] = useState([])
  const [gpsData, setgpsData] = useState([])
  const [selectedRange, setselectedRange] = useState([])
  const [isselectedRange, setisselectedRange] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [isSessionsLoading, setisSessionsLoading] = useState(true)
  const [deviceSessionDataTable, setdeviceSessionDataTable] = useState([]);
  const [selectedMainTab, setSelectedMainTab] = React.useState(0);

  let handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getDevices(context.setData);

  }, [context.setData]);

  useEffect(() => {
    //console.log('context', context);
    if (context.data.devices) {
      //setdevices(context.data.devices.results)
      let interval;
      getGPSData(context.setData, context.data.devices.results);
      interval = setInterval(() => {
        //console.log('getGPSData');
        getGPSData(context.setData, context.data.devices.results);
      }, 30000);

      return () => {
        clearInterval(interval);
        //console.log('clear getGPSData');
      };

    }
    // eslint-disable-next-line
  }, [context.data.devices]);

  useEffect(() => {
    if (context.data.gpsData) {
      // console.log('context.data.gpsData', context.data.gpsData);
      let gData = context.data.gpsData.map((item, index) => {
        return {
          "coordinates": [parseFloat(item.devicelocation.long), parseFloat(item.devicelocation.lat)],
          "name": item.device_name,
          "aliasName": item.alias_name,
          feed_status: item.feed_status,
          is_active: item.is_active,
          last_seen: item.last_seen
        }
      });
      setgpsData(gData)
      setisLoading(false)
    }
  }, [context.data.gpsData]);

  useEffect(() => {
    if (context.data.allDevices) {
      let gData = context.data.allDevices
      setdevices(gData)
      setisLoading(false)
    }
  }, [context.data.allDevices]);

  const onRefreshData = () => {
    getDevices(context.setData);
  };

  useEffect(() => {
    if (context.setData && selectedRange && isselectedRange) {
      getalldeviceSession(context.setData, selectedRange)
    }
  }, [context.setData, selectedRange]);

  useEffect(() => {
    if (context.data.summaryData) {
      let sampleArr = []
      if(context.data.summaryData && context.data.summaryData.length > 0){
        context.data.summaryData.map((item, index) => {
          require('d3-request').csv(item, (error, response) => {
            if (!error) {
              if (response) {
                sampleArr.push(response)
              } else {
                setdeviceSessionDataTable([]);
                setisselectedRange(false)
                setisSessionsLoading(false)
              }
            }
            else {
              setdeviceSessionDataTable([]);
              setisselectedRange(false)
              setisSessionsLoading(false)
            }
            Promise.all(sampleArr).then((values) => {
              setdeviceSessionDataTable(values);
              setisselectedRange(false)
              setisSessionsLoading(false)
            })
          })
        });
      }
      else{
              setdeviceSessionDataTable([]);
              setisselectedRange(false)
              setisSessionsLoading(false)
      }
    }
  }, [context.data.summaryData]);

  useEffect(() => {
    if (deviceSessionDataTable) {
      setdeviceSessionDataTable(deviceSessionDataTable);
    }
  }, [deviceSessionDataTable]);

  const onDateChange = (dateRange) => {
    if (!isselectedRange) {
      //console.log('onDateChange', dateRange)
      setisSessionsLoading(true)
      setselectedRange(dateRange);
      setisselectedRange(true)
    }
  }
  const handleTabChange = (event, newValue) => {
    setSelectedMainTab(newValue);
  };
  return (
    <div className={classes.root}>
      {/* <Container maxWidth="lg"> */}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper} elevation={3}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              centered
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderRadius: "0px 0px 4px 4px",
                  transform: "matrix(1, 0, 0, -1, 0, 0)",
                },
              }}
            >
              <Tab label="CONTRACTOR" className={classes.tabHeader} />
              {/* <Tab label="FEEDS" className={classes.tabHeader} /> */}
            </Tabs>
            <div>
              <TabPanel value={value} index={0}>
                <ContractorChart />
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
                <FeedList />
              </TabPanel> */}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8} style={{ zIndex: 11 }}>
          <Paper className={classes.paper} elevation={3}>
            <div className={classes.paperHeader}>REAL TIME MAPS</div>
            <div className={classes.mapsContainer} id="rt-map">
              {isLoading === false ?
                <div>
                  {/* <DeviceMap /> */}
                  {gpsData && gpsData.length > 0 && <ClusterMap data={gpsData} />}
                </div>
                : <div className={classes.mapLoader} ><CircularProgress /></div>}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Tabs
              value={selectedMainTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
              centered
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderRadius: "0px 0px 4px 4px",
                  transform: "matrix(1, 0, 0, -1, 0, 0)",
                },
              }}
            >
              <Tab label="ACTIVE DEVICES" className={classes.tabTitle} />
              <Tab label="SESSIONS OVERVIEW" className={classes.tabTitle} />
            </Tabs>
            <TabPanel value={selectedMainTab} index={0}>
              <div style={{ display: 'block', width: '100%' }} >
                <div className={classes.paperHeader}>
                  <div style={{ paddingTop: '30px' }}>
                    <div>
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.refreshbtn}
                        onClick={onRefreshData}
                      >
                        <Refresh style={{ marginRight: "12px" }}></Refresh> REFRESH DATA
                  </Button>
                    </div>
                  </div>
                </div>
                <div className={classes.activeTableContainer}>
                  <ActiveDevicesTable devices={devices} />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={selectedMainTab} index={1}>
              <div style={{ display: 'block', width: '100%' }} >
                <div className={classes.paperHeader}>
                  <div style={{ paddingTop: '0px' }}>
                    <div style={{ width: '30%' }}>
                      <CustomDateRangeFilter label="Duration"
                        className={classes.filter}
                        onDateChange={onDateChange}></CustomDateRangeFilter>
                    </div>
                  </div>
                </div>
                {isSessionsLoading === false ?
                <div className={classes.activeTableContainer}>
                  {deviceSessionDataTable && deviceSessionDataTable.length >0  ?
                          <SessionReportTable session={deviceSessionDataTable} /> :
                  <div style={{ justifyContent: 'center', textAlign: 'center', fontFamily: 'Karla', fontWeight: "bold", marginTop: '10%', marginBottom :'10%' }} > No data to display  </div>}      
                </div> : <div className={classes.tableLoader} ><CircularProgress /></div>}
              </div>
            </TabPanel>
          </Paper>
        </Grid>

      </Grid>
      {/* </Container> */}
    </div>
  );
}
