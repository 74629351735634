import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Themes from "./themes";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";

import { SnackbarProvider } from 'notistack';
import { ConfirmDialogProvider } from "./context/ConfirmDialogContext";

// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';

import { config } from './config';

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";
const token = localStorage.getItem("id_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "JWT " + token;
}

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000}>
          <ConfirmDialogProvider>
            <App />
          </ConfirmDialogProvider>
        </SnackbarProvider>
        {/* </MuiPickersUtilsProvider> */}
      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>
  ,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
