import React from "react";
import axios from "axios";
import moment from 'moment'
// import {config} from "../config"

const DeviceDetailsContext = React.createContext();


const rootReducer = (state, action) => {
    switch (action.type) {
        case "GET_DEVICES":
            return {
                ...state,
                isLoaded: true,
                devices: action.payload,
                error: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                devices: null,
                error: action.payload
            };
        case "GET_GPS_DATA":
            return {
                ...state,
                gpsData: action.payload,
                error: ''
            };
        case "GET_ALL_DEVICES_DATA":
            return {
                ...state,
                isLoaded: true,
                allDevices: action.payload,
                error: ''
            };
        case "GET_ALL_DEVICES_DATA_ERROR":
            return {
                ...state,
                isLoaded: true,
                allDevices: null,
                error: action.payload
            };
        case "GET_SUMMARY_DATA":
            return {
                ...state,
                summaryData: action.payload,
                error: ''
            };
        case "GET_SUMMARY_DATA_ERROR":
            return {
                ...state,
                summaryData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

const DeviceDetailsProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        devices: null,
        gpsData: null,
        summaryData: null,
        error: ''
    });
    return (
        <DeviceDetailsContext.Provider value={{ data, setData }}>
            {children}
        </DeviceDetailsContext.Provider>
    );
};

const useDeviceDetailsState = () => {
    const context = React.useContext(DeviceDetailsContext);
    return context;
};

export function getDevices(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}
function calculateDaysBetweenDates(startDate, endDate) {
    let date = []
    while (moment(startDate) <= moment(endDate)) {
        date.push(startDate);
        startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
    }
    return date;
}

export function getalldeviceSession(dispatch, dateRange) {
    // let dateArr = [];
    let reqArr = [];
    let reqDetailArr = [];
    let noOfDays = moment(dateRange[1]).diff(dateRange[0], 'days') + 1;

    let startDate = moment(dateRange[0]);
    // let endDate = moment(dateRange[1]);
    let isToday = startDate.isSame(moment(), 'day');
    let dateListArr = calculateDaysBetweenDates(moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD'))
    if (isToday) {
        return null
    } else if (noOfDays === 1) {
        // let formattedDate = startDate.format('YYYYMMDD');
        let startdateQuery = startDate.format('YYYY-MM-DD')
        reqArr.push(axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?file_created_at=${startdateQuery}&file_type=device_session_summary`)
            .then(res => {
                let fData = res.data.results.filter(l => {
                    return l.file_type === 'device_session_summary' && l.file_name.includes("all-devices")
                });
                return fData;
            })
            .catch(err => {
                return null; //err.response;
            })
        );
    }
    else {
        dateListArr.forEach((date, index) => {
            reqArr.push(axios.get(`/${localStorage.getItem("organization")}/get-processed-data/?file_created_at=${date}&file_type=device_session_summary`)
                .then(res => {
                    let fData = res.data.results.filter(l => {
                        return l.file_type === 'device_session_summary' && l.file_name.includes("all-devices");
                    });
                    return fData;
                })
                .catch(err => {
                    return null;
                })
            );
        })

    }

    // let result = []
    axios.all(reqArr).then(axios.spread((...responses) => {
        // result = responses;
        let resultArr = []
        for (let r = 0; r < responses.length; r++) {
            if (responses[r].length > 0) {
                var detailResp = responses[r]
                if (detailResp) {
                    for (let i = 0; i < detailResp.length; i++) {
                        reqDetailArr.push(axios.get(`/${localStorage.getItem("organization")}/get-processed-data/${detailResp[i].id}`)
                            .then(res => {
                                return res.data;
                            })
                            .catch(err => {
                                return null;
                            }))
                    }
                }

            }
        }
        axios.all(reqDetailArr).then(axios.spread((...detailResponses) => {
            if (detailResponses) {
                for (let d = 0; d < detailResponses.length; d++) {
                    var detailData = detailResponses[d]
                    if (detailData) {
                        resultArr.push(detailData.file_s3_url)
                    }
                }
                dispatch({ type: "GET_SUMMARY_DATA", payload: resultArr });
            }
        }))


    })).catch(err => {
        dispatch({ type: "GET_SUMMARY_DATA_ERROR", payload: err });
    });


}

export function getGPSData(dispatch, devicedata) {
    // return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {

    //     let devicedata = res.data.results;
    if (devicedata) {
        axios.get(`/${localStorage.getItem("organization")}/devices-metas`).then(res => {
            let devicesMeta = res.data.results;
            let gpsdevices = devicedata.map(d => {

                let mdata = devicesMeta.filter(m => m.device_id === d.device_id)
                if (mdata && mdata.length > 0) {
                    d.meta = mdata[0];
                    if (mdata[0].last_gps && mdata[0].last_gps.coordinates && mdata[0].last_gps.coordinates.length === 2) {
                        d.devicelocation = { lat: mdata[0].last_gps.coordinates[1], long: mdata[0].last_gps.coordinates[0] }
                        d.is_active = mdata[0].is_active;
                        d.last_seen = mdata[0].last_seen_gps;
                        d.calibrated_height = mdata[0].calibrated_height
                    }
                }
                return d;
            })
            let allDevices = gpsdevices
            gpsdevices = gpsdevices.filter(d => d.devicelocation);
            dispatch({ type: "GET_GPS_DATA", payload: gpsdevices });
            dispatch({ type: "GET_ALL_DEVICES_DATA", payload: allDevices });
        });
    }

    // });
    // return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
    //     // dispatch({ type: "UPDATE_DEVICES", payload: res.data });

    //     let devicedata = res.data.results;
    //     if (devicedata) {
    //         let reqArr = [];
    //         for (let i = 0; i < devicedata.length; i++) {
    //             // getFeeds(devicedata[i].device_id)
    //             //reqArr.push(axios.get(`/${localStorage.getItem("organization")}/feeds?device__device_id=${devicedata[i].device_id}`))
    //             reqArr.push(axios.get(`/${localStorage.getItem("organization")}/data-full/?device_id=${devicedata[i].device_id}&limit=2`))

    //         }
    //         axios.all(reqArr).then(axios.spread((...responses) => {
    //             let gpsdevices = []
    //             for (let r = 0; r < responses.length; r++) {
    //                 let res = responses[r];
    //                 if (res.data.results && res.data.results[0] && devicedata[r].feed_status) {
    //                     let device = devicedata[r];
    //                     if(res.data.results[0].gps && res.data.results[0].gps.coordinates)
    //                     {
    //                         device.devicelocation = {lat: res.data.results[0].gps.coordinates[1], long: res.data.results[0].gps.coordinates[0]}
    //                     }
    //                     else{
    //                         device.devicelocation = {lat: "1.288993868", long: "103.790927742"};  
    //                     }

    //                     gpsdevices.push(device);
    //                 }
    //                 // for (let f = 0; f < res.data.results.length; f++) {
    //                 //     if (res.data.results[f].category == "gps" && devicedata[r].feed_status && res.data.results[f].feed_data.length > 0) {
    //                 //         //action.payload.data.results[f].feed_data[0].value
    //                 //         let device = devicedata[r];
    //                 //         device.devicelocation = JSON.parse(res.data.results[f].feed_data[0].value);

    //                 //         gpsdevices.push(device);
    //                 //     }
    //                 // }
    //             }
    //             dispatch({ type: "GET_GPS_DATA", payload: gpsdevices });
    //             //console.log('devicedata', gpsdevices)
    //         }))
    //     }


    // }).catch(err => {
    //     // signOut(userDispatch,history);
    //     console.log(err);
    // });
}

export { DeviceDetailsProvider, DeviceDetailsContext, useDeviceDetailsState };