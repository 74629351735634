import React, { Component } from 'react';
// import { render } from 'react-dom';
import { _MapContext as MapContext, StaticMap, NavigationControl, FullscreenControl, WebMercatorViewport } from 'react-map-gl';
import { AmbientLight, PointLight, LightingEffect } from '@deck.gl/core';
// import { HexagonLayer } from '@deck.gl/aggregation-layers';
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
// import { ColumnLayer } from '@deck.gl/layers';
// import { greatCircle, point, isobands, polygon, pointGrid, isolines, circle } from '@turf/turf';
// import { MinimizeRounded } from '@material-ui/icons';
import { DataFilterExtension } from '@deck.gl/extensions';
import moment from 'moment'
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w';   // eslint-disable-line

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});
const dataFilter = new DataFilterExtension({
  filterSize: 1,
  fp64: false
});
const pointLight1 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-3.807751, 54.104682, 8000]
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight1, pointLight2 });

// const material = {
//   ambient: 0.64,
//   diffuse: 0.6,
//   shininess: 32,
//   specularColor: [51, 51, 51]
// };

const INITIAL_VIEW_STATE = {
  longitude: 103.8146803,
  latitude: 1.4572969,
  zoom: 18,
  minZoom: 5,
  maxZoom: 22,
  pitch: 15,
  bearing: 0
};

const colorRange = [
  [1, 128, 1]
];

const elevationScale = { min: 0, max: 1 };

const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
  // // Calculate corner values of bounds
  // const pointsLong = points.map(point => point.geometry[0])
  // const pointsLat = points.map(point => point.geometry[1])
  // const cornersLongLat = [
  //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
  //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
  // ]
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({ width: window.innerWidth - 125, height: 300 })
    .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}


// function getCenterPoint(bounding_box) {
//   return [(bounding_box[0][0] + bounding_box[1][0]) / 2, (bounding_box[0][1] + bounding_box[1][1]) / 2];
// }

export default class DetailedMap extends Component {
  static get defaultColorRange() {
    return colorRange;
  }

  constructor(props) {
    super(props);

    // let { data } = props;
    // let currentViewState = {}
    // const timeRange = this._getTimeRange(data);
    // if (data && data.length > 0 && data[0].geometry) {
    //   var lastData = data.length - 1
    //   var BOUNDING_BOX = [
    //     data[0].geometry,
    //     data[lastData].geometry
    //   ];
    //   currentViewState = {
    //     longitude: getCenterPoint(BOUNDING_BOX)[0], latitude: getCenterPoint(BOUNDING_BOX)[1], zoom: 18,
    //     minZoom: 5,
    //     maxZoom: 22,
    //     pitch: 15,
    //     bearing: 0
    //   };
    // }
    // else {
    //   currentViewState = INITIAL_VIEW_STATE
    // }
    this.state = {
      elevationScale: elevationScale.min,
      x: 0,
      y: 0,
      tooltip: {},
      radius: 1.5,
      viewState: INITIAL_VIEW_STATE,
      // timeRange,
      filterValue: null,
      mapStyleType: 'map',
      geoJson: null,
    };
    this._renderhoveredItems = this._renderhoveredItems.bind(this);
    this.selectedMaplayer = (this.props.mapLayers.filter(d => d.checked))[0]
  }

  _renderhoveredItems() {
    const { x, y, tooltip } = this.state;

    if (tooltip && tooltip.points) {

      let pmin = tooltip.points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
      // let mind = tooltip.points.filter(d => d.value == tmin);
      return <div className="tooltip" style={{ left: x, top: y }}>
        {/* <div>Device Type: {mind[0].deviceType}</div> */}
        {/*<div>Device: {pmin.deviceType}</div>*/}
        {/* <div>Grass Height: {pmin.value}cm</div> */}
        <div>Time: {moment(pmin.time).format('DD-MMM-YYYY hh:mm a')}</div>
      </div>
    }
    return null;
  }
  _renderLayers() {
    // radius = 1, upperPercentile = 100, coverage = 2, mapLayers,
    const { data } = this.props;
    const { filterValue, geoJson } = this.state;
    // console.log('geoJson', geoJson)
    let mapData = data;
    let viewLayers = []
    if (geoJson) {
      viewLayers.push(new GeoJsonLayer({
        id: 'geojson',
        data: geoJson,
        //opacity: 0.5,
        stroked: true,
        // filled: true,
        extruded: false,
        // wireframe: false,
        getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
        getFillColor: f => [171, 171, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
        // getLineColor: [255, 255, 255],
        // pickable: true,
        // onHover: this._onHover,
        // onClick: this._onClick
      }))
    }

    if (filterValue) {
      viewLayers.push(new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: mapData,
        pickable: true,
        radiusScale: 0.7,
        radiusMinPixels: 0.5,
        //radius:0.5,
        //opacity: 0.5,
        //stroked: true,
        filled: true,
        //radius: 1.5,
        //elevationScale: 0,
        //getElevation: d => 0,
        getPosition: d => d.geometry,
        //getRadius: d => Math.sqrt(d.exits),
        getFillColor: d => [1, 128, 1],
        getFilterValue: d => d.timeStamp,
        filterRange: [filterValue[0], filterValue[1]],
        extensions: [dataFilter],
        //getLineColor: d => [0, 0, 0]
        // pickable: true,
        // extruded: false,

        // elevationScale: 0,
        // getPosition: d => d.geometry,
        // onHover: ({ object, x, y }) => {
        //   this.setState({ tooltip: object, x, y })
        // },
        // getElevationValue: () => 0,
        // getElevationWeight: () => 0,
        // elevationRange: [0, 0],
        // colorRange: [
        //     [1, 128, 1, 120],
        //     [1, 128, 255, 120],
        //     [255, 128, 1, 120]
        // ],
      })
      )
    }
    return viewLayers;
  }
  componentDidMount() {
    this.modifyGeoJson()
    this.setViewPort();

    if (this.props.filterValue) {
      // console.log('filterValue', this.props.filterValue);
      this.setState({ filterValue: this.props.filterValue });
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.data !== this.props.data) {
    //   if (this.props.data && this.props.data.length > 0) {
    //     const timeRange = this._getTimeRange(this.props.data);
    //     this.setState({ timeRange, filterValue: timeRange });
    //   }
    // }

    if (prevProps.filterValue !== this.props.filterValue) {
      // console.log('filterValue', this.props.filterValue);
      this.setState({ filterValue: this.props.filterValue });
    }

    if (prevProps.locationData !== this.props.locationData) {
      this.modifyGeoJson()
      this.setViewPort();
    }
  }

  modifyGeoJson() {
    let { locationData } = this.props;
    let modifiedJson = [];
    if (locationData) {
      locationData.forEach((item, i) => {
        // console.log('geoJsons item'+i, item.geometry);
        let polygons = []
        if (item && item.geometry && item.geometry.coordinates && item.geometry.coordinates.length > 0) {
          // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
          polygons = item.geometry.coordinates.map(c => {
            let mItem = {
              ...item,
              geometry: {
                type: 'Polygon',
                coordinates: [c]
              },
              parentIndex: i
            }
            return mItem;
          })
        }
        modifiedJson = modifiedJson.concat(polygons);
      });
    }
    // console.log('modifiedJson', modifiedJson);

    this.setState({
      geoJson: {
        "type": "FeatureCollection",
        features: modifiedJson
      }
    });
  }

  setViewPort() {
    // let { data } = this.props;
    let { locationData, data } = this.props;

    let pointsLong;
    let pointsLat;

    if (data && data.length > 0) {
      pointsLong = data.map(point => point.geometry[0])
      pointsLat = data.map(point => point.geometry[1])
    }

    if (locationData && locationData.length>0) {
      locationData.forEach(loc => {
        pointsLong.push(loc.properties.cornersLatLng[0][0], loc.properties.cornersLatLng[1][0])
        pointsLat.push(loc.properties.cornersLatLng[0][1], loc.properties.cornersLatLng[1][1])
      })
    }
    let cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
    ]
    const bounds = getBoundsForPoints(cornersLongLat)
    let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
    this.setState({ viewState: currentViewState });
    
  }

  _getTimeRange(data) {
    if (!data) {
      return null;
    }
    return data.reduce(
      (range, d) => {
        const t = d.timeStamp;
        range[0] = Math.min(range[0], t)
        range[1] = Math.max(range[1], t)
        return range;
      },
      [Infinity, -Infinity]
    );
  }

  render() {
    // const { mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef' } = this.props;

    let mapStyle = '';
    const { mapStyleType } = this.state;
    if (mapStyleType === 'satellite') {
      mapStyle = 'mapbox://styles/mapbox/satellite-v9';
    } else {
      mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef'
    }

    // const pointdata = greatCircle([0, 0], [100, 10])
    // const { timeRange, filterValue } = this.state;

    // const { data } = this.props;

    // let currentViewState = {}
    // if (data && data.length > 0 && data[0].geometry) {
    //   currentViewState = { longitude: data[0].geometry[0], latitude: data[0].geometry[1], zoom: 16 };
    // }
    //this.setState({viewState: currentViewState});

    return (
      <DeckGL
        layers={this._renderLayers()}
        effects={[lightingEffect]}
        initialViewState={INITIAL_VIEW_STATE}
        viewState={this.state.viewState}
        getTooltip={({ object }) => object && `Time :${object.time}\n Grass Height:${object.value}\n Accuracy: ${object.accuracy}`}
        // onViewStateChange={(viewState) => {
        //   // you can manipulate the viewState here if needed
        //   // currentViewState = viewState;
        //   this.setState({viewState: viewState});
        //   // deck.setProps({viewState: currentViewState});
        // }}
        onViewStateChange={(v) => {
          this.setState({ viewState: v.viewState });

        }}
        controller={true}
        ContextProvider={MapContext.Provider}
      >
        <StaticMap
          reuseMaps
          mapStyle={mapStyle}
          preventStyleDiffing={true}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
        </StaticMap>

        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
            onClick={() => this.setState({ mapStyleType: 'satellite' })}
            hidden={mapStyleType === 'satellite'} >
            <SatelliteIcon variant="filled"></SatelliteIcon>
          </div>
          <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
            onClick={() => this.setState({ mapStyleType: 'map' })}
            hidden={mapStyleType === 'map'}>
            <MapIcon variant="filled"></MapIcon>
          </div>
          <FullscreenControl container={document.getElementById('detailedmap')} />
          <NavigationControl showCompass={false} />
        </div>

        {this._renderhoveredItems}
      </DeckGL>
    );
  }
}
