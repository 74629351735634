import React from "react";
import { BarChart, Bar, XAxis, Tooltip, Legend, LabelList } from "recharts";

const data = [
  { name: "Soon Sin", type1: 7, type2: 1, zone: '(North West)'},
  { name: "Ho Eng Huat", type1: 4, type2: 2, zone: '(South West)'},
  { name: "Yong Beng Huat", type1: 4, type2: 0, zone: '(South West)'},
  { name: "BNL Service", type1: 4, type2: 0, zone: '(Botanic Gardens)'},
  { name: "Kim-Bock", type1: 4, type2: 1, zone: '(Bishan Park)'}
];

let mapping = {
  type2: "Mower",
  // type2: "Tractor",
  type1: "Knapp-sack",
};

const renderMappedLegend = (value, entry) => {
  return <span>{mapping[value]}</span>;
};

const formatToolTipLabel = (value, name, props) => {
  // console.log(value, name)
  return [value, mapping[name]];
};

const formatToolTipTitle = (value, name, props) => {
  // console.log(value, name)
  
  return `${value} ${data.filter(d=>d.name===value)[0].zone}`;
};


let ContractorChart = (props) => {
  return (
    <BarChart
      width={300}
      height={230}
      data={data}
      margin={{ top: 20, right: 0, left: 0, bottom: 10 }}
    >
      <XAxis dataKey="name" tickCount={5} interval={0} hide={true} height={50} angle={-45} type="category">
      {/* <LabelList dataKey="name" position="center" angle="-90"  /> */}
      </XAxis>
      {/* mirror={true} */}

  <Tooltip itemStyle={{ color: "#000" }} formatter={formatToolTipLabel} labelFormatter={formatToolTipTitle}/>
      <Legend verticalAlign="top" formatter={renderMappedLegend} />
      <Bar dataKey="type1" stackId="a" fill="#C0FFB3">
        <LabelList dataKey="name" position="center" angle={-90} style={{fill:'#6E6E6E'}} />
      </Bar>
      <Bar dataKey="type2" stackId="a" fill="#9399FF">
        {/* <LabelList dataKey="name" position="center" angle={-90} style={{fill:'#6E6E6E'}} /> */}
      </Bar>
      {/* <Bar dataKey="type3" stackId="a" fill="#5EDFFF" /> */}
    </BarChart>
  );
};

export default ContractorChart;
