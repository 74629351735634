import React, { Component } from 'react';
// import { render } from 'react-dom';
import { StaticMap, NavigationControl, FullscreenControl, _MapContext as MapContext, WebMercatorViewport } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import { LightingEffect, AmbientLight, _SunLight as SunLight } from '@deck.gl/core';
// import { scaleThreshold } from 'd3-scale';
// import { area } from '@turf/turf';
import moment from 'moment'
// import Button from "@material-ui/core/Button";
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';

// Set your mapbox token here
const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXR1bGt1bXIiLCJhIjoiY2xyYWNzN2JoMDhyazJsbnh3MWR1aWU2MCJ9.BDBKCFichBcahbwbDOxmjA' //'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w'; //process.env.MapboxAccessToken; // eslint-disable-line

// // Source data GeoJSON
// const DATA_URL =
//     'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/geojson/vancouver-blocks.json'; // eslint-disable-line

// export const COLOR_SCALE = scaleThreshold()
//     .domain([-0.6, -0.45, -0.3, -0.15, 0, 0.15, 0.3, 0.45, 0.6, 0.75, 0.9, 1.05, 1.2])
//     .range([
//         [65, 182, 196],
//         [127, 205, 187],
//         [199, 233, 180],
//         [237, 248, 177],
//         // zero
//         [255, 255, 204],
//         [255, 237, 160],
//         [254, 217, 118],
//         [254, 178, 76],
//         [253, 141, 60],
//         [252, 78, 42],
//         [227, 26, 28],
//         [189, 0, 38],
//         [128, 0, 38]
//     ]);

const INITIAL_VIEW_STATE = {
    longitude: 103.8146803,
    latitude: 1.3572969,
    // longitude: 103.85945279634636, latitude: 1.3000360700768983,
    // longitude:103.81506192440402, latitude:1.461421158707049,
    zoom: 11,
    maxZoom: 20,
    pitch: 0,
    bearing: 0
}

const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 1.0
});

const dirLight = new SunLight({
    timestamp: Date.UTC(2019, 7, 1, 22),
    color: [255, 255, 255],
    intensity: 1.0,
    _shadow: true
});

// const landCover = [[[-123.0, 49.196], [-123.0, 49.324], [-123.306, 49.324], [-123.306, 49.196]]];

// let deviceTypes = ["Lawn Mower", "Tractor", "KnapSack"]
// let contractors = ["Tai Sun", "Edwards"]
// let qualities = ["Good", "Medium", "Bad"]
const colorArr = {
    "Good": [99, 194, 146],
    "Medium": [255, 186, 90],
    "Bad": [235, 69, 89]
}

export default class BaseMap extends Component {
    constructor(props) {
        super(props);


        this.state = {
            hoveredObject: null,
            viewState: INITIAL_VIEW_STATE,
            geoJson: null,
            mapStyleType: 'map'
        };
        this._onHover = this._onHover.bind(this);
        this._onClick = this._onClick.bind(this);
        this._renderTooltip = this._renderTooltip.bind(this);


        const lightingEffect = new LightingEffect({ ambientLight, dirLight });
        lightingEffect.shadowColor = [0, 0, 0, 0.5];
        this._effects = [lightingEffect];

        // this.classes = {
        //     row: {
        //         display: 'flex'
        //     },
        //     rowlabel: {
        //         flexGrow: 1
        //     }
        // }
    }

    _onHover({ x, y, object }) {
        // if(object){
        this.setState({ x, y, hoveredObject: object });
        // }
    }

    _onClick(event) {
        // console.log('click', event);
        this.setState({ hoveredObject: null });
        this.props.onAreaSelected(this.props.geoJsons[event.object.parentIndex]);
    }
    _renderLayers() {
        // const {data = DATA_URL} = this.props;
        const { geoJson } = this.state;
        // let modifiedJson = [];
        // geoJsons.forEach((item, i) => {
        //     console.log('geoJsons item'+i, item.geometry);
        //     let polygons = []
        //     if (item && item.geometry && item.geometry.coordinates && item.geometry.coordinates.length>0) {
        //         // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
        //         polygons = item.geometry.coordinates.map(c => {
        //             let mItem = { ...item,
        //                 geometry: {
        //                     type: 'Polygon',
        //                     coordinates: [c]
        //                 },
        //                 parentIndex: i 
        //              }
        //             return mItem;
        //         })
        //     }
        //     modifiedJson = modifiedJson.concat(polygons);
        // });
        // console.log('modifiedJson', modifiedJson);

        // const modifiedJson = geoJsons.map((item,index)=>{
        //     item.geom.type = 'Polygon'
        //     item.type = 'Polygon'
        //     item.contractor = contractors[Math.floor(Math.random() * Math.floor(2))]
        //     item.quality = qualities[Math.floor(Math.random() * Math.floor(3))]
        //     return {
        //         type: 'Feature',
        //         properties: item,
        //         geometry: {
        //             type : 'Polygon',
        //             coordinates: item.geom.coordinates.map(item=> item[0])
        //         }
        //     }
        // });

        // const data = {
        //     "type": "FeatureCollection",
        //     features: modifiedJson
        // }
        // const data = multijson; //mygeojson;

        // console.log('data', data);

        if (!this.props.isDetailView) {
            return [
                // only needed when using shadows - a plane for shadows to drop on
                //   new PolygonLayer({
                //     id: 'ground',
                //     data: landCover,
                //     stroked: false,
                //     getPolygon: f => f,
                //     getFillColor: [0, 0, 0, 0]
                //   }),
                new GeoJsonLayer({
                    id: 'geojson',
                    data: geoJson,
                    //opacity: 0.5,
                    stroked: true,
                    // filled: true,
                    extruded: false,
                    // wireframe: false,
                    getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                    getFillColor: f => colorArr[f.properties.quality], //[10, 207, 131, 255], //f.properties.color,
                    // getLineColor: [255, 255, 255],
                    pickable: true,
                    onHover: this._onHover,
                    onClick: this._onClick
                })
            ];
        } else {
            return []
        }
    }

    _renderTooltip() {
        const { x, y, hoveredObject } = this.state;
        // console.log('hoveredObject', hoveredObject)
        // return null;
        return (
            hoveredObject && (
                <div className="tooltip" style={{ top: y, left: x }}>
                    <div style={{ minWidth: 200 }}>
                        {/* <b>{hoveredObject.properties.name}</b> */}
                        {/* <div>{(props.filters.locations.filter(item=>item.id==hoveredObject.properties.id))[0].label}</div> */}
                        <div className="title">{hoveredObject.properties.loc_desc}</div>
                        <div className="drow"><div className="drowlabel">Agency</div><div className="drowvalue">{hoveredObject.properties.agency}</div></div>
                        <div className="drow"><div className="drowlabel">Branch CD</div><div className="drowvalue">{hoveredObject.properties.branch_cd}</div></div>
                        {/* <div className="drow"><div className="drowlabel">Eve ID</div><div className="drowvalue">{hoveredObject.properties.eve_id}</div></div> */}
                        <div className="drow"><div className="drowlabel">Total Area</div><div className="drowvalue">{hoveredObject.properties.grass_cut} Ha</div></div>
                        <div className="drow"><div className="drowlabel">Coverage</div><div className="drowvalue">-</div></div>
                        {/* <div className="drow"><div className="drowlabel">Coverage</div><div className="drowvalue">{hoveredObject.properties.coverage} Ha ({hoveredObject.properties.coveragePercent}%) Ha</div></div> */}
                        <div className="drow"><div className="drowlabel">Quality of Cut</div><div className="drowvalue">{hoveredObject.properties.quality}</div></div>
                        <div className="drow"><div className="drowlabel">Contractor Name</div><div className="drowvalue">{hoveredObject.properties.contractor}</div></div>
                        <div className="drow"><div className="drowlabel">Zone</div><div className="drowvalue">{hoveredObject.properties.zone}</div></div>
                        <div className="drow"><div className="drowlabel">Location ID</div><div className="drowvalue">{hoveredObject.properties.loc_cd}</div></div>
                        <div className="drow"><div className="drowlabel">Operational Hours</div><div className="drowvalue">{hoveredObject.properties.operationalhrs} hrs</div></div>
                        {/* <div className="drow"><div className="drowlabel">Number of equipments</div><div className="drowvalue">2</div></div> */}
                        <div className="drow"><div className="drowlabel">Last Cut</div><div className="drowvalue">{moment(hoveredObject.properties.lastCut).format('DD-MM-YYYY')}</div></div>
                        {/* <div className="drow"><div className="drowlabel">Decription</div></div><div><div className="drowvalue">{hoveredObject.properties.loc_desc}</div></div> */}
                        {/* <ul>
                            {hoveredObject.properties.equipments.list.map(item => {
                                return <div className="drow"><div className="drowlabel">{item.type}</div><div className="drowvalue">{item.count}</div></div>
                            })}
                        </ul> */}
                    </div>
                </div>
            )
        );
    }

    componentDidUpdate(prevProps) {

        if (prevProps.geoJsons !== this.props.geoJsons) {

            this.modifyGeoJson()
            this.setViewPort();
        }

        if(prevProps.mapStyleType!==this.props.mapStyleType){
            this.setState({ mapStyleType: this.props.mapStyleType })
        }
    }
    componentDidMount() {
        this.modifyGeoJson()
        this.setViewPort();

        if(this.props.mapStyleType){
            this.setState({ mapStyleType: this.props.mapStyleType })
        }
    }

    modifyGeoJson() {
        let { geoJsons } = this.props;
        let modifiedJson = [];
        geoJsons.forEach((item, i) => {
            // console.log('geoJsons item'+i, item.geometry);
            let polygons = []
            if (item && item.geometry && item.geometry.coordinates && item.geometry.coordinates.length > 0) {
                // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
                polygons = item.geometry.coordinates.map(c => {
                    let mItem = {
                        ...item,
                        geometry: {
                            type: 'Polygon',
                            coordinates: [c]
                        },
                        parentIndex: i
                    }
                    return mItem;
                })
            }
            modifiedJson = modifiedJson.concat(polygons);
        });
        // console.log('modifiedJson', modifiedJson);

        this.setState({
            geoJson: {
                "type": "FeatureCollection",
                features: modifiedJson
            }
        });
    }
    setViewPort() {
        // console.log('setPort');
        let { geoJsons } = this.props;

        if (geoJsons && geoJsons.length > 0) {
            let cornersLatLng = [[+Infinity, +Infinity], [-Infinity, -Infinity]];
            cornersLatLng = geoJsons.reduce((res, loc) => {
                res[0][0] = loc.properties.cornersLatLng[0][0] < res[0][0] ? loc.properties.cornersLatLng[0][0] : res[0][0];
                res[0][1] = loc.properties.cornersLatLng[0][1] < res[0][1] ? loc.properties.cornersLatLng[0][1] : res[0][1];
                res[1][0] = loc.properties.cornersLatLng[1][0] > res[1][0] ? loc.properties.cornersLatLng[1][0] : res[1][0];
                res[1][1] = loc.properties.cornersLatLng[1][1] > res[1][1] ? loc.properties.cornersLatLng[1][1] : res[1][1];

                return res;
            }, cornersLatLng)

            // console.log('cornersLatLng', cornersLatLng);
            try {
                const viewport = new WebMercatorViewport({ width: window.innerWidth - 84, height: 600 })
                    .fitBounds(cornersLatLng, { padding: 50 }) // Can also use option: offset: [0, -100]

                let currentViewState = { longitude: viewport.longitude, latitude: viewport.latitude, zoom: viewport.zoom, transitionDuration: 1000, bearing: 0, pitch: 15 };
                this.setState({ viewState: currentViewState });
            } catch (Err) {
                console.log('cornersLatLng Err', Err);
            }

        }
    }

    render() {
        // const {mapStyle = 'mapbox://styles/mapbox/light-v9'} = this.props;

        // const mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef';
        let mapStyle = '';
        const { mapStyleType } = this.state;
        if (mapStyleType === 'satellite') {
            mapStyle = 'mapbox://styles/mapbox/satellite-v9';
        } else {
            mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef'
        }

        return (
            <DeckGL
                layers={this._renderLayers()}
                //effects={this._effects}
                initialViewState={INITIAL_VIEW_STATE}
                controller={true}
                ContextProvider={MapContext.Provider}
                viewState={this.state.viewState}
                onViewStateChange={(v) => {
                    this.setState({ viewState: v.viewState });
                }}
            >
                <StaticMap
                    reuseMaps
                    mapStyle={mapStyle}
                    preventStyleDiffing={true}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                />

                <div style={{ position: 'absolute', right: 10, top: 10 }}>
                    <div style={{backgroundColor:'#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc'}}
                    onClick={() => this.props.onMapStyleChange('satellite')}
                    hidden={mapStyleType==='satellite'} >
                        <SatelliteIcon variant="filled"></SatelliteIcon>
                    </div>
                    <div style={{backgroundColor:'#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc'}}
                    onClick={() => this.props.onMapStyleChange('map')}
                    hidden={mapStyleType==='map'}>
                        <MapIcon variant="filled"></MapIcon>
                    </div>
                    <FullscreenControl container={document.querySelector('body')} />
                    <NavigationControl showCompass={false} />
                </div>

                {this._renderTooltip}
            </DeckGL>
        );
    }
}

// export default BaseMap
