import React, { Component } from 'react'
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { WebMercatorViewport } from 'react-map-gl';
import moment from 'moment'
import { GeoJsonLayer, ColumnLayer } from '@deck.gl/layers';
import { DataFilterExtension } from '@deck.gl/extensions';
import { ScatterplotLayer, LineLayer, TextLayer } from '@deck.gl/layers';

const google = window.google;

const INITIAL_VIEW_STATE = {
    longitude: 103.8146803,
    latitude: 1.4572969,
    zoom: 15,
    minZoom: 5,
    maxZoom: 22,
    pitch: 15,
    bearing: 0
};

const elevationScale = { min: 0, max: 1 };

const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({ width: window.innerWidth - 84, height: 400 })
        .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport
    return { longitude, latitude, zoom }
}

const dataFilter = new DataFilterExtension({
    filterSize: 1,
    fp64: false
});
export default class GoogleDetailedMap extends Component {
    constructor(props) {
        super(props);
        // this.google_maps_api_key = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0'
        this.api = null;
        this.map = null;
        this.overlay = null;

        this.state = {
            elevationScale: elevationScale.min,
            x: 0,
            y: 0,
            tooltip: {},
            radius: 1.5,
            viewState: INITIAL_VIEW_STATE,
            // timeRange,
            filterValue: null,
            mapStyleType: 'map',
            coverageGeoJson: null,
            geoJson: null,
            toolTipInfo: null,
            avgGrassHeight : null
        };
        this._renderhoveredItems = this._renderhoveredItems.bind(this);
        this.selectedMaplayer = (this.props.mapLayers.filter(d => d.checked))[0]

    }

    // Load the Google Maps Platform JS API async
    // loadScript() {
    //     const GOOGLE_MAPS_API_KEY = 'AIzaSyCpBLidfzWIU79yk3EIVO_VZZEvFCcbLM0';
    //     const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    //     const head = document.querySelector('head');
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = GOOGLE_MAPS_API_URL;
    //     head.appendChild(script);
    //     return new Promise(resolve => {
    //         script.onload = resolve;
    //     });
    // }

    async initMapWithOverlay(options) {
        // Init the Google Maps JS API and base map
        // await this.loadScript();
        this.api = google.maps;
        this.overlay = new GoogleMapsOverlay();
        const mapElement = document.getElementById('devicedetailgmap');
        if (mapElement) {
            this.map = new this.api.Map(mapElement, {
                center: { lng: 103.8146803, lat: 1.4572969 },
                zoom: 12,
                // styles: map_styles
                mapTypeControl: true,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
            });
            // Put the Deck.gl overlay on the map
            this.overlay.setMap(this.map);
        }
      
    }

    // Reposition the map for selected demo
    setMap(map_options) {
        this.map.setCenter(map_options.center);
        this.map.setZoom(map_options.zoom);
    }

    setLayer(deckgl_layers) {
        this.overlay.setProps({ layers: deckgl_layers });
    }

    componentDidMount() {
        this.initMapWithOverlay();

        this.modifyGeoJson();
        this.modifyCoverageGeoJson();
        this.setViewPort();

        if (this.props.filterValue) {
            // console.log('filterValue', this.props.filterValue);
            this.setState({ filterValue: this.props.filterValue });
            if(this.props.data){
                var filter = this.props.data.filter(f => f.timeStamp > this.props.filterValue[0] && f.timeStamp < this.props.filterValue[1])
                var totalRecords = 0
                var totalGrassHeight = 0
                filter.map((data, index) => {
                    if (data.value !== null) {
                        totalGrassHeight = totalGrassHeight + parseFloat(data.value)
                        totalRecords = totalRecords + 1
                    }
                })
                var avgGrassHeightVal = parseFloat(totalGrassHeight / totalRecords)
                this.setState({ avgGrassHeight: avgGrassHeightVal.toFixed(2) })
            }
        } 
    }

    componentDidUpdate(prevProps) {

        if (prevProps.locationData !== this.props.locationData) {
            this.modifyGeoJson()
            this.setViewPort();
        }

        if (prevProps.data !== this.props.data) {
            this.overlay.setProps({ layers: this._renderLayers() });
        }
        if (prevProps.coverage !== this.props.coverage) {
            this.modifyCoverageGeoJson();
        }
        if (prevProps.filterValue !== this.props.filterValue) {
            // console.log('filterValue', this.props.filterValue);
            this.setState({ filterValue: this.props.filterValue },()=>{
                this.overlay.setProps({ layers: this._renderLayers() });
            });
            if(this.props.data){
                var filter = this.props.data.filter(f => f.timeStamp > this.props.filterValue[0] && f.timeStamp < this.props.filterValue[1])
                var totalRecords = 0
                var totalGrassHeight = 0
                filter.map((data, index) => {
                    if (data.value !== null) {
                        totalGrassHeight = totalGrassHeight + parseFloat(data.value)
                        totalRecords = totalRecords + 1
                    }
                })
                var avgGrassHeightVal = parseFloat(totalGrassHeight / totalRecords)
                this.setState({ avgGrassHeight: avgGrassHeightVal.toFixed(2) })
                
                let pointsLong;
                let pointsLat;

                if (filter && filter.length > 0) {
                    pointsLong = filter.map(point => point.geometry[0])
                    pointsLat = filter.map(point => point.geometry[1])
                
                let cornersLongLat = [
                    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
                    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
                ]
                const bounds = getBoundsForPoints(cornersLongLat)
                let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
                this.setState({ viewState: currentViewState });

                this.map.setCenter({ lng: bounds.longitude, lat: bounds.latitude });
                this.map.setZoom(bounds.zoom);
                }
            }
        }
    }

    _renderhoveredItems() {
        const { x, y, tooltip, avgGrassHeight } = this.state;
        if (tooltip && tooltip.time) {
            //console.log(tooltip, 'tip')
            // let pmin = tooltip.points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
            // let mind = tooltip.points.filter(d => d.value == tmin);
            return <div className="tooltip" style={{ left: x, top: y }}>
                {/* <div>Device Type: {mind[0].deviceType}</div> */}
                {/*<div>Device: {pmin.deviceType}</div>*/}
                {/* <div>Grass Height: {pmin.value}cm</div> */}
                {/* {(avgGrassHeight !== null) &&<div>Avg.Grass Height - {avgGrassHeight} cm</div>} */}
                <div className="drow"><div className="drowlabel" style={{marginRight: '12%'}}>Date </div><div className="drowvalue" style={{textAlign:'right'}}>{moment(tooltip.time).format('DD-MMM-YYYY hh:mm a')}</div></div> 
                {(tooltip.value !== null) ? <div className="drow"><div className="drowlabel" style={{marginRight: '2%'}}>Grass Height </div><div className="drowvalue">{tooltip.value} cm</div></div> : <div className="drow"><div className="drowlabel">Grass Height </div><div className="drowvalue">null</div></div> }
                {tooltip.distance !== null && <div className="drow"><div className="drowlabel"style={{marginRight: '2%'}}>Actual Distance </div><div className="drowvalue">{tooltip.distance} cm</div></div>}
                {(tooltip.accuracy !== null) ? <div className="drow"><div className="drowlabel"style={{marginRight: '2%'}}>GPS Accuracy </div><div className="drowvalue">{tooltip.accuracy} </div></div> : <div className="drow"><div className="drowlabel">GPS Accuracy </div><div className="drowvalue">null</div></div> }
                {tooltip.error && <div className="drow"><div className="drowlabel"style={{marginRight: '12%'}}>Error </div><div className="drowvalue" style={{textAlign:'right'}}>{tooltip.error}</div></div>}
                {tooltip.SOG && <div className="drow"><div className="drowlabel"style={{marginRight: '2%'}}>Speed </div><div className="drowvalue">{tooltip.SOG} kmph</div></div>}
            </div>
        }
        return null;
    }
    _renderhoveredGrassHeight() {
        const { tooltip, avgGrassHeight } = this.state;
        if (avgGrassHeight !== null) {
            return <div className="tooltip" style={{ left: 0, top: 0, borderRadius : 0, width:'15%', padding: '3px', height:'25px' }}>
                {(avgGrassHeight !== null) && <div className="drow"><div className="drowlabel">Avg.Grass Height </div><div className="drowvalue">{avgGrassHeight} cm</div></div>}
                {/* {(avgGrassHeight !== null) &&<div style={{fontSize : '11.5px'}}>Avg.Grass Height : {avgGrassHeight} cm</div>} */}
            </div>
        }
        return null;
    }

    getGradientColor(grassHeight){
      if(grassHeight === 0 ){
            return [125, 125, 125]
      }
      else if (grassHeight > 10) {
        return [128, 10, 10]
      }
    //   else if(grassHeight >= 10 ){
    //     return [128, 10, 10]
    //     }
    //   else if(grassHeight > 5 && grassHeight < 10){
    //       var redValue = (grassHeight-5) * 20
    //       var greenValue = (grassHeight-5) * 10
    //         return [redValue + 120, 100 - greenValue, 20]
    //   }
      else if (grassHeight > 0 && grassHeight <=10 ){
            return [1, 128, 1]
      } else{
          return [71, 71, 71]
      }
    }
    _renderLayers() {
        // radius = 1, upperPercentile = 100, coverage = 2, mapLayers,
        const { data } = this.props;
        const { filterValue, geoJson, coverageGeoJson } = this.state;
        // console.log('geoJson', geoJson)
        let mapData = data;
        if(mapData){
            for(let i=0;i< mapData.length;i++ ){
              if(mapData[i+1]){
                if(!mapData[i].start){
                    mapData[i].start = mapData[i].geometry
                }
                if(!mapData[i].end){
                    mapData[i].end = mapData[i+1].geometry
                }
                if(!mapData[i].nextGrassHeight){
                    mapData[i].nextGrassHeight = mapData[i+1].convertedgrassHeight
                }
               }
            }
        }
        let lastIndexID = mapData.length - 1
        let textData = mapData.map((item, index) => {
            if(index === 0){
                return {
                timeStamp : item.timeStamp,
                coordinates: item.geometry,
                isData : true,
                name: 'Start',
                }
            }
            if(index === lastIndexID){
                return {
                timeStamp : item.timeStamp,
                coordinates: item.geometry,
                isData : true,
                name: 'End',
                }
            }
          }).filter(item => {
              if(item && item.isData){
                return item
              }
          });
        let viewLayers = []
        if (geoJson) {
            viewLayers.push(new GeoJsonLayer({
                id: 'geojson',
                data: geoJson,
                //opacity: 0.5,
                stroked: true,
                // filled: true,
                extruded: false,
                // wireframe: false,
                getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                getFillColor: f => [171, 171, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
                // getLineColor: [255, 255, 255],
                // pickable: true,
                // onHover: this._onHover,
                // onClick: this._onClick
            }))
        }
        if (coverageGeoJson) {
            viewLayers.push(new GeoJsonLayer({
                id: 'geojson1',
                data: coverageGeoJson,
                //opacity: 0.5,
                stroked: true,
                // filled: true,
                extruded: false,
                // wireframe: false,
                getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
                getFillColor: f => [99, 194, 146], //[0, 0, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
            }))
        }

        if (filterValue) {
            // viewLayers.push(new LineLayer({
            //     id: 'line-path',
            //     data: mapData,
            //     opacity: 1,
            //     getSourcePosition: d => d.start,
            //     getTargetPosition: d => d.end,
            //     getColor : d => this.getGradientColor(d.convertedgrassHeight), //(d.nextGrassHeight && d.nextGrassHeight > 5) ? [173, 50, 50] : (d.nextGrassHeight === 0 ) ? [255, 165, 0] : [1, 128, 1],
            //     //getTargetColor : d => (d.nextGrassHeight && d.nextGrassHeight > 5) ? [173, 50, 50] : (d.nextGrassHeight === 0 ) ? [255, 165, 0] : [1, 128, 1],
            //     //getSourceColor: d => [41,163,41],
            //     //getTargetColor: d => [204,51,0],
            //     //getColor :  d => [1, 128, 1],
            //     autoHighlight : false,
            //     getWidth : 8,
            //     getFilterValue: d => d.timeStamp,
            //     filterRange: [filterValue[0], filterValue[1]],
            //     extensions: [dataFilter],
            //     //pickable: true
            // }))
            viewLayers.push(new ScatterplotLayer({
                id: 'scatterplot-layer',
                data: mapData,
                pickable: true,
                radiusScale: 0.7,
                radiusMinPixels: 0.5,
                //radius:0.5,
                //opacity: 0.5,
                //stroked: true,
                filled: true,
                //radius: 1.5,
                //elevationScale: 0,
                //getElevation: d => 0,
                getPosition: d => d.geometry,
                //getRadius: d => Math.sqrt(d.exits),
                //getFillColor: d => (d.convertedgrassHeight && d.convertedgrassHeight > 5) ? [173, 50, 50] : [1, 128, 1],         
                getFillColor: d => this.getGradientColor(d.convertedgrassHeight),      //(d.convertedgrassHeight && d.convertedgrassHeight > 5) ? [173, 50, 50] : (d.convertedgrassHeight === 0 ) ? [255, 165, 0] : [1, 128, 1],                       
                getFilterValue: d => d.timeStamp,
                filterRange: [filterValue[0], filterValue[1]],
                extensions: [dataFilter],
                //getLineColor: d => [0, 0, 0]
                // pickable: true,
                // extruded: false,

                // elevationScale: 0,
                // getPosition: d => d.geometry,
                onHover: ({ object, x, y }) => {
                  this.setState({ tooltip: object, x, y })
                },
                // getElevationValue: () => 0,
                // getElevationWeight: () => 0,
                // elevationRange: [0, 0],
                // colorRange: [
                //     [1, 128, 1, 120],
                //     [1, 128, 255, 120],
                //     [255, 128, 1, 120]
                // ],
            }))
            viewLayers.push(new TextLayer({
                id: 'cluster-text-layer',
                data: textData,
                getText: d => {
                  return d.name
                },
                getPosition: d => d.coordinates,
                getColor: d => [255, 255, 255],
                backgroundColor : [0, 0, 0],
                getSize: d => 20,
                sizeMaxPixels : 15,
                getFilterValue: d => d.timeStamp,
                filterRange: [filterValue[0], filterValue[1]],
                extensions: [dataFilter],
              }))
            
        }
        return viewLayers;
    }

    modifyGeoJson() {
        let { locationData } = this.props;
        let modifiedJson = [];
        if (locationData) {
            locationData.forEach((item, i) => {
                // console.log('geoJsons item'+i, item.geometry);
                let polygons = []
                if (item && item.geometry && item.geometry.coordinates && item.geometry.coordinates.length > 0) {
                    // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
                    polygons = item.geometry.coordinates.map(c => {
                        let mItem = {
                            ...item,
                            geometry: {
                                type: 'Polygon',
                                coordinates: [c]
                            },
                            parentIndex: i
                        }
                        return mItem;
                    })
                }
                modifiedJson = modifiedJson.concat(polygons);
            });
        }
        // console.log('modifiedJson', modifiedJson);

        this.setState({
            geoJson: {
                "type": "FeatureCollection",
                features: modifiedJson
            }
        },()=>{
            this.overlay.setProps({ layers: this._renderLayers() });
        });
    }

    modifyCoverageGeoJson() {
        let { coverage } = this.props;
        // console.log('detail coverage', coverage)
        let polygons = []
        let toolTipInfo = []
        let datas = []
        let geodatas = []
        if (coverage) {
            coverage.forEach(res => {
                datas.push(res)
                 //console.log('detail coverage res', res)
                if (datas) {
                    datas.forEach(f => {
                        // console.log('detail coverage res f', f)
                        toolTipInfo.push({
                            //date: res.date,
                            props: f.properties
                        })
                        if (f.geometry) {
                            geodatas.push(f.geometry)
                            geodatas.forEach(g => {
                                if (g.coordinates) {
                                    let polys = g.coordinates.map(c => {
                                        let mItem = {
                                            properties: f.properties,
                                            geometry: {
                                                type: 'Polygon',
                                                coordinates: [c[0]]
                                            }
                                        }
                                        return mItem;
                                    })
                                    polygons = polygons.concat(polys)
                                }
                            })
                        }

                        if (f.geometry.coordinates && f.geometry.coordinates.length > 0) {
                            // let coordinated = f.geometry.coordinates.map(c=>c[0]);
                            // console.log('coordinated', coordinated)
                            f.geometry.coordinates.forEach(ci => {
                                let polys = ci.map(c => {
                                    let mItem = {
                                        properties: f.properties,
                                        geometry: {
                                            type: 'Polygon',
                                            coordinates: [c]
                                        }
                                    }
                                    return mItem;
                                })
                                polygons = polygons.concat(polys)
                            })

                        }
                    })
                }
            })
        }
        const mapGeoJson = {
            "type": "FeatureCollection",
            features: polygons
        }
        // console.log('modifyGeoJson', mapGeoJson)
        this.setState({ coverageGeoJson: mapGeoJson }
            , () => {
                this.overlay.setProps({ layers: this._renderLayers() });
            });
        // console.log('toolTipInfo', toolTipInfo);
        this.setState({ toolTipInfo: toolTipInfo })
    }

    setViewPort() {
        // let { data } = this.props;
        let { locationData, locationClicked, data } = this.props;

        let pointsLong;
        let pointsLat;

        if (data && data.length > 0) {
            pointsLong = data.map(point => point.geometry[0])
            pointsLat = data.map(point => point.geometry[1])
        }

        if (locationData && locationData.length > 0) {
            if(locationClicked){
                locationData.forEach(loc => {
                    if(locationClicked === loc.properties.loc_cd){
                        pointsLat = []
                        pointsLong = []
                        pointsLong.push(loc.properties.cornersLatLng[0][0], loc.properties.cornersLatLng[1][0])
                        pointsLat.push(loc.properties.cornersLatLng[0][1], loc.properties.cornersLatLng[1][1])
                    }
                })
            }
            else{
                locationData.forEach(loc => {
                    pointsLong.push(loc.properties.cornersLatLng[0][0], loc.properties.cornersLatLng[1][0])
                    pointsLat.push(loc.properties.cornersLatLng[0][1], loc.properties.cornersLatLng[1][1])
                })
            }
        }
        let cornersLongLat = [
            [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
            [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
        ]
        const bounds = getBoundsForPoints(cornersLongLat)
        let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
        this.setState({ viewState: currentViewState });

        this.map.setCenter({ lng: bounds.longitude, lat: bounds.latitude });
        this.map.setZoom(bounds.zoom);

    }

    _getTimeRange(data) {
        if (!data) {
            return null;
        }
        return data.reduce(
            (range, d) => {
                const t = d.timeStamp;
                range[0] = Math.min(range[0], t)
                range[1] = Math.max(range[1], t)
                return range;
            },
            [Infinity, -Infinity]
        );
    }

    render() {
        return (
            <div>
                <div id="devicedetailgmap" style={{ height: '500px' }}></div>
                {this._renderhoveredItems()}
                {this._renderhoveredGrassHeight()}
            </div>
        )
    }
}
