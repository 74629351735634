import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import NotificationsIcon from "@material-ui/icons/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listItem: {},
  iconContainer: {
    // width: "40px",
    // height: "40px",
    // borderRadius: "40px",
    // background: "rgba(94, 223, 255, 0.2)",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
  },
  textContent: {
    // width: "280px",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#c4d7d7",
    },
  },
}));

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

let FeedList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItem disableGutters className={classes.listItem}>
          <div className={classes.iconContainer}>
            <NotificationsIcon />
          </div>
          <div className={classes.textContent}>
            organicbear529 of Lee Cooper started operation at 3605 Parker Rd <span className="time">11:54</span>
          </div>
        </ListItem>
        <Divider />
        <ListItem disableGutters className={classes.listItem}>
          <div className={classes.iconContainer}>
            <NotificationsIcon />
          </div>
          <div className={classes.textContent}>
            organicbear529 of Lee Cooper started operation at 3605 Parker Rd <span className="time">11:54</span>
          </div>
        </ListItem>
        <Divider />
        <ListItem disableGutters className={classes.listItem}>
          <div className={classes.iconContainer}>
            <NotificationsIcon />
          </div>
          <div className={classes.textContent}>
            organicbear529 of Lee Cooper started operation at 3605 Parker Rd <span className="time">11:54</span>
          </div>
          
        </ListItem>
      </List>
    </div>
  );
};

export { FeedList };
