import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    morebtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginTop: '-20px',
        position: 'fixed',
        marginLeft: '-51px',
        zIndex: 100

    },
    detailmorebtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginTop: 'calc(50vh - 18px)',
        position: 'absolute',
        marginLeft: '-50px',
        zIndex: -1
    },
    detaillessbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginTop: 'calc(50vh - 18px)',
        position: 'absolute',
        marginLeft: '-105px',
        zIndex: -1
    },
    modifycolbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        marginTop: '-20px'
    },
    bottompanel: {
        position: "fixed",
        width: "80%",
        bottom: 0,
        left: "calc(10% + 48px)",
        height: "100px",
        transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    fullpanel: {
        height: "calc(100vh - 200px)",
        // overflow: 'auto'
    },
    btmPanelContent: {
        backgroundColor: "#FFFFFF",
        borderRadius: "24px 24px 0 0",
    },
    detailbtmPanelContent: {
        backgroundColor: "#FFFFFF",
        // borderRadius: "24px 24px 0 0",
    },
    filter: {
        flexGrow: 1,
        flexBasis: 0,
        padding: "12px",
        minWidth: 0
    },
    row: {
        display: 'flex'
    },
    rowlabel: {
        flexGrow: 1
    },
    tile: {
        flexGrow: 1, flexBasis: 0, margin: "12px"
    },
    detailtile: {
        width: 'calc(50% - 24px)', margin: "12px"
    },
    locationName: {
        flexGrow: 1,
        fontSize: '20px',
        fontFamily: 'Rubik',
        color: '#426262'
    },
    filterChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        padding: '8px 16px',
        backgroundColor: '#F1FCFC',
        border: '1px solid #C4D7D7',
        borderRadius: '21px',
        marginRight: '16px',
        marginTop: '10px'
    },
    topPanel: {
        position: "fixed",
        width: "80%",
        top: "64px",
        left: "calc(10% + 48px)",
        height: "auto",
        // borderRadius: "24px 24px 0 0",
        transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        //   display: 'flex',
        //   paddingTop: '10px'
    },
    backbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        // position: 'fixed',
        marginTop: '10px'
    },
    calcProgress: {
        padding: '5px 10px',
        marginTop: '10px',
        backgroundColor: '#ffffff',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: '10px',
        color: '#2c7873'
    },
    layerbtn: {
        // borderRadius: '18px',
        // fontSize: '14px',
        // color: '#2C7873',
        // fontFamily: 'Karla',
        // fontWeight: 'bold',
        // backgroundColor: '#F1FCFC',
        position: 'fixed',
        top: '74px',
        right: 'calc(10% + 142px)'
    },
    hidebtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        position: 'fixed',
        top: '74px',
        right: 'calc(10% - 48px)'
    },
    coverageBtn: {
        // display: 'inline-block',
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        position: 'fixed',
        top: '74px',
        right: 'calc(10% + 330px)',
        // paddingLeft: '16px'
        padding: '0 4px 0 16px'
    },
    detailbottompanel: {
        position: "fixed",
        width: "5px",
        bottom: 0,
        // left: "calc(10% + 48px)",
        height: "calc(100vh - 63px)",
        transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        right: 0
    },
    detailfullpanel: {
        // height: "calc(100vh - 220px)",
        // overflow: 'auto'
        width: "50%",
    },
    tooltip: {
        pointerEvents: 'none',
        position: 'absolute',
        minWidth: '266px',
        height: 'auto', //'245px'
        background: 'rgba(33, 49, 49, 0.8)',
        borderRadius: '8px',
        padding: '16px',
        zIndex: 9,
        color: '#fff',
    },

    timecontrol: {
        marginTop: '50px', //'0px',
        transition: theme.transitions.create(["margin-top"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position: 'absolute',
        right: 40
    },
    hideControls: {
        marginTop: '-191px'
    },
    hiddenbottompanel: {
        height: '75px'
    },
}));