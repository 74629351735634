import React, { useState, useEffect } from "react";
// import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";
import { Grid, Select, MenuItem, Card, Tabs, Tab, Button, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
//context
import { useDevicesState, getDevices, getDeviceGroups, updateDeviceGroup, addDeviceGroup, deleteDeviceGroup } from "../../context/DevicesContext";
import EditDevice from "./EditDevice";

import TextField from '@material-ui/core/TextField';
import InfoTooltip from "../../components/InfoTooltip"
import { useSnackbar } from 'notistack';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useConfirmDialog } from "../../context/ConfirmDialogContext";

function a11yProps(index) {
    return {
        id: `deviceslist-tab-${index}`,
        'aria-controls': `deviceslist-tabpanel-${index}`,
    };
}

function DeviceManagement() {
    var classes = useStyles();
    // var theme = useTheme();
    const context = useDevicesState();
    // eslint-disable-next-line
    const {showDialog,closeDialog} = useConfirmDialog();

    const { enqueueSnackbar } = useSnackbar();

    const [display, setdisplay] = useState('deviceslist')
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [devices, setdevices] = useState([])
    const [deviceGroups, setdeviceGroups] = useState([])
    const [selectedDevice, setselectedDevice] = useState(null)

    const [selectedGroup, setselectedGroup] = useState(null)
    const [groupName, setgroupName] = useState('')
    const [deviceGroup, setdeviceGroup] = useState([])
    const [groupDesc, setgroupDesc] = useState('')



    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const onDeviceGroupEditSubmit = () => {
        // console.log('groupdetails', { groupName, deviceGroup, groupDesc })

        
        if(display==='editGroup'){
            let values = {
                id: selectedGroup.id,
                name: groupName,
                devices: deviceGroup,
                description: groupDesc
            }
            updateDeviceGroup(context.setData, values)
        }
        if(display==='addGroup'){
            let values = {
                name: groupName,
                devices: deviceGroup,
                description: groupDesc
            }
            addDeviceGroup(context.setData, values)
        }
    }
    const cancelDeviceGroupEdit = () => {
        setgroupName('')
        setgroupDesc('')
        setdeviceGroup([])
        setdisplay('deviceslist');
    }

    useEffect(() => {
        if (context.setData) {
            getDevices(context.setData);
        }
    }, [context.setData]);

    useEffect(() => {
        // console.log('context', context);
        if (context.data.devices) {
            setdevices(context.data.devices)
        }
    }, [context.data.devices]);

    useEffect(() => {
        // console.log('context', context);
        if (context.data.deviceGroups) {
            setdeviceGroups(context.data.deviceGroups)
        }
    }, [context.data.deviceGroups]);

    useEffect(() => {
        // console.log('context', context);
        if (selectedTab === 1 && !context.data.deviceGroups) {
            getDeviceGroups(context.setData)
        }
    }, [context.setData,context.data.deviceGroups, selectedTab]);

    useEffect(() => {
        // console.log('onDeviceGriupUpdated')
        if (context.data.updateDeviceGroupError) {
            enqueueSnackbar('Failed to update Device Group', { variant: 'error' });
        }
        if (context.data.updateDeviceGroup) {
            enqueueSnackbar('Device Group updated successfully', { variant: 'success' });
            // console.log('onDeviceGriupUpdated 1')
            context.setData({ type: 'CLEAR_DEVICE_GROUP_DATA' })
            setgroupName('')
            setgroupDesc('')
            setdeviceGroup([])
            setdisplay('deviceslist');
            getDeviceGroups(context.setData)
        }
        // eslint-disable-next-line
    }, [context.setData, enqueueSnackbar, context.data.updateDeviceGroup, context.data.updateDeviceGroupError]);

    useEffect(() => {
        // console.log('onDeviceGroupAdded')
        if (context.data.addDeviceGroupError) {
            enqueueSnackbar('Failed to add Device Group', { variant: 'error' });
        }
        if (context.data.addDeviceGroup) {
            enqueueSnackbar('Device Group added successfully', { variant: 'success' });
            // console.log('onDeviceGroupAdded 1')
            context.setData({ type: 'CLEAR_DEVICE_GROUP_DATA' })
            setgroupName('')
            setgroupDesc('')
            setdeviceGroup([])
            setdisplay('deviceslist');
            getDeviceGroups(context.setData)
        }
        // eslint-disable-next-line
    }, [context.setData, enqueueSnackbar,context.data.addDeviceGroup, context.data.addDeviceGroupError]);

    useEffect(() => {
        // console.log('onDeviceGroupDeleted')
        if (context.data.deleteDeviceGroupError) {
            enqueueSnackbar('Failed to delete Device Group', { variant: 'error' });
        }
        if (context.data.deleteDeviceGroup) {
            enqueueSnackbar('Device Group deleted successfully', { variant: 'success' });
            // console.log('onDeviceGroupDeleted 1')
            context.setData({ type: 'CLEAR_DEVICE_GROUP_DATA' })
            getDeviceGroups(context.setData)
        }
        // eslint-disable-next-line
    }, [context.setData, enqueueSnackbar, context.data.deleteDeviceGroup, context.data.deleteDeviceGroupError]);

    return (
        <Grid container spacing={4}>
            {(display === 'deviceslist' || display === 'editGroup' || display === 'addGroup' ) && <Grid item lg={12} xs={12}>
                <Card style={{ margin: 15 }}>
                    <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
                        <Tab label="Devices" {...a11yProps(0)} />
                        <Tab label="Device Groups" {...a11yProps(1)} />
                    </Tabs>
                    <div role="tabpanel" hidden={selectedTab !== 0} id='deviceslist-tabpanel-0' aria-labelledby='deviceslist-tabpanel-0'>
                        {context && context.data && context.data.isLoaded && <MaterialTable
                            title="Manage Devices"
                            columns={[
                                { title: "Device Name", field: "device_name" },
                                { title: "Alias Name", field: "alias_name" },
                                { title: "Device Type", field: "device_type" },
                                { title: "Location", field: "location" }
                            ]}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit device',
                                    onClick: (event, rowData) => {
                                        setselectedDevice(rowData);
                                        setdisplay('editdevice');
                                    }
                                },
                                {
                                    icon: 'add',
                                    tooltip: 'Add device',
                                    isFreeAction: true,
                                    onClick: (event) => setdisplay('adddevice')
                                }
                            ]}
                            data={devices.results}
                            options={{
                                // filtering: true,
                                // grouping: true,
                                headerStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    color: "#698D8D",
                                    backgroundColor: "#F1FCFC",
                                    padding: "4px 12px",
                                },
                                cellStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    fontWeight: "bold",
                                    padding: "4px 12px",
                                },
                                rowStyle: {
                                    height: "auto",
                                },
                                searchFieldStyle: {
                                    // color: 'red'
                                },
                                pageSize: 10,
                                actionsColumnIndex: -1
                            }}
                        />}
                    </div>
                    <div role="tabpanel" hidden={selectedTab !== 1} d='deviceslist-tabpanel-1' aria-labelledby='deviceslist-tabpanel-1'>
                        {display !== 'editGroup' && display !== 'addGroup' && context && context.data && context.data.deviceGroups && <MaterialTable
                            title="Manage Device Groups"
                            columns={[
                                { title: "Group Name", field: "name" },
                                {
                                    title: "Devices", field: "devices",
                                    render: rowData => {
                                        return rowData.devices.map((d, i) => <div key={i}>{d.alias_name}</div>)
                                    }
                                },
                                { title: "Description", field: "description" }
                            ]}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit group',
                                    onClick: (event, rowData) => {
                                        setselectedGroup(rowData)
                                        setgroupName(rowData.name)
                                        setgroupDesc(rowData.description)
                                        let dG = [];
                                        if (rowData.devices) {
                                            dG = rowData.devices.map(d => d.id)
                                        }
                                        setdeviceGroup(dG)
                                        setdisplay('editGroup')
                                    }
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete group',
                                    onClick: (event, rowData) => {
                                        let dProps = {
                                            title: 'Delete Group',
                                            content:`Group - ( ${rowData.name} ) will be permanently deleted. Are you sure you want to delete it?`,
                                            onOk: ()=>{
                                                deleteDeviceGroup(context.setData, rowData.id);
                                            }
                                        }
                                        showDialog(dProps)
                                    }
                                },
                                {
                                    icon: 'add',
                                    tooltip: 'Add Group',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setselectedGroup(null)
                                        setgroupName('')
                                        setgroupDesc('')
                                        setdeviceGroup([])
                                        setdisplay('addGroup')
                                    }
                                }
                            ]}
                            data={deviceGroups.results}
                            options={{
                                // filtering: true,
                                // grouping: true,
                                headerStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    color: "#698D8D",
                                    backgroundColor: "#F1FCFC",
                                    padding: "4px 12px",
                                },
                                cellStyle: {
                                    fontSize: "12px",
                                    fontFamily: "Karla",
                                    fontWeight: "bold",
                                    padding: "4px 12px",
                                },
                                rowStyle: {
                                    height: "auto",
                                },
                                searchFieldStyle: {
                                    // color: 'red'
                                },
                                pageSize: 10,
                                actionsColumnIndex: -1
                            }}
                        />}
                        {(display === 'editGroup' || display === 'addGroup') && <div>
                            <form className={classes.editform} onSubmit={onDeviceGroupEditSubmit}>
                                <Typography variant="h6" gutterBottom>
                                    {(display === 'editGroup')?'Edit Device Group':'Add Device Group'}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <TextField fullWidth required id="group-name" value={groupName} onChange={(e) => setgroupName(e.target.value)} label="Group Name" className={classes.editformfield} />
                                    <InfoTooltip contentKey="GroupName" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <FormControl required className={classes.formControl}>
                                        <InputLabel id="group-devices">Devices</InputLabel>
                                        <Select
                                            labelId="group-devices-label"
                                            id="group-devices"
                                            multiple
                                            value={deviceGroup}
                                            onChange={(e) => setdeviceGroup(e.target.value)}
                                        >
                                            {devices && devices.results && devices.results.map((d, i) => {
                                                return <MenuItem key={i} value={d.id}>{d.alias_name}</MenuItem>
                                            })

                                            }
                                        </Select>
                                    </FormControl>
                                    {/* <TextField fullWidth required id="rule-type" value={ruleType} onChange={(e) => setruleType(e.target.value)} label="Rule Type" className={classes.editformfield} /> */}
                                    <InfoTooltip contentKey="GroupDevices" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <TextField fullWidth id="group-description" value={groupDesc} onChange={(e) => setgroupDesc(e.target.value)} label="Description" className={classes.editformfield} />
                                    <InfoTooltip contentKey="GroupDesc" />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="primary" size="small" style={{ width: '100%' }} type="submit"
                                        disabled={!(groupName && deviceGroup && deviceGroup.length > 0)}
                                    >Submit</Button></div>
                                    <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="default" size="small" style={{ width: '100%' }} onClick={cancelDeviceGroupEdit}>Cancel</Button></div>
                                </div>
                            </form>
                        </div>}
                    </div>

                </Card>
            </Grid>}
            {(display === 'editdevice' || display === 'adddevice') && <Grid item lg={12} xs={12}>
                <EditDevice device={selectedDevice} mode={display} backToList={() => {setselectedDevice(null);setdisplay('deviceslist')}} onDeviceUpdated={() => { setdisplay('deviceslist'); setselectedDevice(null); getDevices(context.setData); }} />
            </Grid>
            }

            {/* <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={(showError) ? true : false}
                onClose={() => setshowError('')}
                key="errorsnack"
                autoHideDuration={5000}
            >
                <div style={{backgroundColor:'#EB4559',color:'#fff',padding:10, fontSize: 14, borderRadius: 5}}>
                    {showError}
                </div>
            </Snackbar> */}
        </Grid>
    )
}

export default DeviceManagement
