import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import moment from 'moment'

ReactFC.fcRoot(FusionCharts, TimeSeries);

// const jsonify = res => res.json();
// const dataFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/dual-y-axis-data.json"
// ).then(jsonify);
// const schemaFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/dual-y-axis-schema.json"
// ).then(jsonify);

const dataSource = {
    chart: {
        multicanvas: false
    },
    // caption: {
    //     text: "All Feed Data"
    // },
    yaxis: []
};

export default class FeedGraph extends React.Component {
    constructor(props) {
        super(props);
        this.onFetchData = this.onFetchData.bind(this);
        this.state = {
            timeseriesDs: {
                type: "timeseries",
                renderAt: "container",
                width: "100%",
                height: window.innerHeight - 305,
                dataSource
            }
        };
    }

    componentDidMount() {
        this.onFetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data || prevProps.selectedFeeds !== this.props.selectedFeeds) {
            this.onFetchData();
        }
    }

    onFetchData() {
        // Promise.all([dataFetch, schemaFetch]).then(res => {

        const { data, selectedFeeds } = this.props
        //console.log(data,'DDD')
        // console.log('onFetchData')

        let yAxis = []
        // let chartdataMap = {};
        let schema = [{
            "name": "Date",
            "type": "date",
            "format": "%Y-%m-%d %H:%M:%S"
        }];
        if (selectedFeeds && selectedFeeds.length > 0) {
            // let fds = Object.keys(data[0].data)
            let fds = selectedFeeds.filter(f=>f.name!=='Time');
            for(let f=0;f<fds.length;f++){
                schema.push({
                    "name": fds[f].name,
                    "type": "number"
                })
                yAxis.push({
                    plot: [
                        {
                            value: fds[f].name,
                            connectnulldata: true,
                            type: "line"
                        }
                    ],
                    format: {
                        suffix: ''
                    }
                })
            }

            let chartData = data.map(d=>{
                let ds = [moment(d.completed_at).format('YYYY-MM-DD HH:mm:ss')]
                for(let f=0;f<fds.length;f++){
                    if(fds[f].name === 'grass_height'){
                        var dVal = d[fds[f].name]?d[fds[f].name]/10:null
                        ds.push(dVal)
                    }
                    else{
                    ds.push(d[fds[f].name]?d[fds[f].name]:null)
                    }
                }
                return ds;
            })

            // console.log('chartData',chartData);
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                chartData,
                schema
            );
            const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
            // console.log('fusionTable', fusionTable)
            timeseriesDs.dataSource.data = fusionTable
            this.setState({
                timeseriesDs
            });
        }
       
    }


    // onFetchData() {
    //     // Promise.all([dataFetch, schemaFetch]).then(res => {

    //     const { data } = this.props

    //     console.log('onFetchData')

    //     let yAxis = []
    //     let chartdataMap = {};
    //     let schema = [{
    //         "name": "Date",
    //         "type": "date",
    //         "format": "%Y-%m-%d %H:%M:%S"
    //     }];
    //     for (let f = 0; f < data.length;  f++) { //data.length;
    //         let feed = data[f];
    //         if (feed.length > 0) {
    //             schema.push({
    //                 "name": feed[0].feed.name,
    //                 "type": "number"
    //             })
    //             yAxis.push({
    //                 plot: [
    //                     {
    //                         value: feed[0].feed.name,
    //                         connectnulldata: true,
    //                         type: "line"
    //                     }
    //                 ],
    //                 format: {
    //                     suffix: feed[0].feed.unit_symbol
    //                 }
    //             })
    //             for (let d = 0; d < feed.length; d++) {
    //                 if (!chartdataMap[feed[d].completed_at]) {
    //                     chartdataMap[feed[d].completed_at] = [moment(feed[d].completed_at).format('YYYY-MM-DD HH:mm:ss')]
    //                 } 
    //                 chartdataMap[feed[d].completed_at][f+1] = feed[d].value
    //             }
    //         }
    //     }

    //     let chartData = Object.values(chartdataMap);

    //     // const data = [["2018-01-01 00:00:00.000", "64.62", "5.275", "10.00"], ["2018-01-01 01:00:00.000", "65.63", "7.098", "11.00"], ["2018-01-01 02:00:00.000", "65.75", "4.857", "12.00"]];
    //     // const schema = [{
    //     //     "name": "Date",
    //     //     "type": "date",
    //     //     "format": "%Y-%m-%d %H:%M:%S"
    //     // },
    //     // {
    //     //     "name": "Energy",
    //     //     "type": "number"
    //     // },
    //     // {
    //     //     "name": "Temperature",
    //     //     "type": "number"
    //     // },
    //     // {
    //     //     "name": "Energy2",
    //     //     "type": "number"
    //     // }];
    //     const fusionTable = new FusionCharts.DataStore().createDataTable(
    //         chartData,
    //         schema
    //     );
    //     const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
    //     // console.log('fusionTable', fusionTable)
    //     timeseriesDs.dataSource.data = fusionTable
    //     this.setState({
    //         timeseriesDs
    //     });
    //     // });
    // }

    render() {
        return (
            <div>
                {this.state.timeseriesDs.dataSource.data ? (
                    <ReactFC {...this.state.timeseriesDs} />
                ) : (
                        ""
                    )}
            </div>
        );
    }
}

//====================================================




// // Step 1 - Include react
// import React, {useEffect, useState} from "react";

// // Step 2 - Include the react-fusioncharts component
// import ReactFC from "react-fusioncharts";

// // Step 3 - Include the fusioncharts library
// import FusionCharts from "fusioncharts";

// // Step 4 - Include the chart type
// import Column2D from "fusioncharts/fusioncharts.charts";
// import TimeSeries from "fusioncharts/fusioncharts.timeseries";
// import PowerCharts from 'fusioncharts/fusioncharts.powercharts';

// // Step 5 - Include the theme as fusion
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// import moment from 'moment'

// // Step 6 - Adding the chart and theme as dependency to the core fusioncharts
// ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);


// // Preparing the chart data
// // const chartData = [
// //     {
// //         label: "Venezuela",
// //         value: "290"
// //     },
// //     {
// //         label: "Saudi",
// //         value: "260"
// //     },
// //     {
// //         label: "Canada",
// //         value: "180"
// //     },
// //     {
// //         label: "Iran",
// //         value: "140"
// //     },
// //     {
// //         label: "Russia",
// //         value: "115"
// //     },
// //     {
// //         label: "UAE",
// //         value: "100"
// //     },
// //     {
// //         label: "US",
// //         value: "30"
// //     },
// //     {
// //         label: "China",
// //         value: "30"
// //     }
// // ];

// const fusionTable = new FusionCharts.DataStore().createDataTable(
//     [["01-Feb-11",8866],["04-Feb-11 09:00",2174],["04-Feb-11 10:00",2084],["04-Feb-11 11:00",1503],["05-Feb-11",4928]],
//     [{
//         "name": "Time",
//         "type": "date",
//         "format": "%d-%b-%y"
//       }, {
//         "name": "Grocery Sales Value",
//         "type": "number"
//       }]
//   );

// let chartData = [
//     {'Time': moment('2020-07-01').toDate(),'Grocery Sales Value': 8866},
//     {'Time': moment('2020-07-02').toDate(),'Grocery Sales Value': 8000}
// ]


// let axisValues = [{
//     title: '',
//     titlepos: "left",
//     // numberprefix: "$",
//     divlineisdashed: "1",
//     // maxvalue: "200",
//     dataset: [
//       {
//         seriesname: 'RTK',
//         linethickness: "3",
//         data: [{value: 10, value: 20}] //this.multiAxisdata
//       }
//     ]
//   }] 
// // Create a JSON object to store the chart configurations
// const chartConfigs = {
//     type: "multiaxisline", // The chart type
//     // width: "700", // Width of the chart
//     height: "400", // Height of the chart
//     dataFormat: "json", // Data type
//     dataSource: {
//         chart: {
//           caption: "IBRD Subscriptions and Voting Powers",
//           subcaption: "For OECD countries",
//           showvalues: "0",
//           labeldisplay: "ROTATE",
//           rotatelabels: "1",
//           plothighlighteffect: "fadeout",
//           plottooltext: "$seriesName in $label : <b>$dataValue</b>",
//           theme: "fusion"
//         },
//         axis: [
//           {
//             title: "Subscription Amount",
//             titlepos: "left",
//             numberprefix: "$",
//             divlineisdashed: "1",
//             maxvalue: "100000",
//             dataset: [
//               {
//                 seriesname: "Subscription Amount",
//                 linethickness: "3",
//                 data: [
//                   {
//                     value: "38450.2"
//                   },
//                   {
//                     value: "16544.4"
//                   },
//                   {
//                     value: "10659.4"
//                   },
//                   {
//                     value: "9657.4"
//                   },
//                   {
//                     value: "9040.4"
//                   },
//                   {
//                     value: "9040.4"
//                   },
//                   {
//                     value: "6992.3"
//                   },
//                   {
//                     value: "6650.5"
//                   },
//                   {
//                     value: "6650.5"
//                   },
//                   {
//                     value: "6337.2"
//                   },
//                   {
//                     value: "5835.4"
//                   },
//                   {
//                     value: "4582.9"
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             title: "Subscription %",
//             axisonleft: "1",
//             titlepos: "left",
//             numdivlines: "8",
//             divlineisdashed: "1",
//             maxvalue: "25",
//             numbersuffix: "%",
//             dataset: [
//               {
//                 seriesname: "Subscription %",
//                 dashed: "1",
//                 data: [
//                   {
//                     value: "17.23"
//                   },
//                   {
//                     value: "7.41"
//                   },
//                   {
//                     value: "4.78"
//                   },
//                   {
//                     value: "4.33"
//                   },
//                   {
//                     value: "4.05"
//                   },
//                   {
//                     value: "4.05"
//                   },
//                   {
//                     value: "3.13"
//                   },
//                   {
//                     value: "2.98"
//                   },
//                   {
//                     value: "2.98"
//                   },
//                   {
//                     value: "2.84"
//                   },
//                   {
//                     value: "2.62"
//                   },
//                   {
//                     value: "2.05"
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             title: "Number of Votes",
//             titlepos: "RIGHT",
//             axisonleft: "0",
//             numdivlines: "5",
//             numbersuffix: "",
//             divlineisdashed: "1",
//             maxvalue: "400000",
//             dataset: [
//               {
//                 seriesname: "Number of Votes",
//                 linethickness: "3",
//                 data: [
//                   {
//                     value: "358196"
//                   },
//                   {
//                     value: "166138"
//                   },
//                   {
//                     value: "107288"
//                   },
//                   {
//                     value: "97268"
//                   },
//                   {
//                     value: "91098"
//                   },
//                   {
//                     value: "91098"
//                   },
//                   {
//                     value: "70617"
//                   },
//                   {
//                     value: "67199"
//                   },
//                   {
//                     value: "67199"
//                   },
//                   {
//                     value: "64066"
//                   },
//                   {
//                     value: "59048"
//                   },
//                   {
//                     value: "46523"
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             title: "Voting %",
//             titlepos: "RIGHT",
//             axisonleft: "0",
//             numdivlines: "5",
//             divlineisdashed: "1",
//             maxvalue: "20",
//             numbersuffix: "%",
//             dataset: [
//               {
//                 seriesname: "Voting %",
//                 dashed: "1",
//                 data: [
//                   {
//                     value: "16.3"
//                   },
//                   {
//                     value: "7.03"
//                   },
//                   {
//                     value: "4.54"
//                   },
//                   {
//                     value: "4.12"
//                   },
//                   {
//                     value: "3.86"
//                   },
//                   {
//                     value: "3.86"
//                   },
//                   {
//                     value: "2.99"
//                   },
//                   {
//                     value: "2.84"
//                   },
//                   {
//                     value: "2.84"
//                   },
//                   {
//                     value: "2.71"
//                   },
//                   {
//                     value: "2.5"
//                   },
//                   {
//                     value: "1.97"
//                   }
//                 ]
//               }
//             ]
//           }
//         ],
//         categories: [
//           {
//             category: [
//               {
//                 label: "2006"
//               },
//               {
//                 label: "2007"
//               },
//               {
//                 label: "2008"
//               },
//               {
//                 label: "2009"
//               },
//               {
//                 label: "2010"
//               },
//               {
//                 label: "2011"
//               },
//               {
//                 label: "2012"
//               },
//               {
//                 label: "2013"
//               },
//               {
//                 label: "2014"
//               },
//               {
//                 label: "2015"
//               },
//               {
//                 label: "2016"
//               },
//               {
//                 label: "2017"
//               }
//             ]
//           }
//         ]
//       }
// };

// function FeedGraph(props) {
//     // console.log('fusionTable',fusionTable)
//     return (
//         <div>
//             <ReactFC {...chartConfigs} />
//         </div>
//     )
// }

// export default FeedGraph
