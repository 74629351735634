import React, { useState, useEffect } from 'react'
import { Card, Tabs, Tab, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
// import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import useStyles, { switchStyles } from "./styles";
import FeedsAndRules from './FeedsAndRules';
import DeviceRules from './DeviceRules';
import BackupSettings from './BackupSettings';
import OTASettings from './OTASettings';
// import InfoIcon from '@material-ui/icons/Info';
// import Tooltip from '@material-ui/core/Tooltip';
// import {  makeStyles } from '@material-ui/core/styles';
// import { helptext } from '../../helptext';
import InfoTooltip from "../../components/InfoTooltip"
// import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
// import MuiAlert from '@material-ui/lab/Alert';
import { useDevicesState, getDeviceDetails, updateDevice,updateDeviceConfig, addDevice } from "../../context/DevicesContext";

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const useCustomTooltipStyle = makeStyles((theme) => ({
//     arrow: {
//         color: theme.palette.common.black,
//     },
//     tooltip: {
//         backgroundColor: theme.palette.common.black,
//         fontSize: '14px'
//     },
// }));

function a11yProps(index) {
    return {
        id: `edit-device-tab-${index}`,
        'aria-controls': `edit-device-tab-${index}`,
    };
}

function EditDevice(props) {

    var classes = useStyles();
    var switchClasses = switchStyles();
    const context = useDevicesState();
    const { enqueueSnackbar } = useSnackbar();

    // const tooltipClasses = useCustomTooltipStyle();

    // const [deviceDatails, setdeviceDatails] = useState(props.device);

    const [mode, setmode] = useState(props.mode);
    const [deviceName, setdeviceName] = useState('');
    const [deviceId, setdeviceId] = useState('');
    const [deviceType, setdeviceType] = useState('');
    const [aliasName, setaliasName] = useState('');
    const [serailNo, setserailNo] = useState('');
    const [deviceLocation, setdeviceLocation] = useState('');
    const [deviceLatitude, setdeviceLatitude] = useState('');
    const [deviceLongitude, setdeviceLongitude] = useState('');
    const [activityTimeout, setactivityTimeout] = useState('');
    const [isGPSCapable, setisGPSCapable] = useState(false)
    const [isGPSEnabled, setisGPSEnabled] = useState(false)
    // const [showError, setshowError] = useState('')

    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const onEditSubmit = (event) => {
        event.preventDefault();
        // console.log('deviceDatails', { deviceName, deviceId, deviceType, aliasName, serailNo, deviceLocation, deviceLatitude, deviceLongitude, activityTimeout });
        if (mode === 'editdevice') {
            let values = {
                device_id: deviceId,//values.deviceId,
                device_name: deviceName,
                device_type: deviceType, //values.deviceType, 
                alias_name: aliasName,
                latitude: deviceLatitude,
                longitude: deviceLongitude,
                activity_timeout: activityTimeout,
                is_gps_capable: isGPSEnabled,
                is_gps_enable: isGPSCapable,
                serial_number: serailNo,
                location: deviceLocation,
            }
            updateDevice(context.setData, values)
        } else {

            let tempDeviceName = deviceName.split("_");
            let tdeviceType = tempDeviceName[0];
            let tdeviceId = tempDeviceName[1];
            let values = {
                device_id: tdeviceId,//values.deviceId,
                device_name: deviceName,
                device_type: tdeviceType, //values.deviceType, 
                alias_name: aliasName,
                latitude: deviceLatitude,
                longitude: deviceLongitude,
                activity_timeout: activityTimeout,
                is_gps_capable: isGPSEnabled,
                is_gps_enable: isGPSCapable,
                serial_number: serailNo,
                location: deviceLocation,
            }
            addDevice(context.setData, values)
        }
    }

    const downloadConfig = () => {
        // console.log('props.device',context.data.selectedDevice.details)
        if (context.data.selectedDevice && context.data.selectedDevice.details && context.data.selectedDevice.details.template) {
            let fileName = 'device_config.json';
            let text = JSON.stringify(context.data.selectedDevice.details.template)
            const element = document.getElementById('downloadAnchor');
            const fileType = fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
            element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
            element.setAttribute('download', fileName);

            var event = new MouseEvent("click");
            element.dispatchEvent(event);
        }
    }

    const selectConfigJson = () => {
        document.getElementById('fileSelector').click();
    }

    const onFileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            var result;
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                // console.log(fileReader.result);
                result = fileReader.result;
                try {
                    result = JSON.parse(result);

                } catch (e) {
                    result = null;
                    enqueueSnackbar('Invalid config', { variant: 'warning' });
                }

                if (result) {
                    let values = {
                        device_id: deviceId,
                        template: result
                    }
                    updateDeviceConfig(context.setData, values)
                }
            }
            fileReader.readAsText(event.target.files[0]);

        }
    }
    useEffect(() => {
        setmode(props.mode)
        if (props.mode === 'adddevice') {
            setdeviceName('');
            setdeviceId('');
            setdeviceType('');
            setaliasName('');
            setserailNo('');
            setdeviceLocation('');
            setdeviceLatitude('');
            setdeviceLongitude('');
            setactivityTimeout('');
            setisGPSCapable('')
            setisGPSEnabled('')
        }
    }, [props.mode]);
    // if (mode ==='editdevice') {
    useEffect(() => {
        if (props.device) {

            setdeviceName(props.device.device_name);
            setdeviceId(props.device.device_id);
            setdeviceType(props.device.device_type);
            setaliasName(props.device.alias_name);
            setserailNo(props.device.serial_number);
            setdeviceLocation(props.device.location);
            setdeviceLatitude(props.device.latitude);
            setdeviceLongitude(props.device.longitude);
            setactivityTimeout(props.device.activity_timeout);
            setisGPSCapable(props.device.is_gps_capable)
            setisGPSEnabled(props.device.is_gps_enable)

            getDeviceDetails(context.setData, props.device.device_id);
        }
    }, [context.setData, props.device]);

    // useEffect(() => {
    //     console.log('context details', context.data.selectedDevice.details)
    //     if (context.data.selectedDevice.details) {
    //         setdeviceDatails(context.data.selectedDevice.details)
    //     }
    // }, [context.data.selectedDevice.details]);

    useEffect(() => {
        // console.log('onDeviceUpdated')
        if (context.data.updateDeviceError) {
            enqueueSnackbar('Failed to update Device', { variant: 'error' });
        }
        if (context.data.updateDevice) {
            enqueueSnackbar('Device updated successfully', { variant: 'success' });
            // console.log('onDeviceUpdated 1')
            context.setData({ type: 'CLEAR_DEVICE_DATA' })
            props.onDeviceUpdated();
        }
        // eslint-disable-next-line
    }, [enqueueSnackbar, context.data.updateDevice, context.data.updateDeviceError]);

    useEffect(() => {
        // console.log('onDeviceAdded')
        if (context.data.addDeviceError) {
            enqueueSnackbar('Failed to add Device', { variant: 'error' });
        }
        if (context.data.addDevice) {
            enqueueSnackbar('Device added successfully', { variant: 'success' });
            // console.log('onDeviceAdded 1')
            context.setData({ type: 'CLEAR_DEVICE_DATA' })
            props.onDeviceUpdated();
        }
        // eslint-disable-next-line
    }, [enqueueSnackbar, context.data.addDevice, context.data.addDeviceError]);
    // }

    useEffect(() => {
        // console.log('onDeviceUpdated')
        if (context.data.updateDeviceConfigError) {
            enqueueSnackbar('Failed to update Device Config', { variant: 'error' });
        }
        if (context.data.updateDeviceConfig) {
            enqueueSnackbar('Device Config updated successfully', { variant: 'success' });
            // console.log('onDeviceUpdated 1')
            context.setData({ type: 'CLEAR_DEVICE_CONFIG_DATA' })
            // props.onDeviceUpdated();
        }
        // eslint-disable-next-line
    }, [enqueueSnackbar, context.data.updateDeviceConfig, context.data.updateDeviceConfigError]);

    return (
        <div>
            <div style={{
                display: "flex",
                padding: "24px 24px 16px 24px",
                alignItems: "center",
            }}>
                {mode === 'editdevice' && <div style={{ flexGrow: 1, fontFamily: 'Rubik', color: '#426262' }}> <Typography variant="h6" color="primary">Edit Device <span style={{ fontSize: '12px' }}>/{props.device.device_name}</span></Typography></div>}
                {mode === 'adddevice' && <div style={{ flexGrow: 1, fontFamily: 'Rubik', color: '#426262' }}><Typography variant="h6" color="primary">Add Device</Typography></div>}
                <div>
                    <Button variant="contained" color="default" className={classes.backbtn} onClick={props.backToList}>
                        <ArrowBackIcon style={{ marginRight: "12px" }}></ArrowBackIcon> BACK
                    </Button>
                </div>
            </div>
            <Card style={{ margin: '0 15px' }}>
                {mode === 'editdevice' && <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary">
                    <Tab label="Device Details" {...a11yProps(0)} />
                    {mode === 'editdevice' && <Tab label="Feeds and Rules" {...a11yProps(1)} />}
                    {mode === 'editdevice' && <Tab label="Device Rules" {...a11yProps(2)} />}
                    {mode === 'editdevice' && <Tab label="OTA Settings" {...a11yProps(3)} />}
                    {mode === 'editdevice' && <Tab label="Backup Settings" {...a11yProps(4)} />}
                    {mode === 'editdevice' && <Tab label="Configuration" {...a11yProps(5)} />}
                </Tabs>}
                <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 0} id='deviceslist-tabpanel-0' aria-labelledby='deviceslist-tabpanel-0'>
                    <form className={classes.editform} onSubmit={onEditSubmit}>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-name" disabled={mode === 'editdevice'} value={deviceName} onChange={(e) => setdeviceName(e.target.value)} label="Device Name" className={classes.editformfield} />
                            <InfoTooltip contentKey="DeviceName" />
                        </div>
                        {mode === 'editdevice' && <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-id" disabled={mode === 'editdevice'} value={deviceId} onChange={(e) => setdeviceId(e.target.value)} label="Device ID" className={classes.editformfield} />
                            <InfoTooltip contentKey="DeviceId" />
                        </div>}
                        {mode === 'editdevice' && <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-type" disabled={mode === 'editdevice'} value={deviceType} onChange={(e) => setdeviceType(e.target.value)} label="Device Type" className={classes.editformfield} />
                            <InfoTooltip contentKey="DeviceType" />
                        </div>}
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="alias-name" value={aliasName} onChange={(e) => setaliasName(e.target.value)} label="Alias Name" className={classes.editformfield} />
                            <InfoTooltip contentKey="AliasName" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="serial-number" value={serailNo} onChange={(e) => setserailNo(e.target.value)} label="Serial Number" className={classes.editformfield} />
                            <InfoTooltip contentKey="SerialNumber" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-location" value={deviceLocation} onChange={(e) => setdeviceLocation(e.target.value)} label="Location" className={classes.editformfield} />
                            <InfoTooltip contentKey="Location" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-latitude" value={deviceLatitude} onChange={(e) => setdeviceLatitude(e.target.value)} label="Latitude" className={classes.editformfield} />
                            <InfoTooltip contentKey="Latitude" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="device-longitude" value={deviceLongitude} onChange={(e) => setdeviceLongitude(e.target.value)} label="Longitude" className={classes.editformfield} />
                            <InfoTooltip contentKey="Longitude" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextField fullWidth required id="activity-timeout" type="number" min="0" value={activityTimeout} onChange={(e) => setactivityTimeout(e.target.value)} label="Activity Timeout (in seconds)" className={classes.editformfield} />
                            <InfoTooltip contentKey="ActivityTimeout" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={isGPSCapable}
                                        control={<Switch color="primary" checked={isGPSCapable} onChange={(event) => setisGPSCapable(event.target.checked)} />}
                                        label="Is GPS Capable"
                                        labelPlacement="start"
                                        classes={switchClasses}
                                    />
                                </FormGroup>
                            </FormControl>
                            <InfoTooltip contentKey="isGpsCapable" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={isGPSEnabled}
                                        control={<Switch color="primary" checked={isGPSEnabled} onChange={(event) => setisGPSEnabled(event.target.checked)} />}
                                        label="Is GPS Enabled"
                                        labelPlacement="start"
                                        classes={switchClasses}
                                    />
                                </FormGroup>
                            </FormControl>
                            <InfoTooltip contentKey="isGpsEnable" />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="primary" size="small" style={{ width: '100%' }} type="submit"
                                disabled={!(deviceName && aliasName && serailNo && deviceLocation && deviceLatitude && deviceLongitude && activityTimeout && (mode === 'adddevice' || (mode === 'editdevice' && deviceType && deviceId)))}
                            >Submit</Button></div>
                            <div style={{ flexGrow: 1, padding: 10 }}><Button variant="contained" color="default" size="small" style={{ width: '100%' }} onClick={props.backToList}>Cancel</Button></div>
                        </div>
                    </form>
                </div>
                {mode === 'editdevice' && <div>
                    <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 1} id='deviceslist-tabpanel-1' aria-labelledby='deviceslist-tabpanel-1'>
                        <FeedsAndRules device={props.device} />
                    </div>
                    <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 2} id='deviceslist-tabpanel-2' aria-labelledby='deviceslist-tabpanel-2'>
                        <DeviceRules device={props.device} />
                    </div>
                    <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 3} id='deviceslist-tabpanel-3' aria-labelledby='deviceslist-tabpanel-3'>
                        <OTASettings device={props.device} />
                    </div>
                    <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 4} id='deviceslist-tabpanel-4' aria-labelledby='deviceslist-tabpanel-4'>
                        <BackupSettings device={props.device} />
                    </div>
                    <div className={classes.edittabpanel} role="tabpanel" hidden={selectedTab !== 5} id='deviceslist-tabpanel-5' aria-labelledby='deviceslist-tabpanel-5'>
                        <div style={{ textAlign: 'center', padding: 50 }}>
                            <div style={{ padding: 15 }}>Download Device Configuration</div>
                            <div>
                                <Button variant="contained" color="primary" size="small" onClick={downloadConfig}>
                                    <CloudDownloadIcon style={{ marginRight: "12px" }}></CloudDownloadIcon> Download
                            </Button>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', padding: 50 }}>
                            <div style={{ padding: 15 }}>Upload Device Configuration</div>
                            <div>
                                <Button variant="contained" color="primary" size="small" onClick={selectConfigJson}>
                                    <CloudUploadIcon style={{ marginRight: "12px" }}></CloudUploadIcon> Upload
                            </Button>
                            </div>
                        </div>
                    </div>
                </div>}
            </Card>

            {/* <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={(showError) ? true : false}
                onClose={() => setshowError('')}
                key="errorsnack"
                autoHideDuration={5000}
            >
                <div style={{ backgroundColor: '#EB4559', color: '#fff', padding: 10, fontSize: 14, borderRadius: 5 }}>
                    {showError}
                </div>
            </Snackbar> */}

            <a id="downloadAnchor" style={{ display: 'none' }} href="/#">download</a>
            <input id="fileSelector" type="file" hidden style={{color:'black'}} placeholder="Upload file..." onChange={onFileSelected} accept=".json,application/json"/>
        </div>

    )
}
export default EditDevice
