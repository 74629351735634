import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import moment from 'moment'

export default function SessionReportTable(props) {
  // const classes = useStyles();
  const [sessions, setsessionData] = useState([])
  // const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let allsessionData = [];
    let sessionData = props.session.map((data, index)=>{
       let detailSessionData =  data.map((item,index)=>{
        allsessionData.push({
            Device : item.Device,
            start_date : item.start_date,
            end_time : item.end_time,
            session_duration : item.session_duration,
            total_area_covered : item.total_area_covered,
            locations_covered : item.locations_covered
          })
        })
    })
    setsessionData(allsessionData)
  }, [props.session])
  return (
    <div style={{ maxWidth: "100%" }} className="btmtable">
      <MaterialTable
        title=""
        columns={[
          { title: "Device", field: "Device" },
          {
            title: "START TIME", field: "start_date",
            defaultSort: 'desc',
            render: rowData => moment.utc(rowData.start_date).local().format('DD-MMM, hh:mm a')
          },
          {
            title: "END TIME", field: "end_time",
            render: rowData => moment.utc(rowData.end_time).local().format('DD-MMM, hh:mm a')
          },
          {
            title: "SESSION DURATION",
            field: "session_duration",
            //type: "numeric",
            grouping: false,
            headerStyle: { textAlign: "left" },
          },
          {
            title: "AREA COVERED (in hA)",
            field: "total_area_covered",
            //type: "numeric",
            grouping: false,
            headerStyle: { textAlign: "left" },
            render: rowData => (rowData.total_area_covered) ? (parseFloat(rowData.total_area_covered / 10000)).toFixed(3) : '-'
          },
          // { title: "Total Data Points", field: "total_count" },
          {
            title: "LOCATIONS COVERED", field: "locations_covered",
          },
        ]}
        data={sessions}
        options={{
          filtering: false,
          grouping: true,
          thirdSortClick: false,
          exportAllData: true,
          exportButton: true,
          headerStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            color: "#698D8D",
            backgroundColor: "#F1FCFC",
            padding: "4px 12px",
          },
          cellStyle: {
            fontSize: "12px",
            fontFamily: "Karla",
            fontWeight: "bold",
            padding: "4px 12px",
          },
          rowStyle: {
            height: "auto",
          },
          searchFieldStyle: {
            // color: 'red'
          },
          pageSize: 20,
          maxBodyHeight: 'calc(100vh - 210px)',
        }}
      ></MaterialTable>
    </div>
  );
}
