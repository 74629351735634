import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    backbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC'
    },
    edittabpanel: {
        minHeight: 'calc(100vh - 200px)',
        backgroundColor: '#fff'
    },
    editform: {
        padding: 24,
        width: '400px'
    },
    editformfield: {
        margin: '5px'
    },
    submitBtn: {
        margin: '0 20'
    },
    // tooltip:{
    //     fontSize: '14px',
    //     backgroundColor: '#000'
    // }
    noDataMessage: {
        height: 'calc(100vh - 180px)',
        textAlign: 'center',
        // padding-top: 100px;
        // fontFamily: '''Montserrat', sans-serif',
        display: 'flex',
        alignItems: 'center'
    },
    formControl: {
        width: '100%',
        margin: '5px'
    }
}));

export const switchStyles = makeStyles((theme) => ({
    labelPlacementStart:{
        flexGrow: 1,
        marginLeft: 0
    },
    label: {
        flexGrow: 1
    }
}));