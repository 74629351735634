import React, { useState, useEffect, useCallback } from 'react'
import Polygonmap from './components/PolygonMapBox'
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { useDevicesNewState, getLocations, getPreviousData, getDevices, getDeviceData, getFeedData, getDeviceDetails, getPreviousDeviceData, getPreviousTrimData, addLocation } from "../../context/DevicesNewContext";
import DateFilter from "../../components/DateFilter";
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import DeviceFilter from "../../components/DeviceFilter"
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function PolygonMap(props) {

    var classes = useStyles();
    const context = useDevicesNewState();
    const [detailData, setdetailData] = useState([]);
    const [selectedLocation, setselectedLocation] = useState(null)
    const [isLoading, setisLoading] = React.useState(true);
    const [selectedDeviceId, setselectedDeviceId] = useState(null);
    const [deviceDetails, setdeviceDetails] = useState(null)
    const [selectedDate, setselectedDate] = useState(null);
    const [selectedDateType, setselectedDateType] = useState('');
    const [currentDate, setcurrentDate] = useState('');
    const [devices, setDevices] = useState([]);
    const [deviceList, setdeviceList] = useState([]);
    const [modal, setModal] = useState(false);
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [locdesc, setlocDesc] = useState('');
    const [polygonData, setpolygonData] = useState([]);
    const [areaCovered, setareaCovered] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setisLoading(true)
        getDevices(context.setData);

    }, [context.setData]);

    useEffect(() => {
        if (context.setData && currentDate && selectedDeviceId) {
            var today = moment().format('DD-MM-YYYY')
            var dateSelected = moment(selectedDate[0]).format('DD-MM-YYYY')
            if (dateSelected === today) {
                setisLoading(true)
                getFeedData(context.setData, selectedDeviceId)
                getDeviceDetails(context.setData, selectedDeviceId);
                getDeviceData(context.setData, selectedDeviceId, moment(selectedDate[0]).format('YYYY-MM-DD'));
            }
            else {
                setisLoading(true)
                getPreviousData(context.setData, deviceDetails.id, deviceDetails.device_id, moment(selectedDate[0]).format('YYYY-MM-DD'))
            }

        }
    }, [context.setData, currentDate, selectedDeviceId]);

    useEffect(() => {
        if (selectedDate) {
            var dateSelected = moment(selectedDate[0]).format('DD-MM-YYYY')
            setcurrentDate(dateSelected)
        }
    }, [selectedDate]);

    useEffect(() => {
        if (context.data.pastData) {
            let ptrimData = context.data.pastData.filter(d => d.file_type === 'trim_data')
            if (ptrimData && ptrimData.length > 0) {
                getPreviousTrimData(context.setData, ptrimData[0].id)
            } else {
                setdetailData([]);
                setisLoading(false)
            }
            let pdeviceData = context.data.pastData.filter(d => d.file_type === 'device_data')
            if (pdeviceData && pdeviceData.length > 0) {
                getPreviousDeviceData(context.setData, pdeviceData[0].id)
            } else {
            }

        } else {
            setdetailData([]);
            setisLoading(false)
        }
        // eslint-disable-next-line
    }, [context.data.pastData, context.data.pastDataError]);


    const getData = useCallback((data, duration, fileType) => {
        if (duration === 'Today') {
            let feedList = [];
            let dataArr = data
            let test = []
            let mapData1 = dataArr.map((d, i) => {
                if (d && d.gps && d.gps.coordinates) {
                    var coordinatesVal = d.gps.coordinates
                    let timeVal = moment(d.completed_at).format('DD-MMM-YYYY hh:mm:ss a')
                    let grassHeight = d.grass_height / 10 + ' cm'
                    return {
                        geometry: coordinatesVal,
                        value: grassHeight,
                        time: timeVal,
                        grass_height: grassHeight,
                        timeStamp: parseInt(moment(d.completed_at).format('x')),
                        locationId: d.loc_cd
                    }
                }
                else {
                    let timeVal = moment(d.completed_at).format('DD-MMM-YYYY hh:mm:ss a')
                    let grassHeight = d.grass_height / 10 + ' cm'
                    return {
                        geometry: [],
                        value: grassHeight,
                        time: timeVal,
                        grass_height: grassHeight,
                        timeStamp: parseInt(moment(d.completed_at).format('x')),
                        locationId: d.loc_cd
                    }
                }
            });
            if (mapData1.length > 0) {
                test = mapData1.filter(f => f.geometry.length > 0)
                let uniqueLocs = [];
                test.forEach(v => {
                    if (v.locationId && uniqueLocs.indexOf(v.locationId) === -1) {
                        uniqueLocs.push(v.locationId)
                    }
                });
                if (uniqueLocs.length > 0) {
                    getLocations(context.setData, uniqueLocs, '');
                }
            }
            setdetailData(test);
            setisLoading(false)
        }
        else {
            let test = []
            require('d3-request').csv(data, (error, response) => {
                if (!error) {
                    // var promises = [];
                    if (fileType === 'trim_data') {
                        const data1 = response.map((d, i) => {
                            if (d && d.lat && d.long) {
                                return {
                                    geometry: [Number(d.long), Number(d.lat)],
                                    value: d.grass_height / 10 + ' cm',
                                    time: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    completed_at: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    timeStamp: parseInt(d.Time) * 1000,
                                    grass_height: d.grass_height,
                                    locationId: d.loc_cd
                                }
                            }
                            else {
                                return {
                                    geometry: [],
                                    value: d.grass_height / 10 + ' cm',
                                    time: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    completed_at: moment.unix(d.Time).format('DD-MMM-YYYY hh:mm:ss a'),
                                    timeStamp: parseInt(d.Time) * 1000,
                                    grass_height: d.grass_height,
                                    locationId: d.loc_cd
                                }
                            }
                        })
                        Promise.all(data1).then((values) => {
                            const uniqueLocs = [];
                            values.forEach(v => {
                                if (v.locationId && uniqueLocs.indexOf(v.locationId) === -1) {
                                    uniqueLocs.push(v.locationId)
                                }
                            });
                            if (uniqueLocs.length > 0) {
                                getLocations(context.setData, uniqueLocs, '');
                            }
                            if (values.length > 0) {
                                test = values.filter(f => f.geometry.length > 0)
                            }
                            setdetailData(test);
                            setisLoading(false)
                        });
                    }
                }
            })
        }
    }, [context.setData]);


    useEffect(() => {
        if (context.data.previousTrimData) {
            getData(context.data.previousTrimData.file_s3_url, 'custom', 'trim_data')
        }
    }, [getData, context.data.previousTrimData, context.data.previousTrimDataError]);

    useEffect(() => {
        if (context.data.previousDeviceData) {
            getData(context.data.previousDeviceData.file_s3_url, 'custom', 'device_data')
        }
    }, [getData, context.data.previousDeviceData, context.data.previousDeviceDataError]);

    useEffect(() => {
        if (context.data.feedData) {
            getData(context.data.feedData, 'Today', '')
        }
    }, [getData, context.data.feedData, context.data.feedDataError]);

    useEffect(() => {
        if (context.data.deviceDetails) {
            setdeviceDetails(context.data.deviceDetails);
        }
    }, [context.data.deviceDetails, context.data.deviceDetailsError]);

    useEffect(() => {
        if (context.data.devices) {
            setdeviceList(context.data.devices);
        }
    }, [context.data.devices, context.data.devices]);


    useEffect(() => {
        var tempList = []
        //console.log(deviceList,'dl')
        if (deviceList && deviceList.results) {
            deviceList.results.map((device, data) => {
                tempList.push({ id: device.device_id, label: device.alias_name })
            })
            setDevices(tempList)
        }
    }, [deviceList]);

    const onDateChange = (date, label) => {
        setselectedDate(date);
        setselectedDateType(label);
    }
    useEffect(() => {
        if (context.data.locations) {
            setselectedLocation(context.data.locations)
        }
    }, [context.data.locations]);

    const onFilterChange = (type, data) => {
        //console.log(type, data);
        data.map((dataList, index) => {
            if (dataList.checked === true) {
                setselectedDeviceId(dataList.id)
            }
        })
    }

    const onDataUpdate = (data) => {
        setpolygonData(data)
    }
    const areaData = (data) => {
        setareaCovered(data)
    }
    const onClickSavePolygon = () => {
        setModal(true)
    }
    const handleClose = () => {
        setModal(false)
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    const handleDescChange = (event) => {
        setlocDesc(event.target.value);
    };
    const onSavePopup = () => {
        if(polygonData.features && polygonData.features[0] && polygonData.features[0]){
           polygonData.features[0].properties = {
                name: name,
                location: location,
                desc: locdesc,
                polygonArea: areaCovered + ' sqm'
            }
        }
        if(polygonData){
            const polygon = {
                ...polygonData,
            }
            
            const fileData = JSON.stringify(polygon);

            if(polygon && polygon.features && polygon.features[0]){
                let firstIndexInfo = polygon.features[0]


                if(firstIndexInfo.geometry){
                    if(firstIndexInfo.geometry.type && firstIndexInfo.geometry.type === 'Polygon'){
                        firstIndexInfo.geometry.type = 'MultiPolygon'
                    }
                    if(firstIndexInfo.geometry.coordinates && firstIndexInfo.geometry.coordinates[0]){
                        firstIndexInfo.geometry.coordinates[0] = [firstIndexInfo.geometry.coordinates[0]]
                    }
                    if(firstIndexInfo.geometry && !firstIndexInfo.geometry.crs){
                        firstIndexInfo.geometry.crs = {"type" : "name", "properties" : {"name":"EPSG:4326"}}
                    }
                    if(firstIndexInfo.properties.polygonArea){
                        firstIndexInfo.properties.polygonArea = firstIndexInfo.properties.polygonArea.split(' ')
                        firstIndexInfo.properties.polygonArea = parseFloat(firstIndexInfo.properties.polygonArea[0])
                    }
                }

                let data = {}

                data = {
                    loc_cd : firstIndexInfo.properties.location,
                    loc_desc : firstIndexInfo.properties.desc,
                    actual_area : firstIndexInfo.properties.polygonArea,
                    geom : firstIndexInfo.geometry
                }
                addLocation(context.setData, data)
            }
        }

        // const blob = new Blob([fileData], { type: "text/plain" });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.download = `${name}.json`;
        // link.href = url;
        // link.click();
        //console.log(details)
        setName('')
        setLocation('')
        setlocDesc('')
        setModal(false)
    }
    useEffect(() => {
        if (context.data.newLocation) {
            if(context.data.newLocation && context.data.newLocation.geometry){
                enqueueSnackbar('Polygon Created Successfully', { variant: 'success' });
            }
        }
        else if(context.data.newLocationError){
            enqueueSnackbar('Polygon Creation Failed', { variant: 'warning' });
        }
    }, [context.data.newLocation, context.data.newLocationError]);
    return (
        <>
            <div style={{
                position: "relative",
                overflow: "hidden",
                //height: "calc(100vh - 64px)",
                minHeight: "400px",
                transition: "height .6s ease-in"
            }}>
                <div style={{ display: 'flex', marginTop: '2%', marginBottom: '1%' }}>
                    <div className={classes.locationName}>{'Create Polygon'}</div>
                    <div style={{ marginRight: '10px' }} >
                        <DeviceFilter
                            options={devices}
                            label="Device"
                            description="device"
                            className={classes.filter}
                            onFilterChange={(data) => onFilterChange('device', data)}
                        ></DeviceFilter>
                    </div >
                    <div style={{ marginRight: '10px' }} className={classes.dateFilterDiv}>
                        <DateFilter
                            options={[]}
                            label="Duration"
                            className={classes.dateFilterChip}
                            onDateChange={onDateChange}
                        ></DateFilter>
                    </div>
                    <div style={{ float: 'right' }} >
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.backbtn}
                            onClick={onClickSavePolygon} >
                            Save Polygon
                         </Button>
                    </div>
                </div>

                <div>
                    <Dialog fullWidth open={modal} onClose={handleClose} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}> Polygon Information </Typography>
                                <Button autoFocus color="inherit" onClick={onSavePopup}>
                                    Save
                        </Button>
                            </Toolbar>
                        </AppBar>
                        <div style={{ padding: '10px 35px 20px 20px' }}>
                            {/* <div>
                                <TextField style={{ width: '100%' }} required id="group-name" value={name} onChange={handleNameChange} label="Name" />
                            </div> */}
                            <div>
                                <TextField style={{ width: '100%' }} required id="group-name" value={location} onChange={handleLocationChange} label="Location" />
                            </div>
                            <div>
                                <TextField style={{ width: '100%' }} required id="group-name" value={locdesc} onChange={handleDescChange} label="Location Description" />
                            </div>
                        </div>
                    </Dialog>

                </div>

                {isLoading === false ? <div>
                    {detailData && detailData.length > 0 ?
                        <div style={{
                            minHeight: 375, height: '425px',
                            position: "relative",
                            overflow: "hidden",
                            transition: "height .6s ease-in"
                        }}>
                            <Polygonmap data={detailData} locationData={selectedLocation} onDataUpdate={(data) => onDataUpdate(data)} areaData={(data) => areaData(data)}></Polygonmap>
                        </div>
                        :
                        <Polygonmap data={detailData} locationData={selectedLocation} onDataUpdate={(data) => onDataUpdate(data)} areaData={(data) => areaData(data)}></Polygonmap>}
                </div>
                    : <div style={{ justifyContent: "center", textAlign: 'center', marginTop: "15%" }}><CircularProgress /></div>
                }
            </div>

        </>
    )
} 
