import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    edittabpanel: {
        minHeight: 'calc(100vh - 200px)',
        backgroundColor: '#fff'
    },
    editform: {
        padding: 24,
        width: '400px'
    },
    editformfield: {
        margin: '5px'
    },
    submitBtn: {
        margin: '0 20'
    },
    // tooltip:{
    //     fontSize: '14px',
    //     backgroundColor: '#000'
    // }
    noDataMessage: {
        height: 'calc(100vh - 180px)',
        textAlign: 'center',
        // padding-top: 100px;
        // fontFamily: '''Montserrat', sans-serif',
        display: 'flex',
        alignItems: 'center'
    },
    formControl: {
        width: '100%',
        margin: '5px'
    },
    tile: {
        flexGrow: 1, flexBasis: 0, margin: "12px" 
    },
    locationName: {
        //flexGrow: 1,
        fontSize: '25px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#2C7873',
        //textAlign:'center',
        marginTop:'1%'
    },
    root: {
      // flexGrow: 1,
      // marginTop: "20px",
      padding: '10px 35px 20px 20px',
      backgroundColor: "#F1FCFC",
    },
    deviceDetails: {
        flexGrow: 1,
        fontSize: '15px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#2C7873',
        marginTop:'18px',
        display:'flex'
    },
    Fwversion: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#2C7873',
        marginTop:'-5px'
    }, 
    TimeLabel: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#426262',
        marginTop:'-5px'
    },
    statusChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        padding: '4px 12px',
        backgroundColor: '#F1FCFC',
        border: '1px solid #C4D7D7',
        borderRadius: '21px',
        marginLeft: '5px'
    },
    filterChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        padding: '8px 16px',
        backgroundColor: '#F1FCFC',
        border: '1px solid #C4D7D7',
        borderRadius: '21px',
        marginLeft: '5px'
    },
    dateFilterDiv:{
        display: 'flex',
        marginTop: '-3%',
        marginRight:'5%'
    },
    dateFilterChip: {
        display: 'inline-block',
        fontSize: '12px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#698D8D',
        //backgroundColor: '#F1FCFC',
        //borderRadius: '21px',
        marginLeft: '5px',
        width:'150px',
        marginTop:'-2%',
        cursor:'pointer'
    },
    filterTitle:{
        color: '#2C7873',
        fontSize: '15px',
        fontFamily: 'Karla',
        fontWeight: 'bold',
    },
    filter: {
        flexGrow: 1, 
        flexBasis: 0, 
        padding: "12px",
        minWidth: 0,
        maxWidth:250
    },
    backbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#F1FCFC',
        position: 'relative',
        marginRight:'12px',
        padding: '3px 12px'
    },
    refreshbtn: {
        borderRadius: '18px',
        fontSize: '14px',
        color: '#2C7873',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        backgroundColor: '#FFFFFF',
        position: 'relative',
        marginRight:'12px',
        padding: '3px 12px',
    },
    mapContainer:{
        minHeight: 325, height: '325px',
        position: "relative",
        overflow: "hidden",
        transition: "height .6s ease-in" 
    },
    paper: {
      color: "#2c7873",
    },
    paperFeedInfo: {
        color: "#2c7873",
        height: '100%'
      },
    paperHeader: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "17px",
      color: "#2c7873",
      padding: "15px 2px 5px 15px",
    },
    timecontrol: {
        //marginTop: '50px', //'0px',
        transition: theme.transitions.create(["margin-top"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          position:'absolute',
          //right: 0
          top:0,
          left:0 ,
          marginTop : '6%'
    },
    slidercontrol : {
        position:'absolute',
          marginTop : '9%',
          marginLeft: '42%',
          top:0,
          left:0
    },
    tableTitle: {
        flexGrow: 1,
        fontSize: '22px',
        marginLeft:'2%',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#2C7873',
        paddingTop:'12px',
        paddingBottom:'12px'
    },
    tabTitle: {
        flexGrow: 1,
        fontSize: '17px',
        fontFamily: 'Rubik',
        fontWeight : 'bold',
        color: '#2C7873',
    },
    tableView:{
        maxHeight: 400, 
        height: '335px',
        position: "relative",
        overflow: "hidden",
        transition: "height .6s ease-in",
        borderBottom: "solid 1px #c4d7d7",
        //padding: "5px"
    },
    detailView:{
        backgroundColor: '#F1FCFC',
        //maxHeight: 400, 
        height: 335,
        position: "relative",
        overflow: "hidden",
        transition: "height .6s ease-in",
        borderBottom: "solid 1px #c4d7d7",
        padding: "15px"
    },
    detailLabel:{
        fontSize: '12px',
        color: '#97B2B2',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        position: 'relative'
    },
    detailValue:{      
        fontSize: '17px',
        color: '#213131',
        fontFamily: 'Karla',
        fontWeight: 'normal',
        position: 'relative'
    }
}));

export const switchStyles = makeStyles((theme) => ({
    labelPlacementStart:{
        flexGrow: 1,
        marginLeft: 0
    },
    label: {
        flexGrow: 1
    }
}));