import React from "react";
import axios from "axios";
// import {config} from "../config"

const DevicesContext = React.createContext();


const rootReducer = (state, action) => {
    switch (action.type) {
        case "GET_DEVICES":
            return {
                ...state,
                isLoaded: true,
                devices: action.payload,
                error: ''
            };
        case "GET_DEVICES_ERROR":
            return {
                ...state,
                isLoaded: true,
                devices: null,
                error: action.payload
            };
        case "GET_DEVICE_GROUPS":
            return {
                ...state,
                deviceGroups: action.payload,
                error: ''
            };
        case "GET_DEVICE_GROUPS_ERROR":
            return {
                ...state,
                deviceGroups: null,
                error: action.payload
            };
        case "GET_DEVICE_FEEDS":
            // state.selectedDevice.feeds = action.payload;
            // state.error ='';
            // return state;
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    feeds: action.payload
                },
                error: ''
            };
        case "GET_DEVICE_FEEDS_ERROR":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    feeds: null
                },
                error: action.payload
            };
        case "GET_DEVICE_RULES":
            // state.selectedDevice.rules = action.payload;
            // state.error ='';
            // return state;
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    rules: action.payload
                },
                error: ''
            };
        case "GET_DEVICE_RULES_ERROR":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    rules: null
                },
                error: action.payload
            };
        case "GET_FEED_RULES":
            // state.selectedDevice.feedRules = action.payload;
            // state.error ='';
            // return state;
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    feedRules: action.payload
                },
                error: ''
            };
        case "GET_FEED_RULES_ERROR":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    feedRules: null
                },
                error: action.payload
            };
        case "GET_DEVICE_DETAILS":
            // state.selectedDevice.datails = action.payload;
            // state.error ='';
            // return state;
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    details: action.payload
                },
                error: ''
            };
        case "GET_DEVICE_DETAILS_ERROR":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    details: null
                },
                error: action.payload
            };
        case "GET_SETTINGS":
            // state.selectedDevice.settings = action.payload;
            // state.error ='';
            // return state;
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    settings: action.payload
                },
                error: ''
            };
        case "GET_SETTINGS_ERROR":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    settings: null
                },
                error: action.payload
            };
        case "DEVICE_UPDATED":
            return {
                ...state,
                updateDevice: action.payload,
                updateDeviceError: ''
            };
        case "DEVICE_UPDATE_ERROR":
            return {
                ...state,
                updateDevice: null,
                updateDeviceError: action.payload
            };
        case "DEVICE_CONFIG_UPDATED":
            return {
                ...state,
                updateDeviceConfig: action.payload,
                selectedDevice: {
                    ...state.selectedDevice,
                    details: action.payload
                },
                updateDeviceConfigError: ''
            };
        case "DEVICE_CONFIG_UPDATE_ERROR":
            return {
                ...state,
                updateDeviceConfig: null,
                updateDeviceConfigError: action.payload
            };
        case "CLEAR_DEVICE_CONFIG_DATA":
            return {
                ...state,
                updateDeviceConfig: null,
                updateDeviceConfigError: ''
            };
        case "DEVICE_ADDED":
            return {
                ...state,
                addDevice: action.payload,
                addDeviceError: ''
            };
        case "DEVICE_ADD_ERROR":
            return {
                ...state,
                addDevice: null,
                addDeviceError: action.payload
            };
        case "CLEAR_DEVICE_DATA":
            return {
                ...state,
                selectedDevice: {},
                appconfigNBIOT: null,
                updateDevice: null,
                updateDeviceError: '',
                updateFeed: null,
                updateFeedError: '',
                addDevice: null,
                addDeviceError: ''
            };
        case "FEED_UPDATED":
            return {
                ...state,
                updateFeed: action.payload,
                updateFeedError: ''
            };
        case "FEED_UPDATE_ERROR":
            return {
                ...state,
                updateFeed: null,
                updateFeedError: action.payload
            };
        case "CLEAR_FEED_DATA":
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    feedRules: null
                },
                updateFeed: null,
                updateFeedError: ''
            };
        case "FEED_RULE_UPDATED":
            return {
                ...state,
                updateFeedRule: action.payload,
                updateFeedRuleError: ''
            };
        case "FEED_RULE_UPDATE_ERROR":
            return {
                ...state,
                updateFeedRule: null,
                updateFeedRuleError: action.payload
            };
        case "CLEAR_FEED_RULE_DATA":
            return {
                ...state,
                updateFeedRule: null,
                updateFeedRuleError: ''
            };
        case "DEVICE_RULE_UPDATED":
            return {
                ...state,
                updateDeviceRule: action.payload,
                updateDeviceRuleError: ''
            };
        case "DEVICE_RULE_UPDATE_ERROR":
            return {
                ...state,
                updateDeviceRule: null,
                updateDeviceRuleError: action.payload
            };
        case "CLEAR_DEVICE_RULE_DATA":
            return {
                ...state,
                updateDeviceRule: null,
                updateDeviceRuleError: ''
            };
        case "DEVICE_GROUP_UPDATED":
            return {
                ...state,
                updateDeviceGroup: action.payload,
                updateDeviceGroupError: ''
            };
        case "DEVICE_GROUP_UPDATE_ERROR":
            return {
                ...state,
                updateDeviceGroup: null,
                updateDeviceGroupError: action.payload
            };
        case "DEVICE_GROUP_ADDED":
            return {
                ...state,
                addDeviceGroup: action.payload,
                addDeviceGroupError: ''
            };
        case "DEVICE_GROUP_ADD_ERROR":
            return {
                ...state,
                addDeviceGroup: null,
                addDeviceGroupError: action.payload
            };
        case "DEVICE_GROUP_DELETED":
            return {
                ...state,
                deleteDeviceGroup: action.payload,
                deleteDeviceGroupError: ''
            };
        case "DEVICE_GROUP_DELETE_ERROR":
            return {
                ...state,
                deleteDeviceGroup: null,
                deleteDeviceGroupError: action.payload
            };
        case "CLEAR_DEVICE_GROUP_DATA":
            return {
                ...state,
                updateDeviceGroup: null,
                updateDeviceGroupError: '',
                addDeviceGroup: null,
                addDeviceGroupError: '',
                deleteDeviceGroup: null,
                deleteDeviceGroupError: ''
            };
        case "GET_OTA_LOGS":
            return {
                ...state,
                OTALogs: action.payload,
                OTALogsError: ''
            };
        case "GET_OTA_LOGS_ERROR":
            return {
                ...state,
                OTALogs: null,
                OTALogsError: action.payload
            };
        case "CLEAR_OTA_LOGS_DATA":
            return {
                ...state,
                OTALogs: null,
                OTALogsError: ''
            };
        case "GET_DEVICE_JOB_DETAILS":
            return {
                ...state,
                devieJobDetails: action.payload,
                devieJobDetailsError: ''
            };
        case "GET_DEVICE_JOB_DETAILS_ERROR":
            return {
                ...state,
                devieJobDetails: null,
                devieJobDetailsError: action.payload
            };
        case "CLEAR_DEVICE_JOB_DETAILS_DATA":
            return {
                ...state,
                devieJobDetails: null,
                devieJobDetailsError: ''
            };
        case "SET_NBIOT_SETTINGS":
            return {
                ...state,
                appconfigNBIOT: action.payload
            };
        case "CLEAR_NBIOT_SETTINGS":
            return {
                ...state,
                appconfigNBIOT: null
            };
        default:
            return state;
    }
}

const DevicesProvider = ({ children }) => {
    const [data, setData] = React.useReducer(rootReducer, {
        isLoaded: false,
        devices: null,
        deviceGroups: null,
        selectedDevice: {},
        error: ''
    });
    return (
        <DevicesContext.Provider value={{ data, setData }}>
            {children}
        </DevicesContext.Provider>
    );
};

const useDevicesState = () => {
    const context = React.useContext(DevicesContext);
    return context;
};

export function getDevices(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices`).then(res => {
        dispatch({ type: "GET_DEVICES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICES_ERROR", payload: err });
    });
}

export function getDeviceGroups(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/devices-groups`).then(res => {
        dispatch({ type: "GET_DEVICE_GROUPS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICE_GROUPS_ERROR", payload: err });
    });
}

export function getDeviceFeeds(dispatch, deviceId) {
    return axios.get(`/${localStorage.getItem("organization")}/feeds?device__device_id=${deviceId}`).then(res => {
        dispatch({ type: "GET_DEVICE_FEEDS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICE_FEEDS_ERROR", payload: err });
    });
}

export function getDeviceRules(dispatch, deviceId) {
    return axios.get(`/${localStorage.getItem("organization")}/devices/${deviceId}/alarm-rules`).then(res => {
        dispatch({ type: "GET_DEVICE_RULES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICE_RULES_ERROR", payload: err });
    });
}

export function getFeedRules(dispatch, feedId) {
    return axios.get(`/${localStorage.getItem("organization")}/feeds/rules/?feed=${feedId}`).then(res => {
        dispatch({ type: "GET_FEED_RULES", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_FEED_RULES_ERROR", payload: err });
    });
}

export function getDeviceDetails(dispatch, deviceId) {
    return axios.get(`/${localStorage.getItem("organization")}/devices/${deviceId}`).then(res => {
        dispatch({ type: "GET_DEVICE_DETAILS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICE_DETAILS_ERROR", payload: err });
    });
}

export function getSettings(dispatch) {
    return axios.get(`/${localStorage.getItem("organization")}/users/${localStorage.getItem("email")}/settings`).then(res => {
        dispatch({ type: "GET_SETTINGS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_SETTINGS_ERROR", payload: err });
    });
}

export function updateDevice(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/devices/${values.device_id}`, values).then(res => {
        dispatch({ type: "DEVICE_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_UPDATE_ERROR", payload: err });
    });
}

export function updateDeviceConfig(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/devices/${values.device_id}`, values).then(res => {
        dispatch({ type: "DEVICE_CONFIG_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_CONFIG_UPDATE_ERROR", payload: err });
    });
}

export function addDevice(dispatch, values) {
    return axios.post(`/${localStorage.getItem("organization")}/devices`, values).then(res => {
        dispatch({ type: "DEVICE_ADDED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_ADD_ERROR", payload: err });
    });
}

export function updateFeed(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/feeds/${values.key}`, values).then(res => {
        dispatch({ type: "FEED_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "FEED_UPDATE_ERROR", payload: err });
    });
}

export function updateFeedRule(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/feeds/rules/${values.id}`, values).then(res => {
        dispatch({ type: "FEED_RULE_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "FEED_RULE_UPDATE_ERROR", payload: err });
    });
}

export function updateDeviceRule(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/devices-alarm-rules/${values.key}`, values).then(res => {
        dispatch({ type: "DEVICE_RULE_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_RULE_UPDATE_ERROR", payload: err });
    });
}

export function updateDeviceGroup(dispatch, values) {
    return axios.patch(`/${localStorage.getItem("organization")}/devices-groups/${values.id}`, values).then(res => {
        dispatch({ type: "DEVICE_GROUP_UPDATED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_GROUP_UPDATE_ERROR", payload: err });
    });
}

export function addDeviceGroup(dispatch, values) {
    return axios.post(`/${localStorage.getItem("organization")}/devices-groups`, values).then(res => {
        dispatch({ type: "DEVICE_GROUP_ADDED", payload: res.data });
    }).catch(err => {
        dispatch({ type: "DEVICE_GROUP_ADD_ERROR", payload: err });
    });
}

export function deleteDeviceGroup(dispatch, id) {
    return axios.delete(`/${localStorage.getItem("organization")}/devices-groups/${id}`).then(res => {
        dispatch({ type: "DEVICE_GROUP_DELETED", payload: 'Deleted Successfully' });
    }).catch(err => {
        dispatch({ type: "DEVICE_GROUP_DELETE_ERROR", payload: err });
    });
}

export function getOTALogs(dispatch, deviceId) {
    return axios.get(`/${localStorage.getItem("organization")}/devices-jobs?devices__device_id=${deviceId}`).then(res => {
        dispatch({ type: "GET_OTA_LOGS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_OTA_LOGS_ERROR", payload: err });
    });
}

export function getDeviceJobDetails(dispatch, jobId, deviceId) {
    return axios.get(`/${localStorage.getItem("organization")}/devices-jobs/${jobId}/device/${deviceId}`).then(res => {
        dispatch({ type: "GET_DEVICE_JOB_DETAILS", payload: res.data });
    }).catch(err => {
        dispatch({ type: "GET_DEVICE_JOB_DETAILS_ERROR", payload: err });
    });
}


export { DevicesProvider, DevicesContext, useDevicesState };