import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List, Typography } from "@material-ui/core";
// import {
//   Home as HomeIcon,
//   NotificationsNone as NotificationsIcon,
//   FormatSize as TypographyIcon,
//   FilterNone as UIElementsIcon,
//   BorderAll as TableIcon,
//   QuestionAnswer as SupportIcon,
//   LibraryBooks as LibraryIcon,
//   HelpOutline as FAQIcon,
//   ArrowBack as ArrowBackIcon,
// } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 6, type: "divider" },
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <Icons.Map /> },
  // { id: 3, label: "Draw Polygon", link: "/app/polygonmap", icon: <Icons.EditLocation /> },
  //{ id: 1, label: "Home", link: "/app/home", icon: <HomeIcon /> },
  // { id: 2, label: "Devices", link: "/app/devices", icon: <HomeIcon /> },
  // { id: 3, label: "Devices-Hex", link: "/app/deviceshex", icon: <HomeIcon /> },
  {
    id: 4,
    label: "Device Details",
    link: "/app/deviceDetails",
    icon: <Icons.Assignment/>,
  },
  // {
  //   id: 5,
  //   label: "Device-Management",
  //   link: "/app/deviceManagement",
  //   icon: <HomeIcon />,
  // },
  {
    id: 5,
    label: "Device Management",
    link: "/app/deviceManagement/manageDevices",
    icon: <Icons.Settings/>,
    // children: [
    //   { label: "Manage Devices", link: "/app/deviceManagement/manageDevices" },
    //   // { label: "Trigger Settings", link: "/app/deviceManagement/triggerSettings" }
    // ]
  },
  // {
  //   id: 6,
  //   label: "Devices New",
  //   link: "/app/devicesNew",
  //   icon: <Icons.Assignment/>,
  // },
  { id: 7, type: "divider" },
  // {
  //   id: 1,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 3,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div> */}
       <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          {layoutState.isSidebarOpened ? (
            <Icons.Close
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "#2C7873" }}
            />
          ) : (
              <Icons.Dehaze
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }} style={{ color: "#2C7873" }}
              />
            )}
        </IconButton>

      </div>
      {isSidebarOpened === true ?
        <div style={{marginTop : "5%"}}>
        <img style={{ marginLeft: "15%" }} src="icon.png" width="41" height="40" alt="icon"/>
       <Typography style={{ marginTop: "5%",float:"right", marginRight:"25%", fontFamily: "'Karla', sans-serif",  fontWeight: "bold" }} >Hi, {localStorage.getItem("first_name")}</Typography>
       </div>
        : null}
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      {/* {isSidebarOpened === true ?
        <div style={{marginTop : "5%"}}>
        <img style={{ marginLeft: "5%" }} src="icon.png" width="41" height="40" alt="icon"/>
       <Typography style={{ marginTop: "5%",float:"right", fontSize:'13px', marginRight:"5%", fontFamily: "'Karla', sans-serif",  fontWeight: "bold" }} >Powered by SpaceAge Labs</Typography>
       </div>
        : null} */}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
