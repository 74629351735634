import * as React from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui"; //styled
import FilterComponent from "./FilterComponent";
import { createTheme } from "baseui";

export default function (props) {
  const primitives = {
    accent: "#2C7873",
  };
  const overrides = {
    colors: {
      buttonPrimaryFill: primitives.accent,
      buttonPrimaryHover: "#1f635e",
      sliderPrimaryColor: "#2C7873",
      sliderSecondaryColor: "rgba(255,255,255, 0.7)",
      buttonPrimaryText: "#2C7873",
    },
  };

  const customTheme = createTheme(primitives, overrides);

  const engine = new Styletron();
  // const Centered = styled("div", {
  //   display: "flex",
  //   flexFlow: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   height: "100%",
  // });

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={customTheme}>
        {/* <Centered> */}
          <FilterComponent {...props}/>
        {/* </Centered> */}
      </BaseProvider>
    </StyletronProvider>
  );
}
