import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  card: {
    minWidth: "100%",
    backgroundImage: props => `url(${props.card})`,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    color: "#fff",
  },
  cardMedia: {
    minWidth: "100%",
  },
  media: {
    height: "140px",
  },
  starIcon: {
    color: "#ffc247",
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  backbtn: {
    borderRadius: '18px',
    fontSize: '14px',
    color: '#2C7873',
    fontFamily: 'Karla',
    fontWeight: 'bold',
    backgroundColor: '#F1FCFC',
    position: 'relative',
    marginRight:'12px',
    padding: '3px 12px',
    marginTop:'2%'
},
  locationName: {
    flexGrow: 1,
    fontSize: '25px',
    fontFamily: 'Rubik',
    fontWeight : 'bold',
    color: '#2C7873',
    //textAlign:'center',
    marginLeft:'1%',
    marginBottom :'2%'
},
appBar: {
  position: 'relative',
},
title: {
  marginLeft: theme.spacing(2),
  flex: 1,
},
}));
