import * as React from "react";
// import { Slider } from "baseui/slider";
// import VolumeGraph from "./VolumeGraphs";
// import { useMockData } from "./hooks/useMockData";
import { styled } from "styletron-react";
import { useStyletron } from "baseui";
// import { getTrackBackground } from "react-range";
import { Button, SHAPE } from "baseui/button"; //SIZE
// import { ButtonGroup } from "baseui/button-group";

import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const Centered = styled("div", {
  display: "flex",
  flexFlow: "column",
  justifyContent: "normal",
  alignItems: "center",
  height: "100%",
});

// const ThumbValue = styled('div', ({$theme}) => ({
//   position: 'absolute',
//   top: '-3em',
//   //width:'75px',
//   textAlign: 'center',
//   background: 'black',
//   color: 'white',
//   borderRadius: '5px',
//   width: '130px'
// }));

export default (props) => {
  //console.log("rendering filter component");
  // let mockData = useMockData();
  const [css] = useStyletron();
  // const [sliderValue, setSliderValue] = React.useState([0, 100]);
  // const [displayData, setDisplayData] = React.useState([]);
  // const [chartType, setChartType] = React.useState("bar");
  // const [playState, setPlayState] = React.useState(false);
  const [isPlaying, setisPlaying] = React.useState(false)

  // let onSliderMoved = ({ value }) => setSliderValue(value);

  // let onSliderMoveCompleted = ({ value }) => {
  //   let total = mockData.grassCutData.length;
  //   let result = value.map((value) => Math.round((value / 100) * total));
  //   let [startIndex, endIndex] = result;
  //   setDisplayData(mockData.grassCutData.slice(startIndex, endIndex));
  //   console.log('value',value);
  // };

  // let onPlayStateChanged = (value) => {
  //   //console.log("[FilterComponent] onPlayStateChanged " + value);
  //   setPlayState(value);
  // };

  // React.useEffect(() => {
  //   //console.log("[FilterComponent] useEffect");
  //   let mounted = true;
  //   if (mounted) {
  //     setDisplayData(mockData.grassCutData);
  //   }
  //   return () => (mounted = false);
  // }, [mockData]);
  

  // React.useEffect(() => {
  //   //console.log("[FilterComponent] useEffect");
  //   if(isPlaying){
  //     _animate();
  //   } else {
      
  //   }
  // }, [isPlaying]);

  const requestRef = React.useRef()

  let maxFVal = -1;
  const _animate = () => {
    //console.log('animprops',props)
    const {min, max} = props; //value
    let diff = (max - min);
    let offset = diff/120;
    let animationSpeed = 60000
    if(diff>3600000){
      animationSpeed = 300000
    }; //(max - min) / 100 ;//5*60*1000;

    if(offset>animationSpeed){
      animationSpeed = offset;
    }
    // const span = value[1] - value[0];
    // var stopValue = ''

    // let newValueMin = value[0] + animationSpeed;
    // let oldValueMin = value[0];
    // if (newValueMin + span >= max) {
    //   newValueMin = min;
    // }
    // stopValue = value[1] - newValueMin
    if(maxFVal===-1){
      maxFVal = min;
    }
    maxFVal += animationSpeed;
    if(maxFVal > max){
      maxFVal = max;
    }
    props.onChange({
      value: [min, maxFVal]
    });

    if(maxFVal === max){
      cancelAnimationFrame(requestRef.current)
      setisPlaying(false)
    }
    else{
      requestRef.current = requestAnimationFrame(_animate);
    }

    // const span = value[1] - value[0];
    // var stopValue = ''

  }



  const startAnimation  = () => {
    if(isPlaying){
      cancelAnimationFrame(requestRef.current)
      props.onChange({
        value: [props.min, props.max]
      });
      setisPlaying(false)
    } else {

      // props.onChange({
      //   value: [oldValueMin, newValueMin + span]
      // });
      setisPlaying(true)
      // console.log('props.min, props.max', props.min, props.max)
      _animate();
      // _animate()
    }
  }

  // const _renderThumbValue = ({$thumbIndex, $value}) => {
  //   const value = $value[$thumbIndex];
  //   return <ThumbValue>{props.formatLabel(value)}</ThumbValue>
    
  // }
  return (
    <div
      className={css({
        // width: "1122px",
        // height: "131px",
        // marginTop: "60px",
        // borderRadius: "8px",
        // backgroundColor: "rgba(241, 252, 252, 1)",
        // boxShadow: "0px 4px 16px rgba(33, 49, 49, 0.16)",
        // backgroundFilter: "blur(16px)",
        // display: "flex",
        // justifyContent: "flex-end",
        // "@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape)": {
        //   //for landscape layou ipad
        //   width: "768px",
        //   height: "131px",
        // },
        // "@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)": {
        //   /* For portrait layouts only */
        //   width: "768px",
        //   height: "131px",
        //   marginLeft: "0px",
        //   marginRight: "0px",
        // },
      })}
    >
      {/* <div> */}
        {/* <VolumeGraph
          data={displayData}
          chartType={chartType}
          play={playState}
          playStateChanged={onPlayStateChanged}
        /> */}
        {/* <Slider
          {...props}
          // value={sliderValue}
          // onChange={onSliderMoved}
          // onFinalChange={onSliderMoveCompleted}
          overrides={{
            Root: {
              style: {
                top: "10px",
                width: "1003px",
                "@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape)": {
                  width: "647px",
                },
              },
            },
            Track: {
              style: ({ $theme }) => {
                return {
                  paddingTop: 0,
                  paddingBottom: 0,
                };
              },
            },
            InnerTrack: {
              style: ({ $theme, $max, $min, $value }) => {
                return {
                  background: getTrackBackground({
                    values: $value,
                    colors:
                      $value.length === 1
                        ? [$theme.colors.primary, $theme.colors.mono400]
                        : [
                            $theme.colors.sliderSecondaryColor,
                            $theme.colors.sliderPrimaryColor,
                            $theme.colors.sliderSecondaryColor,
                          ],
                    min: $min || 0,
                    max: $max || 0,
                    rtl: false,
                  }),
                };
              },
            },
            InnerThumb: {
              style: {
                display: "none",
              },
            },
            Thumb: {
              style: ({ $theme }) => {
                return {
                  backgroundColor: $theme.colors.sliderPrimaryColor,
                  width: "20px",
                  height: "20px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderLeftColor: $theme.colors.sliderPrimaryColor,
                  borderRightColor: $theme.colors.sliderPrimaryColor,
                  borderTopColor: $theme.colors.sliderPrimaryColor,
                  borderBottomColor: $theme.colors.sliderPrimaryColor,
                };
              },
            },
            TickBar: {
              style: {
                display: "none",
              },
            },
            ThumbValue: _renderThumbValue,
            // ThumbValue: {
            //   style: {
            //     display: "none",
            //   },
            // },
          }}
        />*/}
      {/* </div>  */}
      <div className={css({ width: "120px" })}>
        <Centered>
          {/* <ButtonGroup
            size={SIZE.mini}
            className={css({ marginBottom: "10px" })}
          >
            <Button
              overrides={{
                BaseButton: {
                  style: {
                    color: "#2C7873",
                    fontSize: "12px",
                    fontFamily: "Karla",
                  },
                },
              }}
              onClick={() => chartType !== "bar" && setChartType("bar")}
            >
              BAR
            </Button>
            <Button
              overrides={{
                BaseButton: {
                  style: {
                    color: "#2C7873",
                    fontSize: "12px",
                    fontFamily: "Karla",
                  },
                },
              }}
              onClick={() => chartType !== "line" && setChartType("line")}
            >
              LINE
            </Button>
          </ButtonGroup> */}
          <Button
            onClick={startAnimation}
            shape={SHAPE.round}
            overrides={{
              BaseButton: {
                style: {
                  color: "#FFF",
                  width: "56px",
                  height: "56px",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  marginTop: "20px",
                },
              },
            }}
          >
            {isPlaying ? (
              <PauseIcon title="play"/>
            ) : (
              <PlayArrowIcon title="pause"/>
            )}
          </Button>
        </Centered>
      </div>
    </div>
  );
};
