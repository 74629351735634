import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDevicesState, getSettings } from "../../context/DevicesContext";
// import useStyles from "./styles";

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    noDataMessage: {
        height: 'calc(100vh - 180px)',
        textAlign: 'center',
        paddingTop: '150px'
        // padding-top: 100px;
        // fontFamily: '''Montserrat', sans-serif',
        // display: 'flex',
        // alignItems: 'center'
    }
}));

const panelStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'block'
    }
}));

function BackupSettings(props) {

    var classes = useStyles();
    var panelClasses = panelStyle();

    const context = useDevicesState();

    // const [settings, setsettings] = useState(null)
    const [isBackupAvailable, setisBackupAvailable] = useState(false)
    const [isBackupTimeAvailable, setisBackupTimeAvailable] = useState(false)
    const [formattedBackupTime, setformattedBackupTime] = useState('')
    const [settingsArr, setsettingsArr] = useState([])


    useEffect(() => {
        getSettings(context.setData);
    }, [context.setData,props.device]);

    // useEffect(() => {
    //     console.log('context settings', context.data.selectedDevice)
    //     if (context.data.selectedDevice.settings) {
    //         setsettings(context.data.selectedDevice.settings)
    //         populateSettings();
    //     }
    // }, [context.data.selectedDevice.settings]);


     // let formattedBackupTime;
     const populateSettings = useCallback(() => {
        let settingsList = context.data.selectedDevice.details.settings;
        let templateArr = context.data.selectedDevice.details.template;

        if (settingsList) {
            setisBackupAvailable(true);
            let tempsettingsArr = settingsList.menu

            //set cloud values
            if (templateArr && templateArr.protocol && templateArr.protocol === 'nbiot') {
                for (let menuIndex = 0; menuIndex < tempsettingsArr.length; menuIndex++) {
                    if (tempsettingsArr[menuIndex].title.indexOf('RS-485') !== -1) {
                        let panels = tempsettingsArr[menuIndex].panels;
                        for (let p = 0; p < panels.length; p++) {
                            let fields = panels[p].fields;
                            for (let pf = 0; pf < fields.length; pf++) {
                                if (fields[pf].name === 'No. of Transmissions' && fields[pf].children && fields[pf].children.length > 0 && fields[pf].children[0].paramList) {
                                    let paramList = fields[pf].children[0].paramList;
                                    let cloudData = getTransParamData(templateArr);

                                    for (let pl = 0; pl < paramList.length; pl++) {
                                        let pFields = paramList[pl].fields;
                                        for (let pFieldIndex = 0; pFieldIndex < pFields.length; pFieldIndex++) {
                                            if (pFields[pFieldIndex].isCloudData) {
                                                //pFields['value'] = this.getSlaveName(pl);
                                                let slaveName = '';
                                                if (cloudData && cloudData[pl]) {
                                                    for (let tpf = 0; tpf < cloudData[pl].length; tpf++) {
                                                        if (cloudData[pl][tpf].name === 'slaveName') {
                                                            slaveName = cloudData[pl][tpf].value;
                                                        }
                                                    }
                                                }
                                                tempsettingsArr[menuIndex].panels[p].fields[pf].children[0].paramList[pl].fields[pFieldIndex]['value'] = slaveName;
                                            }
                                            else if (pFields[pFieldIndex].name === 'No. of Params') {

                                                let cparamList = pFields[pFieldIndex].paramList;
                                                if (cparamList) {
                                                    for (let cpl = 0; cpl < cparamList.length; cpl++) {
                                                        if (cparamList[cpl].fields) {
                                                            let cpFields = cparamList[cpl].fields;
                                                            for (let cpf = 0; cpf < cpFields.length; cpf++) {
                                                                if (cpFields[cpf].isCloudData) {
                                                                    let paramName = '';
                                                                    if (cloudData && cloudData[pl]) {
                                                                        for (let tpf = 0; tpf < cloudData[pl].length; tpf++) {
                                                                            if (cloudData[pl][tpf].name === 'dataFields') {

                                                                                let df = cloudData[pl][tpf].fields;
                                                                                if (df && df[cpl] && df[cpl].fields && df[cpl].fields[1] && df[cpl].fields[1].name) {
                                                                                    paramName = df[cpl].fields[1].name;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    tempsettingsArr[menuIndex].panels[p].fields[pf].children[0].paramList[pl].fields[pFieldIndex].paramList[cpl].fields[cpf]['value'] = paramName;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            setsettingsArr(tempsettingsArr);
            if (settingsList.time) {
                let backuptime = moment.utc(settingsList.time, "DD-MM-YYYY hh:mm:ss").toDate();
                setformattedBackupTime(moment(backuptime).format('DD-MM-YYYY hh:mm A'))
                setisBackupTimeAvailable(true)
            } else {
                setisBackupTimeAvailable(false)
            }

            let appConfig = context.data.selectedDevice.settings;
            let appconfigNBIOT = [];
            // let deviceConfigType = '';
            if(context.data.selectedDevice.settings){
            if (context.data.selectedDevice.details.device_type === 'SNRI') {
                // deviceConfigType = 'nbiot';

                let appconfig = appConfig.app_config.NBIOT.menu;

                for (let t = 0; t < appconfig.length; t++) {
                    if (appconfig[t].type === 'title') {
                        // this.appconfigNBIOT.push({ title : appconfig})
                        appconfigNBIOT.push({ type: 'title', title: appconfig[t].title });
                    }
                    else if (appconfig[t].type === 'page') {
                        if (!appconfig[t].panels) {
                            appconfigNBIOT.push({ type: 'page', title: appconfig[t].title });
                        }

                        else {
                            appconfigNBIOT.push({ type: 'page', title: appconfig[t].title, panels: appconfig[t].panels });

                            var tempdataArr = [];
                            var tempSettingsdataArr = [];
                            for (let s = 0; s < settingsArr.length; s++) {
                                if (settingsArr[s].panels) {
                                    var backupPanel = settingsArr[s].panels;

                                    for (let h = 0; h < backupPanel.length; h++) {
                                        if (backupPanel[h].fields) {
                                            var backupPanelFields = backupPanel[h].fields;

                                            for (let b = 0; b < backupPanelFields.length; b++) {
                                                tempdataArr.push({ name: backupPanelFields[b].name, value: backupPanelFields[b].value })
                                                if (backupPanelFields[b].children) {
                                                    for (let tp = 0; tp < tempdataArr.length; tp++) {
                                                        if (backupPanelFields[b].children) {
                                                            if (!tempdataArr[tp]['children']) {
                                                                tempdataArr[tp]['children'] = backupPanelFields[b].children;
                                                            }
                                                            var backupchild = backupPanelFields[b].children;
                                                            for (let bc = 0; bc < backupchild.length; bc++) {
                                                                var paramList = backupchild[bc].paramList;
                                                                if (paramList) {
                                                                    for (let pl = 0; pl < paramList.length; pl++) {
                                                                        var paramListFields = paramList[pl].fields;

                                                                        for (let plf = 0; plf < paramListFields.length; plf++) {
                                                                            if (!tempdataArr[tp]['fieldsList']) {
                                                                                tempdataArr[tp]['fieldsList'] = [];
                                                                            }
                                                                            tempdataArr[tp]['fieldsList'].push(paramListFields[plf]);

                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }

                            for (let a = 0; a < appconfigNBIOT.length; a++) {
                                var aPanels = appconfigNBIOT[a].panels;
                                if (aPanels) {
                                    for (let ap = 0; ap < aPanels.length; ap++) {
                                        var pFields = aPanels[ap].fields
                                        if (pFields) {
                                            for (let pf = 0; pf < pFields.length; pf++) {
                                                tempSettingsdataArr.push({ name: pFields[pf].name, readOnly: pFields[pf].readOnly.toString(), inputType: pFields[pf].inputtype })
                                            }
                                        }
                                    }
                                }

                                for (let td = 0; td < tempdataArr.length; td++) {
                                    for (let sd = 0; sd < tempSettingsdataArr.length; sd++) {
                                        if (tempdataArr[td].name === tempSettingsdataArr[sd].name) {
                                            if (!tempSettingsdataArr[sd]['value']) {
                                                tempSettingsdataArr[sd]['value'] = []
                                            }
                                            tempSettingsdataArr[sd]['value'] = tempdataArr[td].value;

                                        }
                                    }
                                }
                                // tempsetArray = tempSettingsdataArr;
                            }
                            for (let ta = 0; ta < tempdataArr.length; ta++) {
                                for (let a = 0; a < appconfigNBIOT.length; a++) {
                                    var aPanelsT = appconfigNBIOT[a].panels;
                                    if (aPanelsT) {
                                        for (let ap = 0; ap < aPanelsT.length; ap++) {
                                            var pFieldsT = aPanelsT[ap].fields
                                            if (pFieldsT) {
                                                for (let pf = 0; pf < pFieldsT.length; pf++) {
                                                    if (tempdataArr[ta].name === pFieldsT[pf].name) {
                                                        if (!pFieldsT[pf]['currentVal']) {
                                                            pFieldsT[pf]['currentVal'] = [];
                                                        }
                                                        pFieldsT[pf]['currentVal'] = tempdataArr[ta].value;
                                                        if (pFieldsT[pf].children) {
                                                            var child = pFieldsT[pf].children;

                                                            for (let c = 0; c < child.length; c++) {
                                                                if (!child[c]['currentVal']) {
                                                                    child[c]['currentVal'] = [];
                                                                }
                                                                var childFields = child[c].paramList
                                                                if (childFields) {
                                                                    for (let g = 0; g < childFields.length; g++) {
                                                                        if (!childFields[g]['currentVal']) {
                                                                            childFields[g]['currentVal'] = [];
                                                                        }
                                                                        childFields[g]['currentVal'] = 'test';

                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            } else {
                // deviceConfigType = 'sigfox'
            }
        }
            context.setData({type:'SET_NBIOT_SETTINGS', payload:appconfigNBIOT})
        }

        // eslint-disable-next-line
    },[settingsArr, context.data.selectedDevice.details]);

    useEffect(() => {
        // console.log('context settings bkp', context.data)
        if (context.data.selectedDevice.settings && context.data.selectedDevice.details) {
            populateSettings();
        }
    }, [context.data.selectedDevice]);

   

    const getTransParamData = (templateArr) => {
        var clouddata;
        if (templateArr && templateArr.schema) {
            for (var f = 0; f < templateArr.schema.fields.length; f++) {
                if (templateArr.schema.fields[f].name === 'parsed_data') {
                    var childfields = templateArr.schema.fields[f].fields;
                    for (var pf = 0; pf < childfields.length; pf++) {
                        if (childfields[pf].name === 'rs485') {
                            clouddata = childfields[pf].frames;
                            // if(frames[paramIndex]){
                            //   for(var tpf=0;tpf<frames[paramIndex].length;tpf++){
                            //     if(frames[paramIndex][tpf].name == 'slaveName'){
                            //       slaveName = frames[paramIndex][tpf].value;
                            //     }
                            //   }
                            // }
                        }
                    }
                }
            }
        }
        return clouddata;
    }

    return (
        <div>
            {isBackupAvailable && <div>

                {isBackupTimeAvailable && <div style={{ textAlign: 'center' }}>
                    <span> Last Backup at - {formattedBackupTime} </span>
                </div>}

                {
                    <div className={classes.root}>
                        {settingsArr.map((setting, i) => {
                            if (!setting.type) {
                                return (
                                    <ExpansionPanel key={i}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div style={{ fontWeight: 'bold' }}>{setting.title}</div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails classes={panelClasses}>

                                            {setting && setting.panels && setting.panels.map((panel, p) => {
                                                return (
                                                    <div style={{ width: '100%' }} key={p}>
                                                        {panel.name && <div style={{ fontWeight: 'bold' }}>{panel.name}</div>}

                                                        {panel && panel.fields && panel.fields.map((field, f) => {
                                                            return (
                                                                <div style={{ width: '100%' }} key={f}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{ flexGrow: 1, flexBasis: 0 }}>{field.name}</div>
                                                                        {!field.selectOptionLabel ? <div style={{ flexGrow: 1, flexBasis: 0 }}>{field.value}</div> : <div style={{ flexGrow: 1, flexBasis: 0 }}>{field.selectOptionLabel}</div>}
                                                                    </div>
                                                                    {field.childParams && field.childParams.map((cParams, c) => {
                                                                        return (<div style={{ display: 'flex' }} key={c}>
                                                                            <div style={{ flexGrow: 1, flexBasis: 0 }}>{cParams.name}</div>
                                                                            <div style={{ flexGrow: 1, flexBasis: 0 }}>{cParams.value}</div>
                                                                        </div>)
                                                                    })}
                                                                    <div style={{ paddingLeft: 100 }}>
                                                                        {field.children && field.children.map((childrens, c) => {
                                                                            if (!childrens.paramList) {
                                                                                return (<div style={{ display: 'flex' }} key={c}>
                                                                                    <div style={{ flexGrow: 1, flexBasis: 0 }}>{childrens.name}</div>
                                                                                    {!childrens.selectOptionLabel ? <div style={{ flexGrow: 1, flexBasis: 0 }}>{childrens.value}</div> : <div style={{ flexGrow: 1, flexBasis: 0 }}>{childrens.selectOptionLabel}</div>}
                                                                                </div>)
                                                                            }
                                                                            else {
                                                                                return (<div key={c}>
                                                                                    {childrens.paramList && childrens.paramList.map((list, cindex) => {
                                                                                        return (<div key={cindex}>
                                                                                            <div>{list.name}</div>
                                                                                            {list.fields && list.fields.map((paramFields, pf) => {
                                                                                                return (<div key={pf}>
                                                                                                    <div style={{ display: 'flex' }}>
                                                                                                        <div style={{ flexGrow: 1, flexBasis: 0 }}>{paramFields.name}</div>
                                                                                                        <div style={{ flexGrow: 1, flexBasis: 0 }}>{paramFields.value}</div>
                                                                                                    </div>
                                                                                                    {paramFields.paramList && paramFields.paramList.map((pList, pl) => {
                                                                                                        return (<div key={pl}>
                                                                                                            {pList.fields && pList.fields.map((pFields, pf) => {
                                                                                                                return (<div key={pf} style={{ display: 'flex' }}>
                                                                                                                    <div style={{ flexGrow: 1, flexBasis: 0 }}>{pFields.name}</div>
                                                                                                                    <div style={{ flexGrow: 1, flexBasis: 0 }}>{pFields.value}</div>
                                                                                                                </div>)
                                                                                                            })}
                                                                                                        </div>)
                                                                                                    })}
                                                                                                </div>)
                                                                                            })

                                                                                            }
                                                                                        </div>)
                                                                                    })}
                                                                                </div>)
                                                                            }
                                                                        })

                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            } else {
                                return null;
                            }

                        })}


                    </div>
                }
            </div>}
            {!isBackupAvailable && <div className={classes.noDataMessage}>No Backup Settings Found.</div>}
        </div>

    )
}

export default BackupSettings
