import React from "react";
// import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
// import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      borderTop: "1px solid #c4d7d7",
      height:"auto"
      //maxHeight: "250px",
      //overflow: "auto",
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
});

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
      className={classes.root}
    >
      {value === index && <div className={classes.center}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
