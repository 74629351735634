import React, { Component } from 'react';
// import { render } from 'react-dom';
import { _MapContext as MapContext, StaticMap, NavigationControl, FullscreenControl, WebMercatorViewport } from 'react-map-gl';
import { AmbientLight, PointLight, LightingEffect } from '@deck.gl/core';
// import { HexagonLayer } from '@deck.gl/aggregation-layers';
import { GeoJsonLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
import { ColumnLayer } from '@deck.gl/layers';
// import { greatCircle, point, isobands, polygon, pointGrid, isolines, circle } from '@turf/turf';
// import { MinimizeRounded } from '@material-ui/icons';
import { DataFilterExtension } from '@deck.gl/extensions';
// import RangeInput from './range-input';
import moment from 'moment'
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';
// import { Provider as StyletronProvider } from 'styletron-react';
// import { LightTheme, BaseProvider } from 'baseui';
// import { Client as Styletron } from 'styletron-engine-atomic';
// import {ScatterplotLayer} from '@deck.gl/layers';
// import logo from "./logo192.png";


// const image = new Image(192, 192);
// image.src = logo;
// const images = ['pattern', logo];

// export const dataLayer = {
//   id: 'data',
//   type: 'fill',
//   paint: {
//     'fill-color': {
//       property: 'percentile',
//       stops: [
//         [0, '#3288bd'],
//         [1, '#66c2a5'],
//         [2, '#abdda4'],
//         [3, '#e6f598'],
//         [4, '#ffffbf'],
//         [5, '#fee08b'],
//         [6, '#fdae61'],
//         [7, '#f46d43'],
//         [8, '#d53e4f']
//       ]
//     },
//     'fill-opacity': 0.8
//   }
// };

// const dataLayer = {
//   'id': 'pattern-layer',
//   'type': 'fill',
//   // 'source': 'source',
//   'paint': {
//     'fill-pattern': 'pattern',
//     // 'fill-color': {
//     //   property: 'percentile',
//     //   stops: [
//     //     [0, '#3288bd'],
//     //     [1, '#66c2a5'],
//     //     [2, '#abdda4'],
//     //     [3, '#e6f598'],
//     //     [4, '#ffffbf'],
//     //     [5, '#fee08b'],
//     //     [6, '#fdae61'],
//     //     [7, '#f46d43'],
//     //     [8, '#d53e4f']
//     //   ]
//     // },
//     // 'fill-opacity': 0.8
//   },
//   'layout': {
//     // 'icon-image': 'myImage'
//     'fill-pattern': 'pattern'
//   },
//   'images': images
// }

// Set your mapbox token here
const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXR1bGt1bXIiLCJhIjoiY2xyYWNzN2JoMDhyazJsbnh3MWR1aWU2MCJ9.BDBKCFichBcahbwbDOxmjA' //'pk.eyJ1IjoiYW5hYmF1YiIsImEiOiJjazgxNndyY2MwM2puM21wdDZldjRqem44In0.qhIfrQkyF5JfLPM-T9Gr5w';   // eslint-disable-line

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const pointLight1 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-3.807751, 54.104682, 8000]
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight1, pointLight2 });

const dataFilter = new DataFilterExtension({
  filterSize: 1,
  fp64: false
});
// const MS_PER_DAY = 1.64e7;
// const material = {
//   ambient: 0.64,
//   diffuse: 0.6,
//   shininess: 32,
//   specularColor: [51, 51, 51]
// };

const INITIAL_VIEW_STATE = {
  longitude: 103.8146803,
  latitude: 1.4572969,
  zoom: 15,
  minZoom: 5,
  maxZoom: 22,
  pitch: 15,
  bearing: 0
};

const colorRange = [
  [1, 128, 1]
];

const elevationScale = { min: 0, max: 1 };

// function getMax(points) {
//   return points.reduce((max, p) => p.value / 10 > max ? p.value / 10 : max, -Infinity);
// }

// function getMin(points) {
//   let tmin = points.reduce((min, p) => p.value / 10 < min ? p.value / 10 : min, +Infinity);
//   if (tmin <= 0.3) {
//     return 0;
//   } else if (tmin > 0.3 && tmin < 0.7) {
//     return 0.5;
//   } else {
//     return 1.0;
//   }
// }

// const applyToArray = (func, array) => func.apply(Math, array)

const getBoundsForPoints = (cornersLongLat) => {
  // // Calculate corner values of bounds
  // const pointsLong = points.map(point => point.geometry[0])
  // const pointsLat = points.map(point => point.geometry[1])
  // const cornersLongLat = [
  //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
  //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
  // ]
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({ width: window.innerWidth - 84, height: 400 })
    .fitBounds(cornersLongLat, { paddingLeft: (64 + 20 + 450) }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}



/* eslint-disable react/no-deprecated */
export default class DetailMap extends Component {
  static get defaultColorRange() {
    return colorRange;
  }

  constructor(props) {
    super(props);

    // let { data } = props;
    // const timeRange = this._getTimeRange(props.data);
    this.state = {
      elevationScale: elevationScale.min,
      x: 0,
      y: 0,
      tooltip: {},
      radius: 1.5,
      viewState: INITIAL_VIEW_STATE,
      geoJson: null,
      mapStyleType: 'map'
      // timeRange,
      // filterValue: timeRange
    };
    this._renderhoveredItems = this._renderhoveredItems.bind(this);
    this.selectedMaplayer = (this.props.mapLayers.filter(d => d.checked))[0]
  }

  _renderhoveredItems() {
    const { x, y, tooltip } = this.state;

    if (tooltip && tooltip.points) {

      let pmin = tooltip.points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
      // let mind = tooltip.points.filter(d => d.value == tmin);

      return <div className="tooltip" style={{ left: x, top: y }}>
        {/* <div>Device Type: {mind[0].deviceType}</div> */}
        {/*<div>Device: {pmin.deviceType}</div>*/}
        <div>Grass Height: {pmin.value}cm</div>
        <div>Time: {moment(pmin.time).format('DD-MMM-YYYY hh:mm a')}</div>
      </div>
    } else if (tooltip && tooltip.time) {
      return <div className="tooltip" style={{ left: x, top: y }}>
        {/* <div>Device Type: {mind[0].deviceType}</div> */}
        {/*<div>Device: {pmin.deviceType}</div>*/}
        <div>Device: {tooltip.deviceName}</div>
        <div>Grass Height: {tooltip.value / 100}cm</div>
        <div>Time: {moment(tooltip.time).format('DD-MMM-YYYY hh:mm:ss a')}</div>
        {/* <div>Time: {tooltip.timeStamp}</div> */}
      </div>
    }
    return null;
  }
  _renderLayers() {
    // radius = 1, upperPercentile = 100, coverage = 2, locationData
    const { data, mapLayers } = this.props;
    const { filterValue, geoJson } = this.state;
    // console.log('locationData', locationData)


    // let polygons = []
    // if (locationData && locationData.geometry && locationData.geometry.coordinates && locationData.geometry.coordinates.length>0) {
    //     // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
    //     polygons = locationData.geometry.coordinates.map(c => {
    //         let mItem = { ...locationData,
    //             geometry: {
    //                 type: 'Polygon',
    //                 coordinates: [c]
    //             }
    //           }
    //         return mItem;
    //     })
    // }
    // const mapGeoJson = {
    //   "type": "FeatureCollection",
    //   features: polygons
    // }

    let mapData = data;
    this.selectedMaplayer = (mapLayers.filter(d => d.checked))[0];
    // if (!mapLayers[checkedParent].checked) {
    mapData = data.filter((d) => {
      for (let i = 0; i < this.selectedMaplayer.children.length; i++) {
        if (this.selectedMaplayer.children[i].checked && this.selectedMaplayer.children[i].isValid(d[this.selectedMaplayer.propName])) {
          return true;
        }
      }
      return false;
    })
    // }
    let colorArr = this.selectedMaplayer.children.map(child => {
      return child.colorArr;
    })
    let filterPropName = this.selectedMaplayer.propName;

    // let currentViewState = {}
    // if (data && data.length > 0 && data[0].geometry) {
    //   currentViewState = { longitude: data[0].geometry[0], latitude: data[0].geometry[1], zoom: 16 };
    // }
    // this.setState({viewState: currentViewState});

    let viewLayers = [

      new GeoJsonLayer({
        id: 'geojson',
        data: geoJson,
        //opacity: 0.5,
        stroked: true,
        // filled: true,
        extruded: false,
        // wireframe: false,
        getElevation: f => 0, //Math.sqrt(f.properties.valuePerSqm) * 10,
        getFillColor: f => [171, 171, 171, 125], //f.properties.color, //colorArr[f.properties.quality], //
        // getLineColor: [255, 255, 255],
        // pickable: true,
        // onHover: this._onHover,
        // onClick: this._onClick
      }),

      // new HexagonLayer({
      //   id: 'hexagon-layer-all',
      //   data: mapData,
      //   pickable: true,
      //   extruded: false,
      //   radius: this.state.radius,
      //   elevationScale: 0,
      //   getPosition: d => d.geometry,
      //   onHover: ({ object, x, y }) => {
      //     // console.log(object);
      //     this.setState({ tooltip: object, x, y })
      //     //   const tooltip = `${object.centroid.join(', ')}\nCount: ${object.points.length}`;
      //     /* Update tooltip
      //        http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
      //     */
      //   },
      //   getElevationValue: () => 0,
      //   getElevationWeight: () => 0,
      //   elevationRange: [0, 0],
      //   colorDomain: [0, colorArr.length - 1],
      //   colorRange: colorArr,
      //   // [
      //   //   [99, 194, 146, 255],
      //   //   [255, 186, 90, 255],
      //   //   [235, 69, 89, 255]
      //   // ],
      //   getColorValue: (points) => {
      //     // console.log('domain', points);
      //     let pmin = points.reduce((min, p) => p.value < min.value ? p : min, { value: +Infinity });
      //     // let mind = points.filter(d=>d.value==tmin);
      //     // if(mind[0].deviceType=='D1'){
      //     //   return 0;
      //     // }
      //     // if(mind[0].deviceType=='D2'){
      //     //   return 1;
      //     // }
      //     // if(mind[0].deviceType=='D3'){
      //     //   return 2;
      //     // }
      //     let colorIndex = 0

      //     let children = this.selectedMaplayer.children;
      //     for (let i = 0; i < children.length; i++) {
      //       if (children[i].checked && children[i].isValid(pmin[filterPropName])) {
      //         colorIndex = i;
      //       }
      //     }
      //     // console.log('points',points,tmin, colorIndex);
      //     return colorIndex;

      //     // if (tmin <= 3) {
      //     //   return 0;
      //     // } else if (tmin > 3 && tmin <= 7) {
      //     //   return 1;
      //     // } else {
      //     //   return 2;
      //     // }
      //   },
      //   // // props added by DataFilterExtension
      //   // getFilterValue: d => d.value,  // in seconds
      //   // filterRange: [0, 3],  // 12:00 - 13:00

      //   // // Define extensions
      //   // extensions: [new DataFilterExtension({ filterSize: 1 })]
      // })
    ];

    if (filterValue) {
      // console.log('dData', mapData)
      viewLayers.push(new ColumnLayer({
        id: 'column-layer',
        data: mapData,
        diskResolution: 6,
        radius: 1.5,
        extruded: false,
        stroked: false,
        pickable: true,
        elevationScale: 0,
        getPosition: d => d.geometry,
        getLineColor: [0, 0, 0],
        getElevation: d => 0,
        getFilterValue: d => d.timeStamp,
        // getLineWidth: 1,
        // lineWidthMinPixels: 1,
        // lineWidthMaxPixels: 1,
        getFillColor: d => {
          // [99, 194, 146, 200]

          let colorIndex = 0;
          let children = this.selectedMaplayer.children;
          for (let i = 0; i < children.length; i++) {
            if (children[i].checked && children[i].isValid(d[filterPropName])) {
              colorIndex = i;
            }
          }
          return colorArr[colorIndex];
        },
        filterRange: [filterValue[0], filterValue[1]],
        // filterSoftRange: [
        //   filterValue[0] * 0.9 + filterValue[1] * 0.1,
        //   filterValue[0] * 0.1 + filterValue[1] * 0.9
        // ],
        extensions: [dataFilter],
        onHover: ({ object, x, y }) => {
          // console.log('tooltip', object);
          this.setState({ tooltip: object, x, y })
        }
      }))
    }

    return viewLayers;
  }

  modifyGeoJson() {
    let { locationData } = this.props;
    let polygons = []
    if (locationData && locationData.geometry && locationData.geometry.coordinates && locationData.geometry.coordinates.length > 0) {
      // console.log('item.geometry.coordinates'+i, item.geometry.coordinates);
      polygons = locationData.geometry.coordinates.map(c => {
        let mItem = {
          ...locationData,
          geometry: {
            type: 'Polygon',
            coordinates: [c]
          }
        }
        return mItem;
      })
    }
    const mapGeoJson = {
      "type": "FeatureCollection",
      features: polygons
    }
    // console.log('modifyGeoJson',mapGeoJson)
    this.setState({ geoJson: mapGeoJson });
  }
  componentDidMount() {
    this.modifyGeoJson()
    // this.setViewPort();

    let { locationData, filterValue } = this.props;
    if (locationData && locationData.properties && locationData.properties.cornersLatLng) {
      const bounds = getBoundsForPoints(locationData.properties.cornersLatLng)
      let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
      this.setState({ viewState: currentViewState });
    }
    if (filterValue) {
      this.setState({ filterValue: filterValue });
    }

    if (this.props.mapStyleType) {
      this.setState({ mapStyleType: this.props.mapStyleType })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.locationData !== this.props.locationData) {
      let { locationData } = this.props;

      this.modifyGeoJson();

      if (locationData && locationData.properties && locationData.properties.cornersLatLng) {
        const bounds = getBoundsForPoints(locationData.properties.cornersLatLng)
        let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
        this.setState({ viewState: currentViewState });
      }
    }
    // if (prevProps.data !== this.props.data) {
    //   if (this.props.data && this.props.data.length > 0) {
    // const timeRange = this._getTimeRange(this.props.data);
    // this.setState({ timeRange, filterValue: timeRange });


    /**** disabling zoom to data ***/
    // // Calculate corner values of bounds
    // const pointsLong = this.props.data.map(point => point.geometry[0])
    // const pointsLat = this.props.data.map(point => point.geometry[1])
    // // pointsLong.push(this.props.locationData.properties.cornersLatLng[0][0])
    // // pointsLong.push(this.props.locationData.properties.cornersLatLng[1][0])
    // // pointsLat.push(this.props.locationData.properties.cornersLatLng[0][1])
    // // pointsLat.push(this.props.locationData.properties.cornersLatLng[1][1])
    // const cornersLongLat = [
    //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
    // ]
    // const bounds = getBoundsForPoints(cornersLongLat)
    // let currentViewState = { ...bounds, bearing: 0, pitch: 15, transitionDuration: 1000 };
    // this.setState({ viewState: currentViewState });
    //   }
    // }

    if (prevProps.filterValue !== this.props.filterValue) {
      // console.log('filterValue', this.props.filterValue)
      this.setState({ filterValue: this.props.filterValue });
    }

    if (prevProps.mapStyleType !== this.props.mapStyleType) {
      this.setState({ mapStyleType: this.props.mapStyleType })
    }

  }

  render() {
    let mapStyle = '';
    const { mapStyleType } = this.state;
    if (mapStyleType === 'satellite') {
      mapStyle = 'mapbox://styles/mapbox/satellite-v9';
    } else {
      mapStyle = 'mapbox://styles/sla/cjnci1u153tqz2ro5eka7t0ef'
    }
    // const pointdata = greatCircle([0, 0], [100, 10])

    // const { data } = this.props;

    // const { timeRange, filterValue } = this.state;

    // let currentViewState = {}
    // if (data && data.length > 0 && data[0].geometry) {
    //   currentViewState = { longitude: data[0].geometry[0], latitude: data[0].geometry[1], zoom: 16 };
    // }

    // this.setState({viewState: currentViewState});
    // const engine = new Styletron();
    return (
      // <StyletronProvider value={engine}>
      //   <BaseProvider theme={LightTheme}>

      <DeckGL
        layers={this._renderLayers()}
        effects={[lightingEffect]}
        initialViewState={INITIAL_VIEW_STATE}
        viewState={this.state.viewState}
        // onViewStateChange={(viewState) => {
        //   // you can manipulate the viewState here if needed
        //   // currentViewState = viewState;
        //   this.setState({viewState: viewState});
        //   // deck.setProps({viewState: currentViewState});
        // }}
        onViewStateChange={(v) => {
          this.setState({ viewState: v.viewState });
          // console.log(v)
          // switch(v.viewState.zoom){
          // if(v.viewState.zoom<10){
          //   this.setState({radius: 250})
          // } else if (v.viewState.zoom>10&&v.viewState.zoom<=11){
          //   this.setState({radius: 200})
          // } else if (v.viewState.zoom>11&&v.viewState.zoom<=12){
          //   this.setState({radius: 150})
          // } else if (v.viewState.zoom>12&&v.viewState.zoom<=13){
          //   this.setState({radius: 100})
          // } else if (v.viewState.zoom>13&&v.viewState.zoom<=14){
          //   this.setState({radius: 100})
          // } else if (v.viewState.zoom>14&&v.viewState.zoom<=15){
          //   this.setState({radius: 50})
          // } else {
          //   this.setState({radius: 5})
          // }
          // }
          // this.setState({radius: (30-v.viewState.zoom)*2})
        }}
        controller={true}
        ContextProvider={MapContext.Provider}
      >
        <StaticMap
          reuseMaps
          mapStyle={mapStyle}
          preventStyleDiffing={true}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >

          {/* <Source type="geojson" data={mygeojson}>
            <Layer {...dataLayer} />
          </Source> */}
        </StaticMap>

        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
            onClick={() => this.setState({ mapStyleType: 'satellite' })}
            hidden={mapStyleType === 'satellite'} >
            <SatelliteIcon variant="filled"></SatelliteIcon>
          </div>
          <div style={{ backgroundColor: '#fff', textAlign: 'centre', borderRadius: '5px', cursor: 'pointer', border: 'solid 1px #ccc' }}
            onClick={() => this.setState({ mapStyleType: 'map' })}
            hidden={mapStyleType === 'map'}>
            <MapIcon variant="filled"></MapIcon>
          </div>
          <FullscreenControl container={document.querySelector('body')} />
          <NavigationControl showCompass={false} />
        </div>

        {this._renderhoveredItems}
      </DeckGL>
      // /* {timeRange && (
      //   <RangeInput
      //     min={timeRange[0]}
      //     max={timeRange[1]}
      //     value={filterValue}
      //     animationSpeed={1000}
      //     formatLabel={this._formatLabel}
      //     onChange={({ value }) => this.changeVal(value)}
      //   />
      // )} */

      // /* MS_PER_DAY * 0.007 */
      //   </BaseProvider>
      // </StyletronProvider> 
    );
  }

  _getTimeRange(data) {
    if (!data) {
      return null;
    }
    return data.reduce(
      (range, d) => {
        const t = d.timeStamp;
        range[0] = Math.min(range[0], t)
        range[1] = Math.max(range[1], t)
        return range;
      },
      [Infinity, -Infinity]
    );
  }

  _formatLabel(t) {
    const date = new Date(t);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short'
    }).replace(/ /g, '-');
    const formattedTime = date.toLocaleTimeString([],
      { hour: '2-digit', minute: '2-digit' })
    return formattedDate + '-' + formattedTime
  }
  changeVal(val) {
    this.setState({ filterValue: val })
  }
}

// export function renderToDOM(container) {
//   render(<App />, container);

//   require('d3-request').csv(DATA_URL, (error, response) => {
//     if (!error) {
//       const data = response.map(d => [Number(d.lng), Number(d.lat)]);
//       render(<App data={data} />, container);
//     }
//   });
// }


// const mygeojson = {
//   "type": "FeatureCollection",
//   "features": [{
//     "type": "Feature",
//     "properties": {
//       "shape": "Polygon",
//       "maps": ["default-map"],
//       "name": "SLA -MK13-1475 Cochrane Lodge 1 & 2",
//       "category": "default",
//       "id": "location1",
//       color: [10, 207, 131, 255],
//       area: 15.2,
//       coverage: 10.3,
//       coveragePercent: 81.2,
//       quality: {
//         category: "Good",
//         percent: "85"
//       },
//       contractor: {
//         name: 'Lee Cooper'
//       },
//       ophrs: 40,
//       equipments: {
//         total: 15,
//         list: [
//           { type: 'Type 1', count: 4 },
//           { type: 'Type 2', count: 5 },
//           { type: 'Type 3', count: 6 }
//         ]
//       }

//     },
//     "geometry": {
//       "type": "Polygon",
//       "coordinates": [
//         [
//           [103.791275, 1.352394],
//           [103.805008, 1.343985],
//           [103.817368, 1.332487],
//           [103.832302, 1.327167],
//           [103.847237, 1.332316],
//           [103.860455, 1.330428],
//           [103.858395, 1.345015],
//           [103.854446, 1.36046],
//           [103.859596, 1.368011],
//           [103.857021, 1.375047],
//           [103.791275, 1.352394]
//         ]
//       ]
//     }
//   }, {
//     "type": "Feature",
//     "properties": {
//       "shape": "Polygon",
//       "maps": ["default-map"],
//       "name": "URA - SEMBAWANG DRIVE HVP",
//       "category": "default",
//       "id": "location2",
//       color: [235, 69, 89, 255],
//       area: 16.2,
//       coverage: 11.3,
//       coveragePercent: 80.2,
//       quality: {
//         category: "Good",
//         percent: 86
//       },
//       contractor: {
//         name: 'Edwards'
//       },
//       ophrs: 40,
//       equipments: {
//         total: 16,
//         list: [
//           { type: 'Type 1', count: 4 },
//           { type: 'Type 2', count: 7 },
//           { type: 'Type 3', count: 6 }
//         ]
//       }
//     },
//     "geometry": {
//       "type": "Polygon",
//       "coordinates": [
//         [
//           [103.7887, 1.35514],
//           [103.857193, 1.379252],
//           [103.857193, 1.382083],
//           [103.857193, 1.388261],
//           [103.854446, 1.394783],
//           [103.844833, 1.395812],
//           [103.837624, 1.393066],
//           [103.824406, 1.391522],
//           [103.813591, 1.394439],
//           [103.777714, 1.383456],
//           [103.779259, 1.377107],
//           [103.781662, 1.367668],
//           [103.783207, 1.360117],
//           [103.7887, 1.35514]
//         ]
//       ]
//     }
//   }, {
//     "type": "Feature",
//     "properties": {
//       "shape": "Polygon",
//       "maps": ["default-map"],
//       "name": "Woodland",
//       "category": "default",
//       "id": "location3",
//       color: [10, 207, 131, 255],
//       area: 15.2,
//       coverage: 10.3,
//       coveragePercent: 81.2,
//       quality: {
//         category: "Good",
//         percent: "85"
//       },
//       contractor: {
//         name: 'Lee Cooper'
//       },
//       ophrs: 40,
//       equipments: {
//         total: 15,
//         list: [
//           { type: 'Type 1', count: 4 },
//           { type: 'Type 2', count: 5 },
//           { type: 'Type 3', count: 6 }
//         ]
//       }
//     },
//     "geometry": {
//       "type": "Polygon",
//       "coordinates": [
//         [
//           [103.812078, 1.458674],
//           [103.814632, 1.459114],
//           [103.815361, 1.460562],
//           [103.815125, 1.460626],
//           [103.814771, 1.459919],
//           [103.811713, 1.459436],
//           [103.812078, 1.458674]
//         ]
//       ]
//     }
//   }]
// }