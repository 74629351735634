import React from 'react'
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { helptext } from '../helptext';

const useCustomTooltipStyle = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '14px'
    },
}));

function InfoTooltip(props) {
    const tooltipClasses = useCustomTooltipStyle();
    if (props && props.contentKey && helptext && helptext[props.contentKey]) {
        // console.log('InfoTooltip', props, helptext, helptext[props.contentKey])
        return (
            <Tooltip arrow title={helptext[props.contentKey]} placement="right" classes={tooltipClasses}>
                <InfoIcon fontSize="small" color="primary" />
            </Tooltip>
        )
    } else {
        return null;
    }
}

export default InfoTooltip
