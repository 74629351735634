import React, { useState, useEffect } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";  //{ MTableToolbar }
// import { useHistory, useLocation } from "react-router-dom";
// import moment from 'moment'

// const StyledMTableToolbar = withStyles({
//     root: {
//         minHeight: '1px',
//         height: '5px',
//         paddingRight: "2%",
//         display: 'contents'
//     },
//     actions: {
//         // marginTop: "-15%",
//         minHeight: '5px',
//         //height: '35px',
//     },
// })(MTableToolbar);

export default function DeviceDataTable(props) {
    const [deviceData, setdeviceData] = useState([])
    // const location = useLocation();
    // const history = useHistory();

    useEffect(() => {
        let dataArr = props.data.map((item, index) => {
            return {
                time: item.time,
                calibratedheight : item.calibratedheight,
                source: item.source,
                status: item.status,
                rebootCounter: item.rebootCounter,
                batterylevel: item.batterylevel,
                rssi: item.rssi,
                lastSeenTime: item.lastSeenTime
            };
        })
        setdeviceData(dataArr);

    }, [props.data])
    return (
        <div style={{ maxWidth: "100%" }} className="btmtable">
            <MaterialTable
                title='' //{'Device - ' + deviceAliasName}
                columns={[
                    { title: "DATE/TIME", field: "lastSeenTime" },
                    { title: "RSSI (dBm)", field: "rssi" },
                    {
                        title: "BATTERY LEVEL (%)",
                        field: "batterylevel",
                        grouping: false,
                        headerStyle: { textAlign: "left" },
                    },
                    { title: "CALIBRATED HEIGHT (cm)", field: "calibratedheight" },
                    { title: "SOURCE", field: "source" },
                    {
                        title: "STATUS",
                        field: "status",
                        grouping: false,
                        headerStyle: { textAlign: "left" },
                    },
                    { title: "REBOOT COUNTER", field: "rebootCounter" },
                ]}
                data={deviceData}
                // components={{
                //     Toolbar: props => (
                //         <div style={{ minHeight: '0px', height: '0px' }}>
                //             <StyledMTableToolbar {...props} />
                //         </div>
                //     )
                // }}
                options={{
                    //toolbar : false,
                    grouping: false,
                    search: false,
                    exportAllData: true,
                    exportButton: true,
                    headerStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        color: "#698D8D",
                        backgroundColor: "#F1FCFC",
                        padding: "4px 12px",
                    },
                    cellStyle: {
                        fontSize: "12px",
                        fontFamily: "Karla",
                        fontWeight: "bold",
                        padding: "4px 12px",
                    },
                    rowStyle: {
                        height: "auto",
                    },
                    pageSize: 100,
                    maxBodyHeight: 'calc(100vh - 431px)',

                }}
            />
        </div>
    );
}
